import {
  FunctionComponentElement,
  JSX,
  ReactNode,
  isValidElement,
} from "react";

import { Dialog as ArkDialog, DialogDescriptionProps } from "@ark-ui/react";
import { css, cx } from "@mobsuccess-devops/styled-system/css";

type DescriptionProps = DialogDescriptionProps;

export function Description({
  asChild,
  ...props
}: DescriptionProps): JSX.Element {
  if (asChild) {
    return <ArkDialog.Description asChild {...props} />;
  }
  return (
    <ArkDialog.Description
      {...props}
      className={cx(
        css({
          gridArea: "description",
          textStyle: "muted",
          color: "interface",
          width: "calc(660px - 32px)",
        }),
        props.className,
      )}
    />
  );
}

export type DescriptionElement = FunctionComponentElement<DescriptionProps>;

export function isDescriptionElement(
  node: ReactNode,
): node is DescriptionElement {
  return isValidElement(node) && node.type === Description;
}
