import { memo, useCallback, useMemo, JSX } from "react";

import styled, { css } from "styled-components";

import {
  isSameDate,
  useDatePickerSingleDayContext,
} from "../../../../../features/date-picker";
import { Container } from "../../../../Container";

type CardDayUIProps = {
  $isDisabled: boolean;
  $isToday: boolean;
  $isSelected: boolean;
};

const disabledCss = css`
  color: ${({ theme }) => theme.palette.interface.lighter[400]};
  cursor: not-allowed;
  &:hover {
    color: ${({ theme }) => theme.palette.interface.lighter[200]};
  }
`;

const enabledCss = css`
  cursor: pointer;
`;

const todayCss = css`
  color: ${({ theme }) => theme.palette.secondary.darker[300]};
  background-color: ${({ theme }) => theme.palette.secondary.lighter[400]};
  font-weight: 600;
  font-size: 12px;
  border-radius: 4px;
`;

const highlightCss = css<CardDayUIProps>`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.secondary.base};
`;

const CardDayUI = styled(Container)<CardDayUIProps>`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.interface.base};
  &:hover {
    background-color: ${({ theme, $isToday, $isSelected }) =>
      !$isToday && !$isSelected && theme.palette.interface.lighter[700]};
  }
  ${({ $isDisabled }) => ($isDisabled ? disabledCss : enabledCss)}
  ${({ $isSelected, $isDisabled }) =>
    $isSelected && !$isDisabled && highlightCss}
  ${({ $isToday, $isDisabled }) => $isToday && !$isDisabled && todayCss}
`;
CardDayUI.displayName = "CardDayUI";

type CardDayProps = {
  day: {
    date: Date;
    isDisabled: boolean;
  };
  onClick?: (data: Date) => void;
};

function CardDay({ day, onClick }: CardDayProps): JSX.Element {
  const { selectedDate } = useDatePickerSingleDayContext();

  const handleClick = useCallback(() => {
    if (day.isDisabled) {
      return;
    }
    onClick?.(day.date);
  }, [onClick, day]);

  const isSelectedDate = useMemo(() => {
    if (!selectedDate) {
      return false;
    }
    return selectedDate ? isSameDate(selectedDate, day.date) : false;
  }, [day.date, selectedDate]);

  return (
    <CardDayUI
      $isDisabled={day.isDisabled}
      $isToday={isSameDate(day.date, new Date())}
      $isSelected={isSelectedDate}
      onClick={handleClick}
      justifyContent="center"
      alignItems="center"
      height="40px"
      width="40px"
    >
      {day.date.getDate()}
    </CardDayUI>
  );
}

const MemoizedComponent = memo(CardDay);
export { MemoizedComponent as CardDay };
