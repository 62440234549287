import { isValidElement, ReactNode, JSXElementConstructor } from "react";

export function isInstanceOfComponents(
  element: ReactNode,
  ...components: Array<JSXElementConstructor<never> | string>
): boolean {
  if (!isValidElement(element)) {
    return false;
  }
  return components.includes(element.type);
}
