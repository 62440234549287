import {
  memo,
  ChangeEventHandler,
  useState,
  useEffect,
  useMemo,
  useCallback,
  KeyboardEventHandler,
  JSX,
} from "react";

import { ReactComponent as CalendarIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/21-Date_Calendar/calendar.svg";

import styled from "styled-components";

import {
  DatePickerSingleDayContext,
  getPreviousAndNextMonth,
  useParsedDate,
} from "../../../features/date-picker";
import { DatePickerSingleDayContextValue } from "../../../types/date-picker-single-day";
import { Container } from "../../Container";
import { Dropdown, DropdownProps } from "../../Dropdown";
import { AdvancedInput } from "../AdvancedInput/AdvancedInput";

import { Header } from "./Header/Header";
import { Month } from "./Month/Month";

type InputUIProps = {
  $customVariant: "md" | "lg";
  $customBorderStyle?: "standard" | "outlined" | "textBackground";
};

const InputUI = styled(AdvancedInput)<InputUIProps>`
  width: ${({ $customVariant }) =>
    $customVariant === "md" ? "200px" : "226px"};
  background-color: ${({ theme }) => theme.palette.white};
`;

InputUI.displayName = "InputUI";

const ClockContainerUI = styled(Container)<InputUIProps>`
  ${({ $customVariant, $customBorderStyle }) =>
    $customBorderStyle === "outlined" && $customVariant === "md"
      ? `
    margin-left: 12px;
    margin-right: 8px;
  `
      : $customBorderStyle === "outlined" && $customVariant === "lg"
        ? `
    margin-left: 16px;
    margin-right: 8px;
  `
        : $customBorderStyle !== "outlined" && $customVariant === "md"
          ? `
    margin-left: 12px;
    margin-right: -4px;
  `
          : `
    margin-left: 16px;
    margin-right: -8px;
  `}
`;

ClockContainerUI.displayName = "ClockContainerUI";

const ClockIconUI = styled(CalendarIcon)<InputUIProps>`
  width: ${({ $customVariant }) => ($customVariant === "md" ? "16px" : "20px")};
  height: ${({ $customVariant }) =>
    $customVariant === "md" ? "16px" : "20px"};
  color: ${({ theme }) => theme.palette.interface.base};
`;

ClockIconUI.displayName = "ClockIconUI";

export type DatePickerSingleDayProps = {
  className?: string;
  value?: Date;
  locale: string;
  variant?: "md" | "lg";
  allowedDate?: DatePickerSingleDayContextValue["allowedDate"];
  onChange: (value?: DatePickerSingleDayContextValue["selectedDate"]) => void;
  anchorElement?: HTMLElement;
  anchorOrigin?: DropdownProps["anchorOrigin"];
  dropdownWidth?: string;
  borderStyle?: "standard" | "outlined" | "textBackground";
  isDisabled?: boolean;
};

const defaultAllowedDate: NonNullable<DatePickerSingleDayProps["allowedDate"]> =
  {
    start: new Date(2017, 0),
    end: new Date(2027, 0, 1),
  };
const defaultDate = new Date();

function DatePickerSingleDay({
  className,
  value: currentDate = defaultDate,
  variant = "md",
  locale,
  allowedDate = defaultAllowedDate,
  onChange,
  anchorElement,
  anchorOrigin = "bottom-left",
  dropdownWidth = "fit-content",
  borderStyle = "standard",
  isDisabled,
}: DatePickerSingleDayProps): JSX.Element {
  const [datePickerElement, setDatePickerElement] =
    useState<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState<string | undefined>(() =>
    currentDate ? currentDate.toLocaleFormat() : undefined,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(currentDate ? currentDate.toLocaleFormat() : undefined);
  }, [currentDate]);

  const parseDate = useParsedDate(locale, currentDate);

  const handleMouseDown = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
    },
    [],
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === "Escape") {
        e.currentTarget.blur();
      }
    },
    [],
  );

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setInputValue(e.target.value);
      onChange(parseDate(e.target.value)?.date);
    },
    [onChange, parseDate],
  );

  const [months, setMonths] = useState<
    DatePickerSingleDayContextValue["months"]
  >(() => {
    const today = new Date();
    const currentMonth = {
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    const [, next] = getPreviousAndNextMonth(currentMonth);
    return [currentMonth, next];
  });

  const handleMonths = useCallback<
    DatePickerSingleDayContextValue["setMonths"]
  >((date) => setMonths(date), []);

  useEffect(() => {
    setMonths([
      { month: currentDate.getMonth(), year: currentDate.getFullYear() },
      { month: currentDate.getMonth() + 1, year: currentDate.getFullYear() },
    ]);
  }, [currentDate]);

  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null,
  );

  const contextValue = useMemo<DatePickerSingleDayContextValue>(() => {
    return {
      allowedDate,
      months,
      setMonths: handleMonths,
      selectedDate: currentDate,
      setSelectedDate: (date) => {
        onChange(date);
        inputElement?.blur();
      },
    };
  }, [allowedDate, months, handleMonths, currentDate, onChange, inputElement]);

  useEffect(() => {
    if (!datePickerElement) {
      return;
    }
    const focusChange = (e: FocusEvent): void => {
      if (e.target !== inputElement) {
        return inputElement?.focus();
      }
      setIsOpen(e.type === "focusin");
      setInputValue(undefined);
    };
    datePickerElement.addEventListener("focusin", focusChange);
    datePickerElement.addEventListener("focusout", focusChange);
    return () => {
      datePickerElement.removeEventListener("focusin", focusChange);
      datePickerElement.removeEventListener("focusout", focusChange);
    };
  }, [datePickerElement, inputElement]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [start] = months;
  return (
    <DatePickerSingleDayContext.Provider value={contextValue}>
      <Container ref={setDatePickerElement}>
        <InputUI
          ref={setInputElement}
          placeholder="date"
          value={inputValue ?? currentDate?.toLocaleFormat()}
          containerRef={setAnchorEl}
          onChange={handleSearch}
          variant={variant}
          $customVariant={variant}
          onKeyDown={handleKeyDown}
          borderStyle={borderStyle}
          isDisabled={isDisabled}
          $customBorderStyle={borderStyle}
          className={className}
        >
          <ClockContainerUI
            $customVariant={variant}
            $customBorderStyle={borderStyle}
          >
            <ClockIconUI $customVariant={variant} />
          </ClockContainerUI>
        </InputUI>
        <Dropdown
          isOpen={isOpen}
          anchorEl={anchorElement ?? anchorEl}
          anchorOrigin={anchorOrigin}
          width={dropdownWidth}
        >
          <Container
            flexDirection="column"
            justifyContent="center"
            onMouseDown={handleMouseDown}
          >
            <Container flexDirection="column" justifyContent="center">
              <Header />
              <Month key={`${start.month}-${start.year}`} month={start} />
            </Container>
          </Container>
        </Dropdown>
      </Container>
    </DatePickerSingleDayContext.Provider>
  );
}

const MemoizedDatePickerSingleDay = memo(DatePickerSingleDay);
export { MemoizedDatePickerSingleDay as DatePickerSingleDay };
