import {
  ReactNode,
  JSX,
  isValidElement,
  FunctionComponentElement,
  useCallback,
} from "react";

import { PaginationPageChangeDetails } from "@ark-ui/react";
import { Table } from "@tanstack/react-table";

import { Pagination } from "../../Pagination/Pagination";

export type TablePaginatorProps<T> = {
  /** @internal */
  _table?: Table<T>;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export function TablePaginator<T>({
  _table: table,
}: TablePaginatorProps<T>): JSX.Element {
  if (!table) {
    throw new Error("TablePaginator must be a child of Table");
  }

  const handlePageChange = useCallback(
    (details: PaginationPageChangeDetails) => {
      table.setPagination((prev) => ({
        ...prev,
        pageIndex: details.page - 1,
        pageSize: details.pageSize,
      }));
    },
    [table],
  );

  return (
    <Pagination.Root
      page={table.getState().pagination.pageIndex + 1}
      pageSize={table.getState().pagination.pageSize}
      count={table.getRowCount()}
      onPageChange={handlePageChange}
    />
  );
}

export type TablePaginatorElement<T> = FunctionComponentElement<
  TablePaginatorProps<T>
>;
export function isTablePaginatorElement<T>(
  node: ReactNode,
): node is TablePaginatorElement<T> {
  return isValidElement(node) && node.type === TablePaginator<T>;
}
