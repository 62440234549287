import { cva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";
import { stack } from "@mobsuccess-devops/styled-system/patterns";

export const card = cva({
  base: {
    ...stack.raw(),
    padding: "lg",
    bg: "white",
    borderRadius: "lg",
  },
});

export const Card = styled("div", card);
