// this file has been generated automatically, do not modify it
import { forwardRef, type ForwardedRef, type SVGProps, type JSX } from "react";

import { styled } from "@mobsuccess-devops/styled-system/jsx";

import { ReactComponent as Be } from "./be.svg";
import { ReactComponent as Fr } from "./fr.svg";

type ComponentProps = SVGProps<SVGSVGElement> & {
  variant: "fr" | "be";
} & { title?: string };

function Component(
  { variant, children: _children, ...props }: ComponentProps,
  ref: ForwardedRef<SVGSVGElement>,
): JSX.Element | null {
  switch (variant) {
    case "fr":
      return <Fr {...props} ref={ref} />;
    case "be":
      return <Be {...props} ref={ref} />;
    default:
      return null;
  }
}

export const Flag = Object.assign(styled(forwardRef(Component)), {
  variants: ["fr", "be"],
});
