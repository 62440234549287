import {
  createAppRoot,
  getBusinessUnit,
} from "@mobsuccess-devops/react-shared";

import { entrypoint } from "./features/constants/entrypoint";
import "./features/sentry";
import "./index.css";
import { unregister } from "./serviceWorker";

createAppRoot({
  businessUnit: getBusinessUnit(),
  entrypoint,
  lingui: {
    defaultLanguage: "fr",
    locales: import.meta.glob("./locales/!(*.d).ts", { eager: true }),
  },
  auth: {
    userPoolsId:
      window._msUserPool?.userPoolsId ??
      import.meta.env.VITE_COGNITO_USER_POOL_ID,
    userPoolsWebClientId:
      window._msUserPool?.userPoolsWebClientId ??
      import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID,
    googleClientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    googleSignupUrl: new URL(
      "/google_sign_in.php",
      import.meta.env.VITE_PLATFORM_ENDPOINT,
    ),
    forgotPasswordUrl: new URL(
      "/password-lost",
      import.meta.env.VITE_PLATFORM_ENDPOINT,
    ),
  },
  data: {
    host: window._msApiEndpoint ?? import.meta.env.VITE_MS_API_ENDPOINT,
    environment: import.meta.env.VITE_MS_ENVIRONEMENT,
    services: {
      rdc: import.meta.env.VITE_DATA_CONTEXT_SERVICE_RDC,
      lcm: import.meta.env.VITE_DATA_CONTEXT_SERVICE_LCM,
      lco: import.meta.env.VITE_DATA_CONTEXT_SERVICE_LCO,
    },
  },
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
