import {
  ForwardedRef,
  memo,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  JSX,
} from "react";

import styled, { useTheme } from "styled-components";

import { NativeProps } from "../../types/native-props";
import { Container } from "../Container";
import { PaletteColorsKeys } from "../Theme/theme";
import { Typography } from "../Typography";
import { Dot } from "../_PandaArk/Badge/Dot/Dot";

import { Cross } from "./Cross/Cross";

type BadgeUIProps = {
  $size: "sm" | "md" | "lg" | "xl";
  $type: "dark" | "light" | "transparent";
  $isChildrenEmpty: boolean;
  $isDot?: boolean;
  $background: PaletteColorsKeys | string;
};

const BadgeUI = styled.div<BadgeUIProps>`
  display: inline-flex;
  background: ${({ $type, $background, theme }) =>
    $type === "transparent"
      ? "transparent"
      : theme.palette && $background in theme.palette
        ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          theme.palette[$background as PaletteColorsKeys].lighter[
            $type === "light" ? 700 : 100
          ]
        : $background};
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: all ${({ theme }) => theme.animation.short} ease-in-out;
  ${({ $size, $isChildrenEmpty }) =>
    $size === "sm" && !$isChildrenEmpty && "padding: 2px 10px;"}
  ${({ $size, $isChildrenEmpty }) =>
    $size === "md" && !$isChildrenEmpty && "padding: 2px 12px;"}
  ${({ $size, $isChildrenEmpty }) =>
    $size === "lg" && !$isChildrenEmpty && "padding: 2px 12px;"}
  ${({ $size, $isChildrenEmpty }) =>
    $size === "xl" && !$isChildrenEmpty && "padding: 4px 16px;"}

  ${({ $isChildrenEmpty, $isDot, $size }) =>
    $isChildrenEmpty &&
    $isDot &&
    $size === "sm" &&
    "width: 20px; height: 20px;"}
  ${({ $isChildrenEmpty, $isDot, $size }) =>
    $isChildrenEmpty &&
    $isDot &&
    $size === "md" &&
    "width: 24px; height: 24px;"}
  ${({ $isChildrenEmpty, $isDot, $size }) =>
    $isChildrenEmpty &&
    $isDot &&
    $size === "lg" &&
    "width: 28px; height: 28px;"}
  ${({ $isChildrenEmpty, $isDot, $size }) =>
    $isChildrenEmpty &&
    $isDot &&
    $size === "xl" &&
    "width: 40px; height: 40px;"}

  &:hover {
    background: ${({ $type, $background, theme }) =>
      theme.palette && $background in theme.palette
        ? $type === "light"
          ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            theme.palette[$background as PaletteColorsKeys].lighter[600]
          : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            theme.palette[$background as PaletteColorsKeys].base
        : $background};
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

BadgeUI.displayName = "BadgeUI";

type IconUIProps = {
  $size: "sm" | "md" | "lg" | "xl";
  $type: "dark" | "light" | "transparent";
  $color?: string;
};

const IconUI = styled(Container)<IconUIProps>`
  & svg {
    color: ${({ $type, $color, theme }) =>
      $type === "light" && theme.palette && $color && $color in theme.palette
        ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          theme.palette[$color as PaletteColorsKeys].base
        : theme.palette.white};
    width: ${({ $size }) =>
      $size === "xl"
        ? "20px"
        : $size === "lg"
          ? "18px"
          : $size === "md"
            ? "16px"
            : "14px"};
    height: ${({ $size }) =>
      $size === "xl"
        ? "20px"
        : $size === "lg"
          ? "18px"
          : $size === "md"
            ? "16px"
            : "14px"};
  }
`;

IconUI.displayName = "IconUI";

type BadgeProps = {
  size?: "sm" | "md" | "lg" | "xl";
  variant?: "light" | "dark";
  content?: string;
  background?: PaletteColorsKeys;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  dot?: boolean;
  cross?: boolean;
  onClickCross?: MouseEventHandler<HTMLDivElement>;
} & NativeProps<"div">;

function defaultOnClickCross(): null {
  return null;
}

function Badge(
  {
    id,
    className,
    content,
    size = "md",
    variant = "light",
    background = "primary",
    leadingIcon = null,
    trailingIcon = null,
    dot = false,
    cross = false,
    onClickCross = defaultOnClickCross,
    ...intrinsicProps
  }: BadgeProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const theme = useTheme();
  return (
    <BadgeUI
      id={id}
      className={className}
      $size={size}
      $type={variant}
      $background={background}
      $isChildrenEmpty={!content}
      $isDot={dot}
      ref={ref}
      {...intrinsicProps}
    >
      {leadingIcon && (
        <IconUI $size={size} $type={variant} $color={background}>
          {leadingIcon}
        </IconUI>
      )}
      {dot && (
        <Dot
          variant={variant}
          background={background}
          size={size}
          isChildrenEmpty={!content}
        />
      )}
      <Typography
        as="span"
        variant={`text-${size}${size === "sm" || size === "xl" ? `-bold` : ``}`}
        color={
          variant === "light" && theme.palette && background in theme.palette
            ? theme.palette[background].base
            : theme.palette.white
        }
        title={content}
      >
        {content}
      </Typography>
      {cross && (
        <Cross
          onClick={onClickCross}
          variant={variant}
          background={background}
          size={size}
        />
      )}
      {trailingIcon && (
        <IconUI $size={size} $type={variant} $color={background}>
          {trailingIcon}
        </IconUI>
      )}
    </BadgeUI>
  );
}

const MemoizedBadge = memo(forwardRef(Badge));
export { MemoizedBadge as Badge };
