import axios, { AxiosInstance, AxiosResponse } from "axios";

async function response(
  this: AxiosInstance,
  response: AxiosResponse,
): Promise<AxiosResponse> {
  if (!("x-body-cache-key" in response.headers)) {
    return response;
  }
  const url = response.headers["x-body-cache-key"];
  const newResponse = await (this ?? axios).get(url, {
    responseType: response.config.responseType,
  });
  newResponse.headers = {
    ...response.headers,
    ...newResponse.headers,
  };
  return newResponse;
}

export const redirect = {
  response,
};
