/// <reference types="../../../types/table.types.d.ts" />
import {
  Children,
  type ForwardedRef,
  type JSX,
  type ReactNode,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";

import { sva, cx, css } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";
import {
  Row,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import {
  TableCaption,
  TableArranger,
  TableFilters,
  TablePaginator,
  TableVirtualizer,
  splitTableDecorators,
  TableHeaderContent,
} from "./Decorators";
import { isTablePaginatorElement } from "./Decorators/Paginator";
import { isTableVirtualizerElement } from "./Decorators/Virtualizer";
import { TableLayout } from "./TableLayout";
import type { TableConfig } from "./createTable";

export type TableRootProps<T extends object> = {
  data: T[];
  className?: string;
  children?: ReactNode;
  config: TableConfig<T>;
  height?: number | string;
  onRowClick?: (row: Row<T>) => void;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function TableRoot<T extends object>(
  { data, config, children, className, onRowClick }: TableRootProps<T>,
  ref: ForwardedRef<HTMLTableElement | null>,
): JSX.Element {
  const [tableElement, setTableElement] = useState<HTMLTableElement | null>(
    null,
  );
  const [virtualContainerElement, setVirtualContainerElement] =
    useState<HTMLDivElement | null>(null);
  useImperativeHandle<HTMLTableElement | null, HTMLTableElement | null>(
    ref,
    () => tableElement,
  );
  const isPaginated = Children.toArray(children).some((child) =>
    isTablePaginatorElement(child),
  );

  const virtualizeElement = Children.toArray(children).find(
    isTableVirtualizerElement,
  );

  const table = useReactTable({
    columnResizeMode: "onChange",
    getPaginationRowModel: isPaginated ? getPaginationRowModel() : undefined,
    data,
    ...config,
  });

  const classes = styles({
    isVirtualized: !!virtualizeElement,
    isPaginated: isPaginated,
  });

  const [decorators] = splitTableDecorators(children, table);

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 75,
    getScrollElement: () => virtualContainerElement,
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 20,
  });

  const virtualContainerStyle = {
    height: virtualizeElement?.props.height,
  };

  return (
    <styled.div size="full" className={cx(classes.root, className)}>
      <styled.div
        className={cx(
          classes.controls,
          Object.keys(decorators).length ? null : css({ gap: "sm" }),
        )}
      >
        {decorators.caption}
        {decorators.arranger}
        {decorators.filtering}
        {decorators.headerContent}
      </styled.div>
      <div
        className={classes["virtual:container"]}
        ref={setVirtualContainerElement}
        style={virtualContainerStyle}
      >
        <TableLayout
          table={table}
          ref={setTableElement}
          onRowClick={onRowClick}
          className={classes.table}
          virtualizer={virtualizeElement ? virtualizer : undefined}
        />
      </div>
      {decorators.pagination && (
        <styled.div
          center
          height="fit-content"
          width="full"
          padding="md"
          backgroundColor="white"
        >
          {decorators.pagination}
        </styled.div>
      )}
    </styled.div>
  );
}

const styles = sva({
  slots: [
    "root",
    "controls",
    "filtering",
    "virtual:container",
    "table",
    "pagination",
  ],
  base: {
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      borderRadius: "md",
      overflow: "hidden",
      backgroundColor: "white",
      shadows: "sm interface.200",
    },
    filtering: {
      position: "relative",
      height: 0,
    },
    controls: {
      position: "relative",
      _empty: {
        display: "none",
      },
      elevation: "header",
      backgroundColor: "interface.200",
      padding: "md",
      alignItems: "center",
      display: "grid",
      gridTemplateColumns: "400px 1fr min-content",
      gridTemplateRows: "auto fit-content",
      gridTemplateAreas: `"caption . arranger"
                          "global-filter filter-state clear"
                          "header-content header-content header-content"`,
      width: "full",
    },
    "virtual:container": {
      height: "fit-content",
      width: "full",
    },
    table: {
      color: "interface",
      display: "grid",
      justifyContent: "start",
      gridTemplateColumns: "1fr",
    },
  },
  variants: {
    isVirtualized: {
      true: {
        table: {
          color: "interface",
          display: "grid",
          justifyContent: "start",
        },
        "virtual:container": {
          position: "relative",
          display: "block",
          overflow: "auto",
        },
      },
      false: {
        table: {
          overflow: "auto",
        },
      },
    },
    isPaginated: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isVirtualized: false,
      isPaginated: false,
      css: {
        table: {
          height: "fit-content",
        },
      },
    },
    {
      isVirtualized: false,
      isPaginated: true,
      css: {
        table: {
          height: "fit-content",
        },
      },
    },
  ],
  defaultVariants: {
    isVirtualized: false,
    isPaginated: false,
  },
});

export const Table = {
  Root: forwardRef(TableRoot),
  Caption: TableCaption,
  Arranger: TableArranger,
  Filters: TableFilters,
  Paginator: TablePaginator,
  Virtualizer: TableVirtualizer,
  HeaderContent: TableHeaderContent,
};
