import { memo, MouseEventHandler, JSX } from "react";

import { ReactComponent as CloseIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";

import styled, { css, useTheme } from "styled-components";

import { Container } from "../../Container";
import { Typography } from "../../Typography";

const HeadlineUI = styled(Container)`
  & > div > svg {
    width: 12px;
    height: 12px;
    color: ${({ theme }) => theme.palette.interface.lighter[300]};
    margin-left: 10px;
    cursor: pointer;
  }
`;
HeadlineUI.displayName = "HeadlineUI";

const typographyCss = css`
  max-width: 290px;
`;
type HeadlineProps = {
  children: string;
  variant: "success" | "warning" | "danger" | "info";
  onClickRemove: MouseEventHandler<HTMLDivElement>;
};

function Headline({
  children,
  variant,
  onClickRemove,
}: HeadlineProps): JSX.Element {
  const theme = useTheme();

  return (
    <HeadlineUI flexDirection="row" justifyContent="space-between">
      <Typography
        as="span"
        variant="text-lg"
        color={theme.palette[variant].base}
        styled={typographyCss}
      >
        {children}
      </Typography>
      <div onClick={onClickRemove}>
        <CloseIcon title="Fermeture de la notification" />
      </div>
    </HeadlineUI>
  );
}

const MemoizedComponent = memo(Headline);
export { MemoizedComponent as Headline };
