import {
  ChangeEvent,
  ElementType,
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  JSX,
} from "react";

import styled, { useTheme, css } from "styled-components";

import { Container } from "../../Container";
import { Typography } from "../../Typography";

type InputUIProps = {
  $size?: "md" | "lg" | "xl";
  $error?: boolean;
  $filled?: boolean;
  $isMinimalist?: boolean;
  $disabled?: boolean;
  $position?: string;
};

const CustomInputUI = styled(Container)<InputUIProps>`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  height: ${({ $size }) =>
    $size === "md" ? "40px" : $size === "lg" ? "48px" : "56px"};
  background-color: ${({ theme }) => theme.palette.white};
  border: 1px solid
    ${({ theme, $error, $isMinimalist }) =>
      $error
        ? theme.palette.danger.base
        : $isMinimalist
          ? "transparent"
          : theme.palette.interface.lighter[200]};
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;

  cursor: ${({ $disabled }) => $disabled && "none"};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

  ${({ $disabled, $isMinimalist, theme }) =>
    $disabled &&
    $isMinimalist &&
    `background-color: ${theme.palette.interface.lighter[700]}`}

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.palette.primary.lighter[200]};
    box-shadow: 0px 0px 0px 4px
      ${({ theme }) => `${theme.palette.primary.base}4C`};
  }

  &:hover {
    ${({ theme, $disabled, $error }) =>
      !$disabled &&
      !$error &&
      `
    border-color: ${theme.palette.interface.lighter[100]};
  `}
  }
`;

CustomInputUI.displayName = "CustomInputUI";

const InputUI = styled.input<InputUIProps>`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  padding: ${({ $position }) => ($position === "leading" ? "0" : "0 16px")};
  color: ${({ theme, $error }) =>
    $error ? theme.palette.danger.base : theme.palette.interface.base};
  font-weight: 400;
  font-size: ${({ theme, $size }) =>
    $size ? theme.typography.fontSize[$size] : undefined};
  line-height: ${({ theme, $size }) =>
    $size ? theme.typography.lineHeight[$size] : undefined};
  outline: none;

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme, $error }) =>
      $error
        ? theme.palette.danger.base
        : theme.palette.interface.lighter[200]};
  }
`;

InputUI.displayName = "InputUI";

const TrailingTextUI = styled(Container)<InputUIProps>`
  margin-left: 16px;
  border-radius: 0 6px 6px 0;
  background: ${({ theme }) => theme.palette.interface.lighter[700]};
  border-left: 1px solid
    ${({ theme, $error }) =>
      $error
        ? theme.palette.danger.base
        : theme.palette.interface.lighter[200]};
  max-width: 75px;
`;

TrailingTextUI.displayName = "TrailingTextUI";

const LeadingTextUI = styled(Container)<InputUIProps>`
  border-radius: 6px 0 0 6px;
  background: ${({ theme }) => theme.palette.interface.lighter[700]};
  border-right: 1px solid
    ${({ theme, $error }) =>
      $error
        ? theme.palette.danger.base
        : theme.palette.interface.lighter[200]};
  max-width: 75px;
  cursor: auto;
`;

LeadingTextUI.displayName = "LeadingTextUI";

const IconUI = styled(Container)<InputUIProps>`
  margin-right: 16px;

  & svg {
    color: ${({ theme, $error }) =>
      $error
        ? theme.palette.danger.base
        : theme.palette.interface.lighter[200]};
    width: ${({ $size }) => ($size === "xl" ? "24px" : "20px")};
    height: ${({ $size }) => ($size === "xl" ? "24px" : "20px")};
  }
`;

IconUI.displayName = "IconUI";

const marginTopCss = css`
  margin-top: 8px;
`;

const marginBottomCss = css`
  margin-bottom: 8px;
`;

const marginDescrCss = css`
  margin: 3px 0 0 4px;
`;

export type InputProps = {
  id?: string;
  className?: string;
  label?: string;
  labelDescription?: string;
  type: "text" | "number";
  placeholder?: string;
  size?: "md" | "lg" | "xl";
  icon?: "without" | "leading" | "trailing";
  RightIcon?: ElementType;
  LeftIcon?: ElementType;
  error?: boolean;
  isMinimalist?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onBlur?: () => void;
  caption?: string;
  onChange: (value: string) => void;
  text?: string;
  textPosition?: "without" | "leading" | "trailing";
  defaultValue?: string;
  maxLength?: number;
  isReadOnly?: boolean;
};

function defaultOnClick(): null {
  return null;
}
function defaultOnBlur(): null {
  return null;
}
function defaultOnChange(): null {
  return null;
}

/**
 *
 * @deprecated Use AdvancedInput instead
 */
function Input(
  {
    label = "",
    placeholder = "",
    size = "md",
    icon = "without",
    type = "text",
    error = false,
    isMinimalist = false,
    disabled = false,
    caption = "",
    text = "",
    textPosition = "without",
    defaultValue = "",
    isReadOnly = false,
    id,
    className,
    labelDescription,
    RightIcon,
    LeftIcon,
    onClick = defaultOnClick,
    onBlur = defaultOnBlur,
    onChange = defaultOnChange,
    maxLength,
  }: InputProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const theme = useTheme();

  const [valueState, setValueState] = useState(
    defaultValue ? defaultValue : "",
  );

  const defaultValueRef = useRef(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setValueState(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    defaultValueRef.current = defaultValue;
  }, [defaultValue]);

  useEffect(() => {
    setValueState(defaultValueRef.current ?? "");
  }, [id]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setValueState(event.target.value);
    },
    [setValueState],
  );

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      onChange(valueState);
    } else {
      mounted.current = true;
    }
  }, [onChange, valueState]);

  const filled = useMemo(
    () => !!valueState && !isMinimalist,
    [valueState, isMinimalist],
  );

  return (
    <Container flexDirection="column" className={className} ref={ref}>
      <Container>
        {label && (
          <Typography
            as="p"
            styled={marginBottomCss}
            variant="text-md-regular"
            color={theme.palette.interface.lighter[100]}
          >
            {label}
          </Typography>
        )}
        {labelDescription && (
          <Typography
            styled={marginDescrCss}
            as="p"
            variant="text-sm"
            color={theme.palette.interface.lighter[100]}
          >
            {`(${labelDescription})`}
          </Typography>
        )}
      </Container>
      <CustomInputUI
        width="100%"
        alignItems="center"
        justifyContent="flex-start"
        padding={icon === "leading" ? "0 16px" : "0"}
        $size={size}
        $error={error}
        $filled={filled}
        $disabled={disabled}
        $isMinimalist={isMinimalist}
      >
        {text && textPosition === "leading" && (
          <LeadingTextUI
            height="100%"
            alignItems="center"
            padding="0 16px"
            overflow="hidden"
            $error={error}
          >
            <Typography
              as="p"
              variant="text-md-regular"
              color={theme.palette.interface.lighter[100]}
            >
              {text}
            </Typography>
          </LeadingTextUI>
        )}
        {icon === "leading" && LeftIcon && (
          <IconUI $error={error} $size={size}>
            <LeftIcon />
          </IconUI>
        )}
        <InputUI
          id={id}
          $error={error}
          $size={size}
          disabled={disabled}
          $isMinimalist={isMinimalist}
          type={type}
          value={valueState}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          $position={icon}
          maxLength={maxLength}
          readOnly={isReadOnly}
          autoFocus={false}
          spellCheck={false}
        />
        {icon === "trailing" && RightIcon && (
          <IconUI $error={error} $size={size}>
            <RightIcon />
          </IconUI>
        )}
        {text && textPosition === "trailing" && (
          <TrailingTextUI
            height="100%"
            alignItems="center"
            padding="0 16px"
            overflow="hidden"
            onClick={onClick}
            $error={error}
          >
            <Typography
              as="p"
              variant="text-md-regular"
              color={theme.palette.interface.lighter[100]}
            >
              {text}
            </Typography>
          </TrailingTextUI>
        )}
      </CustomInputUI>
      {caption && (
        <Typography
          as="p"
          styled={marginTopCss}
          variant="text-sm-regular"
          color={
            error ? theme.palette.danger.base : theme.palette.interface.base
          }
        >
          {caption}
        </Typography>
      )}
    </Container>
  );
}

const MemoizedInput = memo(forwardRef(Input));
export { MemoizedInput as Input };
