import type { JSX } from "react";

import { usePaginationContext } from "@ark-ui/react";
import { sva } from "@mobsuccess-devops/styled-system/css";

import { useLocales } from "../../../features/localization";
import { Typo } from "../../Typo/index";
import { Select } from "../Select/Select";

export type PageSizeSelectorProps = {
  sizes?: number[];
};

const defaultPageSizesOptions = [10, 20, 50, 100];

export function PageSizeSelector({
  sizes = defaultPageSizesOptions,
}: PageSizeSelectorProps): JSX.Element {
  const classes = styles();
  const api = usePaginationContext();
  const locales = useLocales();

  return (
    <div className={classes.root}>
      <Typo.Muted className={classes.label}>
        {locales.pagination.pageSizeLabel} :
      </Typo.Muted>
      <Select.Root
        // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react -- this wont cause rerenders as ark uses behind the scenes it uses vanilla js
        positioning={{ placement: "top" }}
        // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react -- this wont cause rerenders as ark uses behind the scenes it uses vanilla js
        value={[api.pageSize.toString()]}
        items={sizes.map((size) => ({
          label: locales.pagination.pageSizeOptions(size),
          value: size.toString(),
        }))}
        // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react -- this wont cause rerenders as ark uses behind the scenes it uses vanilla js
        onValueChange={(details) => {
          const [value] = details.value.map(Number);
          api.setPageSize(value);
        }}
      />
    </div>
  );
}

const styles = sva({
  slots: ["root", "label"],
  base: {
    root: {
      display: "flex",
      gap: "sm",
      alignItems: "center",
    },
    label: {
      whiteSpace: "nowrap",
    },
  },
});
