import {
  Children,
  cloneElement,
  createRef,
  RefAttributes,
  RefObject,
  useEffect,
  useMemo,
  JSX,
} from "react";

export function useSingleChildWithRef<T = Element>(
  children: JSX.Element,
): JSX.Element & { ref: RefObject<T> } {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const child = Children.only(children) as JSX.Element & RefAttributes<T>;
  const ref = useMemo(() => {
    if (child.ref) {
      if (typeof child.ref === "function") {
        return createRef<T>();
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return child.ref as RefObject<T>;
    }
    return createRef<T>();
  }, [child.ref]);

  useEffect(() => {
    if (typeof child.ref === "function") {
      child.ref(ref.current);
    }
  }, [child, ref]);

  return useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return cloneElement(child, { ref }) as JSX.Element & { ref: RefObject<T> };
  }, [child, ref]);
}
