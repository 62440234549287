import { memo, useEffect, useState, JSX } from "react";

import { ReactComponent as CheckIcon } from "@mobsuccess-devops/streamline/custom/react-ui/check-mark.svg";

import styled, { useTheme } from "styled-components";

import { Container } from "../Container";
import { getHorizontalPosition, animations } from "../Toaster/Toaster";
import { Typography } from "../Typography";

type SnackbarUIProps = {
  $background: string;
  $position: "right" | "left";
  $isRemoved: boolean;
};

const SnackbarUI = styled(Container)<SnackbarUIProps>`
  z-index: 1100;
  width: fit-content;
  border-radius: 8px;
  align-self: ${({ $position }) =>
    $position === "left" ? "flex-start" : "flex-end"};
  background-color: ${({ $background, theme }) =>
    $background === "dark"
      ? theme.palette.interface.darker[100]
      : $background === "light"
        ? theme.palette.white
        : "none"};
  border-radius: 8px;
  animation: ${({ $position }) => animations[$position].in}
    ${({ theme }) => theme.animation.short} ease-in-out;
  ${({ $isRemoved, $position }) => $isRemoved && animations[$position].out};
  box-shadow: ${({ theme }) => theme.shadows.medium};
`;

SnackbarUI.displayName = "SnackbarUI";

type SVGUIProps = {
  $background: string;
};
const SVGUI = styled(CheckIcon)<SVGUIProps>`
  width: 18px;
  height: 13px;
  margin-right: 12px;
  path {
    stroke: ${({ $background, theme }) =>
      $background === "dark"
        ? theme.palette.white
        : $background === "light"
          ? theme.palette.interface.darker[100]
          : "none"};
    stroke-width: 2px;
  }
`;

SVGUI.displayName = "SVGUI";

export type SnackbarProps = {
  uuid: string;
  background?: string;
  position?: string;
  showIcon?: boolean;
  title?: string;
  timeout?: number;
  onRemove(position: string, uuid: string): void;
  isRemoved: boolean;
  isFirstDisplay: boolean;
};

function Snackbar({
  uuid,
  timeout = 300,
  title = "",
  background = "",
  position = "",
  showIcon = false,
  onRemove,
}: SnackbarProps): JSX.Element {
  const theme = useTheme();
  const horizontal = getHorizontalPosition(position);
  const [removed, setRemoved] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemoved(true);
      setTimeout(() => {
        onRemove(position, uuid);
      }, 300);
    }, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, [onRemove, position, timeout, uuid]);
  return (
    <SnackbarUI
      alignItems="center"
      justifyContent="center"
      padding="8px 12px"
      overflow="hidden"
      $background={background}
      $position={horizontal}
      $isRemoved={removed}
    >
      {showIcon && <SVGUI $background={background} title="Check Icon" />}
      <Typography
        as="p"
        variant="text-md"
        color={
          background === "dark"
            ? theme.palette.white
            : background === "light"
              ? theme.palette.interface.base
              : "inherit"
        }
      >
        {title}
      </Typography>
    </SnackbarUI>
  );
}

const MemoizedComponent = memo(Snackbar);
export { MemoizedComponent as Snackbar };
