// this file has been generated automatically, do not modify it
import { forwardRef, type ForwardedRef, type SVGProps, type JSX } from "react";

import { styled } from "@mobsuccess-devops/styled-system/jsx";

import { ReactComponent as Bold } from "./bold.svg";
import { ReactComponent as Light } from "./light.svg";
import { ReactComponent as Regular } from "./regular.svg";

type ComponentProps = SVGProps<SVGSVGElement> & {
  variant: "regular" | "light" | "bold";
} & { title?: string };

function Component(
  { variant, children: _children, ...props }: ComponentProps,
  ref: ForwardedRef<SVGSVGElement>,
): JSX.Element | null {
  switch (variant) {
    case "regular":
      return <Regular {...props} ref={ref} />;
    case "light":
      return <Light {...props} ref={ref} />;
    case "bold":
      return <Bold {...props} ref={ref} />;
    default:
      return null;
  }
}

export const MeasureRulerDivider = Object.assign(
  styled(forwardRef(Component)),
  {
    variants: ["regular", "light", "bold"],
  },
);
