// this file has been generated automatically, do not modify it
import { forwardRef, type ForwardedRef, type SVGProps, type JSX } from "react";

import { styled } from "@mobsuccess-devops/styled-system/jsx";

import { ReactComponent as Custom } from "./custom.svg";
import { ReactComponent as Primary } from "./primary.svg";
import { ReactComponent as Provenance } from "./provenance.svg";
import { ReactComponent as Secondary } from "./secondary.svg";
import { ReactComponent as Target } from "./target.svg";
import { ReactComponent as Tertiary } from "./tertiary.svg";

type ComponentProps = SVGProps<SVGSVGElement> & {
  variant:
    | "tertiary"
    | "target"
    | "secondary"
    | "provenance"
    | "primary"
    | "custom";
} & { title?: string };

function Component(
  { variant, children: _children, ...props }: ComponentProps,
  ref: ForwardedRef<SVGSVGElement>,
): JSX.Element | null {
  switch (variant) {
    case "tertiary":
      return <Tertiary {...props} ref={ref} />;
    case "target":
      return <Target {...props} ref={ref} />;
    case "secondary":
      return <Secondary {...props} ref={ref} />;
    case "provenance":
      return <Provenance {...props} ref={ref} />;
    case "primary":
      return <Primary {...props} ref={ref} />;
    case "custom":
      return <Custom {...props} ref={ref} />;
    default:
      return null;
  }
}

export const Area = Object.assign(styled(forwardRef(Component)), {
  variants: [
    "tertiary",
    "target",
    "secondary",
    "provenance",
    "primary",
    "custom",
  ],
});
