/* eslint-disable @mobsuccess-devops/mobsuccess/assets */

/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import { JSX, type ReactNode } from "react";

import { Dialog, Portal, type DialogRootProps } from "@ark-ui/react";
import { ReactComponent as CloseIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";
import { cx, sva } from "@mobsuccess-devops/styled-system/css";
import { Box, HStack } from "@mobsuccess-devops/styled-system/jsx";
import { vstack, center } from "@mobsuccess-devops/styled-system/patterns";

import { IconButton } from "../../Button";
import { Typo } from "../../Typo";

import { ModalAction } from "./ModalAction";
import { ModalContent } from "./ModalContent";
import { ModalTrigger } from "./ModalTrigger";
import { splitModalComponents } from "./modal.utils";

export type ModalRootProps = Omit<DialogRootProps, "children"> & {
  className?: string;
  children: ReactNode;
  title: string;
};

function ModalRoot({
  className,
  children,
  title,
  ...rest
}: ModalRootProps): JSX.Element {
  const classes = styles();
  const { actions, content, trigger } = splitModalComponents(children);

  return (
    <Dialog.Root {...rest}>
      {trigger}
      <Portal>
        <Dialog.Backdrop className={classes.backdrop} />
        <Dialog.Positioner className={classes.positioner}>
          <Dialog.Content className={cx(classes.modal, className)}>
            <HStack className={classes.header}>
              <Typo.Title>{title}</Typo.Title>
              <Dialog.CloseTrigger asChild>
                <IconButton
                  aria-label="Close modal"
                  className={classes.closeTrigger}
                >
                  <CloseIcon />
                </IconButton>
              </Dialog.CloseTrigger>
            </HStack>
            <Box className={classes.content}>{content}</Box>
            <HStack className={classes.footer}>{actions}</HStack>
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
}

const styles = sva({
  slots: [
    "backdrop",
    "positioner",
    "modal",
    "header",
    "closeTrigger",
    "content",
    "footer",
  ],
  base: {
    backdrop: {
      ...vstack.raw(),
      position: "fixed",
      zIndex: "9998",
      inset: "0",
      backgroundColor: "black",
      opacity: "0.5",
    },
    positioner: {
      ...center.raw(),
      inset: "0",
      position: "fixed",
      zIndex: "9999",
      overflowY: "auto",
    },
    modal: {
      ...vstack.raw(),
      gap: "0",
      height: "90vh",
      width: "min(90vw, 1000px)",
      backgroundColor: "white",
      borderRadius: "md",
      overflow: "hidden",
      shadows: "xl rgba(35,43,82,0.02)",
    },
    header: {
      width: "full",
      justifyContent: "space-between",
      paddingY: "md",
      paddingX: "xl",
      shadow: "0 4px 12px rgba(0,0,0,0.04)",
      zIndex: "1",
    },
    closeTrigger: {
      "& svg": {
        color: "interface",
      },
    },
    content: {
      backgroundColor: "interface.100",
      padding: "xl",
      size: "full",
      overflow: "auto",
    },
    footer: {
      justifyContent: "flex-end",
      paddingY: "md",
      paddingX: "xl",
      width: "full",
      shadow: "0 -4px 12px rgba(0,0,0,0.04)",
    },
  },
});

export const Modal = {
  Root: ModalRoot,
  Action: ModalAction,
  Content: ModalContent,
  Trigger: ModalTrigger,
};
