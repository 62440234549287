// this file has been generated automatically, do not modify it
import { forwardRef, type ForwardedRef, type SVGProps, type JSX } from "react";

import { styled } from "@mobsuccess-devops/styled-system/jsx";

import { ReactComponent as Default } from "./default.svg";
import { ReactComponent as Hide } from "./hide.svg";

type ComponentProps = SVGProps<SVGSVGElement> & {
  variant: "hide" | "default";
} & { title?: string };

function Component(
  { variant, children: _children, ...props }: ComponentProps,
  ref: ForwardedRef<SVGSVGElement>,
): JSX.Element | null {
  switch (variant) {
    case "hide":
      return <Hide {...props} ref={ref} />;
    case "default":
      return <Default {...props} ref={ref} />;
    default:
      return null;
  }
}

export const Eye = Object.assign(styled(forwardRef(Component)), {
  variants: ["hide", "default"],
});
