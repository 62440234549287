type UpdateQueueState = "empty" | "queued" | "listening";

export class UpdateQueue<InstanceKey> {
  private _queue: Record<string, Map<InstanceKey, UpdateQueueState>> = {};

  ensureQueue(id: string): Map<InstanceKey, UpdateQueueState> {
    if (!(id in this._queue)) {
      this._queue[id] = new Map();
    }
    return this._queue[id];
  }

  update(
    id: string,
    instance: InstanceKey,
    state: UpdateQueueState,
  ): UpdateQueueState | undefined {
    const queue = this.ensureQueue(id);
    const current = queue.get(instance);
    queue.set(instance, state);
    return current;
  }

  dispatch(
    id: string,
    newState: UpdateQueueState,
    shouldUpdate: (state: UpdateQueueState) => boolean,
  ): void {
    const queue = this.ensureQueue(id);
    for (const [instance, value] of queue.entries()) {
      if (shouldUpdate(value)) {
        queue.set(instance, newState);
      }
    }
  }

  removeInstance(id: string, instance: InstanceKey): void {
    const queue = this.ensureQueue(id);
    queue.delete(instance);
  }
}
