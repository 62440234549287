import {
  cva,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

export const bodyStyle = cva({
  base: {
    textStyle: "body",
    color: "interface",
  },
});

export type BodyProps = RecipeVariantProps<typeof bodyStyle>;

export const Body = styled("p", bodyStyle);
