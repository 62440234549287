import { memo, JSX } from "react";

import styled, { keyframes, useTheme, css } from "styled-components";

import { Container } from "../Container";
import { Typography } from "../Typography";

const bounce = keyframes`
  0% {
    transform: translateY(-50px) scaleX(1) scaleY(1);
  }
  75% {
    transform: translateY(-8px) scaleX(0.9) scaleY(1.1);
  }
  95%, 100% {
    transform: translateY(0) scaleX(1.1) scaleY(0.7);
  }
`;

const bounceShadow = keyframes`
  0% {
    opacity: 0.3;
    transform: scaleX(0.5) scaleY(0.5);
  }
  90% {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
`;

const BallUI = styled.div<{ $overlay?: string }>`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 5px;
    margin: 8px 0 0 -8px;
    border-radius: 50%;
    background-color: rgba(79, 91, 146, 0.2);
    -webkit-filter: blur(1px);
    filter: blur(1px);
    animation: ${bounceShadow} 0.5s cubic-bezier(0.7, 0, 1, 1) infinite
      alternate;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    border-radius: 50%;
    background-color: ${({ theme, $overlay }) =>
      $overlay === "dark" || $overlay === "base"
        ? theme.palette.white
        : theme.palette.primary.lighter[100]};
    animation: ${bounce} 0.5s cubic-bezier(0.7, 0, 1, 1) infinite alternate;
  }
`;

BallUI.displayName = "BallUI";

export type BlockProps = {
  text?: string;
  overlay?: "white" | "light" | "base" | "dark";
};

const TextCss = css`
  margin-top: 32px;
  text-align: center;
  max-width: 400px;
`;

function Block({ text = "", overlay = "base" }: BlockProps): JSX.Element {
  const theme = useTheme();

  return (
    <Container
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <BallUI aria-label="ball" $overlay={overlay} />
      {text && (
        <Typography
          as="p"
          variant="text-lg"
          color={
            overlay === "dark" || overlay === "base"
              ? theme.palette.white
              : theme.palette.interface.base
          }
          styled={TextCss}
        >
          {text}
        </Typography>
      )}
    </Container>
  );
}

const MemoizedComponent = memo(Block);
export { MemoizedComponent as Block };
