import type { JSX, ReactNode } from "react";

import { toastAnatomy } from "@ark-ui/anatomy";
import { Toast as ArkToast } from "@ark-ui/react";
import { ReactComponent as CrossIcon } from "@mobsuccess-devops/streamline/bold/01-Interface Essential/33-Form-Validation/close.svg";
import { ReactComponent as DangerIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/danger.svg";
import { ReactComponent as InfoIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/info.svg";
import { ReactComponent as SuccessIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/success.svg";
import { ReactComponent as WarningIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/warning.svg";
import { sva } from "@mobsuccess-devops/styled-system/css";
import { hstack } from "@mobsuccess-devops/styled-system/patterns";
import type { Options, Type } from "@zag-js/toast";

import { Button } from "../Button/Button";

const icons = {
  info: InfoIcon,
  error: DangerIcon,
  success: SuccessIcon,
  custom: WarningIcon,
  loading: InfoIcon, // should not be available / defined for type safety
};

type ToastProps = Options<ReactNode>;

function toTypeString(type?: Type): "success" | "error" | "loading" | "info" {
  switch (type) {
    case "success":
    case "error":
    case "loading":
    case "info":
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return type as "success" | "error" | "loading" | "info";
    default:
      return "info";
  }
}

export function Toast({
  title,
  actions,
  description,
  type: $type,
}: ToastProps): JSX.Element {
  const type = toTypeString($type);
  const classes = styles({
    type,
    withActions: !!actions,
  });
  const Icon = icons[type];
  return (
    <ArkToast.Root className={classes.root}>
      <Icon className={classes.icon} />
      <ArkToast.Title className={classes.title}>{title}</ArkToast.Title>
      <ArkToast.Description className={classes.description}>
        {description}
      </ArkToast.Description>
      <ArkToast.CloseTrigger className={classes.closeTrigger}>
        <CrossIcon />
      </ArkToast.CloseTrigger>
      {actions && (
        <div className={classes.actions}>
          {actions.map(({ label, ...props }) => (
            <ArkToast.ActionTrigger key={props.id} asChild>
              <Button type="button" {...props}>
                {label}
              </Button>
            </ArkToast.ActionTrigger>
          ))}
        </div>
      )}
    </ArkToast.Root>
  );
}

const styles = sva({
  slots: toastAnatomy.extendWith("icon", "actions").keys(),
  base: {
    root: {
      display: "grid",
      gap: "md",
      gridTemplateAreas: `
          "icon title closeTrigger"
          ". description description"
        `,
      gridTemplateColumns: "auto 1fr auto",
      gridTemplateRows: "auto auto",
      opacity: "var(--opacity)",
      zIndex: "var(--z-index)",
      listStyle: "none",
      boxShadow: "md",
      height: "var(--height)",
      width: "388px",
      overflowWrap: "anywhere",
      scale: "var(--scale)",
      translate: "var(--x) var(--y) 0",
      willChange: "translate, opacity, scale",
      transitionDuration: "slow",
      transitionProperty: "translate, scale, opacity, height",
      transitionTimingFunction: "default",
      padding: "md",
      borderRadius: "md",
      borderLeft: "4px solid",
      backgroundColor: "white",
      borderColor: "colorPalette",
      animation: "slide-in .2s ease-in-out",
      _closed: {
        animation: "slide-out 0.2s ease-in-out",
      },
    },
    icon: {
      alignSelf: "center",
      gridArea: "icon",
      size: "md",
    },
    title: {
      gridArea: "title",
      color: "colorPalette",
      textStyle: "subTitle",
    },
    actions: {
      ...hstack.raw(),
      gap: "sm",
      justifySelf: "end",
      gridArea: "actions",
      justifyContent: "flex-end",
    },
    description: {
      gridArea: "description",
      color: "interface",
      textStyle: "body",
    },
    closeTrigger: {
      justifySelf: "end",
      alignSelf: "center",
      gridArea: "closeTrigger",
      outline: "none",
      border: "none",
      cursor: "pointer",
      background: "transparent",
      fill: "interface.300",
      size: "xs",
      transition: "all 0.2s ease-in-out",
      _hover: {
        fill: "interface.400",
        rotate: "90deg",
      },
    },
  },
  variants: {
    type: {
      success: {
        root: {
          colorPalette: "success",
        },
      },
      error: {
        root: {
          colorPalette: "danger",
        },
      },
      info: {
        root: {
          colorPalette: "info",
        },
      },
      custom: {
        root: {
          colorPalette: "warning",
        },
      },
      loading: {
        root: {
          colorPalette: "info",
        },
      },
    },
    withActions: {
      true: {
        root: {
          gridTemplateAreas: `
              "icon title closeTrigger"
              ". description description"
              ". actions actions"
            `,
          gridTemplateRows: "auto auto auto",
        },
      },
    },
  },
  defaultVariants: {
    type: "info",
    withActions: false,
  },
});
