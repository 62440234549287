import { useEffect } from "react";

const target = new URL("/dashboard", window.location.origin);

export default function Index(): null {
  useEffect(() => {
    window.location.assign(target.toString());
  }, []);

  return null;
}
