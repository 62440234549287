import {
  type FunctionComponentElement,
  type JSX,
  type ReactNode,
  isValidElement,
} from "react";

import {
  Dialog as ArkDialog,
  type UseDialogContext,
  type DialogTriggerProps,
  useDialogContext,
} from "@ark-ui/react";
import { cx } from "@mobsuccess-devops/styled-system/css";
import type { RecipeVariantProps } from "@mobsuccess-devops/styled-system/types";

import { IconButton, type iconButtonStyles } from "../../Button/IconButton";
import { buttonStyles } from "../Button/Button";

type AsChildProps = {
  asChild: true;
};

type ButtonVariant = {
  asChild?: false;
} & RecipeVariantProps<typeof buttonStyles>;

type IconButtonVariant = {
  asChild?: false;
  variant: "icon";
} & RecipeVariantProps<typeof iconButtonStyles>;

type TriggerProps = Omit<DialogTriggerProps, "asChild" | "children"> & {
  children?: ReactNode | ((dialog: UseDialogContext) => ReactNode);
} & (AsChildProps | ButtonVariant | IconButtonVariant);

export function Trigger({
  children: $children,
  ...props
}: TriggerProps): JSX.Element {
  const dialog = useDialogContext();
  const children =
    typeof $children === "function" ? $children(dialog) : $children;
  if (props.asChild) {
    return <ArkDialog.Trigger {...props}>{children}</ArkDialog.Trigger>;
  }
  if (props.variant === "icon") {
    return (
      <ArkDialog.Trigger asChild {...props} className={props.className}>
        <IconButton {...props} className={props.className}>
          {children}
        </IconButton>
      </ArkDialog.Trigger>
    );
  }
  return (
    <ArkDialog.Trigger
      {...props}
      className={cx(buttonStyles(), props.className)}
    >
      {children}
    </ArkDialog.Trigger>
  );
}

export type TriggerElement = FunctionComponentElement<TriggerProps>;

export function isTriggerElement(node: ReactNode): node is TriggerElement {
  return isValidElement(node) && node.type === Trigger;
}
