import { memo, useCallback, useEffect, useState, JSX } from "react";

import styled, { css, keyframes, useTheme } from "styled-components";

import { LinearButton } from "../Button/LinearButton";
import { Container } from "../Container";
import { Typography } from "../Typography";

import { Headline } from "./Headline/Headline";
import { Icon } from "./Icon/Icon";

export const fromLeftAnimation = keyframes`
  from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
`;

const leftIncss = css`
  animation: ${fromLeftAnimation} ${({ theme }) => theme.animation.short}
    ease-in-out;
`;

const leftOutcss = css`
  transition: ${({ theme }) => theme.animation.short} ease-in-out;
  transform: translate3d(-100%, 0, 0);
`;

export const fromRightAnimation = keyframes`
  from {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
`;

const rightIncss = css`
  animation: ${fromRightAnimation} ${({ theme }) => theme.animation.short}
    ease-in-out;
`;

const rightOutcss = css`
  transition: ${({ theme }) => theme.animation.short} ease-in-out;
  transform: translate3d(100%, 0, 0);
`;

export const animations = {
  right: {
    in: rightIncss,
    out: rightOutcss,
    align: "flex-end",
  },
  left: {
    in: leftIncss,
    out: leftOutcss,
    align: "flex-start",
  },
};

const ContentUI = styled(Container)`
  margin-left: 16px;
  overflow: auto;
  overflow-wrap: break-word;
`;

ContentUI.displayName = "ContentUI";

type BarUIProps = {
  $variant: "success" | "warning" | "danger" | "info";
};

const BarUI = styled.div<BarUIProps>`
  width: 4px;
  background: ${({ theme, $variant }) => theme.palette[$variant].base};
`;

BarUI.displayName = "BarUI";

type ToasterUIProps = {
  $position: "left" | "right";
  $isRemoved: boolean;
};

const wiggleAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(0);
  }
`;

const ToasterContainerUI = styled(Container)`
  z-index: 1100;
  &.wiggle {
    animation: ${wiggleAnimation} 0.3s ease-in-out;
  }
`;

const ToasterUI = styled(Container)<ToasterUIProps>`
  width: 388px;
  border-radius: 8px;
  ${({ $position }) => animations[$position].in}
  box-shadow: ${({ theme }) => theme.shadows.medium};
  background-color: ${({ theme }) => theme.palette.white};
  align-self: ${({ $position }) => animations[$position].align};
  transition: all ${({ theme }) => theme.animation.short} ease-in-out;
  ${({ $isRemoved, $position }) => $isRemoved && animations[$position].out}
`;

ToasterUI.displayName = "ToasterUI";

export type ToasterProps = {
  id?: string;
  uuid: string;
  title: string;
  timeout?: number;
  isRemoved?: boolean;
  buttonText?: string;
  description?: string;
  isFirstDisplay?: boolean;
  onButtonClick?: () => void;
  onRemove(position: string, uuid: string): void;
  variant: "success" | "warning" | "danger" | "info";
  position: "topRight" | "topLeft" | "bottomRight" | "bottomLeft";
};
function defaultOnButtonCLick(): null {
  return null;
}

export function getHorizontalPosition(position: string): "left" | "right" {
  if (["topLeft", "bottomLeft"].includes(position)) {
    return "left";
  }
  return "right";
}

function Toaster({
  id,
  uuid,
  variant,
  title,
  description,
  buttonText,
  onButtonClick = defaultOnButtonCLick,
  onRemove,
  position,
  timeout,
}: ToasterProps): JSX.Element {
  const horizontal = getHorizontalPosition(position);
  const [removed, setRemoved] = useState(false);
  const theme = useTheme();

  const handleClickRemove = useCallback(() => {
    setRemoved(true);
    setTimeout(() => {
      onRemove(position, uuid);
    }, 300);
  }, [onRemove, position, uuid]);

  useEffect(() => {
    if (!timeout) {
      return;
    }
    const timer = setTimeout(() => {
      setRemoved(true);
      setTimeout(() => {
        onRemove(position, uuid);
      }, 300);
    }, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, [onRemove, position, timeout, uuid]);

  const toasterId = id ? `toaster-${id}` : undefined;

  return (
    <ToasterContainerUI overflow="hidden" id={toasterId}>
      <ToasterUI
        flexDirection="row"
        $position={horizontal}
        $isRemoved={removed}
      >
        <BarUI $variant={variant} />
        <Container width="100%" flexDirection="row" padding="16px">
          <Icon variant={variant} />
          <ContentUI flexDirection="column" flex="1" gap="16px">
            <Headline variant={variant} onClickRemove={handleClickRemove}>
              {title}
            </Headline>
            {description && (
              <Typography
                as="p"
                variant="text-md-light"
                color={theme.palette.interface.base}
              >
                {description}
              </Typography>
            )}
            {buttonText && (
              <Container justifyContent="flex-end">
                <LinearButton color={variant} onClick={onButtonClick}>
                  {buttonText}
                </LinearButton>
              </Container>
            )}
          </ContentUI>
        </Container>
      </ToasterUI>
    </ToasterContainerUI>
  );
}

const MemoizedToaster = memo(Toaster);
export { MemoizedToaster as Toaster };
