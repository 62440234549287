import { memo, ReactNode, useCallback, useMemo, useState, JSX } from "react";

import { createPortal } from "react-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { Container } from "../Container";
import { Snackbar } from "../Snackbar";
import { Toaster } from "../Toaster";

import {
  NotificationContext,
  type NotificationContextType,
  ToastOptions,
} from "./context/context";
import {
  type Positions,
  type Notification,
  type Notifications,
} from "./types/types";

type PositionUIProps = {
  $top?: number;
  $left?: number;
  $right?: number;
  $bottom?: number;
};

const PositionUI = styled(Container)<PositionUIProps>`
  position: absolute;
  width: calc(388px + 36px); //388px (notification width) + 18px (margin) * 2;
  z-index: 9999;
  top: ${({ $top }) =>
    typeof $top !== "undefined" && !isNaN($top) ? `${$top}px` : "auto"};
  bottom: ${({ $bottom }) =>
    typeof $bottom !== "undefined" && !isNaN($bottom)
      ? `${$bottom}px`
      : "auto"};
  left: ${({ $left }) =>
    typeof $left !== "undefined" && !isNaN($left) ? `${$left}px` : "auto"};
  right: ${({ $right }) =>
    typeof $right !== "undefined" && !isNaN($right) ? `${$right}px` : "auto"};
`;

PositionUI.displayName = "PositionUI";

const columnsPositions = {
  topRight: {
    right: true,
    top: true,
  },
  topLeft: {
    left: true,
    top: true,
  },
  bottomRight: {
    right: true,
    bottom: true,
  },
  bottomLeft: {
    left: true,
    bottom: true,
  },
} as const;

type NotificationWrapperProps = {
  children: ReactNode;
};

/**
 *
 * @deprecated use useToasters instead
 * @see https://github.com/mobsuccess-devops/mobsuccess-front/blob/master/packages/react-ui/src/components/_PandaArk/Toaster/Toaster.tsx
 */
function NotificationWrapper({
  children,
}: NotificationWrapperProps): JSX.Element {
  const [notifications, setNotifications] = useState<Notifications>(() => ({
    topRight: [],
    topLeft: [],
    bottomRight: [],
    bottomLeft: [],
  }));

  const add = useCallback<NotificationContextType["add"]>(
    ({ position, id, ...options }) => {
      const uuid = uuidv4();
      const notificationToAdd: Notification = {
        ...options,
        id,
        remove: false,
        uuid: uuid,
        firstDisplay: true,
      };

      setNotifications((prevNotifications) => {
        const positionned = prevNotifications[position];
        const notification = id && positionned.find((n) => n.id === id);
        if (id && notification) {
          const element = document.getElementById(`${options.type}-${id}`);
          if (element) {
            const classname = element.className;
            element.setAttribute("class", classname + " wiggle");
            setTimeout(() => {
              element.setAttribute("class", classname);
            }, 300);
          }
          return prevNotifications;
        }
        return {
          ...prevNotifications,
          [position]: [...positionned, notificationToAdd],
        };
      });
    },
    [],
  );

  const remove = useCallback((position: Positions, uuid: string) => {
    setNotifications((prevNotifications) => {
      const positionned = prevNotifications[position];
      const notification = positionned.find((n) => n.uuid === uuid);
      if (!notification) {
        return prevNotifications;
      }
      return {
        ...prevNotifications,
        [position]: positionned.filter((n) => n.uuid !== uuid),
      };
    });
  }, []);

  const value = useMemo(
    () => ({
      add,
      toastError: (content: string, options?: ToastOptions) =>
        add({
          variant: "danger",
          title: content,
          position: "topRight",
          type: "toaster",
          description: options?.description,
          timeOut: options?.dimiss ? 5000 : undefined,
        }),
      toastSuccess: (content: string, options?: ToastOptions) =>
        add({
          variant: "success",
          title: content,
          position: "topRight",
          type: "toaster",
          description: options?.description,
          timeOut: options?.dimiss ? 5000 : undefined,
        }),
      toastInfo: (content: string, options?: ToastOptions) =>
        add({
          variant: "info",
          title: content,
          position: "topRight",
          type: "toaster",
          description: options?.description,
          timeOut: options?.dimiss ? 5000 : undefined,
        }),
      toastWarning: (content: string, options?: ToastOptions) =>
        add({
          variant: "warning",
          title: content,
          position: "topRight",
          type: "toaster",
          description: options?.description,
          timeOut: options?.dimiss ? 5000 : undefined,
        }),
      snackbar: (content: string) =>
        add({
          title: content,
          type: "snackbar",
          showIcon: true,
          background: "dark",
          position: "bottomRight",
        }),
      remove,
    }),
    [add, remove],
  );

  const renderNotifications = useMemo(() => {
    const notificationsColums: ReactNode[] = [];
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const positions = Object.keys(notifications) as (keyof Notifications)[];
    positions.forEach((position) => {
      if (notifications[position].length) {
        notificationsColums.push(
          <PositionUI
            gap="16px"
            flexDirection="column"
            key={position}
            $top={"top" in columnsPositions[position] ? 10 : NaN}
            $bottom={"bottom" in columnsPositions[position] ? 10 : NaN}
            $left={"left" in columnsPositions[position] ? 10 : NaN}
            $right={"right" in columnsPositions[position] ? 10 : NaN}
          >
            {notifications[position].map((notification) => {
              if (notification.type === "toaster") {
                const { onButtonClick } = notification;
                return (
                  <Toaster
                    id={notification.id}
                    uuid={notification.uuid}
                    key={notification.uuid}
                    variant={notification.variant}
                    title={notification.title}
                    description={notification.description}
                    isRemoved={notification.remove}
                    isFirstDisplay={notification.firstDisplay}
                    onRemove={remove}
                    position={position}
                    timeout={notification.timeOut}
                    buttonText={notification.buttonText}
                    onButtonClick={onButtonClick}
                  />
                );
              }
              return (
                <Snackbar
                  timeout={2000}
                  position={position}
                  key={notification.uuid}
                  uuid={notification.uuid}
                  title={notification.title}
                  isRemoved={notification.remove}
                  showIcon={notification.showIcon}
                  background={notification.background}
                  isFirstDisplay={notification.firstDisplay}
                  onRemove={remove}
                />
              );
            })}
          </PositionUI>,
        );
      }
    });
    return notificationsColums;
  }, [notifications, remove]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
      {createPortal(renderNotifications, document.body)}
    </NotificationContext.Provider>
  );
}

/**
 *
 * @deprecated use useToasters instead
 * @see https://github.com/mobsuccess-devops/mobsuccess-front/blob/master/packages/react-ui/src/components/_PandaArk/Toaster/Toaster.tsx
 */
const MemoizedNotificationWrapper = memo(NotificationWrapper);
export { MemoizedNotificationWrapper as NotificationWrapper };
