import { memo, MouseEventHandler, ForwardedRef, forwardRef, JSX } from "react";

import { ReactComponent as CheckIcon } from "@mobsuccess-devops/streamline/custom/react-ui/check.svg";
import { ReactComponent as UncheckIcon } from "@mobsuccess-devops/streamline/custom/react-ui/uncheck.svg";

import styled, { css, useTheme } from "styled-components";

import { NativeProps } from "../../../types/native-props";
import { Container } from "../../Container";
import { Typography } from "../../Typography";

type IsSelected = boolean;
type IsDisabled = boolean;

const FocusedUI = styled.button`
  outline: none;
  border: 2px solid transparent;
  background: none;
  padding: 2px;
  :focus {
    border-radius: 20px;
    border: 2px solid ${({ theme }) => theme.palette.interface.base};
  }

  :disabled {
    cursor: not-allowed;
  }
`;

FocusedUI.displayName = "FocusedUI";

const SwitchUI = styled(Container)<{
  $isSelected: IsSelected;
  $isDisabled: IsDisabled;
}>`
  border-radius: 12px;
  width: 44px;
  cursor: pointer;
  height: 24px;
  background-color: ${({ $isSelected, theme }) =>
    $isSelected
      ? theme.palette.interface.base
      : theme.palette.interface.lighter[300]};

  ${({ $isDisabled, theme }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      background-color: ${theme.palette.interface.base};
      opacity: 0.5;
    `}
`;

SwitchUI.displayName = "SwitchUI";

const PinUI = styled.span<{ $isSelected: IsSelected }>`
  background: ${({ theme }) => theme.palette.white};
  border-radius: 50%;
  right: ${({ $isSelected }) => ($isSelected ? "2px" : "calc(50%)")};
  position: absolute;
  top: 2px;
  width: calc(50% - 2px);
  height: calc(100% - 4px);
  transition: right ease 0.25s;
  display: flex;
  justify-content: center;
  & > svg {
    margin: auto;
  }
`;

PinUI.displayName = "PinUI";

const labelCss = css`
  margin: auto 12px;
`;

export type SwitchProps = {
  label?: string;
  hasIcon?: boolean;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
} & NativeProps<"div">;

function Switch(
  {
    label,
    hasIcon = false,
    isSelected,
    isDisabled = false,
    onClick,
    ...intrinsicProps
  }: SwitchProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const { palette } = useTheme();

  return (
    <Container ref={ref}>
      <FocusedUI disabled={isDisabled} type="button">
        <SwitchUI
          $isSelected={isSelected}
          $isDisabled={isDisabled}
          onClick={!isDisabled ? onClick : undefined}
          {...intrinsicProps}
        >
          <PinUI $isSelected={isSelected}>
            {hasIcon &&
              (isSelected ? (
                <CheckIcon color={palette.interface.lighter[200]} />
              ) : (
                <UncheckIcon color={palette.interface.lighter[300]} />
              ))}
          </PinUI>
        </SwitchUI>
      </FocusedUI>
      {label && (
        <Typography
          id="switch-label"
          styled={labelCss}
          variant="text-md"
          as="span"
          color={palette.interface.base}
        >
          {label}
        </Typography>
      )}
    </Container>
  );
}

const MemoizedSwitch = memo(forwardRef(Switch));
export { MemoizedSwitch as Switch };
