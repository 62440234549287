import {
  ReactNode,
  JSX,
  isValidElement,
  FunctionComponentElement,
} from "react";

import { styled } from "@mobsuccess-devops/styled-system/jsx";
import { Table } from "@tanstack/react-table";

export type TableHeaderContentProps<T> = {
  /** @internal */
  _table?: Table<T>;
  children: ReactNode | ((table: Table<T>) => ReactNode);
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export function TableHeaderContent<T>({
  children,
  _table: table,
}: TableHeaderContentProps<T>): JSX.Element {
  if (!table) {
    throw new Error("TableHeaderContent must be a child of Table");
  }
  return (
    <styled.div gridArea="header-content">
      {typeof children === "function" ? children(table) : children}
    </styled.div>
  );
}

export type TableHeaderContentElement<T> = FunctionComponentElement<
  TableHeaderContentProps<T>
>;
export function isTableHeaderContentElement<T>(
  node: ReactNode,
): node is TableHeaderContentElement<T> {
  return isValidElement(node) && node.type === TableHeaderContent<T>;
}
