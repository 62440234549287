import { JSX } from "react";

import { sva, cx } from "@mobsuccess-devops/styled-system/css";
import { vstack } from "@mobsuccess-devops/styled-system/patterns";

import type { NativeProps } from "../../../types/native-props";

export type LabelProps = {
  required?: boolean;
  description?: string;
} & NativeProps<"label">;

export function Label({
  className,
  children,
  required,
  description,
  ...rest
}: LabelProps): JSX.Element {
  const classes = labelCss({ required });
  return (
    <label className={cx(classes.root, className)} {...rest}>
      <div className={classes.content}>{children}</div>
      {description && (
        <span className={classes.description}>{description}</span>
      )}
    </label>
  );
}

const labelCss = sva({
  slots: ["root", "content", "description"],
  base: {
    root: vstack.raw({ alignItems: "start", gap: "0" }),
    content: {
      textStyle: "body",
      color: "interface",
    },
    description: {
      textStyle: "muted",
      color: "interface.300",
    },
  },
  variants: {
    required: {
      true: {
        content: {
          _after: {
            content: "' *'",
            color: "danger",
          },
        },
      },
    },
  },
});
