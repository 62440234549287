import { JSX } from "react";

import { cx, sva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";
import { Table, flexRender } from "@tanstack/react-table";

type TableFooterProps<T extends object> = {
  table: Table<T>;
  className?: string;
  elevatePin?: boolean;
};

export function TableFooter<T extends object>({
  table,
  className,
  elevatePin,
}: TableFooterProps<T>): JSX.Element | null {
  const classes = styles({
    elevatePin,
  });

  const hasFooter = table
    .getFooterGroups()
    .some((footerGroup) =>
      footerGroup.headers.some((header) => !!header.column.columnDef.footer),
    );

  if (!hasFooter) {
    return null;
  }

  return (
    <styled.tfoot className={className}>
      {table.getFooterGroups().map((footerGroup) => (
        <styled.tr
          key={footerGroup.id}
          className={classes.row}
          data-table-role="footer"
        >
          {footerGroup.headers.map((header) => {
            if (!header.column.getIsVisible()) {
              return null;
            }
            return (
              <th
                key={header.id}
                className={cx(classes.cell, "group")}
                data-table-role="footer-cell"
                data-pinned={header.column.getIsPinned()}
                data-last-column-left={header.column.getIsLastColumn("left")}
                data-first-column-right={header.column.getIsFirstColumn(
                  "right",
                )}
                // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react
                style={{
                  width: header.getSize(),
                  left:
                    header.column.getIsPinned() === "left"
                      ? `${header.column.getStart("left")}px`
                      : undefined,
                  right:
                    header.column.getIsPinned() === "right"
                      ? `${header.column.getStart("right")}px`
                      : undefined,
                }}
              >
                {flexRender(
                  header.column.columnDef.footer,
                  header.getContext(),
                )}
              </th>
            );
          })}
        </styled.tr>
      ))}
    </styled.tfoot>
  );
}

const styles = sva({
  slots: ["row", "cell", "sort", "resize"],
  base: {
    row: {
      display: "flex",
      width: "100%",
      backgroundColor: "interface.100",
    },
    cell: {
      position: "relative",
      padding: "md",
      display: "flex",
      alignItems: "center",
      resize: "horizontal",
      backgroundColor: "inherit",
      "&[data-pinned='left']": {
        position: "sticky",
        elevation: "sticker",
      },
      "&[data-pinned='right']": {
        position: "sticky",
        elevation: "sticker",
      },
    },
    sort: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      color: "interface.300",
      opacity: 0.2,
      height: "full",
      padding: "sm",
      gap: "3px",
      _hover: {
        opacity: 1,
      },
      "& > *:last-child": {
        transform: "rotate(180deg)",
      },
      "&[data-sort-direction='desc']": {
        opacity: 1,
        "& > *:first-child": {
          color: "primary",
        },
      },
      "&[data-sort-direction='asc']": {
        opacity: 1,
        "& > *:last-child": {
          color: "primary",
        },
      },
    },
    resize: {
      position: "absolute",
      height: "full",
      width: "4px",
      top: 0,
      right: 0,
      cursor: "col-resize",
      _groupHover: {
        backgroundColor: "interface.200",
      },
    },
  },
  variants: {
    elevatePin: {
      true: {
        cell: {
          "&[data-pinned='left']": {
            "&[data-last-column-left='true']": {
              boxShadowColor:
                "linear-gradient(to right, token(colors.interface), transparent)",
              shadow: "-2px 0px 4px -4px inset",
            },
          },
          "&[data-pinned='right']": {
            "&[data-first-column-right='true']": {
              boxShadowColor:
                "linear-gradient(to left, token(colors.interface), transparent)",
              shadow: "2px 0px 4px -4px inset",
            },
          },
        },
      },
    },
  },
});
