import { ForwardedRef, memo, useMemo, forwardRef, JSX } from "react";

import styled, {
  FlattenSimpleInterpolation,
  useTheme,
} from "styled-components";

import { TextVariant } from "../../types/input";
import { NativeProps } from "../../types/native-props";
import { useMediaQuery } from "../MediaQuery/use-media-query";

type TypographyUIProps = {
  as: React.ElementType;
  readonly $color: string;
  readonly $fontSize: string;
  readonly $weight: number;
  readonly $lineHeight: string;
  readonly $textAlign: string;
  readonly $transform: string;
  readonly $styled: string;
};

const TypographyUI = styled.div<TypographyUIProps>`
  color: ${({ $color }) => $color ?? "#00000"};
  font-family: ${({ theme }) => theme.fontFamily ?? "Arial, sans-serif"};
  font-size: ${({ $fontSize }) => $fontSize ?? "16px"};
  font-weight: ${({ $weight }) => $weight ?? 400};
  letter-spacing: 0.03em;
  line-height: ${({ $lineHeight }) => $lineHeight ?? "20px"};
  text-align: ${({ $textAlign }) => $textAlign ?? "left"};
  text-transform: ${({ $transform }) => $transform ?? "none"};
  ${({ $styled }) => $styled ?? null}
`;

TypographyUI.displayName = "TypographyUI";

export type TypographyVariantComputedProps = {
  $lineHeight: string;
  $fontSize: string;
  $weight: number;
};

export type VariantWeightProps = {
  variant: string;
  weight: string;
};

export type TypographyProps = {
  variant?: TextVariant;
  transform?: "none" | "capitalize" | "uppercase" | "lowercase";
  as: React.ElementType;
  textAlign?: "left" | "center" | "right";
  color?: string;
  styled?: FlattenSimpleInterpolation | string | null;
} & NativeProps<"div">;

function Typography(
  {
    children,
    variant: variantFull = "md",
    as,
    color = "#000",
    transform = "none",
    styled = null,
    textAlign = "left",
    ...intrinsicProps
  }: TypographyProps,
  ref?: ForwardedRef<HTMLElement>,
): JSX.Element | null {
  const theme = useTheme();
  const isPhablet = useMediaQuery(`(min-width: ${theme.breakpoints.phablet})`);
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop})`);

  const variantResponsive = useMemo(() => {
    const variants = variantFull?.split(" ");
    const smVariant =
      variants?.find((variant) => variant.startsWith("sm:")) || variantFull;
    const mdVariant =
      variants?.find((variant) => variant.startsWith("md:")) || smVariant;
    const lgVariant =
      variants?.find((variant) => variant.startsWith("lg:")) || mdVariant;

    if (!isPhablet && !isDesktop) {
      return smVariant || "md";
    } else if (!isDesktop) {
      return mdVariant || "md";
    } else {
      return lgVariant || "md";
    }
  }, [variantFull, isDesktop, isPhablet]);

  const { variant, weight } = useMemo((): VariantWeightProps => {
    const [propVariant, weight] = variantResponsive
      .replace(/sm:|md:|lg:|text-/g, "")
      .split("-");
    const variant = Object.keys(theme.typography.weight).includes(propVariant)
      ? propVariant
      : "md";
    return { variant, weight: weight || "regular" };
  }, [variantResponsive, theme.typography.weight]);

  const variantProps = useMemo((): TypographyVariantComputedProps => {
    return {
      $lineHeight: theme.typography.lineHeight[variant],
      $fontSize: theme.typography.fontSize[variant],
      $weight:
        theme.typography.weight[variant][weight] ||
        theme.typography.weight[variant].regular,
    };
  }, [
    variant,
    weight,
    theme.typography.lineHeight,
    theme.typography.fontSize,
    theme.typography.weight,
  ]);

  if (children === undefined) {
    return null;
  }

  return (
    <TypographyUI
      ref={ref}
      as={as}
      $color={color}
      $transform={transform}
      $textAlign={textAlign}
      $styled={styled}
      {...variantProps}
      {...intrinsicProps}
    >
      {children}
    </TypographyUI>
  );
}

export type TypographyVariantProps = {
  variant?: string;
  styled?: string;
};

const MemoizedTypography = memo(forwardRef(Typography));
export {
  /** @deprecated use Typo.XXX instead */ MemoizedTypography as Typography,
};
