import {
  DragEventHandler,
  ForwardedRef,
  MouseEventHandler,
  ReactNode,
  cloneElement,
  forwardRef,
  memo,
  JSX,
} from "react";

import { ReactComponent as ArrowCurvedIcon } from "@mobsuccess-devops/streamline/custom/lmp/arrow-curve-right.svg";

import styled, { css, useTheme } from "styled-components";

import { Container } from "../../Container";
import { Typography } from "../../Typography";

type ItemUIProps = {
  $isActive: boolean;
  $isDisabled: boolean;
  $isDraggable?: boolean;
};

const activeCss = css`
  background-color: ${({ theme }) => theme.palette.primary.lighter[700]};

  p,
  svg {
    color: ${({ theme }) => `${theme.palette.primary.base}`};
  }
`;

const disabledCss = css`
  cursor: not-allowed;
  opacity: 0.5;
`;

const ItemUI = styled(Container)<ItemUIProps>`
  transition: all 0.4s cubic-bezier(0.8, 1, 0.17, 1);
  background-color: ${({ theme }) => `${theme.palette.white}`};
  cursor: ${({ $isDraggable }) => ($isDraggable ? "move" : "pointer")};

  p {
    transition: all 0.4s cubic-bezier(0.8, 1, 0.17, 1);
  }

  svg {
    max-width: 20px;
    color: ${({ theme }) => `${theme.palette.interface.base}`};
  }

  &:hover {
    ${({ $isDisabled }) => !$isDisabled && activeCss}
  }

  ${({ $isActive }) => $isActive && activeCss}
  ${({ $isDisabled }) => $isDisabled && disabledCss}
`;

ItemUI.displayName = "ItemUI";

const ContainerSvgUI = styled(Container)`
  svg {
    // Fix on safari
    overflow: visible;
  }
`;

const ContainerBreakWord = styled(Container)`
  word-break: break-word;
`;

export type ItemProps = {
  children: ReactNode | Array<ReactNode>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  value?: string | number | symbol;
  isCentered?: boolean;
  id?: string;
  isDisabled?: boolean;
  className?: string;
  isActive?: boolean;
  headingAddon?: JSX.Element;
  trailingAddon?: JSX.Element;
  caption?: string;
  captionsPrefix?: string;
  isDraggable?: boolean;
  onDragStart?: DragEventHandler<HTMLDivElement>;
  onDragEnter?: DragEventHandler<HTMLDivElement>;
  onDragOver?: DragEventHandler<HTMLDivElement>;
};

function defaultOnClick(): null {
  return null;
}

function Item(
  {
    children,
    onClick = defaultOnClick,
    className,
    id,
    isDisabled = false,
    value,
    isCentered = false,
    isActive = false,
    headingAddon,
    trailingAddon,
    captionsPrefix,
    caption,
    isDraggable,
    onDragStart,
    onDragEnter,
    onDragOver,
  }: ItemProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const { palette } = useTheme();

  return (
    <ItemUI
      data-value={value}
      data-disabled={isDisabled}
      width="100%"
      padding="16px"
      flexDirection="column"
      gap="8px"
      $isActive={isActive}
      $isDisabled={isDisabled}
      $isDraggable={isDraggable}
      onClick={onClick}
      ref={ref}
      id={id}
      className={className}
      draggable={isDraggable}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      role="option"
    >
      <ContainerSvgUI
        width="100%"
        alignItems="center"
        gap="12px"
        justifyContent={isCentered ? "center" : "flex-start"}
      >
        {headingAddon}
        <ContainerBreakWord
          width="100%"
          justifyContent={isCentered ? "center" : "flex-start"}
        >
          {children}
        </ContainerBreakWord>
        {trailingAddon && cloneElement(trailingAddon, { isActive })}
      </ContainerSvgUI>
      {caption && (
        <Container alignItems="center" gap="4px">
          <ArrowCurvedIcon width="12px" height="12px" />
          <Typography variant="text-sm" as="span" color={palette.primary.base}>
            {captionsPrefix && <>{captionsPrefix}&nbsp;</>}
            {caption}
          </Typography>
        </Container>
      )}
    </ItemUI>
  );
}

const MemoizedItem = memo(forwardRef(Item));
export { MemoizedItem as Item };
