import type { JSX } from "react";

import { colorPickerAnatomy } from "@ark-ui/anatomy";
import { ColorPicker as ArkColorPicker, ColorPicker } from "@ark-ui/react";
import { ReactComponent as EyeDropperIcon } from "@mobsuccess-devops/streamline/regular/12-Design/05-Colors_Painting/color-picker-2.svg";
import { sva } from "@mobsuccess-devops/styled-system/css";
import { HStack } from "@mobsuccess-devops/styled-system/jsx";

export function Controls(): JSX.Element {
  const classes = styles();
  return (
    <>
      <ArkColorPicker.Area className={classes.area}>
        <ArkColorPicker.AreaBackground className={classes.areaBackground} />
        <ArkColorPicker.AreaThumb className={classes.areaThumb} />
      </ArkColorPicker.Area>
      <ArkColorPicker.ChannelSlider
        channel="hue"
        className={classes.channelSlider}
      >
        <ArkColorPicker.TransparencyGrid className={classes.transparencyGrid} />
        <ArkColorPicker.ChannelSliderTrack
          className={classes.channelSliderTrack}
        />
        <ArkColorPicker.ChannelSliderThumb
          className={classes.channelSliderThumb}
        />
      </ArkColorPicker.ChannelSlider>
      <HStack width="full">
        <ColorPicker.ChannelInput
          channel="hex"
          className={classes.channelInput}
        />
        <ColorPicker.EyeDropperTrigger className={classes.eyeDropperTrigger}>
          <EyeDropperIcon />
        </ColorPicker.EyeDropperTrigger>
      </HStack>
    </>
  );
}

const styles = sva({
  slots: colorPickerAnatomy.keys(),
  base: {
    area: {
      width: "full",
      height: "200px",
    },
    areaBackground: {
      size: "full",
      borderRadius: "md",
    },
    areaThumb: {
      size: "12px",
      cursor: "grab",
      _active: {
        cursor: "grabbing",
      },
      border: "2px solid white",
      borderRadius: "full",
    },
    channelSlider: {
      position: "relative",
      width: "100%",
      height: "12px",
    },
    transparencyGrid: {
      size: "full",
      borderRadius: "md",
    },
    channelSliderTrack: {
      size: "full",
      borderRadius: "md",
    },
    channelSliderThumb: {
      size: "sm",
      cursor: "grab",
      _active: {
        cursor: "grabbing",
      },
      border: "2px solid white",
      borderRadius: "full",
      transform: "translate(-50%, -50%)",
    },
    channelInput: {
      width: "calc(100% - 32px)",
      height: "lg",
      outline: "none",
      padding: "xs",
      borderRadius: "md",
      fontFamily: "unset",
      color: "interface",
      border: "2px solid token(colors.interface.300)",
      _focus: {
        borderColor: "interface.400",
      },
    },
    eyeDropperTrigger: {
      center: true,
      size: "lg",
      cursor: "pointer",
      color: "interface",
      border: "2px solid!",
      borderColor: "interface.300!",
      backgroundColor: "transparent",
      borderRadius: "md",
      _childSvg: {
        size: "sm",
      },
    },
  },
});
