import {
  useState,
  MouseEvent,
  ChangeEvent,
  useRef,
  useMemo,
  useCallback,
  SetStateAction,
  memo,
  JSX,
} from "react";

import { ReactComponent as CrossIcon } from "@mobsuccess-devops/streamline/bold/01-Interface Essential/33-Form-Validation/close.svg";
import { ReactComponent as ArrowDownIcon } from "@mobsuccess-devops/streamline/regular/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg";

import styled, { css, useTheme } from "styled-components";

import { AnchorOriginType } from "../../../types/anchor-origin";
import { SelectCollection, SelectOptions } from "../../../types/new-select";
import { Sizes } from "../../../types/sizes";
import { AbstractInput } from "../../AbstractInput";
import { Badge } from "../../Badge";
import { Container } from "../../Container";
import {
  Dropdown,
  DropdownActions,
  DropdownGroup,
  DropdownItem,
} from "../../Dropdown";
import { Typography } from "../../Typography";

import { MultiSelectItems } from "./MultiSelectItems/MultiSelectItems";
import { SelectOption } from "./SelectOption/SelectOption";

const IconUI = styled(Container)<{ $variant: Sizes }>`
  & > svg {
    height: ${({ $variant }) => {
      switch ($variant) {
        case "sm":
          return "16px";
        default:
        case "md":
        case "lg":
          return "20px";
        case "xl":
          return "24px";
      }
    }};
  }
  color: ${({ theme }) => theme.palette.interface.base};
  padding-right: 0;
`;

const CrossIconUI = styled(CrossIcon)<{ $variant: Sizes }>`
  height: 12px;
  fill: ${({ theme }) => theme.palette.danger.base};
  margin: ${({ theme, $variant }) => theme.components.input.padding[$variant]};
  cursor: pointer;
`;

const ArrowDownIconUI = styled(ArrowDownIcon)<{
  $variant: Sizes;
  $isCollapsed?: boolean;
}>`
  flex: 1 0 8px;
  height: 8px;
  color: ${({ theme }) => theme.palette.interface.lighter[100]};
  margin: ${({ theme, $variant }) => theme.components.input.padding[$variant]};
  transform: rotate(${({ $isCollapsed }) => ($isCollapsed ? 0 : -180)}deg);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
`;

const InputUI = styled.input<{ $variant: Sizes }>`
  all: unset;
  flex: 0 1 100%;
  padding-left: 12px;
  font-size: ${({ theme, $variant }) =>
    theme.components.input.fontSize[$variant]};
  color: ${({ theme }) => theme.palette.interface.base};
  line-height: ${({ theme, $variant }) =>
    theme.typography.lineHeight[$variant]};
  letter-spacing: 0.03em;

  &::placeholder {
    color: ${({ theme }) => theme.palette.interface.lighter[300]};
  }
`;

InputUI.displayName = "InputUI";

const DropdownUI = styled(Dropdown)<{ $minWidth: string }>`
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: 600px;
`;

const valueLabelCss = css`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { type SelectOptions };

export type Props = {
  id?: string;
  className?: string;
  options: SelectOptions;
  collections?: Array<SelectCollection>;
  icon?: JSX.Element;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  /** @deprecated use Label component instead */
  label?: string;
  caption?: string;
  isDisabled?: boolean;
  dropdownHeight?: string;
  dropdownPosition?: AnchorOriginType;
  variant?: Sizes;
  state?: "warning" | "error" | "success";
  borderStyle?: "standard" | "outlined" | "textBackground";
  containerWidth?: string;
  placeholder?: string;
  loadingText?: string;
  searchPlaceholder?: string;
  noOptionsMessage?: string;
  selectAllMessage?: string;
  clearAllMessage?: string;
  inCollectionMessage?: string;
  collectionTitle?: string;
};

type Multi = {
  isMulti: true;
  value: Array<string | number> | null | undefined;
  onChange: ((_: string[]) => void) | ((_: number[]) => void);
} & Props;

type Single = {
  isMulti?: false;
  value: string | number | null | undefined;
  onChange: ((_: string) => void) | ((_: number) => void);
} & Props;

export type AdvancedSelectProps = Multi | Single;

const defaultCollections: NonNullable<AdvancedSelectProps["collections"]> = [];
function AdvancedSelect({
  id,
  className,
  value,
  options,
  collections = defaultCollections,
  isMulti = false,
  icon,
  isLoading = false,
  isSearchable = false,
  isClearable = false,
  label,
  caption,
  isDisabled = false,
  dropdownHeight = "400px",
  dropdownPosition = "bottom-left",
  variant = "md",
  state,
  borderStyle = "standard",
  containerWidth,
  placeholder = "",
  loadingText,
  searchPlaceholder = "",
  noOptionsMessage = "",
  selectAllMessage = "",
  clearAllMessage = "",
  inCollectionMessage = "",
  collectionTitle = "",
  onChange,
}: AdvancedSelectProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { palette, components } = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCollection, setSelectedCollection] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const searchedOptions = useMemo(
    () =>
      options.reduce((acc, option) => {
        if (option.type === "option") {
          if (
            option.label.toLowerCase().includes(search.toLowerCase()) &&
            (selectedCollection
              ? selectedCollection === option.collectionId
              : true)
          ) {
            return [...acc, option];
          }
          return acc;
        }
        return [
          ...acc,
          {
            ...option,
            options: option.options.filter(
              (option) =>
                option.label.toLowerCase().includes(search.toLowerCase()) &&
                (selectedCollection
                  ? selectedCollection === option.collectionId
                  : true),
            ),
          },
        ];
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      }, [] as SelectOptions),
    [options, search, selectedCollection],
  );

  const selectedOptions = useMemo(() => {
    return options
      .map((option) => (option.type === "option" ? option : option.options))
      .flat()
      .filter((option) => Array.of(value).flat().includes(option.value));
  }, [options, value]);

  const handleOpenToggle = useCallback(() => {
    if (isDisabled) {
      return;
    }
    setIsOpen((prev) => !prev);
    setSearch("");
    setSelectedCollection("");
  }, [isDisabled, setIsOpen]);

  const handleOptionClick = useCallback(
    (option: { label: string; value: string | number }) => () => {
      if (isDisabled) {
        return;
      }
      if (isMulti === false) {
        // we cast onChange to singleOnChange because we know that isMulti is false here
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const singleOnChange = onChange as (
          _: SetStateAction<string | number>,
        ) => void;
        singleOnChange(option.value);
        setIsOpen(false);
        setSelectedCollection("");
        return;
      }
      // we cast onChange to multiOnChange because we know that isMulti is true here
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const multiOnChange = onChange as (
        _: SetStateAction<Array<string | number>>,
      ) => void;
      if (!Array.isArray(value)) {
        multiOnChange([option.value]);
        return;
      }
      if (value.includes(option.value)) {
        multiOnChange(value.filter((v) => v !== option.value));
        return;
      }
      multiOnChange([...value, option.value]);
    },
    [isDisabled, onChange, isMulti, setIsOpen, value],
  );

  const handleRemoveItem = useCallback(
    (valueToRemove: string | number) => (e: MouseEvent) => {
      e.stopPropagation();
      if (isDisabled || !isMulti) {
        return;
      }

      // we cast onChange to multiOnChange because we know that isMulti is true here
      if (Array.isArray(value)) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const multiOnChange = onChange as (
          _: SetStateAction<Array<string | number>>,
        ) => void;
        multiOnChange(value.filter((v) => v !== valueToRemove));
      }
    },
    [isDisabled, isMulti, onChange, value],
  );

  const handleRemoveAll = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isMulti === false) {
        // we cast onChange to singleOnChange because we know that isMulti is false here
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const singleOnChange = onChange as (
          _: SetStateAction<string | number>,
        ) => void;
        singleOnChange("");
        return;
      }
      // we cast onChange to multiOnChange because we know that isMulti is true here
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const multiOnChange = onChange as (
        _: SetStateAction<Array<string | number>>,
      ) => void;
      multiOnChange([]);
    },
    [onChange, isMulti],
  );

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch],
  );

  const handleSearchClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  const handleClearCollection = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setSelectedCollection("");
    },
    [setSelectedCollection],
  );

  const getCollectionCaption = useCallback(
    (collectionId?: string) => {
      if (!collectionId) {
        return null;
      }
      const collection = collections?.find(
        (collection) => collection.id === collectionId,
      );
      return `${inCollectionMessage} "${collection?.label}"`;
    },
    [collections, inCollectionMessage],
  );

  const multiActions = useMemo(
    () => [
      ...(selectAllMessage
        ? [
            {
              label: selectAllMessage,
              value: "selectAll",
              onClick: () => {
                if (!isMulti) {
                  return;
                }

                // we cast onChange to multiOnChange because we know that isMulti is true here
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const multiOnChange = onChange as (
                  _: SetStateAction<Array<string | number>>,
                ) => void;
                multiOnChange(
                  options
                    .map((option) =>
                      option.type === "option"
                        ? option.value
                        : option.options.map((option) => option.value),
                    )
                    .flat(),
                );
              },
            },
          ]
        : []),
      ...(clearAllMessage
        ? [
            {
              label: clearAllMessage,
              value: "clear",
              onClick: () => {
                if (!isMulti) {
                  return;
                }

                // we cast onChange to multiOnChange because we know that isMulti is true here
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const multiOnChange = onChange as (
                  _: SetStateAction<Array<string | number>>,
                ) => void;
                multiOnChange([]);
              },
            },
          ]
        : []),
    ],
    [selectAllMessage, clearAllMessage, isMulti, onChange, options],
  );

  const collectionActions = useMemo(
    () =>
      collections?.map((collection) => ({
        label: collection.label,
        icon: collection.icon,
        value: collection.id,
        onClick: () => {
          setSelectedCollection(collection.id);
        },
      })),
    [collections],
  );

  return (
    <>
      <AbstractInput
        id={id}
        className={className}
        ref={setAnchorEl}
        label={label}
        caption={caption}
        isDisabled={isDisabled}
        isFocused={isOpen}
        variant={variant}
        state={state}
        borderStyle={borderStyle}
        containerWidth={containerWidth}
        onClick={handleOpenToggle}
        leadingAddon={
          icon && (
            <IconUI
              $variant={variant}
              padding={components.input.padding[variant]}
            >
              {icon}
            </IconUI>
          )
        }
        trailingAddon={
          isClearable && selectedOptions?.length ? (
            <CrossIconUI $variant={variant} onClick={handleRemoveAll} />
          ) : (
            <ArrowDownIconUI $variant={variant} $isCollapsed={isOpen} />
          )
        }
      >
        {selectedCollection && (
          <Badge
            variant="dark"
            size="md"
            background="primary"
            cross
            onClickCross={handleClearCollection}
          >
            {`${inCollectionMessage} : ${selectedCollection}`}
          </Badge>
        )}
        {isSearchable && isOpen ? (
          <InputUI
            ref={inputRef}
            value={search}
            onChange={handleSearchChange}
            onClick={handleSearchClick}
            placeholder={searchPlaceholder}
            $variant={variant}
            autoFocus
          />
        ) : (
          <Container
            padding={components.input.padding[variant]}
            overflow="hidden"
            width="100%"
          >
            {!isMulti ? (
              selectedOptions?.[0] && (
                <Typography
                  styled={valueLabelCss}
                  variant={variant}
                  as="span"
                  color={palette.interface.base}
                >
                  {selectedOptions[0]?.label}
                </Typography>
              )
            ) : (
              <MultiSelectItems
                items={selectedOptions ?? []}
                variant={variant}
                onRemove={handleRemoveItem}
              />
            )}
            {selectedOptions?.length === 0 && (
              <Typography
                styled={valueLabelCss}
                variant={`${variant}-light`}
                as="span"
                color={palette.interface.lighter[200]}
              >
                {isLoading && loadingText ? loadingText : placeholder}
              </Typography>
            )}
          </Container>
        )}
      </AbstractInput>
      <DropdownUI
        isOpen={isOpen && !isLoading}
        anchorEl={anchorEl}
        width="fit-content"
        $minWidth={`${anchorEl?.clientWidth}px`}
        maxHeight={dropdownHeight}
        anchorOrigin={dropdownPosition}
        onClose={handleOpenToggle}
      >
        {isMulti && (selectAllMessage || clearAllMessage) && (
          <DropdownGroup>
            <DropdownActions actions={multiActions || []} />
          </DropdownGroup>
        )}
        {collections.length > 0 && !selectedCollection && (
          <DropdownGroup label={collectionTitle}>
            <DropdownActions actions={collectionActions || []} />
          </DropdownGroup>
        )}
        {searchedOptions.length > 0 ? (
          searchedOptions.map((option, index) =>
            option.type === "option" ? (
              <SelectOption
                key={`${option.value}-${index}`}
                option={option}
                isActive={Array.of(value).flat().includes(option.value)}
                hasCheckbox={isMulti}
                collection={
                  getCollectionCaption(option.collectionId) || undefined
                }
                onClick={handleOptionClick(option)}
              />
            ) : (
              <DropdownGroup
                key={`${option.value}-${index}`}
                label={option.label}
              >
                {option.options.length ? (
                  option.options.map((opt, index) => (
                    <SelectOption
                      key={`${opt.value}-${index}`}
                      option={opt}
                      isActive={Array.of(value).flat().includes(opt.value)}
                      hasCheckbox={isMulti}
                      collection={
                        getCollectionCaption(opt.collectionId) || undefined
                      }
                      onClick={handleOptionClick(opt)}
                    />
                  ))
                ) : (
                  <DropdownItem isDisabled>
                    <Typography
                      variant="text-md"
                      as="p"
                      color={palette.interface.base}
                    >
                      {noOptionsMessage}
                    </Typography>
                  </DropdownItem>
                )}
              </DropdownGroup>
            ),
          )
        ) : (
          <DropdownItem isDisabled>
            <Typography variant="text-md" as="p" color={palette.interface.base}>
              {noOptionsMessage}
            </Typography>
          </DropdownItem>
        )}
      </DropdownUI>
    </>
  );
}

const MemoizedAdvancedSelect = memo(AdvancedSelect);
export { MemoizedAdvancedSelect as AdvancedSelect };
