export function localizeInput(
  element: HTMLInputElement,
  locale?: string,
): void {
  if (!element || !element.value || element.type !== "number") {
    return;
  }
  element.dataset.savedValue = element.value;
  element.dataset.savedType = element.type;
  const valueAsNumber = element.valueAsNumber;
  element.type = "text";
  element.value = window.Intl.NumberFormat(locale).format(valueAsNumber);
}

export function delocalizeInput(element: HTMLInputElement): void {
  if (
    "savedType" in element.dataset === false ||
    element.dataset.savedType !== "number"
  ) {
    return;
  }
  element.value = element.dataset.savedValue ?? "";
  element.type = "number";
  delete element.dataset.savedType;
  delete element.dataset.savedValue;
}

export function unlocalizeNumber(value: unknown): number {
  if (!value) {
    return 0;
  }
  const text = `${value}`;
  const result = parseFloat(
    text
      .replace(/\s/g, "")
      .replace(/,(...)(?=[,.]|$)/g, "$1")
      .replace(/,/, "."),
  );

  if (isNaN(result)) {
    return 0;
  }
  return result;
}
