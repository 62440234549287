import type { JSX } from "react";

import { segmentGroupAnatomy } from "@ark-ui/anatomy";
import {
  SegmentGroup as ArkSegmentGroup,
  type SegmentGroupRootProps,
} from "@ark-ui/react";
import { css, cx, sva } from "@mobsuccess-devops/styled-system/css";
import type { RecipeVariantProps } from "@mobsuccess-devops/styled-system/types";

type TabItem = {
  value: string | number;
  label?: string;
  icon?: JSX.Element;
};

type ArkSegmentGroupProps = {
  options: Array<TabItem>;
} & SegmentGroupRootProps;

type SegmentGroupProps = ArkSegmentGroupProps &
  RecipeVariantProps<typeof segmentGroup>;

export function SegmentGroup({
  className,
  options,
  ...rest
}: SegmentGroupProps): JSX.Element {
  const [variants, elementProps] = segmentGroup.splitVariantProps(rest);

  const classes = segmentGroup(variants);
  const styles = segmentGroup.raw(variants);

  return (
    <ArkSegmentGroup.Root
      {...elementProps}
      className={cx(className, css(styles.root))}
    >
      <ArkSegmentGroup.Indicator className={classes.indicator} />
      {options.map((item) => {
        const hasOnlyIcon = item.icon && !item.label;

        return (
          <ArkSegmentGroup.Item
            className={css(styles.item)}
            key={item.value}
            value={String(item.value)}
            data-has-only-icon={hasOnlyIcon}
          >
            {item.icon}
            {item.label && (
              <ArkSegmentGroup.ItemText className={classes.itemText}>
                {item.label}
              </ArkSegmentGroup.ItemText>
            )}

            <ArkSegmentGroup.ItemControl className={classes.itemControl} />
            <ArkSegmentGroup.ItemHiddenInput />
          </ArkSegmentGroup.Item>
        );
      })}
    </ArkSegmentGroup.Root>
  );
}

const segmentGroup = sva({
  slots: segmentGroupAnatomy.keys(),
  base: {
    root: {
      display: "flex",
      borderRadius: "md",
      width: "fit-content",
      bg: "interface.200",
    },
    item: {
      center: true,
      p: "token(spacing.xs) token(spacing.md)",
      cursor: "pointer",
      gap: "sm",

      transitionProperty: "color",
      transitionDuration: "fast",
      transitionTimingFunction: "ease-in-out",

      color: "interface.500",

      "&:not(:has(> *:nth-child(4)))": {
        gap: 0,
      },

      _childSvg: {
        transitionProperty: "color",
        transitionDuration: "fast",
        transitionTimingFunction: "ease-in-out",
      },
      "&[data-has-only-icon='true']": {
        p: "token(spacing.xs) token(spacing.sm)",
      },
      _hover: {
        color: "interface.600",
        _childSvg: {
          color: "interface.600",
        },
      },
      _checked: {
        bg: "white",
        color: "interface.600",
        borderRadius: "sm",
        _childSvg: {
          color: "interface.600",
        },
      },
    },
  },
  variants: {
    size: {
      sm: {
        root: {
          p: "xs",
        },
        item: {
          _childSvg: {
            size: "16px",
          },
        },
        itemText: {
          textStyle: "muted",
          fontWeight: 400,
        },
      },
      md: {
        root: {
          p: "6px",
        },
        item: {
          _childSvg: {
            size: "20px",
          },
        },
        itemText: {
          textStyle: "body",
        },
      },
      lg: {
        root: {
          p: "sm",
        },
        item: {
          _childSvg: {
            size: "20px",
          },
        },
        itemText: {
          textStyle: "subTitle",
          fontWeight: 500,
        },
      },
      xl: {
        root: {
          p: "sm",
        },
        item: {
          _childSvg: {
            size: "20px",
          },
        },
        itemText: {
          textStyle: "title",
          fontWeight: 400,
        },
      },
    },
  },
  defaultVariants: {
    size: "md",
  },
});
