/* eslint-disable @mobsuccess-devops/mobsuccess/variables */

/* eslint-disable @mobsuccess-devops/mobsuccess/react */
import type { JSX, ReactNode } from "react";

import {
  Portal,
  Select as ArkSelect,
  type SelectRootProps as ArkSelectRootProps,
} from "@ark-ui/react";
import { ReactComponent as DoubleArrowIcon } from "@mobsuccess-devops/streamline/custom/react-ui/double-arrow.svg";
import { cx, sva } from "@mobsuccess-devops/styled-system/css";
import { HStack, Wrap } from "@mobsuccess-devops/styled-system/jsx";

import { useLocales } from "../../../features/localization";
import { dropdownStyles } from "../../../features/shared-styles/dropdown";
import { Typo } from "../../Typo";
import { Button } from "../Button/Button";
import { Checkbox } from "../Checkbox/Checkbox";

import {
  SelectItemStart,
  SelectItemEnd,
  SelectIcon,
  splitSelectDecorators,
  SelectTrigger,
} from "./Decorators";

type SelectItemGroup = {
  type: "group";
  id: string;
  label: string;
  items: SelectItem[];
};

type SelectItem = {
  type?: "item";
  label: string;
  value: string;
  disabled?: boolean;
  decorators?: ReactNode;
};

export type SelectItems = (SelectItem | SelectItemGroup)[];

export type SelectRootProps = Omit<
  ArkSelectRootProps<SelectItem>,
  "items" | "asChild" | "children"
> & {
  width?: string;
  children?: ReactNode;
  items: SelectItems;
  placeholder?: string;
};

function SelectRoot({
  items,
  width,
  children,
  className,
  placeholder,
  ...props
}: SelectRootProps): JSX.Element {
  const flatItems = items
    .flatMap((item) => (item.type === "group" ? item.items : item))
    .map(({ decorators, ...item }) => item);
  const classes = styles();
  const dropdownClasses = dropdownStyles();
  const locales = useLocales();
  const decorators = splitSelectDecorators(children);

  return (
    <ArkSelect.Root
      items={flatItems}
      className={cx(classes.root, className)}
      isItemDisabled={(item) => !!item.disabled}
      {...props}
    >
      <ArkSelect.Control className={classes.control}>
        <ArkSelect.Trigger className={classes.trigger}>
          {decorators.trigger ? (
            decorators.trigger
          ) : (
            <>
              <HStack gap="sm" overflow="hidden">
                {decorators.icon}
                <ArkSelect.ValueText
                  className={classes.placeholder}
                  placeholder={placeholder}
                />
              </HStack>
              <ArkSelect.Indicator className={classes.indicator}>
                <DoubleArrowIcon />
              </ArkSelect.Indicator>
            </>
          )}
        </ArkSelect.Trigger>
      </ArkSelect.Control>
      <Portal>
        <ArkSelect.Positioner>
          <ArkSelect.Content
            className={dropdownClasses.content}
            style={{ width: width ?? "var(--reference-width)" }}
          >
            {props.multiple && (
              <Wrap
                padding="md"
                gap="sm"
                position="sticky"
                top={0}
                backgroundColor="white"
                zIndex={1}
              >
                <ArkSelect.Context>
                  {({ value, setValue }) =>
                    value.length !== flatItems.length && (
                      <Button
                        size="sm"
                        palette="interface"
                        onClick={() =>
                          setValue(
                            flatItems
                              .filter(({ disabled }) => !disabled)
                              .map(({ value }) => value.toString()),
                          )
                        }
                      >
                        {locales.select.selectAll}
                      </Button>
                    )
                  }
                </ArkSelect.Context>
                <ArkSelect.ClearTrigger asChild>
                  <Button palette="interface" size="sm">
                    {locales.select.removeAll}
                  </Button>
                </ArkSelect.ClearTrigger>
              </Wrap>
            )}
            {items.map((item) =>
              item.type === "group" ? (
                <ArkSelect.ItemGroup
                  data-type="group"
                  key={item.id}
                  id={item.id}
                  className={dropdownClasses.group}
                >
                  <ArkSelect.ItemGroupLabel
                    className={dropdownClasses.groupLabel}
                  >
                    {item.label}
                  </ArkSelect.ItemGroupLabel>
                  {item.items.map((item) => (
                    <ArkSelect.Item
                      key={item.value}
                      item={item}
                      className={dropdownClasses.item}
                    >
                      {props.multiple && (
                        <ArkSelect.Context>
                          {({ value }) => (
                            <Checkbox
                              style={{ pointerEvents: "none" }}
                              checked={value.some(
                                (value) => value === item.value,
                              )}
                            />
                          )}
                        </ArkSelect.Context>
                      )}
                      <ArkSelect.ItemText asChild>
                        <HStack>
                          {item.decorators}
                          <Typo.Body order={2}>{item.label}</Typo.Body>
                        </HStack>
                      </ArkSelect.ItemText>
                    </ArkSelect.Item>
                  ))}
                </ArkSelect.ItemGroup>
              ) : (
                <ArkSelect.Item
                  key={item.value}
                  item={item}
                  className={dropdownClasses.item}
                  data-type="item"
                >
                  {props.multiple && (
                    <ArkSelect.Context>
                      {({ value }) => (
                        <Checkbox
                          className={classes.checkbox}
                          checked={value.some((value) => value === item.value)}
                        />
                      )}
                    </ArkSelect.Context>
                  )}
                  <ArkSelect.ItemText asChild>
                    <HStack>
                      {item.decorators}
                      <Typo.Body order={2}>{item.label}</Typo.Body>
                    </HStack>
                  </ArkSelect.ItemText>
                </ArkSelect.Item>
              ),
            )}
          </ArkSelect.Content>
        </ArkSelect.Positioner>
      </Portal>
      <ArkSelect.HiddenSelect />
    </ArkSelect.Root>
  );
}

const styles = sva({
  slots: [
    "root",
    "icon",
    "control",
    "trigger",
    "placeholder",
    "indicator",
    "checkbox",
  ],
  base: {
    root: {
      gap: "sm",
      width: "full",
      position: "relative",
    },
    icon: {
      center: true,
      _childSvg: {
        size: "sm",
      },
      color: "interface",
    },
    control: {
      backgroundColor: "white",
      borderRadius: "md",
      border: "1px solid",
      borderColor: "#959EC9",
      boxShadow:
        "0 0 0 0 color-mix(in srgb, token(colors.primary) 30%, transparent)",
      transition: "box-shadow 0.2s ease-in-out",
      _disabled: {
        pointerEvents: "none",
        opacity: 0.5,
      },
      _hover: {
        borderColor: "interface.400",
      },
      _focus: {
        borderColor: "primary",
        boxShadow:
          "0 0 0 4px color-mix(in srgb, token(colors.primary) 30%, transparent)",
      },
    },
    trigger: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "transparent",
      border: "none",
      paddingX: "sm",
      height: "40px",
      alignItems: "center",
      width: "100%",
    },
    placeholder: {
      textStyle: "body",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "interface",
      textWrap: "nowrap",
    },
    indicator: {
      display: "flex",
      padding: "xs",
      _childSvg: {
        color: "interface",
        size: "8px",
      },
      transition: "ease-in-out 0.2s",
    },
    checkbox: {
      pointerEvents: "none",
    },
  },
});

export const Select = {
  Root: SelectRoot,
  Icon: SelectIcon,
  Trigger: SelectTrigger,
  ItemStart: SelectItemStart,
  ItemEnd: SelectItemEnd,
};
