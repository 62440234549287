import {
  type ChangeEventHandler,
  memo,
  type MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type JSX,
} from "react";

import { ReactComponent as ClockSVG } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/18-Time/time-clock-circle.svg";

import styled from "styled-components";

import { Container } from "../../Container";
import { Dropdown, DropdownItem, DropdownTitle } from "../../Dropdown";
import { AdvancedInput } from "../AdvancedInput/AdvancedInput";

type TimePickerUIProps = {
  $variant: "md" | "lg";
  $borderStyle?: "standard" | "outlined" | "textBackground";
};

const TimePickerUI = styled(AdvancedInput)<TimePickerUIProps>`
  width: ${({ $variant }) => ($variant === "md" ? "95px" : "140px")};
`;

TimePickerUI.displayName = "TimePickerUI";

const ClockContainerUI = styled(Container)<TimePickerUIProps>`
  ${({ $variant, $borderStyle }) =>
    $borderStyle === "outlined" && $variant === "md"
      ? `
    margin-left: 8px;
    margin-right: 2px;
  `
      : $borderStyle === "outlined" && $variant === "lg"
        ? `
    margin-left: 22px;
    margin-right: 8px;
  `
        : $borderStyle !== "outlined" && $variant === "md"
          ? `
    margin-left: 8px;
    margin-right: -10px;
  `
          : `
    margin-left: 20px;
    margin-right: -8px;
  `}
`;

ClockContainerUI.displayName = "ClockContainerUI";

const ClockIconUI = styled(ClockSVG)<TimePickerUIProps>`
  width: ${({ $variant }) => ($variant === "md" ? "16px" : "20px")};
  height: ${({ $variant }) => ($variant === "md" ? "16px" : "20px")};
  color: ${({ theme }) => theme.palette.interface.base};
`;

ClockIconUI.displayName = "ClockIconUI";

const TimePickerListUI = styled(Dropdown)`
  max-height: 350px;
  overflow-y: auto;
`;

TimePickerListUI.displayName = "TimePickerListUI";

const DropdownItemUI = styled(DropdownItem)`
  color: ${({ theme, isDisabled }) =>
    isDisabled
      ? theme.palette.interface.lighter[300]
      : theme.palette.interface.base};
`;

DropdownItemUI.displayName = "DropdownItemUI";
export type TimePickerProps = {
  value?: string;
  step?: number;
  variant?: "md" | "lg";
  range?: Range | Array<Range>;
  disabledTimes?: Array<Range>;
  onChange: (value?: string) => void;
  anchorElement?: HTMLElement;
  dropdownWidth?: string;
  borderStyle?: "standard" | "outlined" | "textBackground";
  locale?: string;
};

type Range = {
  start: string;
  end: string;
};

function getTimeFromRange(range: Range): {
  start: Date;
  end: Date;
} {
  const startMatch = range.start.match(/(\d{2}):(\d{2})/);
  const endMatch = range.end.match(/(\d{2}):(\d{2})/);
  if (!startMatch || !endMatch) {
    throw new Error(`Invalid start or end time: ${range.start} - ${range.end}`);
  }
  const [, startHour, startMinute] = startMatch;
  const [, endHour, endMinute] = endMatch;

  const startTime = new Date();
  startTime.setHours(+startHour);
  startTime.setMinutes(+startMinute);
  startTime.setSeconds(0);
  startTime.setMilliseconds(0);

  const endTime = new Date();
  endTime.setHours(+endHour);
  endTime.setMinutes(+endMinute);
  endTime.setSeconds(0);
  endTime.setMilliseconds(0);

  return {
    start: startTime,
    end: endTime,
  };
}

const defaultDisabledTimes: NonNullable<TimePickerProps["disabledTimes"]> = [];
const defaultRange: NonNullable<TimePickerProps["range"]> = {
  start: "00:00",
  end: "23:59",
};

function TimePicker({
  step = 15,
  value = "12:00",
  variant = "md",
  range = defaultRange,
  onChange,
  disabledTimes = defaultDisabledTimes,
  anchorElement,
  dropdownWidth = "fit-anchor",
  borderStyle = "standard",
  locale,
}: TimePickerProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleFocus = useCallback((e: React.FocusEvent) => {
    if (e.type === "focus") {
      setIsOpen(true);
    }
  }, []);

  const handleCloseDropDown = useCallback(() => {
    setIsOpen(false);
  }, []);

  const options = useMemo(() => {
    const opts: Array<{
      isDisabled: boolean;
      value: Date;
      label: string;
    }> = [];
    const timeRange = Array.isArray(range) ? range : [range];

    for (const rg of timeRange) {
      const { start: startTime, end: endTime } = getTimeFromRange(rg);

      while (startTime.getTime() <= endTime.getTime()) {
        const available = !disabledTimes.find((range) => {
          const { start: unavailableStart, end: unavailableEnd } =
            getTimeFromRange(range);
          return (
            startTime.getTime() >= unavailableStart.getTime() &&
            startTime.getTime() < unavailableEnd.getTime()
          );
        });

        const value = startTime.toLocaleString(locale, {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
        opts.push({
          isDisabled: !available,
          value: new Date(startTime.getTime()),
          label: value,
        });

        startTime.setMinutes(startTime.getMinutes() + step);
      }
    }

    return opts
      .sort((a, b) => {
        return a.value.getTime() - b.value.getTime();
      })
      .map((opt) => ({
        ...opt,
        value: opt.value.toLocaleString(locale, {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        }),
      }))
      .filter((opt) => {
        if (inputValue) {
          return opt.value.startsWith(inputValue);
        }
        return true;
      });
  }, [disabledTimes, inputValue, range, step, locale]);

  const handleSelectElement = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      const { disabled, value } = e.currentTarget.dataset;
      if (disabled === "true") {
        e.stopPropagation();
      }
      onChange(value);
      setInputValue(null);
      setIsOpen(false);
    },
    [onChange],
  );

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (/^(\d{0,2})?(:(\d{0,2})?)?$/.test(e.target.value)) {
        setInputValue(e.target.value);
      } else if (e.target.value === "") {
        setInputValue(e.target.value);
      } else {
        e.preventDefault();
      }
    },
    [],
  );

  useEffect(() => {
    if (!isOpen) {
      setInputValue(null);
    }
  }, [isOpen]);

  return (
    <>
      <Container>
        <TimePickerUI
          placeholder="hh:mm"
          value={inputValue === null ? value : inputValue}
          containerRef={setAnchorEl}
          onChange={handleSearch}
          onFocus={handleToggleFocus}
          onBlur={handleToggleFocus}
          variant={variant}
          $variant={variant}
          borderStyle={borderStyle}
        >
          <ClockContainerUI $variant={variant} $borderStyle={borderStyle}>
            <ClockIconUI $variant={variant} />
          </ClockContainerUI>
        </TimePickerUI>
      </Container>
      <TimePickerListUI
        onClose={handleCloseDropDown}
        anchorOrigin="bottom-right"
        isOpen={isOpen}
        anchorEl={anchorElement ?? anchorEl}
        width={dropdownWidth}
      >
        <DropdownTitle isCentered>Heure</DropdownTitle>
        {options.map((time) => (
          <DropdownItemUI
            key={time.value}
            value={time.value}
            isCentered
            isDisabled={time.isDisabled}
            onClick={handleSelectElement}
          >
            {time.label}
          </DropdownItemUI>
        ))}
      </TimePickerListUI>
    </>
  );
}

const MemoizedTimePicker = memo(TimePicker);
export { MemoizedTimePicker as TimePicker };
