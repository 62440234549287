import {
  FunctionComponentElement,
  JSX,
  ReactNode,
  isValidElement,
} from "react";

import { Dialog as ArkDialog, DialogTitleProps } from "@ark-ui/react";
import { css, cx } from "@mobsuccess-devops/styled-system/css";

type TitleProps = DialogTitleProps;

export function Title({ asChild, ...props }: TitleProps): JSX.Element {
  if (asChild) {
    return <ArkDialog.Title asChild {...props} />;
  }
  return (
    <ArkDialog.Title
      {...props}
      className={cx(
        css({
          gridArea: "title",
          textStyle: "title",
          color: "interface",
        }),
        props.className,
      )}
    />
  );
}

export type TitleElement = FunctionComponentElement<TitleProps>;

export function isTitleElement(node: ReactNode): node is TitleElement {
  return isValidElement(node) && node.type === Title;
}
