/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import {
  Children,
  ReactElement,
  isValidElement,
  JSX,
  type ReactNode,
} from "react";

import { css } from "@mobsuccess-devops/styled-system/css";
import { BoxProps } from "@mobsuccess-devops/styled-system/jsx";

import { Box } from "../../Layout";

export type SelectItemStartProps = {
  children: ReactNode;
};

export function SelectItemStart({
  children,
}: SelectItemStartProps): JSX.Element {
  return (
    <Box order={1} center>
      {children}
    </Box>
  );
}

export type SelectItemEndProps = {
  children: ReactNode;
};

export function SelectItemEnd({ children }: SelectItemEndProps): JSX.Element {
  return (
    <Box order={3} center>
      {children}
    </Box>
  );
}

type SelectIconProps = {
  children: JSX.Element;
};

export function SelectIcon({ children }: SelectIconProps): JSX.Element {
  return (
    <div
      className={css({
        center: true,
        color: "interface",
        _childSvg: {
          size: "sm",
        },
      })}
    >
      {children}
    </div>
  );
}

type SelectIconElement = ReactElement<SelectIconProps, typeof SelectIcon>;
function isSelectIcon(node: ReactNode): node is SelectIconElement {
  return isValidElement(node) && node.type === SelectIcon;
}

type SelectTriggerProps = BoxProps;
// eslint-disable-next-line @mobsuccess-devops/mobsuccess/react
export function SelectTrigger(props: SelectTriggerProps): JSX.Element {
  return <Box center cursor="pointer" {...props} />;
}

type SelectTriggerElement = ReactElement<
  SelectTriggerProps,
  typeof SelectTrigger
>;
function isSelectTrigger(node: ReactNode): node is SelectTriggerElement {
  return isValidElement(node) && node.type === SelectTrigger;
}

type SelectDecorators = {
  icon: SelectIconElement | null;
  trigger: SelectTriggerElement | null;
  rest: ReactNode[];
};

export function splitSelectDecorators(children: ReactNode): SelectDecorators {
  return Children.toArray(children).reduce<SelectDecorators>(
    (acc, child) => {
      if (isSelectIcon(child)) {
        if (acc.icon) {
          throw new Error("Select can only have one icon");
        }
        return { ...acc, icon: child };
      }
      if (isSelectTrigger(child)) {
        if (acc.trigger) {
          throw new Error("Select can only have one trigger");
        }
        return { ...acc, trigger: child };
      }
      return { ...acc, rest: [...acc.rest, child] };
    },
    { icon: null, trigger: null, rest: [] },
  );
}
