import { initSentry, ConsentManager } from "@mobsuccess-devops/react-sentry";

const dsn =
  window._msSentryDsn ??
  "https://4d71a27e1330cdbf82009f1b9f1fe9ba@o1028474.ingest.us.sentry.io/4507577545916416";

export const consentManager = new ConsentManager({
  isConsenting: false, // TODO: implement default consent logic here
  awaitForConsent: true,
  isConsentRequired: false, // TODO: make it required
});

initSentry({
  dsn,
  consentManager,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? "local",
});
