import { memo, JSX } from "react";

import styled from "styled-components";

import { NativeProps } from "../../types/native-props";
import { Container } from "../Container";

import { Block } from "./Block";
import { Inline } from "./Inline";

const BlockLoaderUI = styled(Container)<{ $overlay?: string }>`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 750;
  background-color: ${({ $overlay, theme }) =>
    $overlay === "dark"
      ? `${theme.palette.interface.darker[200]}E5`
      : $overlay === "light"
        ? `${theme.palette.interface.lighter[600]}E5`
        : $overlay === "white"
          ? `${theme.palette.white}E5`
          : $overlay === "base"
            ? `${theme.palette.interface.base}E5`
            : "transparent"};
`;

BlockLoaderUI.displayName = "BlockLoaderUI";

export type BlockLoaderProps = {
  variant?: "inline" | "block";
  overlay?: "white" | "light" | "base" | "dark";
  text?: string;
} & NativeProps<"div">;

function BlockLoader({
  variant = "block",
  overlay,
  text = "",
  ...intrinsicProps
}: BlockLoaderProps): JSX.Element {
  return (
    <BlockLoaderUI
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      $overlay={overlay}
      {...intrinsicProps}
    >
      {variant === "inline" ? (
        <Inline overlay={overlay} />
      ) : (
        <Block text={text} overlay={overlay} />
      )}
    </BlockLoaderUI>
  );
}
const MemoizedComponent = memo(BlockLoader);
export { MemoizedComponent as BlockLoader };
