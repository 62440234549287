export type CardTheme = {
  direction: {
    left: string;
    right: string;
    top: string;
    bottom: string;
  };
  alignItems: {
    left: string;
    right: string;
    top: string;
    bottom: string;
  };
  padding: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  gap: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
};

export const card: CardTheme = {
  direction: {
    left: "row-reverse",
    right: "row",
    top: "column-reverse",
    bottom: "column",
  },
  alignItems: {
    left: "flex-end",
    right: "flex-end",
    top: "flex-start",
    bottom: "flex-start",
  },
  padding: {
    sm: "12px",
    md: "16px",
    lg: "20px",
    xl: "24px",
  },
  gap: {
    sm: "12px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
};
