import { useCallback, useMemo, useState, type JSX } from "react";

import type { I18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { LocalesContext } from "@mobsuccess-devops/react-ui/Locales";

import { LanguageContext, activate, type LanguageContextType } from "./i18n";
import { useReactUILocales } from "./react-ui";

export type LocaleModule = {
  messages: Record<string, string>;
};

type LocalizedProps = {
  i18n: I18n;
  locales: Map<string, LocaleModule["messages"]>;
  children: React.ReactNode;
};

// TODO: update the rule for react-shared
// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export function Localized({
  locales,
  children,
  i18n,
}: LocalizedProps): JSX.Element {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.locale);

  const handleChangeLanguage = useCallback<
    LanguageContextType["onChangeLanguage"]
  >(
    (newLanguage) => {
      activate(i18n, locales, newLanguage);
      setCurrentLanguage(newLanguage);
    },
    [i18n, locales],
  );

  const value = useMemo(() => {
    return {
      language: currentLanguage,
      onChangeLanguage: handleChangeLanguage,
    };
  }, [currentLanguage, handleChangeLanguage]);

  const rui = useReactUILocales();

  return (
    <LanguageContext.Provider value={value}>
      <I18nProvider i18n={i18n}>
        <LocalesContext.Provider value={rui}>
          {children}
        </LocalesContext.Provider>
      </I18nProvider>
    </LanguageContext.Provider>
  );
}
