import { ReactNode, Children, cloneElement } from "react";

import { Table } from "@tanstack/react-table";

import { TableArrangerElement, isTableArrangerElement } from "./Arranger";
import { TableCaptionElement, isTableCaptionElement } from "./Caption";
import { TableFiltersElement, isTableFiltersElement } from "./Filters";
import {
  isTableHeaderContentElement,
  type TableHeaderContentElement,
} from "./HeaderContent";
import { TablePaginatorElement, isTablePaginatorElement } from "./Paginator";
import { isTableVirtualizerElement } from "./Virtualizer";

export type TableDecoratorsElements<T> = {
  caption: TableCaptionElement<T> | null;
  filtering: TableFiltersElement<T> | null;
  pagination: TablePaginatorElement<T> | null;
  arranger: TableArrangerElement<T> | null;
  headerContent: TableHeaderContentElement<T> | null;
};

export function splitTableDecorators<T>(
  children: ReactNode,
  table: Table<T>,
): [TableDecoratorsElements<T>, ReactNode[]] {
  const { rest, ...decorators } = Children.toArray(children).reduce<
    TableDecoratorsElements<T> & { rest: ReactNode[] }
  >(
    (acc, child) => {
      if (isTableCaptionElement<T>(child)) {
        if (typeof child.props.children !== "function") {
          acc.caption = cloneElement(
            child,
            { _table: table },
            child.props.children,
          );
        } else {
          acc.caption = cloneElement(child, {
            _table: table,
            children: child.props.children,
          });
        }
      } else if (isTableFiltersElement<T>(child)) {
        acc.filtering = cloneElement(child, { _table: table });
      } else if (isTablePaginatorElement<T>(child)) {
        acc.pagination = cloneElement(child, { _table: table });
      } else if (isTableVirtualizerElement<T>(child)) {
        return acc;
      } else if (isTableArrangerElement<T>(child)) {
        acc.arranger = cloneElement(child, {
          _table: table,
        });
      } else if (isTableHeaderContentElement<T>(child)) {
        acc.headerContent = cloneElement(child, {
          _table: table,
        });
      }
      return acc;
    },
    {
      caption: null,
      filtering: null,
      pagination: null,
      arranger: null,
      headerContent: null,
      rest: new Array<ReactNode>(),
    },
  );

  return [decorators, rest];
}

export { TableCaption } from "./Caption";
export { TableFilters } from "./Filters";
export { TableArranger } from "./Arranger";
export { TablePaginator } from "./Paginator";
export { TableVirtualizer } from "./Virtualizer";
export { TableHeaderContent } from "./HeaderContent";
