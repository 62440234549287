import type { JSX, ReactElement } from "react";

import { colorPickerAnatomy } from "@ark-ui/anatomy";
import {
  ColorPicker as ArkColorPicker,
  useColorPickerContext,
} from "@ark-ui/react";
import {
  type RecipeVariantProps,
  sva,
} from "@mobsuccess-devops/styled-system/css";

type TriggerProps = RecipeVariantProps<typeof styles> & {
  // ignore the empty object as it ensures props as an object
  // remove comment when adding props
};

export function Trigger({ size }: TriggerProps): JSX.Element {
  const classes = styles({ size });
  const api = useColorPickerContext();

  return (
    <ArkColorPicker.Control className={classes.control}>
      <ArkColorPicker.Trigger className={classes.trigger}>
        <ArkColorPicker.Swatch value={api.value} className={classes.swatch} />
      </ArkColorPicker.Trigger>
    </ArkColorPicker.Control>
  );
}

export type TriggerElement = ReactElement<never, typeof Trigger>;

const styles = sva({
  slots: colorPickerAnatomy.keys(),
  base: {
    control: {
      display: "flex",
      alignItems: "center",
      gap: "sm",
    },
    trigger: {
      padding: "xs",
      cursor: "pointer",
      borderRadius: "md",
      border: "1px solid token(colors.interface.400)",
      backgroundColor: "transparent",
    },
    swatch: {
      size: "full",
      borderRadius: "md",
    },
  },
  variants: {
    size: {
      sm: {
        trigger: {
          size: "32px",
        },
      },
      md: {
        trigger: {
          size: "40px",
        },
      },
      lg: {
        trigger: {
          size: "48px",
        },
      },
      xl: {
        trigger: {
          size: "56px",
        },
      },
    },
  },
  defaultVariants: {
    size: "md",
  },
});
