import { useCallback, useEffect, useState, JSX } from "react";

import { Trans } from "@lingui/macro";
import { Container } from "@mobsuccess-devops/react-ui/Container";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";

import styled, { css, useTheme } from "styled-components";

import { AuthActionEnum } from "../../../public/auth/auth";

const backToLoginCss = css`
  text-decoration: underline;
  cursor: pointer;
`;

const IframeUI = styled.iframe`
  opacity: 0;
  pointer-events: none;
  position: absolute;
`;

type SignoutProps = {
  onChangeAuthAction: (action: AuthActionEnum, state?: object) => void;
};

const timetoRedirect = 10;

function Signout({ onChangeAuthAction }: SignoutProps): JSX.Element {
  const { palette } = useTheme();

  const [time, setTime] = useState(timetoRedirect);

  const handleBackToLogin = useCallback(() => {
    onChangeAuthAction(AuthActionEnum.SignIn);
  }, [onChangeAuthAction]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(handleBackToLogin, timetoRedirect * 1000);
    return () => clearTimeout(timeout);
  }, [handleBackToLogin]);
  return (
    <Container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      gap="24px"
    >
      {window._msSignoutIframeUrl && (
        <IframeUI src={window._msSignoutIframeUrl} />
      )}
      <Typography
        as="span"
        variant="md-light"
        textAlign="center"
        color={palette.interface.lighter[100]}
      >
        <Trans>#Auth.Signout.loggedOut({time})</Trans>
      </Typography>
      <Typography
        as="span"
        styled={backToLoginCss}
        color={palette.primary.base}
        onClick={handleBackToLogin}
      >
        <Trans>#Auth.Signout.back</Trans>
      </Typography>
    </Container>
  );
}

export default Signout;
