import { useMemo, JSX } from "react";

import { Trans, t } from "@lingui/macro";
import { Container } from "@mobsuccess-devops/react-ui/Container";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";
import { ReactComponent as CheckIcon } from "@mobsuccess-devops/streamline/custom/auth/check.svg";
import { ReactComponent as UncheckIcon } from "@mobsuccess-devops/streamline/custom/auth/uncheck.svg";

import { createPortal } from "react-dom";
import styled, { css, useTheme } from "styled-components";

import { PasswordErrors, PasswordRules } from "../../../features/password";

const PasswordTooltipUI = styled(Container)`
  position: absolute;
  z-index: 1000;
  top: calc(100% + 8px);
  border-radius: 4px;
  width: 60%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
`;

const emptyPasswordCss = css`
  background: ${({ theme }) => theme.colors.lightGray[400]};
`;

const weekPasswordCss = css`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.palette.danger.base} 0%,
    ${({ theme }) => theme.palette.danger.base} 33%,
    ${({ theme }) => theme.colors.lightGray[400]} 33%,
    ${({ theme }) => theme.colors.lightGray[400]} 100%
  );
`;

const mediumPasswordCss = css`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.palette.warning.base} 0%,
    ${({ theme }) => theme.palette.warning.base} 66%,
    ${({ theme }) => theme.colors.lightGray[400]} 66%,
    ${({ theme }) => theme.colors.lightGray[400]} 100%
  );
`;

const strongPasswordCss = css`
  background: ${({ theme }) => theme.palette.success.base};
`;

type PasswordStrengthProgressProps = {
  $strength: "strong" | "medium" | "weak" | "empty";
};

const PasswordStrengthProgressUI = styled.div<PasswordStrengthProgressProps>`
  height: 4px;
  width: 100%;
  border-radius: 2px;
  ${({ $strength }) => {
    switch ($strength) {
      case "strong":
        return strongPasswordCss;
      case "medium":
        return mediumPasswordCss;
      case "weak":
        return weekPasswordCss;
      case "empty":
        return emptyPasswordCss;
    }
  }};
`;

type PasswordTooltipProps = {
  show: boolean;
  errors: PasswordErrors["password"];
  anchorElement: HTMLDivElement | null;
};

export default function PasswordTooltip({
  show,
  errors,
  anchorElement,
}: PasswordTooltipProps): JSX.Element | null {
  const { palette } = useTheme();

  const labels = useMemo(() => {
    return {
      [PasswordRules.Length]: t`#Auth.PasswordTooltip.mandatory-rules.length`,
      [PasswordRules.LowerCase]: t`#Auth.PasswordTooltip.mandatory-rules.lowercase`,
      [PasswordRules.UpperCase]: t`#Auth.PasswordTooltip.mandatory-rules.uppercase`,
      [PasswordRules.Number]: t`#Auth.PasswordTooltip.mandatory-rules.number`,
      [PasswordRules.SpecialCharacter]: t`#Auth.PasswordTooltip.mandatory-rules.special-character`,
    };
  }, []);

  const { strength, strengthLabel } = useMemo(() => {
    if (errors.isEmpty) {
      return {
        strength: "empty" as const,
        strengthLabel: undefined,
      };
    }
    if (errors.mandatory.some((rule) => !rule.isValid)) {
      return {
        strength: "weak" as const,
        strengthLabel: t`#Auth.PasswordTooltip.security.weak`,
      };
    }
    if (errors.recommended.some((rule) => !rule.isValid)) {
      return {
        strength: "medium" as const,
        strengthLabel: t`#Auth.PasswordTooltip.security.medium`,
      };
    }
    return {
      strength: "strong" as const,
      strengthLabel: t`#Auth.PasswordTooltip.security.strong`,
    };
  }, [errors.isEmpty, errors.mandatory, errors.recommended]);

  if (!anchorElement || !show) {
    return null;
  }
  return createPortal(
    <PasswordTooltipUI
      gap="12px"
      padding="12px"
      flexDirection="column"
      backgroundColor={palette.white}
    >
      <Container
        flexDirection="column"
        gap="8px"
        width="100%"
        justifyContent="center"
      >
        <Typography as="span" variant="sm-700" color={palette.interface.base}>
          <Trans>#Auth.PasswordTooltip.mandatory-rules</Trans>
        </Typography>
        {errors.mandatory.map((rule) => (
          <Container
            flexDirection="row"
            gap="4px"
            key={rule.kind}
            padding="0 0 0 8px"
          >
            {rule.isValid ? (
              <CheckIcon color={palette.success.darker[100]} />
            ) : (
              <UncheckIcon color={palette.danger.base} />
            )}
            <Typography as="span" variant="xs" color={palette.interface.base}>
              {labels[rule.kind]}
            </Typography>
          </Container>
        ))}
      </Container>
      <Container flexDirection="column" gap="8px" width="100%">
        <Typography as="span" variant="sm-700" color={palette.interface.base}>
          <Trans>#Auth.PasswordTooltip.security</Trans> {strengthLabel}
        </Typography>
        <PasswordStrengthProgressUI $strength={strength} />
      </Container>
      <Typography as="span" variant="xs" color={palette.interface.base}>
        <Trans>#Auth.PasswordTooltip.recommended-rules</Trans>
      </Typography>
    </PasswordTooltipUI>,
    anchorElement,
  );
}
