import { sva } from "@mobsuccess-devops/styled-system/css";

export const dropdownStyles = sva({
  slots: [
    "trigger",
    "content",
    "item",
    "itemPrefix",
    "groupLabel",
    "group",
    "checkbox",
  ],
  base: {
    content: {
      backgroundColor: "white",
      borderRadius: "md",
      boxShadow: "md",
      overflow: "auto",
      animation: "appear 0.2s ease-in-out",
      zIndex: 10000,
      maxHeight: "300px",
      outline: "none",
    },
    item: {
      display: "flex",
      alignItems: "center",
      gap: "sm",
      padding: "12px",
      cursor: "pointer",
      "&:is(:hover, [data-hover]), &:is(:checked, [data-checked], [aria-checked=true], [data-state='checked'])":
        {
          transition: "ease-in-out 0.2s",
          backgroundColor: "primary.200",
          "& > *": {
            color: "primary",
          },
        },
      "[data-type='group'] + &": {
        borderTop: "1px solid",
        borderColor: "#BCC1DE",
      },
      _disabled: {
        cursor: "not-allowed",
        opacity: 0.5,
        backgroundColor: "white!",
        "& > *": {
          color: "interface!",
        },
      },
    },
    itemPrefix: {
      size: "20px",
    },
    groupLabel: {
      paddingY: "md",
      paddingX: "sm",
      textStyle: "muted",
      color: "interface.400",
      textTransform: "uppercase",
    },
    group: {
      "&:not(:first-child)": {
        borderTop: "1px solid",
        borderColor: "#BCC1DE",
      },
    },
  },
});
