import { FormEventHandler, useCallback, useState } from "react";

export enum PasswordRules {
  LowerCase = "lowercase",
  UpperCase = "uppercase",
  Number = "number",
  Length = "length",
  SpecialCharacter = "special-character",
}

export enum PasswordFailLevel {
  Mandatory = "mandatory",
  Recommended = "recommended",
}

const passwordRules = [
  {
    kind: PasswordRules.LowerCase,
    regex: /[a-z]/,
    level: PasswordFailLevel.Mandatory,
  },
  {
    kind: PasswordRules.UpperCase,
    regex: /[A-Z]/,
    level: PasswordFailLevel.Mandatory,
  },
  {
    kind: PasswordRules.Number,
    regex: /[0-9]/,
    level: PasswordFailLevel.Mandatory,
  },
  {
    kind: PasswordRules.Length,
    regex: /(.+){8,}/,
    level: PasswordFailLevel.Mandatory,
  },
  {
    kind: PasswordRules.SpecialCharacter,
    regex: /[^a-zA-Z0-9]/,
    level: PasswordFailLevel.Recommended,
  },
];

export type PasswordFail = {
  kind: PasswordRules;
  isValid: boolean;
};

export enum PasswordError {
  None = "none",
  Empty = "empty",
  MissMatch = "miss-match",
  Weak = "weak",
}

export function validatePassword(
  password: string,
): Record<PasswordFailLevel, PasswordFail[]> & { isEmpty: boolean } {
  const fails: Record<PasswordFailLevel, PasswordFail[]> & {
    isEmpty: boolean;
  } = {
    isEmpty: !password,
    [PasswordFailLevel.Mandatory]: [],
    [PasswordFailLevel.Recommended]: [],
  };

  passwordRules.forEach((rule) => {
    fails[rule.level].push({
      kind: rule.kind,
      isValid: rule.regex.test(password),
    });
  });

  return fails;
}

export type PasswordErrors = {
  password: Record<PasswordFailLevel, PasswordFail[]> & {
    isEmpty: boolean;
  };
  confirm: boolean;
};

export function usePasswordValidation(): [
  PasswordErrors,
  FormEventHandler<HTMLFormElement>,
] {
  const [errors, setErrors] = useState<PasswordErrors>({
    password: {
      isEmpty: true,
      [PasswordFailLevel.Mandatory]: passwordRules
        .filter((rule) => rule.level === PasswordFailLevel.Mandatory)
        .map((rule) => ({ kind: rule.kind, isValid: false })),
      [PasswordFailLevel.Recommended]: passwordRules
        .filter((rule) => rule.level === PasswordFailLevel.Recommended)
        .map((rule) => ({ kind: rule.kind, isValid: false })),
    },
    confirm: false,
  });

  const handlePasswordChange = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      const password = event.currentTarget.password.value;
      const confirm = event.currentTarget["confirm-password"].value;

      const passwordErrors = validatePassword(password);
      setErrors({
        password: passwordErrors,
        confirm: password !== confirm,
      });
    },
    [],
  );

  return [errors, handlePasswordChange];
}
