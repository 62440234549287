import {
  cva,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

export const subTitleStyle = cva({
  base: {
    textStyle: "subTitle",
    color: "interface",
  },
});

export type SubTitleProps = RecipeVariantProps<typeof subTitleStyle>;

export const SubTitle = styled("h2", subTitleStyle);
