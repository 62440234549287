import { CSSProperties, ReactElement, ReactNode, JSX } from "react";

import {
  RecipeVariantProps,
  cva,
  cx,
} from "@mobsuccess-devops/styled-system/css";

import { Typo } from "../../../Typo";

type FeedbackProps = {
  className?: string;
  children?: ReactNode;
} & RecipeVariantProps<typeof styles>;

export function Feedback({
  children,
  className,
  ...variants
}: FeedbackProps): JSX.Element {
  const classname = styles(variants);
  const css: CSSProperties = {
    gridArea: variants.position === "start" ? "start-feedback" : "end-feedback",
  };

  return (
    <Typo.Muted className={cx(classname, className)} style={css}>
      {children}
    </Typo.Muted>
  );
}

const styles = cva({
  base: {
    color: "colorPalette",
    colorPalette: "interface",
    _empty: {
      display: "none",
    },
  },
  variants: {
    position: {
      start: {
        justifySelf: "start",
      },
      end: {
        justifySelf: "end",
        textAlign: "right",
      },
    },
    state: {
      default: {},
      warning: {
        colorPalette: "warning",
      },
      error: {
        colorPalette: "danger",
      },
    },
  },
  defaultVariants: {
    position: "end",
    state: "default",
  },
});

export type FeedbackElement = ReactElement<FeedbackProps, typeof Feedback>;
