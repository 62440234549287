import {
  cva,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

export const titleStyle = cva({
  base: {
    textStyle: "title",
    color: "interface",
  },
});

export type TitleProps = RecipeVariantProps<typeof titleStyle>;

export const Title = styled("h1", titleStyle);
