/**
 * This function is used to sort items with a specific order of discriminant.
 * When the order do not contains all discriminant, the remaining items are appended at the end.
 */
export function sortItems<T>(
  items: T[],
  discriminate: (item: T) => string | number,
  order: Array<string | number>,
): Array<T> {
  const remaining = new Map(items.map((item) => [discriminate(item), item]));
  let sortedItems: T[] = [];

  for (const id of order) {
    const item = remaining.get(id);
    if (item) {
      sortedItems.push(item);
      remaining.delete(id);
    }
  }

  sortedItems = sortedItems.concat([...remaining.values()]);

  return sortedItems;
}
