import { ForwardedRef, memo, MouseEvent, forwardRef, JSX } from "react";

import { ReactComponent as CrossIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";

import styled, { css, useTheme } from "styled-components";

import { Sizes } from "../../../../../types/sizes";
import { Container } from "../../../../Container";
import { Typography } from "../../../../Typography";

const MultiSelectItemUI = styled(Container)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.primary.lighter[600]};
`;

const IconButtonUI = styled.button`
  all: unset;
  width: 8px;
  height: 8px;
  font-size: 0;
  cursor: pointer;
`;

IconButtonUI.displayName = "IconButtonUI";

const typographyCss = css`
  white-space: nowrap;
`;

export type MultiSelectItemProps = {
  children: string;
  variant: Sizes;
  isRest?: boolean;
  onClick?: (_: MouseEvent) => void;
};

function MultiSelectItem(
  { children, variant, isRest = false, onClick }: MultiSelectItemProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const { palette } = useTheme();

  return (
    <MultiSelectItemUI
      ref={ref}
      alignItems="center"
      justifyContent="center"
      padding={variant === "sm" || variant === "md" ? "4px 8px" : "6px 10px"}
      gap={variant === "sm" || variant === "md" ? "12px" : "14px"}
      backgroundColor={isRest ? palette.white : palette.primary.lighter[700]}
    >
      <Typography
        as="p"
        variant={variant}
        color={palette.primary.lighter[isRest ? 200 : 100]}
        styled={typographyCss}
      >
        {children}
      </Typography>
      {!isRest && (
        <IconButtonUI onClick={onClick} type="button">
          <CrossIcon color={palette.primary.lighter[100]} />
        </IconButtonUI>
      )}
    </MultiSelectItemUI>
  );
}

const MemoizedComponent = memo(forwardRef(MultiSelectItem));
export { MemoizedComponent as MultiSelectItem };
