import { ReactNode, memo, useCallback, useMemo, JSX } from "react";

import styled, { css, useTheme } from "styled-components";

import {
  getAllDaysInMonthUI,
  useDatePickerContext,
  useWeekdaysLabels,
} from "../../../../features/date-picker";
import { Typography } from "../../../Typography";

import { CardDay } from "./CardDay/CardDay";

type CalendarItemUIProps = {
  $possiblyInvisible: boolean;
};

const CalendarItemUI = styled.div<CalendarItemUIProps>`
  flex: 1;
  padding: 20px;
  width: 320px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${({ $possiblyInvisible }) => $possiblyInvisible && "display: none;"}
  }
`;
CalendarItemUI.displayName = "CalendarItemUI";

const CalendarMonthsWrapperUI = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 40px);
  margin-top: 10px;
`;
CalendarMonthsWrapperUI.displayName = "CalendarMonthsWrapperUI";

const CalendarDaysWrapperUI = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 40px);
`;
CalendarDaysWrapperUI.displayName = "CalendarDaysWrapperUI";

const customTextMonthCss = css`
  display: flex;
  justify-content: center;
`;

function getLocaleDate(
  {
    year,
    month,
  }: {
    year: number;
    month: number;
  },
  locale?: string,
): string {
  const date = new Date(year, month, 1);
  const m = date.toLocaleString(locale, { month: "long" });
  const y = date.toLocaleString(locale, { year: "numeric" });
  return `${m.slice(0, 3)}${m.length > 3 ? "." : ""} ${y}`;
}

export type MonthProps = {
  children?: ReactNode;
  current: Date;
  month: {
    year: number;
    month: number;
  };
  possiblyInvisible?: boolean;
};

function Month({
  month,
  current,
  possiblyInvisible = false,
}: MonthProps): JSX.Element {
  const theme = useTheme();
  const { locale, setSelectedRange, allowedDate } = useDatePickerContext();

  const daysOfWeek = useWeekdaysLabels(locale);

  const handleSelectDate = useCallback(
    (date: Date) => {
      if (
        !allowedDate.start ||
        date < allowedDate.start ||
        !allowedDate.end ||
        date > allowedDate.end
      ) {
        return;
      }
      setSelectedRange?.((range) => {
        const { start, end } = range ?? {};
        if (!range || !start) {
          return {
            start: date,
          };
        }
        if (!end) {
          if (date < start) {
            return {
              start: date,
              end: start,
            };
          }

          return {
            start,
            end: date,
          };
        }
        return {
          start: date,
        };
      });
    },
    [allowedDate.end, allowedDate.start, setSelectedRange],
  );

  const days = useMemo(() => {
    return getAllDaysInMonthUI(month, allowedDate);
  }, [allowedDate, month]);
  return (
    <CalendarItemUI $possiblyInvisible={possiblyInvisible}>
      <Typography
        as="span"
        variant="text-sm-bold"
        color={theme.palette.interface.lighter[200]}
        transform="capitalize"
      >
        {getLocaleDate(month, locale)}
      </Typography>
      <CalendarMonthsWrapperUI>
        <Typography
          as="span"
          variant="text-sm-bold"
          color={theme.palette.interface.lighter[200]}
          styled={customTextMonthCss}
        >
          {daysOfWeek["sunday"]}
        </Typography>
        <Typography
          as="span"
          variant="text-sm-bold"
          color={theme.palette.interface.lighter[200]}
          styled={customTextMonthCss}
        >
          {daysOfWeek["monday"]}
        </Typography>
        <Typography
          as="span"
          variant="text-sm-bold"
          color={theme.palette.interface.lighter[200]}
          styled={customTextMonthCss}
        >
          {daysOfWeek["tuesday"]}
        </Typography>
        <Typography
          as="span"
          variant="text-sm-bold"
          color={theme.palette.interface.lighter[200]}
          styled={customTextMonthCss}
        >
          {daysOfWeek["wednesday"]}
        </Typography>
        <Typography
          as="span"
          variant="text-sm-bold"
          color={theme.palette.interface.lighter[200]}
          styled={customTextMonthCss}
        >
          {daysOfWeek["thursday"]}
        </Typography>
        <Typography
          as="span"
          variant="text-sm-bold"
          color={theme.palette.interface.lighter[200]}
          styled={customTextMonthCss}
        >
          {daysOfWeek["friday"]}
        </Typography>
        <Typography
          as="span"
          variant="text-sm-bold"
          color={theme.palette.interface.lighter[200]}
          styled={customTextMonthCss}
        >
          {daysOfWeek["saturday"]}
        </Typography>
      </CalendarMonthsWrapperUI>
      <CalendarDaysWrapperUI>
        {days.map((d) => (
          <CardDay
            key={d.date.getTime()}
            day={d}
            currentDate={current}
            onClick={handleSelectDate}
          />
        ))}
      </CalendarDaysWrapperUI>
    </CalendarItemUI>
  );
}

const MemoizedComponent = memo(Month);
export { MemoizedComponent as Month };
