import type { ReactNode, JSX } from "react";

import { switchAnatomy } from "@ark-ui/anatomy";
import {
  Switch as ArkSwitch,
  type SwitchRootProps,
} from "@ark-ui/react/switch";
import { ReactComponent as CheckIcon } from "@mobsuccess-devops/streamline/custom/react-ui/check.svg";
import { ReactComponent as UncheckIcon } from "@mobsuccess-devops/streamline/custom/react-ui/uncheck.svg";
import { sva } from "@mobsuccess-devops/styled-system/css";
import type { RecipeVariantProps } from "@mobsuccess-devops/styled-system/types";

export type SwitchProps = Omit<SwitchRootProps, "asChild" | "children"> & {
  label?: ReactNode;
  icon?: boolean;
} & RecipeVariantProps<typeof styles>;

export function Switch({
  label,
  icon,
  type,
  size,
  variant,
  ...props
}: SwitchProps): JSX.Element {
  const classes = styles({ size, variant, type });

  return (
    <ArkSwitch.Root {...props} className={classes.root}>
      <ArkSwitch.Control className={classes.control}>
        <ArkSwitch.Thumb className={classes.thumb}>
          {icon &&
            (props.checked ? (
              <CheckIcon data-checked="true" className={classes.icon} />
            ) : (
              <UncheckIcon className={classes.icon} />
            ))}
        </ArkSwitch.Thumb>
      </ArkSwitch.Control>
      {label && (
        <ArkSwitch.Label className={classes.label}>{label}</ArkSwitch.Label>
      )}
      <ArkSwitch.HiddenInput />
    </ArkSwitch.Root>
  );
}

const styles = sva({
  slots: switchAnatomy.extendWith("icon").keys(),
  base: {
    root: {
      display: "inline-flex",
      colorPalette: "interface",
    },
    control: {
      position: "relative",
      borderRadius: "12px",
      cursor: "pointer",
      backgroundColor: "colorPalette",
      _hover: {
        backgroundColor: "colorPalette",
      },
      _checked: {
        backgroundColor: "colorPalette",
      },
      _disabled: {
        cursor: "not-allowed",
        opacity: 0.5,
      },
      _before: {
        content: "''",
        position: "absolute",
        inset: "-4px",
        outline: "none",
        border: "2px solid",
        borderColor: "transparent",
        background: "none",
        padding: "2px",
      },
      _focus: {
        _before: {
          borderRadius: "20px",
          borderColor: "colorPalette",
        },
      },
    },
    thumb: {
      backgroundColor: "white",
      borderRadius: "50%",
      right: "calc(50%)",
      position: "absolute",
      top: "2px",
      width: "calc(50% - 2px)",
      height: "calc(100% - 4px)",
      transition: "right ease 0.25s",
      display: "flex",
      justifyContent: "center",
      _childSvg: {
        margin: "auto",
      },
      _checked: {
        right: "2px",
      },
    },
    label: {
      margin: "auto 12px",
      color: "interface",
    },
    icon: {
      color: "colorPalette.400",
      _checked: {
        color: "colorPalette",
      },
    },
  },
  variants: {
    variant: {
      interface: { root: { colorPalette: "interface" } },
      primary: { root: { colorPalette: "primary" } },
      success: { root: { colorPalette: "success" } },
      warning: { root: { colorPalette: "warning" } },
    },
    type: {
      default: {
        control: {
          backgroundColor: "interface.400",
          _hover: { backgroundColor: "interface" },
          _focus: {
            _before: {
              borderRadius: "20px",
              borderColor: "interface",
            },
          },
          _checked: {
            backgroundColor: "primary.400",
            _hover: { backgroundColor: "primary" },
            _focus: {
              _before: {
                borderRadius: "20px",
                borderColor: "primary",
              },
            },
          },
        },
        icon: {
          color: "interface.400",
          _hover: { color: "interface" },
          _checked: {
            color: "primary.400",
            _hover: { color: "primary" },
          },
        },
      },
      progress: {
        control: {
          backgroundColor: "warning.400",
          _hover: { backgroundColor: "warning" },
          _focus: {
            _before: {
              borderRadius: "20px",
              borderColor: "warning",
            },
          },
          _checked: {
            backgroundColor: "success.400",
            _hover: { backgroundColor: "success" },
            _focus: {
              _before: {
                borderRadius: "20px",
                borderColor: "success",
              },
            },
          },
        },
        icon: {
          color: "warning.400",
          _hover: { color: "warning" },
          _checked: {
            color: "success.400",
            _hover: { color: "success" },
          },
        },
      },
    },
    size: {
      sm: {
        control: {
          width: "32px",
          height: "16px",
        },
        thumb: {
          width: "calc(50% - 4px)",
          height: "calc(100% - 4px)",
          margin: "0 2px 0 0",
          _checked: {
            margin: "0 0 0 2px",
          },
        },
        label: {
          textStyle: "sm",
        },
      },
      md: {
        control: {
          width: "44px",
          height: "24px",
        },
        label: {
          textStyle: "md",
        },
      },
    },
  },
  defaultVariants: {
    size: "md",
    variant: "interface",
  },
});
