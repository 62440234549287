import {
  cva,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

export const iconButtonStyles = cva({
  base: {
    center: true,
    borderRadius: "sm",
    position: "relative",
    outline: "none",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    transition: "background .2s ease-in-out",
    _childSvg: {
      transition: "height .2s ease-in-out, width .2s ease-in-out",
    },
    _focus: {
      background: "interface.200",
    },
    _disabled: {
      opacity: "0.5",
      cursor: "not-allowed",
      background: "transparent!",
      color: "interface.400",
    },
  },
  variants: {
    size: {
      xs: {
        size: "24px",
        _childSvg: {
          size: "12px",
        },
        _hover: {
          _childSvg: {
            size: "16px",
          },
          _disabled: {
            _childSvg: {
              size: "12px",
            },
          },
        },
        _focus: {
          bg: "interface.200",
        },
      },
      sm: {
        size: "28px",
        _childSvg: {
          size: "16px",
        },
        _hover: {
          _childSvg: {
            size: "20px",
          },
          _disabled: {
            _childSvg: {
              size: "16px",
            },
          },
        },

        _focus: {
          bg: "interface.200",
        },
      },
      md: {
        size: "40px",
        _childSvg: {
          size: "20px",
        },
        _hover: {
          _childSvg: {
            size: "24px",
          },
          _disabled: {
            _childSvg: {
              size: "20px",
            },
          },
        },
        _focus: {
          bg: "interface.200",
        },
      },
      lg: {
        size: "48px",
        _childSvg: {
          size: "24px",
        },
        _hover: {
          _childSvg: {
            size: "28px",
          },
          _disabled: {
            _childSvg: {
              size: "24px",
            },
          },
        },
      },
      _focus: {
        bg: "interface.200",
      },
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

export type IconButtonProps = RecipeVariantProps<typeof iconButtonStyles>;

export const IconButton = styled("button", iconButtonStyles);
