import { cloneElement, type CSSProperties, type JSX } from "react";

import {
  sva,
  cx,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { HStack } from "@mobsuccess-devops/styled-system/jsx";

import { Label } from "../../_PandaArk/Label/Label";

import { type FieldDecorators, splitTooltipElement } from "./decorators";

export type FieldLayoutProps = RecipeVariantProps<typeof styles> & {
  name: string;
  label?: string;
  labelId?: string;
  htmlFor?: string;
  required?: boolean;
  description?: string;
  offsetLabel?: string;
  children: JSX.Element;
  decorators?: FieldDecorators;
};

export function FieldLayout({
  name,
  label,
  labelId,
  htmlFor,
  children,
  required,
  decorators,
  description,
  orientation,
  offsetLabel,
}: FieldLayoutProps): JSX.Element {
  const classes = styles({
    orientation,
  });

  const gridArea: CSSProperties = { gridArea: name };

  const [tooltip, feedbacks] = splitTooltipElement(decorators);

  return (
    <div className={classes.root} style={gridArea}>
      <HStack
        className={classes.label}
        // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react -- no need to memoize styles as they are not passed through react
        style={{
          height: offsetLabel ? offsetLabel : undefined,
        }}
      >
        {label && (
          <Label
            id={labelId}
            htmlFor={htmlFor ?? name}
            required={required}
            color="interface.400"
            description={description}
          >
            {label}
          </Label>
        )}
        {tooltip}
      </HStack>
      {cloneElement(children, {
        className: cx(classes.field, children.props.className),
      })}
      {feedbacks}
    </div>
  );
}

const styles = sva({
  slots: ["root", "label", "field"],
  base: {
    root: { display: "grid", gap: 1 },
    label: { gridArea: "label", alignContent: "center" },
    field: { gridArea: "field" },
  },
  defaultVariants: { orientation: "vertical" },
  variants: {
    orientation: {
      horizontal: {
        root: {
          gridTemplateAreas: `"label field field"
                              ". start-feedback end-feedback"`,
          justifyContent: "flex-start",
          alignItems: "center",
        },
      },
      vertical: {
        root: {
          gridTemplateAreas: `"label label"
                              "field field"
                              "start-feedback end-feedback"
                            `,
          alignContent: "flex-start",
          justifyContent: "space-between",
        },
      },
    },
  },
});
