import {
  memo,
  useMemo,
  Children,
  useState,
  useCallback,
  MouseEventHandler,
  useImperativeHandle,
  ForwardedRef,
  forwardRef,
  FocusEventHandler,
  useEffect,
  JSX,
} from "react";

import styled, { css, useTheme } from "styled-components";

import {
  delocalizeInput,
  localizeInput,
  unlocalizeNumber,
} from "../../../features/input/number-localized";
import { NativeProps } from "../../../types/native-props";
import { AbstractInput } from "../../AbstractInput";
import { Container } from "../../Container";
import { Typography } from "../../Typography";

type InputUIProps = {
  $variant: "sm" | "md" | "lg" | "xl";
  $textPosition: "start" | "end";
  $borderStyle?: "standard" | "outlined" | "textBackground";
};

const InputUI = styled.input<InputUIProps>`
  position: relative;
  font-size: ${({ theme, $variant }) =>
    theme.components.input.fontSize[$variant]};
  cursor: inherit;
  width: 100%;
  text-align: ${({ $textPosition }) => $textPosition};
  border: none;
  outline: none;
  color: ${({ theme }) => theme.palette.interface.base};
  background-color: transparent;
  &::placeholder {
    color: ${({ theme }) => theme.palette.interface.lighter[300]};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  &:hover {
    ${({ $borderStyle, theme }) =>
      $borderStyle === "textBackground" &&
      css`
        background-color: ${theme.palette.interface.lighter[700]};
      `}
  }

  &:focus {
    ${({ $borderStyle, theme }) =>
      $borderStyle === "textBackground" &&
      css`
        background-color: ${theme.palette.interface.lighter[700]};
      `}
  }
`;

InputUI.displayName = "InputUI";

const textDecorationCss = css`
  pointer-events: none;
`;

type IgnoredIntrinsicProps = "disabled" | "onClick";

export type AdvancedInputProps = {
  prefix?: string;
  suffix?: string;
  compact?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  locale?: string;
  containerWidth?: string;
  value?: string | number;
  textPosition?: "start" | "end";
  variant?: "sm" | "md" | "lg" | "xl";
  state?: "warning" | "error" | "success";
  addonPosition?: "leading" | "trailing";
  onClick?: MouseEventHandler<HTMLDivElement>;
  containerRef?: ForwardedRef<HTMLDivElement>;
  children?: JSX.Element | [JSX.Element, JSX.Element];
  borderStyle?: "standard" | "outlined" | "textBackground";
  /** @deprecated use Label component instead */
  label?: string;
  caption?: string;
  numberOptions?: Intl.NumberFormatOptions | undefined;
} & NativeProps<"input", IgnoredIntrinsicProps>;

type AdvancedInputNumberProps = AdvancedInputProps;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function AdvancedInputNumber(
  {
    type,
    defaultValue,
    value,
    numberOptions,
    ...props
  }: AdvancedInputNumberProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const normalize = (value: string) =>
    value === "" ? "" : String(Number(value));
  const localize: (value: string) => string = useCallback(
    (value: string) =>
      value === "" || isNaN(Number(value))
        ? ""
        : Number(value).toLocaleString(props.locale, numberOptions),
    [props.locale, numberOptions],
  );

  const [stringValue, setStringValue] = useState<string>("");
  useEffect(() => {
    const newValue: number | undefined =
      typeof defaultValue === "number"
        ? defaultValue
        : typeof defaultValue === "string"
          ? defaultValue === ""
            ? undefined
            : Number(defaultValue)
          : !value && value !== 0
            ? undefined
            : Number(value);
    const newLocalizeValue: string =
      newValue === undefined ? "" : localize(String(newValue));
    setStringValue((oldValue) => {
      const val =
        normalize(String(unlocalizeNumber(oldValue))) ===
        normalize(String(newValue))
          ? oldValue
          : newValue !== undefined
            ? newLocalizeValue
            : "";
      return val;
    });
  }, [value, defaultValue, localize, setStringValue]);

  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const newValue = event.currentTarget.value;
      const newStringValue: string = (() => {
        if (newValue === null || newValue === undefined || newValue === "") {
          return "";
        } else {
          return String(unlocalizeNumber(newValue));
        }
      })();
      setStringValue((oldValue) => {
        if (
          props.onChange &&
          normalize(oldValue) !== normalize(newStringValue)
        ) {
          const valueAsNumber =
            newStringValue === ""
              ? NaN
              : numberOptions
                ? unlocalizeNumber(
                    Number(newStringValue).toLocaleString(
                      undefined,
                      numberOptions,
                    ),
                  )
                : Number(newStringValue);

          props.onChange({
            ...event,
            currentTarget: {
              ...{
                ...event.currentTarget,
                id: event.currentTarget?.id,
                name: event.currentTarget?.name,
                className: event.currentTarget?.className,
                value: newStringValue,
                valueAsNumber,
              },
            },
            target: {
              ...{
                ...event.target,
                id: event.target?.id,
                name: event.target?.name,
                className: event.target?.className,
                value: newStringValue,
                valueAsNumber,
              },
            },
          });
        }
        return newValue;
      });
    },
    [props, numberOptions],
  );
  const onFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(() => {
    setStringValue((oldValue) => {
      if (oldValue === "") {
        return oldValue;
      }
      return unlocalizeNumber(oldValue).toLocaleString(props.locale, {
        ...numberOptions,
        useGrouping: false,
      });
    });
  }, [numberOptions, props.locale]);

  const onBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      const newValue = event.currentTarget.value;
      const newStringValue =
        newValue === "" ? "" : String(unlocalizeNumber(newValue));
      if (newValue) {
        setStringValue(localize(newStringValue));
      }
      if (props.onBlur) {
        props.onBlur({
          ...event,
          currentTarget: {
            ...{
              ...event.currentTarget,
              id: event.currentTarget?.id,
              name: event.currentTarget?.name,
              className: event.currentTarget?.className,
              value: newStringValue,
              valueAsNumber:
                newStringValue === "" ? NaN : Number(newStringValue),
            },
          },
          target: {
            ...{
              ...event.target,
              id: event.target?.id,
              name: event.target?.name,
              className: event.target?.className,
              value: newStringValue,
              valueAsNumber:
                newStringValue === "" ? NaN : Number(newStringValue),
            },
          },
        });
      }
    },
    [localize, props],
  );
  const newProps = {
    ...props,
    type: "text",
    value: stringValue,
    onChange,
    onFocus,
    onBlur,
    numberOptions: undefined,
  };
  return AdvancedInputText(newProps, ref);
}

export const numberOptionsDecimal1 = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
};

export const numberOptionsDecimal2 = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const numberOptionsDecimal0 = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const numberOptionsDecimal0To2 = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

type AdvancedInputTextProps = AdvancedInputProps;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function AdvancedInputText(
  {
    id,
    state,
    suffix,
    prefix,
    locale,
    compact = false,
    variant = "md",
    onClick,
    onChange,
    children,
    className,
    isDisabled = false,
    placeholder,
    textPosition = "start",
    containerRef,
    addonPosition,
    containerWidth,
    borderStyle = "standard",
    label,
    caption,
    ...inputProps
  }: AdvancedInputTextProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  if (Children.count(children) > 2) {
    throw new Error(
      "AdvancedInput can only have 2 children, leading and trailing",
    );
  }

  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null,
  );

  const mode = useMemo(() => {
    if ("value" in inputProps) {
      if ("defaultValue" in inputProps) {
        throw new Error(
          "AdvancedInput can only have one of defaultValue or value",
        );
      }
      return "controlled";
    }
    return "uncontrolled";
  }, [inputProps]);

  const { value, defaultValue } = inputProps;

  const [isEmpty, setIsEmpty] = useState<boolean>(() => {
    if (mode === "controlled") {
      return value === undefined || value === null || value === "";
    }
    return defaultValue === undefined || defaultValue === null;
  });

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (onChange) {
        onChange(event);
      }
      setIsEmpty(event.currentTarget.value === "");
    },
    [onChange],
  );

  const handleClickWrapper = useCallback(() => {
    if (inputElement) {
      inputElement.focus();
    }
  }, [inputElement]);

  const valueProps = useMemo(() => {
    if (mode === "controlled") {
      return {
        value: value ?? "",
      };
    }
    return {
      defaultValue,
    };
  }, [defaultValue, mode, value]);

  const { leadingAddon, trailingAddon } = useMemo(() => {
    const [leadingAddon, trailingAddon] = Children.toArray(children);
    if (!trailingAddon) {
      return addonPosition === "trailing"
        ? { trailingAddon: leadingAddon }
        : {
            leadingAddon,
          };
    }
    return { leadingAddon, trailingAddon };
  }, [addonPosition, children]);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  useImperativeHandle(ref, () => inputElement as HTMLInputElement, [
    inputElement,
  ]);

  const { palette, components } = useTheme();

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      if (inputProps.onBlur) {
        inputProps.onBlur(event);
      }
      localizeInput(event.currentTarget, locale);
    },
    [inputProps, locale],
  );

  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      delocalizeInput(event.currentTarget);
      if (inputProps.onFocus) {
        inputProps.onFocus(event);
      }
    },
    [inputProps],
  );

  useEffect(() => {
    if (!inputElement || inputElement === document.activeElement) {
      return;
    }
    localizeInput(inputElement, locale);
    return () => {
      delocalizeInput(inputElement);
    };
  }, [inputElement, locale]);

  return (
    <AbstractInput
      state={state}
      caption={caption}
      isCompact={compact}
      onClick={onClick}
      className={className}
      isDisabled={isDisabled}
      label={label}
      variant={variant}
      containerRef={containerRef}
      containerWidth={containerWidth}
      leadingAddon={leadingAddon}
      trailingAddon={trailingAddon}
      borderStyle={borderStyle}
    >
      <Container
        gap="4px"
        width="100%"
        alignItems="center"
        onClick={handleClickWrapper}
        padding={
          borderStyle !== "outlined"
            ? components.input.padding[variant]
            : undefined
        }
      >
        {prefix && (
          <Typography
            styled={textDecorationCss}
            as="span"
            variant={`text-${variant}`}
            color={
              isEmpty ? palette.interface.lighter[300] : palette.interface.base
            }
          >
            {prefix}
          </Typography>
        )}
        <InputUI
          {...inputProps}
          {...valueProps}
          id={id}
          disabled={isDisabled}
          onChange={handleChange}
          ref={setInputElement}
          placeholder={placeholder}
          $variant={variant}
          $textPosition={textPosition}
          $borderStyle={borderStyle}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {suffix && (
          <Typography
            styled={textDecorationCss}
            as="span"
            variant={`text-${variant}`}
            color={
              isEmpty ? palette.interface.lighter[300] : palette.interface.base
            }
          >
            {suffix}
          </Typography>
        )}
      </Container>
    </AbstractInput>
  );
}

const AdvancedInputNumberWithRef = forwardRef(AdvancedInputNumber);
const AdvancedInputTextWithRef = forwardRef(AdvancedInputText);

function AdvancedInput(
  // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react
  props: AdvancedInputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  return props.type === "number" ? (
    <AdvancedInputNumberWithRef {...props} ref={ref} />
  ) : (
    <AdvancedInputTextWithRef {...props} ref={ref} />
  );
}

const MemoizedAdvancedInput = memo(forwardRef(AdvancedInput));
export { MemoizedAdvancedInput as AdvancedInput };
