import { Dispatch, SetStateAction, useCallback, useState } from "react";

export type UseToggleResult = readonly [
  boolean,
  () => void,
  Dispatch<SetStateAction<boolean>>,
];

export function useToggle(initial = false): UseToggleResult {
  const [value, setValue] = useState(initial);

  const toggle = useCallback(() => {
    setValue((previous) => !previous);
  }, []);

  return [value, toggle, setValue];
}
