import { memo, JSX } from "react";

import styled from "styled-components";

import { NativeProps } from "../../../../types/native-props";
import { PaletteColorsKeys } from "../../../Theme/theme";

type DotUIProps = {
  $type: "dark" | "light" | "transparent";
  $background: PaletteColorsKeys;
  $size: "sm" | "md" | "lg" | "xl";
  $isChildrenEmpty: boolean;
};

const DotUI = styled.div<DotUIProps>`
  width: ${({ $isChildrenEmpty }) => ($isChildrenEmpty ? `8px` : `6px`)};
  height: ${({ $isChildrenEmpty }) => ($isChildrenEmpty ? `8px` : `6px`)};
  border-radius: 50%;
  background: ${({ $type, $background, theme }) =>
    theme.palette[$background].lighter[$type === "dark" ? 500 : 200]};
  ${({ $size, $isChildrenEmpty }) =>
    $size === "sm" && !$isChildrenEmpty && "margin-right: 7px;"};
  ${({ $size, $isChildrenEmpty }) =>
    $size === "md" && !$isChildrenEmpty && "margin-right: 7px;"};
  ${({ $size, $isChildrenEmpty }) =>
    $size === "lg" && !$isChildrenEmpty && "margin-right: 9px;"};
  ${({ $size, $isChildrenEmpty }) =>
    $size === "xl" && !$isChildrenEmpty && "margin-right: 11px;"};
`;

DotUI.displayName = "DotUI";

type DotProps = {
  variant: "dark" | "light" | "transparent";
  background: PaletteColorsKeys;
  size: "sm" | "md" | "lg" | "xl";
  isChildrenEmpty: boolean;
} & NativeProps<"div">;

function Dot({
  variant,
  background,
  size,
  isChildrenEmpty,
}: DotProps): JSX.Element {
  return (
    <DotUI
      $background={background}
      $type={variant}
      $size={size}
      $isChildrenEmpty={isChildrenEmpty}
    />
  );
}

const MemoizedComponent = memo(Dot);
export { MemoizedComponent as Dot };
