import { memo, useCallback, useMemo, JSX } from "react";

import { RouterProvider, RouterProviderProps } from "react-router-dom";

import { RouterContext } from "../../features/router/context";
import { KeepParam } from "../../types/KeepParam";

export type RouterBaseProps = {
  keep?: Array<KeepParam>;
  navigationDelay?: number;
} & RouterProviderProps;

const defaultKeep: Array<KeepParam> = [];

/* istanbul ignore next - If we don't ignore, coverage is not met because of the two default assignment. Indeed, the code will never pass through those, because we still have the defaultProps. So until then, we deactivate coverage for this component. */
function RouterBase({
  keep = defaultKeep,
  navigationDelay = 300,
  router,
  fallbackElement,
}: RouterBaseProps): JSX.Element {
  const listener = useMemo(() => {
    return new EventTarget();
  }, []);

  const dispatchNavigationEvent = useCallback(() => {
    listener.dispatchEvent(new CustomEvent("navigate"));
  }, [listener]);

  const value = useMemo(() => {
    return {
      keep,
      navigationDelay,
      listener,
      dispatchNavigationEvent,
    };
  }, [keep, navigationDelay, listener, dispatchNavigationEvent]);

  return (
    <RouterContext.Provider value={value}>
      <RouterProvider router={router} fallbackElement={fallbackElement} />
    </RouterContext.Provider>
  );
}

export default memo(RouterBase);
