import {
  type JSX,
  type ReactNode,
  createContext,
  useContext,
  useMemo,
} from "react";

import { createToaster, Toaster as ArkToaster } from "@ark-ui/react";

import { Snack } from "./Snack";
import { Toast } from "./Toast";
import { createSnackApi, type SnackApi } from "./snack.api";
import { createToastApi, type ToastApi } from "./toast.api";

type ToasterContextType = {
  toast: ToastApi;
  snack: SnackApi;
};

const ToasterContext = createContext<ToasterContextType | null>(null);

export function useToasters(): ToasterContextType {
  const context = useContext(ToasterContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
}

type ToasterProps = {
  children?: ReactNode | ((ctx: ToasterContextType) => JSX.Element);
};

export function Toaster({ children }: ToasterProps): JSX.Element {
  const toast = createToaster({
    placement: "top-end",
    duration: 5000,
    removeDelay: 200,
  });

  const snack = createToaster({
    placement: "bottom-end",
    duration: 5000,
    removeDelay: 200,
  });

  const value = useMemo<ToasterContextType>(
    () => ({
      toast: createToastApi(toast),
      snack: createSnackApi(snack),
    }),
    [toast, snack],
  );

  return (
    <ToasterContext.Provider value={value}>
      <ArkToaster toaster={toast}>
        {/* eslint-disable-next-line @mobsuccess-devops/mobsuccess/react */}
        {(toast) => <Toast {...toast} />}
      </ArkToaster>
      <ArkToaster toaster={snack}>
        {/* eslint-disable-next-line @mobsuccess-devops/mobsuccess/react */}
        {(snack) => <Snack {...snack} />}
      </ArkToaster>
      {typeof children === "function" ? children(value) : children}
    </ToasterContext.Provider>
  );
}
