import {
  memo,
  MouseEventHandler,
  ReactNode,
  ForwardedRef,
  forwardRef,
  JSX,
} from "react";

import styled, { useTheme } from "styled-components";

import { Container } from "../../../Container";
import { Typography } from "../../../Typography";

export type ActionProps = {
  children: ReactNode | Array<ReactNode>;
  onClick: MouseEventHandler<HTMLDivElement>;
  id?: string;
  className?: string;
  icon?: ReactNode;
  value?: string;
};

const DropdownActionUI = styled(Container)`
  border-radius: 8px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.8, 1, 0.17, 1);

  &:hover {
    background-color: ${({ theme }) => theme.palette.interface.lighter[500]};
  }

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.interface.darker[100]};
  }
`;

function Action(
  { children, onClick, className, id, icon = null, value }: ActionProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const { palette } = useTheme();
  return (
    <DropdownActionUI
      padding="10px 16px"
      backgroundColor={palette.interface.lighter[600]}
      justifyContent="center"
      alignItems="center"
      gap="12px"
      onClick={onClick}
      ref={ref}
      id={id}
      className={className}
      data-value={value}
    >
      {icon}
      <Typography
        as="span"
        color={palette.interface.darker[100]}
        variant="text-md"
      >
        {children}
      </Typography>
    </DropdownActionUI>
  );
}

const MemoizedComponent = memo(forwardRef(Action));
export { MemoizedComponent as Action };
