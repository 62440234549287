import { Children, type ReactNode } from "react";

import { type ActionElement, isActionElement } from "./Action";
import { type ContentElement, isContentElement } from "./Content";
import { type DescriptionElement, isDescriptionElement } from "./Description";
import { type TitleElement, isTitleElement } from "./Title";
import { type TriggerElement, isTriggerElement } from "./Trigger";

type DialogComponents = {
  trigger: TriggerElement | null;
  title: TitleElement | null;
  description: DescriptionElement | null;
  content: ContentElement | null;
  actions: ActionElement[];
  rest: ReactNode[];
};

export function splitDialogComponents(children: ReactNode): DialogComponents {
  return Children.toArray(children).reduce(
    (acc: DialogComponents, child) => {
      if (isTriggerElement(child)) {
        return { ...acc, trigger: child };
      }
      if (isTitleElement(child)) {
        return { ...acc, title: child };
      }
      if (isDescriptionElement(child)) {
        return { ...acc, description: child };
      }
      if (isContentElement(child)) {
        return { ...acc, content: child };
      }
      if (isActionElement(child)) {
        return { ...acc, actions: [...acc.actions, child] };
      }
      return { ...acc, rest: [...acc.rest, child] };
    },
    {
      trigger: null,
      title: null,
      description: null,
      content: null,
      rest: [],
      actions: [],
    },
  );
}
