import {
  type ForwardedRef,
  type ReactNode,
  type RefObject,
  forwardRef,
  type JSX,
} from "react";

import {
  NumberInput as ArkNumberInput,
  type NumberInputRootProps as ArkNumberInputRootProps,
} from "@ark-ui/react";
import { css, cx } from "@mobsuccess-devops/styled-system/css";

import {
  type InputVariants,
  inputStyles,
} from "../../../features/shared-styles/input";
import {
  type TextFieldDecorationVariants,
  textFieldDecorationStyles,
} from "../../../features/shared-styles/text-fields";
import {
  InputLeadingAddon,
  InputPrefix,
  InputSuffix,
  InputTrailingAddon,
} from "../AbstractInput/Decorators";

export type NumberInputRootProps = InputVariants &
  TextFieldDecorationVariants &
  ArkNumberInputRootProps & {
    containerRef?: RefObject<HTMLDivElement>;
    children?: ReactNode;
    locale: string;
    placeholder?: string;
  };

const defaultFormatOptions = {};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function NumberInputRoot(
  {
    containerRef,
    children,
    locale,
    placeholder,
    formatOptions = defaultFormatOptions,
    ...rest
  }: NumberInputRootProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const [variants, restProps] = inputStyles.splitVariantProps(rest);
  const [decorationVariants, inputProps] =
    textFieldDecorationStyles.splitVariantProps(restProps);

  const styles = inputStyles(variants);
  const decoration = textFieldDecorationStyles(decorationVariants);

  return (
    <div
      ref={containerRef}
      data-role="root"
      data-disabled={inputProps.disabled ? "true" : undefined}
      className={cx(styles.root, inputProps.className, decoration, "group")}
    >
      {children}
      <ArkNumberInput.Root
        {...inputProps}
        ref={ref}
        formatOptions={formatOptions}
        className={css({ width: "100%", order: 2 })}
        locale={locale}
      >
        <ArkNumberInput.Input
          className={styles.input}
          placeholder={placeholder}
        />
      </ArkNumberInput.Root>
    </div>
  );
}

export const NumberInput = {
  Root: forwardRef(NumberInputRoot),
  Prefix: InputPrefix,
  Suffix: InputSuffix,
  LeadingAddon: InputLeadingAddon,
  TrailingAddon: InputTrailingAddon,
};
