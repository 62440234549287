/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import { ReactNode, memo, useCallback, useMemo, JSX } from "react";

import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { SortableContext, useSortable, arrayMove } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@mobsuccess-devops/styled-system/css";
import { Box, BoxProps, styled } from "@mobsuccess-devops/styled-system/jsx";

import { sortItems } from "../../features/utils/sortItems";

type HandleProps = BoxProps & {
  id: string;
  children: ReactNode;
};

function Handle({ id, ...rest }: HandleProps): JSX.Element {
  const { setActivatorNodeRef, listeners } = useSortable({ id });

  return (
    <Box
      ref={setActivatorNodeRef}
      cursor="grab"
      _active={css.raw({ cursor: "grabbing" })}
      id={id}
      {...rest}
      {...listeners}
    />
  );
}

type ItemProps = {
  id: string;
  disabled?: boolean;
  children: ReactNode;
  customHandle?: boolean;
};

function Item({
  id,
  children,
  disabled,
  customHandle,
}: ItemProps): JSX.Element {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id, transition: null, disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handle = !customHandle ? { ...listeners, ...attributes } : {};

  return (
    <styled.li
      width="full"
      listStyleType="none"
      ref={setNodeRef}
      style={style}
      {...handle}
    >
      {children}
    </styled.li>
  );
}

export type ListProps<T> = {
  items: Array<T>;
  children: ReactNode;
  className?: string;
  onOrderChange: (items: Array<T>) => void;
  discriminate: (item: T) => string | number;
};

const modifiers = [restrictToVerticalAxis, restrictToParentElement];

function List<T>({
  items,
  children,
  className,
  discriminate,
  onOrderChange,
}: ListProps<T>): JSX.Element {
  const discriminants = useMemo(
    () => items.map(discriminate),
    [discriminate, items],
  );

  const handleDragEnd = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (!over || !active || over.id === active.id) {
        return;
      }
      const reorderd = arrayMove(
        discriminants,
        discriminants.indexOf(active.id),
        discriminants.indexOf(over.id),
      );
      onOrderChange(sortItems(items, discriminate, reorderd));
    },
    [discriminants, discriminate, items, onOrderChange],
  );

  return (
    <DndContext modifiers={modifiers} onDragEnd={handleDragEnd}>
      <SortableContext items={discriminants}>
        <ul className={className}>{children}</ul>
      </SortableContext>
    </DndContext>
  );
}

export const Orderable = {
  List: List,
  Item: memo(Item),
  Handle: memo(Handle),
};
