import {
  ReactNode,
  memo,
  ForwardedRef,
  MouseEventHandler,
  useState,
  useCallback,
  useEffect,
  useMemo,
  Children,
  isValidElement,
  forwardRef,
  useImperativeHandle,
  JSX,
} from "react";

import styled, { keyframes, useTheme } from "styled-components";

import { isInstanceOfComponents } from "../../features/utils/is-instance-of-components";
import { AnchorOriginType } from "../../types/anchor-origin";
import { ClickAwayListener } from "../ClickAwayListener";
import { Container } from "../Container";
import { Separator } from "../Separator";

import { Actions } from "./Actions/Actions";
import { Group } from "./Group/Group";

const pop = keyframes`
  0% {
    opacity: 0;
    scale: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    scale: 1.02;
  }
  100% {
    scale: 1;
  }
`;

type DropdownUIProps = {
  $top: number;
  $left: number;
  $right: number;
  $bottom: number;
  $anchorOrigin?: AnchorOriginType;
  $maxHeight: string;
};

const DropdownUI = styled(Container).attrs(
  ({ $anchorOrigin, $top, $bottom, $left, $right }: DropdownUIProps) => {
    let styles = {};
    switch ($anchorOrigin) {
      case "top-left":
        styles = {
          top: `calc(${$top}px - 4px)`,
          transform: "translateY(-100%)",
          left: `${$left}px`,
        };
        break;
      case "top-right":
        styles = {
          top: `calc(${$top}px - 4px)`,
          transform: "translateY(-100%) translateX(-100%)",
          left: `${$right}px`,
        };
        break;
      case "bottom-left":
        styles = {
          top: `${$bottom + 4}px`,
          left: `${$left}px`,
        };
        break;
      case "bottom-right":
        styles = {
          top: `calc(${$bottom}px + 4px)`,
          transform: "translateX(-100%)",
          left: `${$right}px`,
        };
        break;
    }
    return {
      style: styles,
    };
  },
)<DropdownUIProps>`
  position: fixed;
  z-index: 1001;
  animation: ${pop} 0.2s ease-in-out;
  box-sizing: border-box;
  background-color: ${({ theme }) => `${theme.palette.white}`};
  border-radius: 8px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12);
  max-height: ${({ $maxHeight }) => $maxHeight};
`;

DropdownUI.displayName = "DropdownUI";

export type Positions = {
  top: number;
  left: number;
  right: number;
  bottom: number;
  width: number;
};

export type DropdownProps = {
  id?: string;
  width?: string;
  maxHeight?: string;
  isOpen?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClose?: MouseEventHandler<HTMLElement>;
  hasSeparatorsHidden?: boolean;
  anchorOrigin?: AnchorOriginType;
  anchorEl: HTMLElement | null;
  children: ReactNode | Array<ReactNode>;
};

function defaultOnCLose(): null {
  return null;
}

function Dropdown(
  {
    id,
    width = "300px",
    maxHeight = "unset",
    isOpen = false,
    onClick,
    onClose = defaultOnCLose,
    children,
    anchorEl,
    className,
    anchorOrigin = "top-right",
    hasSeparatorsHidden = false,
  }: DropdownProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element | null {
  const [positions, setPositions] = useState<Positions | null>(null);

  const { top, left, right, bottom } = positions || {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  const { palette } = useTheme();
  const computePositions = useCallback(() => {
    if (!anchorEl) {
      return null;
    }
    const { top, left, right, bottom } = anchorEl.getBoundingClientRect();

    const width = anchorEl.offsetWidth;

    return {
      top,
      left,
      right,
      bottom,
      width: Math.round(width),
    };
  }, [anchorEl]);

  useEffect(() => {
    setPositions(computePositions());
    const onResize = (): void => {
      setPositions(computePositions());
    };
    document.addEventListener("resize", onResize, { passive: true });
    document.addEventListener("wheel", onResize, { passive: true });
    document.addEventListener("touchmove", onResize, { passive: true });
    return () => {
      document.removeEventListener("resize", onResize);
      document.removeEventListener("wheel", onResize);
      document.removeEventListener("touchmove", onResize);
    };
  }, [computePositions, isOpen]);

  const childrenWithSeparator = useMemo(() => {
    if (hasSeparatorsHidden) {
      return children;
    }
    const newChildren: Array<ReactNode> = [];
    Children.forEach(children, (child, index) => {
      if (
        !index ||
        !isValidElement(child) ||
        typeof child.type === "string" ||
        !isInstanceOfComponents(child, Actions, Group)
      ) {
        newChildren.push(child);
        return;
      }
      // get component name from react element
      newChildren.push(
        <Separator
          color={palette.interface.lighter[400] ?? "transparent"}
          key={`separator-${index}`}
        />,
        child,
      );
    });
    return newChildren;
  }, [children, hasSeparatorsHidden, palette.interface.lighter]);

  const [dropdownElement, setDropdownElement] = useState<HTMLDivElement | null>(
    null,
  );

  const actualAnchorOrigin = useMemo<AnchorOriginType>(() => {
    const height = dropdownElement?.offsetHeight ?? 0;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const horizontal = anchorOrigin.split("-")[1] as "left" | "right";
    return window.innerHeight - bottom < height
      ? `top-${horizontal}`
      : `bottom-${horizontal}`;
  }, [anchorOrigin, bottom, dropdownElement]);

  // Disabling because we now that dropdownElement is not null
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => dropdownElement!, [dropdownElement]);

  return isOpen && positions && anchorEl ? (
    <ClickAwayListener
      allowedElement={anchorEl}
      onClickAway={onClose}
      mouseEvent="mousedown"
    >
      <DropdownUI
        onClick={onClick}
        flexDirection="column"
        overflow="auto"
        className={className}
        id={id}
        ref={setDropdownElement}
        $top={top}
        $left={left}
        $right={right}
        width={width === "fit-anchor" ? `${positions.width}px` : width}
        $maxHeight={maxHeight}
        $bottom={bottom}
        $anchorOrigin={actualAnchorOrigin}
      >
        {childrenWithSeparator}
      </DropdownUI>
    </ClickAwayListener>
  ) : null;
}

const MemoizedDropdown = memo(forwardRef(Dropdown));
export { MemoizedDropdown as Dropdown };
