import { createContext, useContext } from "react";

import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";

import { basename } from "path";

export type LocaleModule = {
  messages: Record<string, string>;
};

export type LanguageContextType = {
  language: string;
  onChangeLanguage: (language: string) => void;
};

export const LanguageContext = createContext<LanguageContextType | null>(null);

export function useLanguageContext(): LanguageContextType {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("LanguageContext is not defined");
  }
  return context;
}

export function useI18n(): I18n {
  const { i18n } = useLingui();
  return i18n;
}

const sharedLocales = import.meta.glob<LocaleModule>(
  "../../locales/!(*.d).ts",
  {
    eager: true,
  },
);

export function buildLocales(
  webappsLocales: Record<string, LocaleModule>,
): Map<string, LocaleModule["messages"]> {
  const allLocales = [
    ...Object.entries(sharedLocales),
    ...Object.entries(webappsLocales),
  ];
  return allLocales.reduce((locales, [key, module]) => {
    const locale = basename(key, ".ts");
    if (!locales.has(locale)) {
      locales.set(locale, {});
    }
    const loaded = locales.get(locale);
    locales.set(locale, {
      ...loaded,
      ...module.messages,
    });
    return locales;
  }, new Map<string, LocaleModule["messages"]>());
}

export function activate(
  i18n: I18n,
  locales: Map<string, LocaleModule["messages"]>,
  language: string,
): void {
  if (!locales.has(language)) {
    throw new Error(`Language ${language} is not supported`);
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const catalog = locales.get(language) as Record<string, string>;

  i18n.load(language, catalog);
  i18n.activate(language);
}
