import {
  memo,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useState,
  JSX,
} from "react";

import styled, { css, useTheme } from "styled-components";

import { Container } from "../../Container";
import { Typography } from "../../Typography";

type RadioButtonUIProps = {
  $isDisabled?: boolean;
};

const hoverCss = css`
  &:hover {
    input {
      border-color: ${({ theme }) => theme.palette.interface.lighter[100]};
    }
    input::before {
      background-color: ${({ theme }) => theme.palette.primary.base};
    }
  }
`;

const RadioButtonUI = styled(Container)<RadioButtonUIProps>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};
  span {
    opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  }
  ${({ $isDisabled }) => !$isDisabled && hoverCss}
`;

RadioButtonUI.displayName = "RadioButtonUI";

const disabledCss = css`
  cursor: not-allowed;
  background-color: ${({ theme }) => theme.palette.interface.lighter[600]};
  &:checked {
    &::before {
      background-color: ${({ theme }) => theme.palette.interface.lighter[300]};
    }
  }
`;

const checkedCss = css`
  &::before {
    content: "";
    position: absolute;
    inset: 25%;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.palette.primary.lighter[100]};
  }

  &:focus {
    border-color: ${({ theme }) =>
      theme.palette.primary.lighter[100]} !important;
    box-shadow: 0px 0px 0px 4px
      ${({ theme }) => theme.palette.primary.base + "26"};
  }
`;

const InputUI = styled.input<RadioButtonUIProps>`
  -webkit-appearance: none;
  outline: none;
  position: relative;
  pointer-events: none;
  height: 20px;
  width: 20px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.interface.lighter[400]};
  border-radius: 50%;
  &:focus {
    border-color: ${({ theme }) =>
      theme.palette.interface.lighter[200]} !important;
    box-shadow: 0px 0px 0px 4px
      ${({ theme }) => theme.palette.interface.base + "26"};
  }
  &:hover {
    border-color: ${({ theme }) => theme.palette.interface.lighter[100]};
  }
  ${({ checked }) => checked && checkedCss}
  ${({ $isDisabled }) => $isDisabled && disabledCss}
`;

InputUI.displayName = "InputUI";

const labelCss = css`
  pointer-events: none;
`;

export type RadioButtonProps<T extends string | number | boolean> = {
  id?: string;
  className?: string;
  label?: string;
  name?: string;
  value: T;
  isDisabled?: boolean;
  onChange?: (event: MouseEvent<HTMLDivElement>, newValue: T) => void;
  isChecked?: boolean;
};

function defaultOnChange(): null {
  return null;
}

function RadioButton<T extends string | number | boolean>({
  id,
  label,
  value,
  name,
  className = "",
  isDisabled = false,
  isChecked = false,
  onChange = defaultOnChange,
}: RadioButtonProps<T>): JSX.Element {
  const { palette } = useTheme();
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null,
  );

  const handleClickRadioButton = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (!isDisabled) {
        inputElement?.focus();
        onChange?.(event, value);
      }
    },
    [inputElement, isDisabled, onChange, value],
  );
  return (
    <RadioButtonUI
      id={id}
      gap="8px"
      alignItems="center"
      className={className}
      $isDisabled={isDisabled}
      onClick={handleClickRadioButton}
    >
      <InputUI
        readOnly
        name={name}
        type="radio"
        value={value.toString()}
        checked={isChecked}
        ref={setInputElement}
        $isDisabled={isDisabled}
      />
      {label && (
        <Typography
          as="span"
          styled={labelCss}
          variant="text-md-medium"
          color={palette.interface.base}
        >
          {label}
        </Typography>
      )}
    </RadioButtonUI>
  );
}

const MemoizedRadioButton = memo(RadioButton);
export { MemoizedRadioButton as RadioButton };
