import {
  memo,
  Fragment,
  useCallback,
  useEffect,
  EventHandler,
  SyntheticEvent,
  JSX,
} from "react";

import {
  useComponentMountedRef,
  useSingleChildWithRef,
} from "../../features/react-tools";

export type ClickAwayListenerProps = {
  allowedElement?: HTMLElement | SVGElement | null;
  mouseEvent?: "click" | "mouseup" | "mousedown" | false;
  touchEvent?: "touchstart" | "touchend" | "touchmove" | false;
  children: JSX.Element;
  onClickAway: EventHandler<SyntheticEvent<HTMLElement | SVGElement>>;
};

function defaultOnClickAway(): null {
  return null;
}

function ClickAwayListener({
  allowedElement = null,
  children,
  onClickAway = defaultOnClickAway,
  mouseEvent = "click",
  touchEvent = "touchstart",
}: ClickAwayListenerProps): JSX.Element {
  const activatedRef = useComponentMountedRef();
  const child = useSingleChildWithRef(children);
  const ref = child.ref;

  const handleClickAway = useCallback<EventListener>(
    (e) => {
      if (!activatedRef.current || !ref.current) {
        return;
      }
      const target = e.target;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      if (allowedElement && allowedElement.contains(target as Node)) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      if (target && !ref.current.contains(target as Node)) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        onClickAway(e as unknown as SyntheticEvent<HTMLElement | SVGElement>);
      }
    },
    [onClickAway, ref, activatedRef, allowedElement],
  );

  useEffect(() => {
    const doc = ref.current?.ownerDocument ?? document;
    if (mouseEvent) {
      doc.addEventListener(mouseEvent, handleClickAway);
      return () => {
        doc.removeEventListener(mouseEvent, handleClickAway);
      };
    }
  }, [handleClickAway, mouseEvent, ref]);

  useEffect(() => {
    const doc = ref.current?.ownerDocument ?? document;
    if (touchEvent) {
      doc.addEventListener(touchEvent, handleClickAway);
      doc.addEventListener("touchmove", handleClickAway);
      return () => {
        doc.removeEventListener(touchEvent, handleClickAway);
        doc.removeEventListener("touchmove", handleClickAway);
      };
    }
  }, [touchEvent, handleClickAway, ref]);

  return <Fragment>{child}</Fragment>;
}

const MemoizedClickAwayListener = memo(ClickAwayListener);
export { MemoizedClickAwayListener as ClickAwayListener };
