import { useEffect } from "react";

function js(
  strings: string[] | TemplateStringsArray,
  ...args: unknown[]
): string {
  return strings.reduce((acc, str, i) => {
    const arg = args[i];
    if (arg) {
      if (typeof arg === "function") {
        return `${acc}${str}${arg()}`;
      }
      return `${acc}${str}${arg}`;
    }
    return `${acc}${str}`;
  }, "");
}

export const scripts = {
  js,
};

export function useScript({
  id,
  src,
  javascript,
}: {
  id?: string;
  src?: string;
  javascript?: string;
}): void {
  useEffect(() => {
    const script = document.createElement("script");
    if (id) {
      script.setAttribute("id", id);
    }
    if (!javascript && !src) {
      return;
    }

    if (src) {
      script.src = src;
    } else if (javascript) {
      script.innerText = javascript;
    }

    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src, javascript, id]);
}
