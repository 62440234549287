import { createContext, type ContextType, useContext } from "react";

export const LocalesContext = createContext({
  select: {
    selectAll: "Select all",
    removeAll: "Remove all",
  },
  accountDropdown: {
    searchPlaceholder: "Search...",
  },
  pagination: {
    pageSizeLabel: "Display",
    pageSizeOptions: (size: number) => `${size} lines`,
  },
  colorPicker: {
    savedSectionLabel: "Saved colors",
  },
  filters: {
    searchPlaceholder: "Search",
  },
});

export function useLocales(): ContextType<typeof LocalesContext> {
  return useContext(LocalesContext);
}
