import { ComponentProps } from "react";

import { RecipeVariantProps, cva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

import {
  TextFieldDecorationVariants,
  textFieldDecorationStyles,
} from "../../../features/shared-styles/text-fields";

export type TextAreaProps = Omit<ComponentProps<"textarea">, "ref"> &
  RecipeVariantProps<typeof textAreaStyles> &
  TextFieldDecorationVariants;

const textAreaStyles = cva({
  base: {
    fontFamily: '"Poppins", sans-serif',
    appearance: "none",
    background: "none",
    border: "1px solid",
    borderRadius: "sm",
    padding: "sm",
    width: "full",
    fontSize: "md",
    _disabled: {
      opacity: 0.4,
    },
    _placeholder: {
      color: "#BCC1DE",
    },
  },
});

export const TextArea = styled(
  styled("textarea", textAreaStyles),
  textFieldDecorationStyles,
);
