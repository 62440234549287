import type { JSX, ReactElement } from "react";

import {
  ColorPicker as ArkColorPicker,
  useColorPickerContext,
} from "@ark-ui/react";
import { ReactComponent as CrossIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";
import { sva, cx } from "@mobsuccess-devops/styled-system/css";
import { Circle } from "@mobsuccess-devops/styled-system/jsx";
import type { Color } from "@zag-js/color-utils";

import { colorPickerAnatomy } from "./anatomy";

type ShortcutProps = {
  color?: string | Color | null;
  onRemove?: () => void;
};

export function Shortcut({
  color,
  onRemove,
}: ShortcutProps): JSX.Element | null {
  const classes = styles({
    removable: !!onRemove,
  });

  const api = useColorPickerContext();

  if (!color) {
    return null;
  }
  return (
    <ArkColorPicker.Swatch
      value={color}
      // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react -- this is a valid use case
      onClick={() => api.setValue(color)}
      className={cx(classes.shortcutSwatch, "group")}
      {...colorPickerAnatomy.build().shortcutSwatch.attrs}
    >
      <Circle
        className={classes["shortcutSwatch:remove"]}
        // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react
        onClick={(event) => {
          event.stopPropagation();
          onRemove?.();
        }}
        {...colorPickerAnatomy.build()["shortcutSwatch:remove"].attrs}
      >
        <CrossIcon />
      </Circle>
    </ArkColorPicker.Swatch>
  );
}

export type ShortcutElement = ReactElement<ShortcutProps, typeof Shortcut>;

const styles = sva({
  slots: colorPickerAnatomy.keys(),
  base: {
    shortcutSwatch: {
      size: "lg",
      borderRadius: "md",
      cursor: "pointer",
    },
    "shortcutSwatch:remove": {
      top: "0",
      right: "0",
      size: "xs",
      fontSize: "xs",
      display: "none",
      cursor: "pointer",
      position: "absolute",
      color: "white",
      transform: "translate(30%, -30%)",
      border: "1px solid token(colors.interface)",
      backgroundColor: "white",
      center: true,
      _childSvg: {
        color: "interface",
        size: "5px",
      },
      _groupHover: {
        display: "flex",
      },
    },
  },
  variants: {
    removable: {
      false: {
        "shortcutSwatch:remove": {
          _groupHover: {
            display: "none",
          },
        },
      },
    },
  },
});
