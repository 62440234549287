import {
  FunctionComponentElement,
  JSX,
  ReactNode,
  isValidElement,
} from "react";

import { styled } from "@mobsuccess-devops/styled-system/jsx";

type ContentProps = {
  children: ReactNode;
  className?: string;
};

export function Content({ children, className }: ContentProps): JSX.Element {
  return (
    <styled.div gridArea="content" size="full" className={className}>
      {children}
    </styled.div>
  );
}

export type ContentElement = FunctionComponentElement<ContentProps>;

export function isContentElement(node: ReactNode): node is ContentElement {
  return isValidElement(node) && node.type === Content;
}
