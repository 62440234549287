export enum FormValidationError {
  None = "none",
  Weak = "weak",
  Empty = "empty",
  Missmatch = "missmatch",
}

export enum BusinessUnits {
  Farly = "farly",
  Widely = "widely",
  Mobsuccess = "mobsuccess",
  Storysuccess = "storysuccess",
  Leboncoin = "leboncoin",
}
