import type { JSX, ReactNode } from "react";

import { dialogAnatomy } from "@ark-ui/anatomy";
import {
  Dialog as ArkDialog,
  type DialogRootProps as ArkDialogRootProps,
  Portal,
} from "@ark-ui/react";
import { ReactComponent as CrossIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";
import { sva } from "@mobsuccess-devops/styled-system/css";
import {
  vstack,
  hstack,
  center,
} from "@mobsuccess-devops/styled-system/patterns";

import { IconButton } from "../../Button";

import { Action } from "./Action";
import { Content } from "./Content";
import { Description } from "./Description";
import { Title } from "./Title";
import { Trigger } from "./Trigger";
// eslint-disable-next-line @mobsuccess-devops/mobsuccess/assets
import { splitDialogComponents } from "./dialog.utils";

export type DialogRootProps = Omit<ArkDialogRootProps, "children"> & {
  children: ReactNode;
  showCloseTrigger?: boolean;
  onCloseTriggerClick?: () => void;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function DialogRoot({
  children,
  showCloseTrigger = true,
  onCloseTriggerClick,
  ...rest
}: DialogRootProps): JSX.Element {
  const classes = dialog();

  const { trigger, title, description, content, actions } =
    splitDialogComponents(children);

  return (
    <ArkDialog.Root {...rest}>
      {trigger}
      <Portal>
        <ArkDialog.Backdrop className={classes.backdrop} />
        <ArkDialog.Positioner className={classes.positioner}>
          <ArkDialog.Context>
            {/* eslint-disable-next-line @mobsuccess-devops/mobsuccess/react */}
            {({ open }) => (
              <ArkDialog.Content
                className={classes.content}
                hidden={open ? undefined : true}
              >
                {showCloseTrigger && (
                  <ArkDialog.CloseTrigger asChild>
                    <IconButton
                      className={classes.closeTrigger}
                      onClick={onCloseTriggerClick}
                    >
                      <CrossIcon />
                    </IconButton>
                  </ArkDialog.CloseTrigger>
                )}
                {title}
                {description}
                {content}
                {!!actions.length && (
                  <div className={classes.actions}>{actions}</div>
                )}
              </ArkDialog.Content>
            )}
          </ArkDialog.Context>
        </ArkDialog.Positioner>
      </Portal>
    </ArkDialog.Root>
  );
}

const dialog = sva({
  slots: dialogAnatomy.extendWith("actions").keys(),
  base: {
    backdrop: {
      ...vstack.raw(),
      position: "fixed",
      zIndex: "10000",
      inset: "0",
      backgroundColor: "black",
      opacity: "0.5",
    },
    positioner: {
      ...center.raw(),
      inset: "0",
      position: "fixed",
      zIndex: "10050",
      overflowY: "auto",
    },
    content: {
      display: "grid",
      gridGap: "md",
      gridTemplateAreas: `
        "title close"
        "description description"
        "content content"
        "actions actions"
      `,
      gridTemplateColumns: "1fr min-content",
      backgroundColor: "white",
      borderRadius: "lg",
      boxShadow: "md",
      padding: "md",
      pointerEvents: "auto",
    },
    closeTrigger: {
      gridArea: "close",
    },
    actions: {
      gridArea: "actions",
      ...hstack.raw(),
      justifySelf: "end",
      width: "fit-content",
    },
  },
});

export const Dialog = {
  Root: DialogRoot,
  Trigger: Trigger,
  Title: Title,
  Description: Description,
  Content: Content,
  Action: Action,
  Context: ArkDialog.Context,
};
