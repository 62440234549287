import {
  ReactNode,
  JSX,
  isValidElement,
  FunctionComponentElement,
  useCallback,
  useState,
  useId,
} from "react";
import { useMemo } from "react";

import { CheckboxCheckedChangeDetails } from "@ark-ui/react";
import { ReactComponent as DraggableIcon } from "@mobsuccess-devops/streamline/custom/react-ui/drag-indicator-small.svg";
import { ReactComponent as OrderIcon } from "@mobsuccess-devops/streamline/regular/05-Internet-Networks-Servers/07-Data-Transfer/data-transfer-horizontal.svg";
import { css } from "@mobsuccess-devops/styled-system/css";
import { HStack, VStack, styled } from "@mobsuccess-devops/styled-system/jsx";
import { vstack } from "@mobsuccess-devops/styled-system/patterns";
import { Column, Table, VisibilityState } from "@tanstack/react-table";

import { Orderable } from "../../../Orderable";
import { Typo } from "../../../Typo";
import { Checkbox } from "../../Checkbox/Checkbox";
import { Dialog } from "../../Dialog/Dialog";
import { getColumnLabel } from "../createTable";

type TableArrangerLayoutProps<T> = {
  id: string;
  table: Table<T>;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function TableArrangerLayout<T>({
  id,
  table,
}: TableArrangerLayoutProps<T>): JSX.Element {
  const [visibility, setVisibility] = useState<VisibilityState>(
    table.getState().columnVisibility,
  );

  const [order, setOrder] = useState(table.getState().columnOrder);

  const [left, center, right] = useMemo(
    () =>
      table.getAllLeafColumns().reduce<Tupple<Column<T, unknown>[], 3>>(
        (acc, column) => {
          if (column.getIsPinned() === "left") {
            acc[0].push(column);
          } else if (column.getIsPinned() === "right") {
            acc[2].push(column);
          } else {
            acc[1].push(column);
          }
          return acc;
        },
        [[], [], []],
      ),
    [table],
  );

  const handleToggleVisibility = useCallback((columnId: string) => {
    return ({ checked }: CheckboxCheckedChangeDetails) => {
      if (checked === "indeterminate") {
        return;
      }
      setVisibility((prev) => {
        return {
          ...prev,
          [columnId]: checked,
        };
      });
    };
  }, []);

  const handleChangeOrder = useCallback(
    (newOrder: string[]) => {
      setOrder([
        ...left.map((column) => column.id),
        ...newOrder,
        ...right.map((column) => column.id),
      ]);
    },
    [left, right],
  );

  const handleSubmit = useCallback(() => {
    table.setColumnVisibility(visibility);
    table.setColumnOrder(order);
  }, [table, visibility, order]);

  const handleReset = useCallback(() => {
    table.resetColumnVisibility();
    table.resetColumnOrder();
  }, [table]);

  return (
    <VStack gap="sm" alignItems="flex-start" width="100%">
      {left.map((column) => (
        <HStack key={column.id}>
          <DraggableIcon
            className={css({ color: "interface.300", cursor: "not-allowed" })}
          />
          <Checkbox
            checked={column.getIsVisible()}
            onCheckedChange={handleToggleVisibility(column.id)}
          />
          <Typo.Body>{getColumnLabel(column)}</Typo.Body>
        </HStack>
      ))}
      <Orderable.List
        className={vstack({
          gap: "sm",
          alignItems: "start",
          _active: {
            borderColor: "primary",
            borderWidth: "2px solid",
          },
        })}
        items={center.map((column) => column.id)}
        // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react
        discriminate={(item) => item}
        onOrderChange={handleChangeOrder}
      >
        {center
          .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id) || 0)
          .map((column) => (
            <Orderable.Item id={column.id} key={column.id} customHandle>
              <HStack key={column.id}>
                <Orderable.Handle id={column.id}>
                  <DraggableIcon
                    className={css({ color: "primary", cursor: "grab" })}
                  />
                </Orderable.Handle>
                <Checkbox
                  checked={column.getIsVisible()}
                  onCheckedChange={handleToggleVisibility(column.id)}
                />
                <Typo.Body>{getColumnLabel(column)}</Typo.Body>
              </HStack>
            </Orderable.Item>
          ))}
      </Orderable.List>
      {right.map((column) => (
        <HStack key={column.id}>
          <DraggableIcon
            className={css({ color: "interface.300", cursor: "not-allowed" })}
          />
          <Checkbox
            checked={column.getIsVisible()}
            onCheckedChange={handleToggleVisibility(column.id)}
          />
          <Typo.Body>{getColumnLabel(column)}</Typo.Body>
        </HStack>
      ))}
      <styled.button display="none" id={`${id}-reset`} onClick={handleReset} />
      <styled.button
        display="none"
        id={`${id}-submit`}
        onClick={handleSubmit}
      />
    </VStack>
  );
}

type TableArrangerProps<T> = {
  /** @internal */
  _table?: Table<T>;
};
// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export function TableArranger<T>({
  _table: table,
}: TableArrangerProps<T>): JSX.Element {
  if (!table) {
    throw new Error("TableArranger must be a child of Table");
  }

  const id = useId();

  const handleForwardClick = useCallback(
    (type: string) => {
      return () => {
        document.getElementById(`${id}-${type}`)?.click();
      };
    },
    [id],
  );

  return (
    <styled.div gridArea="arranger" justifySelf="end">
      <Dialog.Root unmountOnExit>
        <Dialog.Title>Arrange your columns</Dialog.Title>
        <Dialog.Trigger
          size="lg"
          variant="icon"
          className={css({
            gridArea: "ordering",
            border: "1px solid",
            color: "interface",
            borderColor: "interface.300",
            backgroundColor: "white",
          })}
        >
          <OrderIcon className={css({ size: "sm!" })} />
        </Dialog.Trigger>
        <Dialog.Content>
          <TableArrangerLayout table={table} id={id} />
        </Dialog.Content>
        <Dialog.Action
          variant="light"
          action="close"
          onClick={handleForwardClick("reset")}
        >
          Reset
        </Dialog.Action>
        <Dialog.Action
          variant="dark"
          action="validate"
          onClick={handleForwardClick("submit")}
        >
          Save
        </Dialog.Action>
      </Dialog.Root>
    </styled.div>
  );
}

export type TableArrangerElement<T> = FunctionComponentElement<
  TableArrangerProps<T>
>;
export function isTableArrangerElement<T>(
  node: ReactNode,
): node is TableArrangerElement<T> {
  return isValidElement(node) && node.type === TableArranger<T>;
}
