import { memo, useMemo, JSX } from "react";

import { ReactComponent as ArrowIcon } from "@mobsuccess-devops/streamline/regular/52-Arrows-Diagrams/01-Arrows/arrow-right.svg";

import styled, { useTheme } from "styled-components";

import { useDatePickerContext } from "../../../../features/date-picker";
import { Container } from "../../../Container";
import { Typography } from "../../../Typography";

const ArrowIconUI = styled(ArrowIcon)`
  width: 7.5px;
  color: ${({ theme }) => theme.palette.interface.lighter[300]};
`;

ArrowIconUI.displayName = "ArrowIconUI";

function SelectedRange(): JSX.Element | null {
  const { palette } = useTheme();
  const { selectedRange, locale } = useDatePickerContext();

  const startDate = useMemo(() => {
    if (!selectedRange) {
      return null;
    }
    const { start } = selectedRange;
    if (!start) {
      return null;
    }
    return start?.toLocaleDateString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }, [locale, selectedRange]);

  const endDate = useMemo(() => {
    if (!selectedRange) {
      return null;
    }
    const { end } = selectedRange;
    if (!end) {
      return null;
    }
    return end?.toLocaleDateString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }, [locale, selectedRange]);

  if (!startDate || !endDate) {
    return null;
  }
  return (
    <Container gap="10px">
      <Typography as="span" variant="md" color={palette.interface.base}>
        {startDate}
      </Typography>
      <ArrowIconUI />
      <Typography as="span" variant="md" color={palette.interface.base}>
        {endDate}
      </Typography>
    </Container>
  );
}

const MemoizedComponent = memo(SelectedRange);
export { MemoizedComponent as SelectedRange };
