import {
  JSX,
  ReactNode,
  RefObject,
  forwardRef,
  ForwardedRef,
  ComponentPropsWithoutRef,
} from "react";

import { cx } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

import {
  inputStyles,
  InputVariants,
} from "../../../features/shared-styles/input";
import {
  textFieldDecorationStyles,
  TextFieldDecorationVariants,
} from "../../../features/shared-styles/text-fields";
import {
  InputLeadingAddon,
  InputPrefix,
  InputSuffix,
  InputTrailingAddon,
} from "../AbstractInput/Decorators";

type BaseInputProps = {
  containerRef?: RefObject<HTMLDivElement>;
  children?: ReactNode;
} & ComponentPropsWithoutRef<"input">;

export type InputRootProps = BaseInputProps &
  InputVariants &
  TextFieldDecorationVariants;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function InputRoot(
  { containerRef, children, ...rest }: InputRootProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const [variants, restProps] = inputStyles.splitVariantProps(rest);
  const [decorationVariants, inputProps] =
    textFieldDecorationStyles.splitVariantProps(restProps);

  const classes = inputStyles(variants);
  const decoration = textFieldDecorationStyles(decorationVariants);

  return (
    <div
      ref={containerRef}
      data-role="root"
      data-disabled={inputProps.disabled || undefined}
      className={cx(classes.root, inputProps.className, decoration, "group")}
    >
      {children}
      <styled.input
        order={2}
        placeholder=""
        width="100%"
        {...inputProps}
        ref={ref}
        className={classes.input}
      />
    </div>
  );
}

export const Input = {
  Root: forwardRef(InputRoot),
  Prefix: InputPrefix,
  Suffix: InputSuffix,
  LeadingAddon: InputLeadingAddon,
  TrailingAddon: InputTrailingAddon,
};
