import { useCallback, type JSX, type ReactElement } from "react";

import { useColorPickerContext } from "@ark-ui/react";
import { IconAddCircle } from "@mobsuccess-devops/icons";
import { sva } from "@mobsuccess-devops/styled-system/css";
import type { Color } from "@zag-js/color-utils";

import { colorPickerAnatomy } from "./anatomy";

type AddShortcutProps = {
  disabled?: boolean;
  conflicts?: Array<string | Color>;
  onAdd: (color: Color) => void;
};

const defaultConflicts = new Array<string | Color>();

export function AddShortcut({
  onAdd,
  disabled,
  conflicts = defaultConflicts,
}: AddShortcutProps): JSX.Element {
  const api = useColorPickerContext();

  const hasConflict = conflicts
    .map((c) => c.toString("hex"))
    .includes(api.value.toString("hex"));

  const classes = styles({ conflict: hasConflict });
  const handleSave = useCallback(() => {
    onAdd(api.value);
  }, [api.value, onAdd]);

  return (
    <button
      className={classes.addShortcutButton}
      onClick={handleSave}
      disabled={disabled || hasConflict}
      type="button"
      {...colorPickerAnatomy.build().addShortcutButton.attrs}
    >
      <IconAddCircle variant="regular" size="md" color="interface.300" />
    </button>
  );
}

export type AddShortcutElement = ReactElement<
  AddShortcutProps,
  typeof AddShortcut
>;

const styles = sva({
  slots: colorPickerAnatomy.keys(),
  base: {
    addShortcutButton: {
      size: "lg",
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      center: true,
      _disabled: {
        cursor: "not-allowed",
      },
    },
  },
  variants: {
    conflict: {
      false: {
        addShortcutButton: {
          _hover: {
            _childSvg: {
              color: "interface.300",
            },
          },
        },
      },
    },
  },
});
