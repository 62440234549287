import type { JSX } from "react";

import { checkboxAnatomy } from "@ark-ui/anatomy";
import { Checkbox as ArkCheckbox, type CheckboxRootProps } from "@ark-ui/react";
import { ReactComponent as CheckIcon } from "@mobsuccess-devops/streamline/custom/react-ui/custom_checked-checkbox.svg";
import { sva } from "@mobsuccess-devops/styled-system/css";

import { Typo } from "../../Typo";

export type CheckboxProps = Omit<
  CheckboxRootProps,
  "checked" | "asChild" | "children"
> & {
  checked: NonNullable<CheckboxRootProps["checked"]>;
  label?: string;
  description?: string;
};

export function Checkbox({
  label,
  description,
  required = false,
  ...props
}: CheckboxProps): JSX.Element {
  const classes = styles({ required });
  return (
    <ArkCheckbox.Root {...props}>
      <div className={classes.root}>
        <ArkCheckbox.Control className={classes.control}>
          <ArkCheckbox.Indicator className={classes.indicator}>
            <CheckIcon className={classes.checkmark} />
          </ArkCheckbox.Indicator>
          <ArkCheckbox.Indicator indeterminate className={classes.indicator}>
            <div id="dash" className={classes.dash} />
          </ArkCheckbox.Indicator>
        </ArkCheckbox.Control>
        {label && (
          <ArkCheckbox.Label asChild>
            <Typo.Body className={classes.label} color="interface">
              {label}
            </Typo.Body>
          </ArkCheckbox.Label>
        )}
        {description && (
          <Typo.Muted className={classes.description}>{description}</Typo.Muted>
        )}
      </div>
      <ArkCheckbox.HiddenInput />
    </ArkCheckbox.Root>
  );
}

const styles = sva({
  slots: checkboxAnatomy.extendWith("checkmark", "dash", "description").keys(),
  base: {
    root: {
      position: "relative",
      display: "grid",
      gridTemplateAreas: `
        "control label"
        "none description"`,
      gridTemplateColumns: "auto 1fr",
      columnGap: "sm",
      alignItems: "center",
    },
    control: {
      gridArea: "control",
      bg: "white",
      position: "relative",
      display: "flex",
      size: "md",
      rounded: "sm",
      border: "1px solid transparent",
      boxSizing: "content-box",
      cursor: "pointer",
      "&[data-state=unchecked]": {
        borderColor: "#959EC9",
      },
      _hover: {
        _checked: {
          bg: "primary",
        },
        _indeterminate: {
          bg: "primary",
        },
        "&[data-state=unchecked]": {
          borderColor: "interface.400",
        },
      },
      boxShadow:
        "0 0 0 0 color-mix(in srgb, token(colors.colorPalette) 30%, transparent)",
      transition: "box-shadow 0.2s ease-in-out",
      _focus: {
        boxShadow:
          "0 0 0 4px color-mix(in srgb, token(colors.primary) 30%, transparent)",
        "&[data-state=unchecked]": {
          boxShadow:
            "0 0 0 4px color-mix(in srgb, token(colors.interface.400) 30%, transparent)",
        },
      },
      _checked: {
        bg: "primary.400",
        _disabled: {
          bg: "#BCC1DE",
          borderColor: "#BCC1DE",
        },
      },
      _indeterminate: {
        bg: "primary.400",
        _disabled: {
          bg: "#BCC1DE",
          borderColor: "#BCC1DE",
        },
      },
      _disabled: {
        bg: "interface.200",
        borderColor: "#BCC1DE",
        pointerEvents: "none",
      },
    },
    indicator: {
      size: "full",
      center: true,
    },
    checkmark: {
      size: "md",
      color: "white",
    },
    dash: {
      width: "14px",
      height: "2px",
      borderRadius: "60px",
      background: "white",
    },
    label: {
      gridArea: "label",
      _groupDisabled: {
        color: "#BCC1DE",
      },
    },
    description: {
      gridArea: "description",
    },
  },
  variants: {
    required: {
      true: {
        label: {
          "&::after": {
            content: "' *'",
            color: "danger",
          },
        },
      },
    },
  },
});
