import { BrowserOptions, makeFetchTransport } from "@sentry/react";
import { Transport, BaseTransportOptions } from "@sentry/types";

import { nativeFetch } from "./fetch";

export type ConsentManagerOptions = {
  isConsenting: boolean;
  awaitForConsent: boolean;
  isConsentRequired: boolean;
};

export type ConsentManagerResult = {
  flushAwaitingConsent: () => void;
  beforeSend: NonNullable<BrowserOptions["beforeSend"]>;
  beforeBreadcrumb: NonNullable<BrowserOptions["beforeBreadcrumb"]>;
  beforeSendTransaction: NonNullable<BrowserOptions["beforeSendTransaction"]>;
};

export class ConsentManager {
  private _isConsenting: boolean = false;
  private awaitingConsent = new Set<() => void>();
  private environment: string | null = null;

  constructor(private options: ConsentManagerOptions) {
    this._isConsenting = options.isConsenting;
  }

  private get isConsenting(): boolean {
    return (
      ["local", "development"].includes(this.environment ?? "") ??
      this._isConsenting
    );
  }

  public setEnvironment(environment: string): void {
    this.environment = environment;
  }

  public setConsent(consent: boolean): void {
    this._isConsenting = consent;
    if (!consent) {
      return;
    }
    const resolvers = Array.from(this.awaitingConsent.values());
    this.awaitingConsent.clear();
    for (const resolve of resolvers) {
      resolve();
    }
  }

  public async fetch(
    input: string | URL | globalThis.Request,
    init?: RequestInit,
  ): Promise<Response> {
    const fetchSentry = () => nativeFetch(input, init);
    if (this.isConsenting || !this.options.isConsentRequired) {
      return fetchSentry();
    }
    if (!this.options.awaitForConsent) {
      return new Response("Cannot send request without consent", {
        status: 400,
        statusText: "Bad Request",
      });
    }
    await new Promise<void>((resolve) => {
      this.awaitingConsent.add(resolve);
    });
    return fetchSentry();
  }
}

export function createConsentManagerTransport(consentManager: ConsentManager) {
  return (options: BaseTransportOptions): Transport => {
    return makeFetchTransport(
      options,
      consentManager.fetch.bind(consentManager),
    );
  };
}
