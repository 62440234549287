import {
  ChangeEventHandler,
  FocusEventHandler,
  memo,
  useLayoutEffect,
  useRef,
  JSX,
} from "react";

import styled, { useTheme } from "styled-components";

import { AbstractInput } from "../../AbstractInput";

const AbstractInputUI = styled(AbstractInput)`
  height: fit-content;
`;

const TextareaUI = styled.textarea<{ $variant: Variant }>`
  all: unset;
  resize: vertical;
  word-wrap: break-word;
  width: 100%;
  font-size: ${({ theme, $variant }) =>
    theme.components.input.fontSize[$variant]};
  line-height: 18px;
  padding: ${({ theme, $variant }) => theme.components.input.padding[$variant]};
  color: ${({ theme }) => theme.palette.interface.base};
  &::placeholder {
    color: ${({ theme }) => theme.palette.interface.lighter[300]};
  }
`;

type Variant = "sm" | "md" | "lg" | "xl";

export type TextareaProps = {
  id?: string;
  className?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  /** @deprecated use Label component instead */
  label?: string;
  placeholder?: string;
  isDisabled?: boolean;
  containerWidth?: string;
  state?: "warning" | "error" | "success";
  caption?: string;
  variant?: Variant;
  rows?: "auto" | number;
};

function Textarea({
  id,
  className,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  isDisabled = false,
  containerWidth,
  state,
  caption,
  variant = "md",
  rows = "auto",
}: TextareaProps): JSX.Element {
  const ref = useRef<HTMLTextAreaElement>(null);
  const { components } = useTheme();

  useLayoutEffect(() => {
    if (rows === "auto" && ref.current?.scrollHeight) {
      ref.current.style.height = "0px";
      ref.current.style.height =
        ref.current.scrollHeight -
        parseInt(components.input.padding.md) * 2 +
        "px";
    }
    // We want to trigger this effect when the value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <AbstractInputUI
      className={className}
      isDisabled={isDisabled}
      containerWidth={containerWidth}
      label={label}
      state={state}
      caption={caption}
      variant={variant}
    >
      <TextareaUI
        id={id}
        disabled={isDisabled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        rows={rows === "auto" ? 1 : rows}
        $variant={variant}
      />
    </AbstractInputUI>
  );
}

const MemoizedTextarea = memo(Textarea);
export { MemoizedTextarea as Textarea };
