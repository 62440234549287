import {
  memo,
  ReactNode,
  MouseEventHandler,
  ForwardedRef,
  forwardRef,
  useMemo,
  JSX,
} from "react";

import styled, { css, useTheme } from "styled-components";

import { Container } from "../Container";
import { Typography } from "../Typography";

type AbstractInputUIProps = {
  $isCompact?: boolean;
  $isDisabled: boolean;
  $isFocused: boolean;
  $borderStyle?: "standard" | "outlined" | "textBackground";
  $variant: "sm" | "md" | "lg" | "xl";
  $state?: "warning" | "error" | "success";
};

const disabledCss = css<AbstractInputUIProps>`
  cursor: not-allowed;
  opacity: 0.5;
  border-color: ${({ theme }) =>
    theme.components.input.border.disabled(theme)} !important;
`;

const standardCss = css<AbstractInputUIProps>`
  border: 1px solid;
  border-radius: ${({ theme, $isCompact }) =>
    theme.components.input.borderRadius[$isCompact ? "compact" : "default"]};
  border-color: ${({ theme }) => theme.components.input.border.default(theme)};
  &:hover {
    border-color: ${({ theme }) => theme.components.input.border.hover(theme)};
  }
  &:focus-within {
    border-color: ${({ theme }) => theme.components.input.border.focus(theme)};
    box-shadow: 0px 0px 0px 4px
      ${({ theme }) => `${theme.palette.primary.base}26`};
  }
  ${({ theme, $isFocused }) =>
    $isFocused &&
    `border-color: ${theme.components.input.border.focus(theme)};
    box-shadow: 0px 0px 0px 4px ${theme.palette.primary.base}26;`}
  border-color: ${({ theme, $state }) =>
    $state && theme.components.input.border[$state](theme)} !important;
`;

const outlinedCss = css<AbstractInputUIProps>`
  &::after {
    position: absolute;
    bottom: 0px;
    height: 1px;
    width: 0%;
    left: 50%;
    content: "";
    transition:
      width 0.3s ease-in-out,
      left 0.3s ease-in-out;
  }
  &:hover {
    &::after {
      left: 0;
      background-color: ${({ theme }) =>
        theme.components.input.border.hover(theme)};
      width: 100%;
    }
  }
  &:focus-within {
    &::after {
      left: 0;
      background-color: ${({ theme }) =>
        theme.components.input.border.focus(theme)};
      width: 100%;
    }
  }
  &::after {
    background-color: ${({ theme, $state }) =>
      $state && theme.components.input.border[$state](theme)} !important;
  }
`;

const textBackgroundCss = css<AbstractInputUIProps>`
  border: none;
`;

const AbstractInputUI = styled(Container)<AbstractInputUIProps>`
  height: ${({ theme, $variant }) => theme.components.input.height[$variant]};
  overflow: hidden;
  ${({ $borderStyle }) =>
    $borderStyle === "outlined"
      ? outlinedCss
      : $borderStyle === "textBackground"
        ? textBackgroundCss
        : standardCss};
  ${({ $isDisabled }) => $isDisabled && disabledCss};
`;

AbstractInputUI.displayName = "AbstractInputUI";

export type AbstractInputProps = {
  id?: string;
  caption?: string;
  isCompact?: boolean;
  className?: string;
  /** @deprecated use Label component instead */
  label?: string;
  isDisabled?: boolean;
  isFocused?: boolean;
  children?: ReactNode;
  leadingAddon?: ReactNode;
  containerWidth?: string;
  trailingAddon?: ReactNode;
  borderStyle?: "standard" | "outlined" | "textBackground";
  variant?: "sm" | "md" | "lg" | "xl";
  state?: "warning" | "error" | "success";
  containerRef?: ForwardedRef<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

function AbstractInput(
  {
    variant = "md",
    isCompact = false,
    isDisabled = false,
    isFocused = false,
    id,
    state,
    children,
    caption,
    onClick,
    className,
    label,
    containerRef,
    containerWidth,
    leadingAddon,
    trailingAddon,
    borderStyle = "standard",
  }: AbstractInputProps,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const { palette } = useTheme();

  const [labelTitle, labelDescription, hasAsterisk] = useMemo(() => {
    if (!label) {
      return [undefined, undefined, false];
    }

    let modifiedLabel = label;
    let description;

    if (label.includes("(")) {
      [, modifiedLabel, description] = label.match(/^(.*) (\(.+\))$/) ||
        label.match(/^(.*) (\(.+\*)$/) || [undefined, label, undefined];
    }

    const hasAsterisk =
      modifiedLabel.includes("*") || description?.includes("*");
    modifiedLabel = modifiedLabel.replace("*", "");
    description = description?.replace("*", "");

    return [modifiedLabel, description, hasAsterisk];
  }, [label]);
  return (
    <Container
      gap="8px"
      flexDirection="column"
      ref={containerRef}
      width={containerWidth}
    >
      {labelTitle && (
        <Container gap="4px" alignItems="baseline">
          <Typography
            as="span"
            variant="text-md-regular"
            color={palette.interface.lighter[100]}
          >
            {labelTitle}
          </Typography>
          {labelDescription && (
            <Typography
              as="span"
              variant="text-sm"
              color={palette.interface.lighter[100]}
            >
              {labelDescription}
            </Typography>
          )}
          {hasAsterisk && (
            <Typography
              as="span"
              variant="text-md-regular"
              color={palette.danger.base}
            >
              *
            </Typography>
          )}
        </Container>
      )}
      <AbstractInputUI
        id={id}
        ref={ref}
        gap="4px"
        width="100%"
        $state={state}
        onClick={onClick}
        $variant={variant}
        $isCompact={isCompact}
        alignItems="center"
        className={className}
        $isDisabled={isDisabled}
        $isFocused={isFocused}
        $borderStyle={borderStyle}
      >
        {leadingAddon}
        {children}
        {trailingAddon}
      </AbstractInputUI>
      {caption && (
        <Typography
          as="p"
          variant="text-sm-regular"
          color={
            state === "error"
              ? palette.danger.base
              : state === "warning"
                ? palette.warning.base
                : palette.interface.base
          }
        >
          {caption}
        </Typography>
      )}
    </Container>
  );
}

const MemoizedComponent = memo(forwardRef(AbstractInput));
export { MemoizedComponent as AbstractInput };
