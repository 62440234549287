import { type JSX, useCallback, Children } from "react";

import { paginationAnatomy as arkPaginationAnatomy } from "@ark-ui/anatomy";
import {
  Pagination as ArkPagination,
  type UsePaginationContext,
  type PaginationRootProps as ArkPaginationRootProps,
} from "@ark-ui/react";
import { ReactComponent as DoubleArrowLeftIcon } from "@mobsuccess-devops/streamline/regular/52-Arrows-Diagrams/01-Arrows/arrow-button-left-1.svg";
import { ReactComponent as ArrowIcon } from "@mobsuccess-devops/streamline/regular/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg";
import { sva, cx } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

import { PageSizeSelector } from "./PageSizeSelector";

export type PaginationRootProps = ArkPaginationRootProps & {
  children?: JSX.Element;
};

export const paginationAnatomy = arkPaginationAnatomy.extendWith(
  "firstTrigger",
  "lastTrigger",
);

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables -- this should be legal as we want to avoid naming overlap between the root component and it's composition
function PaginationRoot({
  children,
  ...props
}: PaginationRootProps): JSX.Element {
  const classes = styles();
  const navigateFar = useCallback(
    (api: UsePaginationContext, direction: "first" | "last") => {
      return () => {
        api.setPage(direction === "first" ? 1 : api.totalPages);
      };
    },
    [],
  );

  if (children) {
    const child = Children.only(children);
    if (child.type !== PageSizeSelector) {
      throw new Error(
        "Pagination can only have PageSizeSelector as a child component.",
      );
    }
  }

  return (
    <ArkPagination.Root
      {...props}
      className={cx(classes.root, props.className)}
    >
      <ArkPagination.Context>
        {/* eslint-disable-next-line @mobsuccess-devops/mobsuccess/react */}
        {(api) => (
          <>
            <div className={classes.pageSelector}>
              <styled.button
                disabled={api.page === 1}
                onClick={navigateFar(api, "first")}
                className={cx(
                  classes.trigger,
                  classes.prevTrigger,
                  classes.element,
                )}
                {...paginationAnatomy.build().firstTrigger.attrs}
              >
                <DoubleArrowLeftIcon />
              </styled.button>
              <ArkPagination.PrevTrigger
                className={cx(
                  classes.prevTrigger,
                  classes.trigger,
                  classes.element,
                )}
              >
                <ArrowIcon />
              </ArkPagination.PrevTrigger>
              {api.pages
                .reduce((acc, page) => {
                  if (page.type === "ellipsis") {
                    acc.push({
                      type: "ellipsis",
                      key: `ellipsis-${acc.filter((item) => item.type === "ellipsis").length + 1}`,
                    });
                  } else {
                    acc.push(page);
                  }
                  return acc;
                }, new Array<{ type: "ellipsis"; key: string } | { type: "page"; value: number }>())
                .map((page, index) =>
                  page.type === "page" ? (
                    <ArkPagination.Item
                      key={page.value}
                      className={cx(classes.item, classes.element)}
                      {...page}
                    >
                      {page.value}
                    </ArkPagination.Item>
                  ) : (
                    <ArkPagination.Ellipsis
                      key={page.key}
                      index={index}
                      className={cx(classes.ellipsis, classes.element)}
                    >
                      &#8230;
                    </ArkPagination.Ellipsis>
                  ),
                )}
              <ArkPagination.NextTrigger
                className={cx(
                  classes.trigger,
                  classes.nextTrigger,
                  classes.element,
                )}
              >
                <ArrowIcon />
              </ArkPagination.NextTrigger>
              <styled.button
                disabled={api.page === api.totalPages}
                onClick={navigateFar(api, "last")}
                className={cx(
                  classes.trigger,
                  classes.nextTrigger,
                  classes.element,
                )}
                {...paginationAnatomy.build().lastTrigger.attrs}
              >
                <DoubleArrowLeftIcon />
              </styled.button>
            </div>
            {children}
          </>
        )}
      </ArkPagination.Context>
    </ArkPagination.Root>
  );
}

const styles = sva({
  slots: paginationAnatomy
    .extendWith("trigger", "element", "pageSelector")
    .keys(),
  base: {
    root: {
      display: "flex",
      gap: "md",
      alignItems: "center",
    },
    pageSelector: {
      display: "flex",
      width: "fit-content",
      height: "full",
      borderRadius: "md",
      border: "1px solid",
      backgroundColor: "white",
      overflow: "hidden",
      borderColor: "#959EC9",
      _hover: {
        borderColor: "interface.400",
      },
    },
    element: {
      center: true,
      size: "40px",
      cursor: "pointer",
      outline: "none",
      border: "none",
      textStyle: "muted",
      borderRight: "1px solid",
      borderColor: "interface.200!",
      backgroundColor: "white",
      _last: {
        borderRight: "none",
      },
    },
    trigger: {
      color: "interface",
      cursor: "pointer",
      center: true,
      _childSvg: {
        size: "xs",
      },
      _disabled: {
        cursor: "not-allowed",
        color: "interface.300",
        backgroundColor: "white",
      },
      _hover: {
        backgroundColor: "interface.100",
      },
    },
    item: {
      center: true,
      size: "40px",
      color: "interface",
      _hover: {
        backgroundColor: "interface.100",
      },
      _selected: {
        backgroundColor: "primary.100!",
        color: "primary",
      },
    },
    ellipsis: {
      cursor: "default",
      paddingX: "md",
      paddingY: "sm",
      color: "interface",
    },
    nextTrigger: {
      "&>svg": {
        rotate: "180deg",
      },
    },
  },
});

export const Pagination = {
  Root: PaginationRoot,
  PageSizeSelector,
};
