const whoami =
  import.meta.env.VITE_WHOAMI || import.meta.env.VITE_STORYBOOK_WHOAMI;

const release = import.meta.env.VITE_MSSENTRYRELEASE;

const sampleRate = isNaN(+import.meta.env.VITE_SENTRY_SAMPLE_RATE)
  ? null
  : +import.meta.env.VITE_SENTRY_SAMPLE_RATE;

export const env = {
  whoami,
  release,
  sampleRate,
};
