import { InputTheme, input, CardTheme, card } from "./components";

/* Animation */
export type Animation = {
  short: string;
  long: string;
};

/* Breakpoint */
export type Breakpoint = {
  sm: string;
  md: string;
  phablet: string;
  lg: string;
  ipad: string;
  xl: string;
  xxl: string;
  xxxl: string;
};

/* Breakpoints */
export type Breakpoints = {
  mobile: string;
  phablet: string;
  tablet: string;
  laptop: string;
  desktop: string;
  monitor: string;
  widescreen: string;
};

/* ComponentsDesign*/
export type Components = {
  input: InputTheme;
  card: CardTheme;
};

/* Colors */
export type ColorPaletteDark = {
  100: string;
  200: string;
  300: string;
  400?: string;
};

export type ColorPaletteLight = {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
};

export type Colors = {
  black: string;
  coral: string;
  darkGray: ColorPaletteDark;
  lightGray: ColorPaletteLight;
  gray: string;
  pink: string;
  seance: string;
  viking: string;
  white: string;
  rajah: string;
  pirateGold: string;
  mercury: string;
  dustyGray: string;
  rajahAlt: string;
  roseOfSharon: string;
  iceCold: string;
};

/* Font sizes and weights */
export type FontSize = {
  small: string;
  base: string;
  medium: string;
  large: string;
  ultraLarge: string;
};

export type FontWeight = {
  hard: number;
  bold: number;
  h3: number;
  h2: number;
  h1Alt: number;
  h1: number;
};

/* Padding */
export type Padding = {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  0.5: string;
  1.5: string;
  2.5: string;
  3.5: string;
};

/* Widgets */
export type WidgetPadding = {
  sm: string;
  md: string;
  lg: string;
};

export type Widget = {
  padding: WidgetPadding;
  borderRadius: string;
  boxShadow: string;
};

/* Shadow */
export type Shadow = {
  medium: string;
};

/* Typography */

export type TypographyFontWeightVariant = {
  [key: string]: number;
};

export type Typography = {
  fontSize: {
    [key: string]: string;
  };
  lineHeight: {
    [key: string]: string;
  };
  weight: {
    [key: string]: TypographyFontWeightVariant;
  };
};

/* LMP */
export type LmpColor = {
  base: string;
  darker: ColorPaletteDark;
  lighter: ColorPaletteLight;
};

export type Lmp = {
  red: LmpColor;
  orange: LmpColor;
  yellow: LmpColor;
  apple: LmpColor;
  green: LmpColor;
  mint: LmpColor;
  blue: LmpColor;
  lavender: LmpColor;
  violet: LmpColor;
  pink: LmpColor;
};

/* Neutral theme */
export type Neutral = {
  100: string;
  200: string;
  300: string;
  400: string;
};

/* Theme */
export type Theme = {
  animation: Animation;
  borderRadius: string;
  widgetRadius: string;
  widgetShadow: string;
  boxShadow: string;
  boxShadowNavbar: string;
  breakpoint: Breakpoint;
  breakpoints: Breakpoints;
  components: Components;
  neutral: Neutral;
  colors: Colors;
  fontFamily: string;
  fontSize: FontSize;
  lineHeight: FontSize;
  fontWeight: FontWeight;
  padding: Padding;
  widget: Widget;
  shadows: Shadow;
  typography: Typography;
  lmp: Lmp;
  palette: Palette;
};

export type Palette = {
  color: (opacity: number) => string;
  dark: string;
  white: string;
  graphs: {
    [key: string]: string;
  };
} & Record<PaletteColorsKeys, PaletteColor>;
export type Palettes = {
  [key: string]: Palette;
  widely: Palette;
  mobsuccess: Palette;
  decathlon: Palette;
  leboncoin: Palette;
};

const mobsuccessPalette = {
  color: (opacity = 1): string => `rgba(171, 110, 206, ${opacity})`,
  dark: "#000000",
  white: "#FFFFFF",
  primary: {
    base: "#6C1F98",
    darker: {
      100: "#551778",
      200: "#431060",
      300: "#350A4D",
    },
    lighter: {
      100: "#9231C9",
      200: "#B375D7",
      300: "#CCA7E2",
      400: "#DBC3E9",
      500: "#E8DCEF",
      600: "#F1EAF6",
      700: "#F8F4FA",
    },
  },
  secondary: {
    base: "#F66A4C",
    darker: {
      100: "#EE411B",
      200: "#C92F0D",
      300: "#AA2508",
    },
    lighter: {
      100: "#FC9882",
      200: "#FDAF9D",
      300: "#FFCBC3",
      400: "#FAE0DC",
      500: "#F9E9E7",
      600: "#FBF0EF",
      700: "#FDF8F7",
    },
  },
  interface: {
    base: "#4F5B92",
    darker: {
      100: "#2E3661",
      200: "#232B52",
      300: "#1D233E",
    },
    lighter: {
      100: "#606DAF",
      200: "#959EC9",
      300: "#BCC1DE",
      400: "#E3E6F5",
      500: "#E9EAF6",
      600: "#F1F2F8",
      700: "#F8F9FB",
    },
  },
  success: {
    base: "#00B8A2",
    darker: {
      100: "#008F7E",
      200: "#006B5F",
      300: "#004D44",
    },
    lighter: {
      100: "#02D7BE",
      200: "#05E6CC",
      300: "#07F2D7",
      400: "#71F9E9",
      500: "#A8FAF1",
      600: "#D4FCF8",
      700: "#ECFEFC",
    },
  },
  danger: {
    base: "#FE7272",
    darker: {
      100: "#FA4242",
      200: "#EA0B0B",
      300: "#B90909",
    },
    lighter: {
      100: "#FE8B8B",
      200: "#FE9F9F",
      300: "#FEB3B3",
      400: "#FEC3C3",
      500: "#FEDCDC",
      600: "#FEEBEB",
      700: "#FFF5F5",
    },
  },
  warning: {
    base: "#FDA63F",
    darker: {
      100: "#FC8E0D",
      200: "#DE7903",
      300: "#AB5E02",
    },
    lighter: {
      100: "#FFBA6B",
      200: "#FFC88A",
      300: "#FFD4A3",
      400: "#FFDBB3",
      500: "#FFE5C7",
      600: "#FFEEDB",
      700: "#FEF6EB",
    },
  },
  info: {
    base: "#7BC2EA",
    darker: {
      100: "#4FAEE3",
      200: "#2296D8",
      300: "#196C9A",
    },
    lighter: {
      100: "#8FCDF0",
      200: "#A4D8F4",
      300: "#B5E0F8",
      400: "#C7E8F9",
      500: "#D5EEFB",
      600: "#E3F4FD",
      700: "#F1FAFE",
    },
  },
  graphs: {
    budget: "#959EC9",
    progression: "#00D9C0",
    reach: "#6C1F98",
    impression: "#00D9C0",
    clics: "#F66A4C",
    visits: "#00D9C0",
    map: "#00D9C0",
  },
};

const palettes: Palettes = {
  widely: {
    color: (opacity = 1): string => `rgba(171, 110, 206, ${opacity})`,
    dark: "#000000",
    white: "#FFFFFF",
    primary: {
      base: "#2E22C3",
      darker: {
        100: "#1A1197",
        200: "#0C0561",
        300: "#07023A",
      },
      lighter: {
        100: "#5347EB",
        200: "#6D63EE",
        300: "#8E86F9",
        400: "#A59EFF",
        500: "#BDB8FF",
        600: "#D9D6FF",
        700: "#E7E5FF",
      },
    },
    secondary: {
      base: "#80E0B6",
      darker: {
        100: "#4AD497",
        200: "#29AD73",
        300: "#20885A",
      },
      lighter: {
        100: "#B4EDD4",
        200: "#CEF3E3",
        300: "#DEF7EC",
        400: "#FEAFAF3",
        500: "#F3FCF8",
        600: "#FBFEFD",
        700: "#FFFFFF",
      },
    },
    interface: {
      base: "#4F5B92",
      darker: {
        100: "#2E3661",
        200: "#232B52",
        300: "#1D233E",
      },
      lighter: {
        100: "#606DAF",
        200: "#959EC9",
        300: "#BCC1DE",
        400: "#E3E6F5",
        500: "#E9EAF6",
        600: "#F1F2F8",
        700: "#F8F9FB",
      },
    },
    success: {
      base: "#00B8A2",
      darker: {
        100: "#008F7E",
        200: "#006B5F",
        300: "#004D44",
      },
      lighter: {
        100: "#02D7BE",
        200: "#05E6CC",
        300: "#07F2D7",
        400: "#71F9E9",
        500: "#A8FAF1",
        600: "#D4FCF8",
        700: "#ECFEFC",
      },
    },
    danger: {
      base: "#FE7272",
      darker: {
        100: "#FA4242",
        200: "#EA0B0B",
        300: "#B90909",
      },
      lighter: {
        100: "#FE8B8B",
        200: "#FE9F9F",
        300: "#FEB3B3",
        400: "#FEC3C3",
        500: "#FEDCDC",
        600: "#FEEBEB",
        700: "#FFF5F5",
      },
    },
    warning: {
      base: "#FDA63F",
      darker: {
        100: "#FC8E0D",
        200: "#DE7903",
        300: "#AB5E02",
      },
      lighter: {
        100: "#FFBA6B",
        200: "#FFC88A",
        300: "#FFD4A3",
        400: "#FFDBB3",
        500: "#FFE5C7",
        600: "#FFEEDB",
        700: "#FEF6EB",
      },
    },
    info: {
      base: "#7BC2EA",
      darker: {
        100: "#4FAEE3",
        200: "#2296D8",
        300: "#196C9A",
      },
      lighter: {
        100: "#8FCDF0",
        200: "#A4D8F4",
        300: "#B5E0F8",
        400: "#C7E8F9",
        500: "#D5EEFB",
        600: "#E3F4FD",
        700: "#F1FAFE",
      },
    },
    graphs: {
      budget: "#959EC9",
      progression: "#00D9C0",
      reach: "#6C1F98",
      impression: "#00D9C0",
      clics: "#F66A4C",
      visits: "#00D9C0",
      map: "#00D9C0",
    },
  },
  mobsuccess: mobsuccessPalette,
  leboncoin: {
    ...mobsuccessPalette,
    primary: {
      base: "#FF6626",
      darker: {
        100: "#E65C22",
        200: "#D3541E", // deprecated
        300: "#C04C1A", // deprecated
      },
      lighter: {
        100: "#FFA37D",
        200: "#FFA37D",
        300: "#FFE0D4",
        400: "#FFF5F2", // deprecated
        500: "#FFF9F7", // deprecated
        600: "#FFFCFA", // deprecated
        700: "#FEF1E9",
      },
    },
    interface: {
      ...mobsuccessPalette.interface,
      base: "#152133",
      lighter: {
        ...mobsuccessPalette.interface.lighter,
        100: "#9EABB7",
        200: "#9EABB7",
        300: "#9EABB7",
      },
      darker: {
        ...mobsuccessPalette.interface.darker,
        300: "#152133",
      },
    },
    graphs: {
      ...mobsuccessPalette.graphs,
      budget: "#9EABB7",
      reach: "#FF6626",
      clics: "#F66A4C",
    },
  },
  decathlon: {
    color: (opacity = 1): string => `rgba(171, 110, 206, ${opacity})`,
    dark: "#000000",
    white: "#FFFFFF",
    primary: {
      base: "#0076B4",
      darker: {
        100: "#006499",
        200: "#004D75",
        300: "#003C5C",
      },
      lighter: {
        100: "#9231C9",
        200: "#1AB0FF",
        300: "#61C9FF",
        400: "#99DCFF",
        500: "#C7ECFF",
        600: "#DBF3FF",
        700: "#F0FAFF",
      },
    },
    secondary: {
      base: "#0076B4",
      darker: {
        100: "#F0D800",
        200: "#E0CA00",
        300: "#C7B400",
      },
      lighter: {
        100: "#FFEA28",
        200: "#FFEF5C",
        300: "#FFF48A",
        400: "#FFF7A8",
        500: "#FFF9C2",
        600: "#FFFCD6",
        700: "#FFFDE5",
      },
    },
    interface: {
      base: "#4F5B92",
      darker: {
        100: "#2E3661",
        200: "#232B52",
        300: "#1D233E",
      },
      lighter: {
        100: "#606DAF",
        200: "#959EC9",
        300: "#BCC1DE",
        400: "#E3E6F5",
        500: "#E9EAF6",
        600: "#F1F2F8",
        700: "#F8F9FB",
      },
    },
    success: {
      base: "#00B8A2",
      darker: {
        100: "#008F7E",
        200: "#006B5F",
        300: "#004D44",
      },
      lighter: {
        100: "#02D7BE",
        200: "#05E6CC",
        300: "#07F2D7",
        400: "#71F9E9",
        500: "#A8FAF1",
        600: "#D4FCF8",
        700: "#ECFEFC",
      },
    },
    danger: {
      base: "#EE000C",
      darker: {
        100: "#FA4242",
        200: "#EA0B0B",
        300: "#B90909",
      },
      lighter: {
        100: "#FE8B8B",
        200: "#FE9F9F",
        300: "#FEB3B3",
        400: "#FEC3C3",
        500: "#FEDCDC",
        600: "#FEEBEB",
        700: "#FFF5F5",
      },
    },
    warning: {
      base: "#FFEA28",
      darker: {
        100: "#FC8E0D",
        200: "#DE7903",
        300: "#AB5E02",
      },
      lighter: {
        100: "#FFBA6B",
        200: "#FFC88A",
        300: "#FFD4A3",
        400: "#FFDBB3",
        500: "#FFE5C7",
        600: "#FFEEDB",
        700: "#FEF6EB",
      },
    },
    info: {
      base: "#61C9FF",
      darker: {
        100: "#4FAEE3",
        200: "#2296D8",
        300: "#196C9A",
      },
      lighter: {
        100: "#8FCDF0",
        200: "#A4D8F4",
        300: "#B5E0F8",
        400: "#C7E8F9",
        500: "#D5EEFB",
        600: "#E3F4FD",
        700: "#F1FAFE",
      },
    },
    graphs: {
      budget: "#959EC9",
      progression: "#02BE8A",
      reach: "#0076B4",
      impression: "#02BE8A",
      clics: "#F66A4C",
      visits: "#02BE8A",
      map: "#02BE8A",
    },
  },
};

const theme: Theme = {
  animation: {
    short: ".3s",
    long: ".5s",
  },
  borderRadius: ".25rem",
  widgetRadius: ".75rem",
  widgetShadow: "0 0.25rem .75rem rgba(0, 0, 0, 0.04)",
  boxShadow: "0 .25rem .75rem rgba(0, 0, 0, 0.04)",
  boxShadowNavbar: "0px 4px 12px 0px #0000000a",
  breakpoint: {
    sm: "36rem",
    md: "48rem",
    phablet: "50rem",
    lg: "62rem",
    ipad: "1025px",
    xl: "75rem",
    xxl: "87.5rem",
    xxxl: "115.625rem",
  },
  breakpoints: {
    mobile: "640px",
    phablet: "640px",
    tablet: "768px",
    laptop: "1024px",
    desktop: "1280px",
    monitor: "1440px",
    widescreen: "1536px",
  },
  components: {
    input,
    card,
  },
  neutral: {
    100: "#152133",
    200: "#8C96A2",
    300: "#9EABB7",
    400: "#F2F5F6",
  },
  colors: {
    black: "#000000",
    coral: "#FF8C51",
    darkGray: {
      100: "#4F5886",
      200: "#252A41",
      300: "#1D233E",
      400: "#151B35",
    },
    lightGray: {
      100: "#606DAF",
      200: "#959EC9",
      300: "#BCC1DE",
      400: "#E3E6F5",
      500: "#E9EAF6",
      600: "#F1F2F8",
      700: "#F8F9FB",
    },
    gray: "#4F5B92",
    pink: "#FFCBC3",
    seance: "#6C1F98",
    viking: "#00D9C0",
    white: "#ffffff",
    rajah: "#F7CD7B",
    pirateGold: "#B77900",
    mercury: "#E4E4E4",
    dustyGray: "#969696",
    rajahAlt: "#F7B87D",
    roseOfSharon: "#BD5B00",
    iceCold: "#B2F3EC",
  },
  fontFamily: "Poppins",
  fontSize: {
    small: "10px",
    base: "12px",
    medium: "14px",
    large: "20px",
    ultraLarge: "30px",
  },
  lineHeight: {
    small: "14px",
    base: "16px",
    medium: "20px",
    large: "32px",
    ultraLarge: "52px",
  },
  fontWeight: {
    hard: 700,
    bold: 600,
    h3: 500,
    h2: 500,
    h1Alt: 400,
    h1: 300,
  },
  padding: {
    0: "0",
    0.5: "0.125rem",
    1: "0.25rem",
    1.5: "0.375rem",
    2: "0.5rem",
    2.5: "0.625rem",
    3: "0.75rem",
    3.5: "0.875rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
  },
  widget: {
    padding: {
      sm: "1.25rem",
      md: "2rem",
      lg: "2.5rem",
    },
    borderRadius: "0.75rem",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.04)",
  },
  shadows: {
    medium: "0px 4px 12px rgba(0, 0, 0, 0.04)",
  },
  typography: {
    fontSize: {
      title: "38px",
      xs: "10px",
      sm: "12px",
      md: "14px",
      lg: "16px",
      xl: "20px",
      "2xl": "28px",
      xxl: "30px",
    },
    lineHeight: {
      title: "48px",
      xs: "14px",
      sm: "16px",
      md: "20px",
      lg: "24px",
      xl: "32px",
      "2xl": "42px",
      xxl: "52px",
    },
    weight: {
      title: {
        light: 300,
        regular: 400,
        bold: 700,
      },
      xs: {
        regular: 400,
        bold: 600,
      },
      sm: {
        regular: 400,
        bold: 600,
      },
      md: {
        light: 300,
        regular: 500,
        bold: 600,
      },
      lg: {
        light: 300,
        regular: 500,
        bold: 600,
      },
      xl: {
        light: 300,
        regular: 400,
        bold: 500,
      },
      "2xl": {
        light: 300,
        regular: 400,
        bold: 500,
      },
      xxl: {
        regular: 300,
      },
    },
  },
  lmp: {
    red: {
      base: "#ee3e3e",
      darker: {
        100: "#a30303",
        200: "#bc0505",
        300: "#dd0b0b",
      },
      lighter: {
        100: "#f06161",
        200: "#f37e7e",
        300: "#f1a9a9",
        400: "#f4c0c0",
        500: "#f6d7d7",
        600: "#f9ebeb",
        700: "#faf3f3",
      },
    },
    orange: {
      base: "#ee8b19",
      darker: {
        100: "#ab5e06",
        200: "#c16b08",
        300: "#d5780c",
      },
      lighter: {
        100: "#eca24d",
        200: "#eeb573",
        300: "#eecba1",
        400: "#f2dbc0",
        500: "#f5e6d4",
        600: "#f8efe4",
        700: "#fbf7f2",
      },
    },
    yellow: {
      base: "#e3c111",
      darker: {
        100: "#b19608",
        200: "#c5a70b",
        300: "#d2b20d",
      },
      lighter: {
        100: "#ebcd32",
        200: "#eed864",
        300: "#f0e08d",
        400: "#f3e8ae",
        500: "#f5edc3",
        600: "#f8f3d9",
        700: "#fbf9ee",
      },
    },
    apple: {
      base: "#9ec922",
      darker: {
        100: "#6f920b",
        200: "#7fa710",
        300: "#8eb915",
      },
      lighter: {
        100: "#acd439",
        200: "#bbdb60",
        300: "#cce487",
        400: "#d9eaaa",
        500: "#e2efbf",
        600: "#e9f2cf",
        700: "#f4f7e7",
      },
    },
    green: {
      base: "#57c93b",
      darker: {
        100: "#408f2c",
        200: "#49a433",
        300: "#4fb735",
      },
      lighter: {
        100: "#78db5f",
        200: "#91e07d",
        300: "#a7e798",
        400: "#bdecb0",
        500: "#ccefc2",
        600: "#dcf3d6",
        700: "#eaf7e7",
      },
    },
    mint: {
      base: "#21caa2",
      darker: {
        100: "#149677",
        200: "#17a886",
        300: "#1bba94",
      },
      lighter: {
        100: "#41dbb6",
        200: "#6ce1c5",
        300: "#93e6d1",
        400: "#aceada",
        500: "#c2eee3",
        600: "#d5f2eb",
        700: "#e8f8f4",
      },
    },
    blue: {
      base: "#1ab1f1",
      darker: {
        100: "#0782b6",
        200: "#0897d3",
        300: "#0ca2e1",
      },
      lighter: {
        100: "#3bbcf3",
        200: "#63c8f3",
        300: "#87d3f3",
        400: "#a4dcf4",
        500: "#c0e6f6",
        600: "#d8eff9",
        700: "#e7f5fb",
      },
    },
    lavender: {
      base: "#7186f6",
      darker: {
        100: "#334fe8",
        200: "#4a65f2",
        300: "#6078f6",
      },
      lighter: {
        100: "#8496f5",
        200: "#97a6f6",
        300: "#acb8f7",
        400: "#c4ccf8",
        500: "#d7ddfa",
        600: "#e1e6fb",
        700: "#eff1fc",
      },
    },
    violet: {
      base: "#a75bf2",
      darker: {
        100: "#7e14e6",
        200: "#8f2bf2",
        300: "#9e48f3",
      },
      lighter: {
        100: "#b679f3",
        200: "#c08df4",
        300: "#cca4f4",
        400: "#d5b7f4",
        500: "#dfc8f6",
        600: "#e8d7f8",
        700: "#f3eafb",
      },
    },
    pink: {
      base: "#d25bd4",
      darker: {
        100: "#98309a",
        200: "#b436b6",
        300: "#c744c9",
      },
      lighter: {
        100: "#e381e5",
        200: "#e798e9",
        300: "#eaa9eb",
        400: "#eebbee",
        500: "#f1c9f1",
        600: "#f4d9f4",
        700: "#f8e8f8",
      },
    },
  },
  palette: palettes.widely,
};

export type PaletteColor = {
  base: string;
  darker: {
    [key: number]: string;
  };
  lighter: {
    [key: number]: string;
  };
};

export type PaletteColorsKeys =
  | "primary"
  | "secondary"
  | "success"
  | "info"
  | "warning"
  | "danger"
  | "interface";

export { palettes, theme };

export default theme;
