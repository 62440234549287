import { memo, useCallback, JSX } from "react";

import { useNavigate } from "@mobsuccess-devops/react-router";

import { css } from "styled-components";

import { DarkButton } from "../Button/DarkButton";
import { Container } from "../Container";
import { Typography } from "../Typography";

const titleCss = css`
  font-size: 40px;
  font-weight: 700;
`;

function ErrorPage(): JSX.Element {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Container
      gap="20px"
      width="100vw"
      height="100vh"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      backgroundColor="white"
    >
      <Typography as="h1" styled={titleCss}>
        🤷 Oops!
      </Typography>
      <Typography as="span" textAlign="center">
        Something was not right, please try again later <br />
        Feel free to contact the support if the problem persists.
      </Typography>
      <DarkButton onClick={handleGoBack}>
        <span>🏡</span> Bring me back home
      </DarkButton>
    </Container>
  );
}

const MemoizedComponent = memo(ErrorPage);
export { MemoizedComponent as ErrorPage };
