import { memo, useCallback, useMemo, JSX } from "react";

import styled from "styled-components";

import {
  getPreviousAndNextMonth,
  useDatePickerSingleDayContext,
} from "../../../../../features/date-picker";
import { SelectOptions } from "../../../../../types/new-select";
import { AdvancedSelect } from "../../../AdvancedSelect/AdvancedSelect";

const MonthPickerUI = styled.div`
  display: flex;
  gap: 10px;
`;

MonthPickerUI.displayName = "MonthPickerUI";

export type MonthPickerProps = {
  month: string;
  year: string;
  allowedMonths: SelectOptions[];
  allowedYears: SelectOptions[];
  onMonthChange: (month: string) => void;
  onYearChange: (year: string) => void;
};

const SelectUI = styled(AdvancedSelect)`
  width: 120px;
  text-transform: capitalize;
`;

function MonthPicker(): JSX.Element {
  const { months, setMonths, allowedDate } = useDatePickerSingleDayContext();

  const [start] = months;

  const { year, month } = useMemo(() => {
    return {
      year: start.year.toString(),
      month: start.month.toString(),
    };
  }, [start]);

  const allowedMonths = useMemo<SelectOptions>(() => {
    return Array(12)
      .fill(null)
      .map((_, i) => new Date(start.year, i))
      .filter((date) => {
        return (
          (!allowedDate.start ||
            date >=
              new Date(
                allowedDate.start.getFullYear(),
                allowedDate.start.getMonth(),
              )) &&
          (!allowedDate.end ||
            date <=
              new Date(
                allowedDate.end.getFullYear(),
                allowedDate.end.getMonth(),
              ))
        );
      })
      .map((date) => {
        return {
          value: date.getMonth().toString(),
          label: date.toLocaleString(undefined, { month: "long" }),
          type: "option" as const,
        };
      });
  }, [allowedDate.end, allowedDate.start, start.year]);

  const allowedYears = useMemo<SelectOptions>(() => {
    if (!allowedDate.start || !allowedDate.end) {
      return [];
    }
    const allowedYears = new Set<number>();
    // add all years included in the max range
    for (
      let i = allowedDate.start.getFullYear();
      i <= allowedDate.end.getFullYear();
      i += 1
    ) {
      allowedYears.add(i);
    }
    return Array.from(allowedYears.values()).map((year) => ({
      value: year.toString(),
      label: year.toString(),
      type: "option",
    }));
  }, [allowedDate]);

  const handleMonthChange = useCallback(
    (value: string) => {
      const month = parseInt(value, 10);
      const newStart = { ...start, month };
      const [, next] = getPreviousAndNextMonth(newStart);
      setMonths([newStart, next]);
    },
    [setMonths, start],
  );

  const handleYearChange = useCallback(
    (value: string) => {
      const year = parseInt(value, 10);
      const newStart = { ...start, year };
      const [, next] = getPreviousAndNextMonth(newStart);
      setMonths([newStart, next]);
    },
    [setMonths, start],
  );
  return (
    <MonthPickerUI>
      <SelectUI
        variant="md"
        value={month}
        id="date-picker-month"
        options={allowedMonths}
        onChange={handleMonthChange}
        borderStyle="textBackground"
      />
      <SelectUI
        variant="md"
        value={year}
        id="date-picker-year"
        options={allowedYears}
        onChange={handleYearChange}
        borderStyle="textBackground"
      />
    </MonthPickerUI>
  );
}

const MemoizedComponent = memo(MonthPicker);
export { MemoizedComponent as MonthPicker };
