import axios, { AxiosInstance } from "axios";

export function registerAxiosInterceptor<
  T extends keyof AxiosInstance["interceptors"],
>(
  type: T,
  ...params: Parameters<AxiosInstance["interceptors"][T]["use"]>
): number {
  // @ts-expect-error TODO fix this error
  return axios.interceptors[type].use(...params);
}

export function ejectAxiosInterceptor(
  type: keyof AxiosInstance["interceptors"],
  intercetorId: number,
): void {
  axios.interceptors[type].eject(intercetorId);
}
