import {
  ReactNode,
  JSX,
  Children,
  ReactElement,
  isValidElement,
  cloneElement,
} from "react";

import {
  type TabTriggerProps as ArkTabTriggerProps,
  Tabs,
} from "@ark-ui/react";
import { cva, cx } from "@mobsuccess-devops/styled-system/css";

import { flattenChildren } from "../../../features/react-tools";

type TabTriggerProps = ArkTabTriggerProps;

export function TabTrigger({
  className,
  ...rest
}: TabTriggerProps): JSX.Element {
  const classname = styles();
  return <Tabs.Trigger {...rest} className={cx(classname, className)} />;
}

const styles = cva({
  base: {
    center: true,
    gap: "12px",
    outline: "none",
    border: "none",
    padding: "sm",
    borderTopRadius: "md",
    color: "interface.400",
    backgroundColor: "interface.200",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    _hover: {
      backgroundColor: "#E9EAF6",
    },
    _selected: {
      color: "interface",
      backgroundColor: "white!",
    },
    _childSvg: {
      size: "xs",
    },
  },
});

type TabTriggerElement = ReactElement<TabTriggerProps, typeof TabTrigger>;
function isTabTrigger(child: ReactNode): child is TabTriggerElement {
  return isValidElement(child) && child.type === TabTrigger;
}

export function splitTabsTriggers(
  children: ReactNode,
  className?: string,
): [triggers: TabTriggerElement[], content: ReactNode[]] {
  const { triggers, content } = Children.toArray(
    flattenChildren(children),
  ).reduce(
    (acc: { triggers: TabTriggerElement[]; content: ReactNode[] }, child) => {
      if (isTabTrigger(child)) {
        const childWithClassName = cloneElement<
          ArkTabTriggerProps & TabTriggerProps
        >(child, {
          className: cx(child.props.className, className),
        });
        if (isTabTrigger(childWithClassName)) {
          acc.triggers.push(childWithClassName);
        }
      } else {
        acc.content.push(child);
      }
      return acc;
    },
    { triggers: [], content: [] },
  );
  return [triggers, content];
}
