import { MutableRefObject, useMemo, useRef } from "react";

function useListeningRef<T>(instance: T): MutableRefObject<T> {
  const listeningRef = useRef<T>(instance);
  return useMemo(() => {
    listeningRef.current = instance;
    return listeningRef;
  }, [instance]);
}

export default useListeningRef;
