import { BusinessUnits } from "../types/enums";

export function getBusinessUnit(): BusinessUnits {
  const searchParams = new URLSearchParams(window.location.search);

  const businessUnit =
    window.location.hostname.includes("leboncoin") ||
    searchParams.get("source_host")?.includes("leboncoin")
      ? BusinessUnits.Leboncoin
      : BusinessUnits.Widely;

  return businessUnit;
}

export function getClient(): "leboncoin" | "mobsuccess" {
  const searchParams = new URLSearchParams(window.location.search);

  return window.location.hostname.includes("leboncoin") ||
    searchParams.get("source_host")?.includes("leboncoin")
    ? "leboncoin"
    : "mobsuccess";
}
