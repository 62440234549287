import { JSX, ReactNode } from "react";

import { tabsAnatomy } from "@ark-ui/anatomy";
import {
  Tabs as ArkTabs,
  type TabsRootProps as ArkTabsRootProps,
} from "@ark-ui/react";
import { cx, sva } from "@mobsuccess-devops/styled-system/css";
import { hstack } from "@mobsuccess-devops/styled-system/patterns";

import { TabContent } from "./TabContent";
import { TabTrigger, splitTabsTriggers } from "./TabTrigger";

type TabsRootProps = {
  children: ReactNode;
} & ArkTabsRootProps;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function TabsRoot({
  children,
  className,
  ...rest
}: TabsRootProps): JSX.Element {
  const classes = styles();
  const [triggers, content] = splitTabsTriggers(children, classes.trigger);
  return (
    <ArkTabs.Root className={cx(classes.root, className)} {...rest}>
      <ArkTabs.List className={classes.list}>
        {triggers}
        <ArkTabs.Indicator className={classes.indicator} />
      </ArkTabs.List>
      {content}
    </ArkTabs.Root>
  );
}

const styles = sva({
  slots: tabsAnatomy.extendWith("contentWrapper").keys(),
  base: {
    root: {
      backgroundColor: "interface.100",
      borderRadius: "md",
      padding: "sm",
    },
    trigger: {
      ...hstack.raw({ gap: "sm" }),
      borderTopRadius: "md",
      padding: "sm",
      textStyle: "body",
      color: "interface.300",
      border: "none",
      backgroundColor: "interface.200",
      outline: "none",
      transition: "all 0.2s",
      _hover: {
        color: { base: "interface.400", _selected: "interface" },
        backgroundColor: { base: "interface.200", _selected: "white" },
      },
      _selected: {
        color: "interface.400",
        backgroundColor: "white",
      },
      _childSvg: {
        size: "sm",
      },
    },
    list: {
      ...hstack.raw({ gap: "xs" }),
      position: "relative",
      overflow: "scroll",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    content: {
      padding: "sm",
      color: "interface.400",
    },
  },
});

export const Tabs = {
  Root: TabsRoot,
  Trigger: TabTrigger,
  Content: TabContent,
};
