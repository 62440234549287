import { JSX } from "react";

import { sva } from "@mobsuccess-devops/styled-system/css";

export type HStackSeparatorProps = {
  children: React.ReactNode;
};
export function HStackSeparator({
  children,
}: HStackSeparatorProps): JSX.Element {
  const classes = style();
  return (
    <div className={classes.layout}>
      <div className={classes.line} />
      {!!children && <div className={classes.text}>{children}</div>}
      <div className={classes.line} />
    </div>
  );
}

const style = sva({
  slots: ["line", "layout", "text"],
  base: {
    layout: {
      position: "relative",
      alignSelf: "stretch",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    line: {
      flex: "1",
      width: "1px",
      background: "primary.200",
    },
    text: {
      flex: "0 0 auto",
      padding: "sm",
    },
  },
});
