import { createContext, useContext } from "react";

import type { AddOptions, Positions } from "../types/types";

export type ToastOptions = {
  description?: string;
  dimiss?: boolean;
};
export type NotificationContextType = {
  add: (props: AddOptions) => void;
  toastError: (content: string, options?: ToastOptions) => void;
  toastSuccess: (content: string, options?: ToastOptions) => void;
  toastInfo: (content: string, options?: ToastOptions) => void;
  toastWarning: (content: string, options?: ToastOptions) => void;
  snackbar: (content: string) => void;
  remove: (position: Positions, uuid: string) => void;
};
export const NotificationContext =
  createContext<NotificationContextType | null>(null);

/**
 *
 * @deprecated use useToasters instead
 * @see https://github.com/mobsuccess-devops/mobsuccess-front/blob/master/packages/react-ui/src/components/_PandaArk/Toaster/Toaster.tsx
 */
export function useNotification(): NotificationContextType {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider",
    );
  }
  return context;
}
