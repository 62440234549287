import { ReactComponent as ArrowIcon } from "@mobsuccess-devops/streamline/regular/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg";
import { css } from "@mobsuccess-devops/styled-system/css";
import {
  Column,
  ColumnDef,
  ColumnHelper,
  TableFeature,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  getFilteredRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getGroupedRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";
import { TableOptions } from "@tanstack/table-core";

import { IconButton } from "../../Button";
import { Checkbox } from "../Checkbox/Checkbox";

const rowValuesFeature: TableFeature<unknown> = {
  createColumn(column, table) {
    column.getColumnUniqueValues = () => {
      return table.getCoreRowModel().rows.reduce((acc, row) => {
        const value = row.getValue(column.id);
        acc.set(value, (acc.get(value) ?? 0) + 1);
        return acc;
      }, new Map());
    };
    column.getColumnFlatUniqueValues = () => {
      return table.getCoreRowModel().flatRows.reduce((acc, row) => {
        const value = row.getValue(column.id);
        acc.set(value, (acc.get(value) ?? 0) + 1);
        return acc;
      }, new Map());
    };
  },
};

// console.log(rowValuesFeature);

type CreateTableOptions<T extends object> = Partial<
  Omit<TableOptions<T>, "columns" | "filterFns">
> & {
  columns:
    | TableOptions<T>["columns"]
    | ((
        helper: ColumnHelper<T> & {
          expander: () => ColumnDef<T>;
          selector: () => ColumnDef<T>;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) => Array<ColumnDef<T, any>>);
};

export type TableConfig<T extends object> = Omit<TableOptions<T>, "data"> & {
  augment(table: Partial<Omit<TableOptions<T>, "data">>): TableConfig<T>;
};

export function createTable<T extends object>({
  columns: columnsFactory,
  ...rest
}: CreateTableOptions<T>): TableConfig<T> {
  const helper = createColumnHelper<T>();
  const columns =
    typeof columnsFactory !== "function"
      ? columnsFactory
      : columnsFactory({
          ...helper,
          expander: () => ({
            id: "expander",
            size: 60,
            enableHiding: false,
            cell: ({ row }) => {
              if (!row.getCanExpand()) {
                return null;
              }
              return (
                <IconButton
                  cursor="pointer"
                  onClick={row.getToggleExpandedHandler()}
                  className={css({
                    color: "interface",
                    transition: "rotate 0.2s",
                    rotate: row.getIsExpanded() ? undefined : "-90deg",
                  })}
                >
                  <ArrowIcon />
                </IconButton>
              );
            },
          }),
          selector: () => ({
            id: "selector",
            size: 60,
            header: ({ table }) => {
              return (
                <Checkbox
                  aria-label="all"
                  checked={
                    table.getIsSomeRowsSelected()
                      ? "indeterminate"
                      : table.getIsAllRowsSelected()
                  }
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              );
            },
            cell: ({ row }) => {
              return (
                <Checkbox
                  aria-label={row.id}
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onChange={row.getToggleSelectedHandler()}
                />
              );
            },
          }),
        });

  const { columns: spannedColumns } = columns.reduce(
    (acc, column) => {
      if (acc.span) {
        return {
          span: acc.span - 1,
          columns: [
            ...acc.columns,
            {
              ...column,
              meta: {
                ...column.meta,
                subrow: {
                  ignore: true,
                },
              },
            },
          ],
        };
      }
      if (column.meta?.subrow?.span) {
        return {
          span: column.meta.subrow.span - 1,
          columns: [...acc.columns, column],
        };
      }
      return {
        span: 0,
        columns: [...acc.columns, column],
      };
    },
    {
      span: 0,
      columns: new Array<ColumnDef<T>>(),
    },
  );

  const config: Omit<TableOptions<T>, "data"> = {
    _features: [rowValuesFeature],
    columns: spannedColumns,
    enableColumnFilters: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    filterFns: {
      oneOf(row, columnId: string, value: string[]) {
        return value.includes(row.getValue(columnId));
      },
    },
    ...rest,
  };
  return {
    ...config,
    augment: (
      table:
        | Partial<Omit<TableOptions<T>, "data">>
        | ((
            table: Omit<TableOptions<T>, "data">,
          ) => Omit<TableOptions<T>, "data">),
    ) =>
      typeof table === "function"
        ? createTable(table(config))
        : createTable({
            ...config,
            ...table,
          }),
  };
}

export function getColumnLabel<T>(column: Column<T>): string {
  return typeof column.columnDef.header === "function"
    ? column.columnDef.meta?.label ?? column.id
    : column.columnDef.header ?? column.id;
}
