import styled from "styled-components";

import { BaseButton, ButtonProps } from "./BaseButton";

export const LinearButton = styled(BaseButton)<ButtonProps>`
  border: 1px solid ${({ $color, theme }) => theme.palette[$color].darker[100]};
  color: ${({ $color, theme }) => theme.palette[$color].darker[100]};
  background: ${({ theme }) => theme.palette.white};
  &:focus {
    box-shadow: ${({ theme, $color }) =>
      `0px 0px 0px 4px ${theme.palette[$color].base + "80"}`};
  }
  &:hover:enabled {
    color: ${({ theme, $color }) => theme.palette[$color].lighter[100]};
  }
`;

LinearButton.displayName = "LinearButton";
