import { memo, useCallback, useMemo, JSX } from "react";

import styled, { css, useTheme } from "styled-components";

import {
  getAllDaysInMonthSingleDayUI,
  useDatePickerSingleDayContext,
  useWeekdaysLabels,
} from "../../../../features/date-picker";
import { Container } from "../../../Container";
import { Typography } from "../../../Typography";

import { CardDay } from "./CardDay/CardDay";

const CalendarMonthsWrapperUI = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 40px);
  margin-top: 10px;
`;
CalendarMonthsWrapperUI.displayName = "CalendarMonthsWrapperUI";

const CalendarDaysWrapperUI = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 40px);
`;
CalendarDaysWrapperUI.displayName = "CalendarDaysWrapperUI";

const customTextDayCss = css`
  display: flex;
  justify-content: center;
`;

const customTextMonthCss = css`
  margin-left: 6px;
`;

export type MonthProps = {
  current?: Date;
  month: {
    year: number;
    month: number;
  };
};

function getLocaleDate(
  {
    year,
    month,
  }: {
    year: number;
    month: number;
  },
  locale?: string,
): string {
  const date = new Date(year, month, 1);
  const m = date.toLocaleString(locale, { month: "long" });
  const y = date.toLocaleString(locale, { year: "numeric" });
  return `${m.slice(0, 3)}${m.length > 3 ? "." : ""} ${y}`;
}

function Month({ month }: MonthProps): JSX.Element {
  const theme = useTheme();

  const { locale, setSelectedDate, allowedDate } =
    useDatePickerSingleDayContext();
  const weekdaysLabel = useWeekdaysLabels(locale);

  const handleSelectDate = useCallback(
    (date: Date) => {
      setSelectedDate(date);
    },
    [setSelectedDate],
  );

  const days = useMemo(() => {
    return getAllDaysInMonthSingleDayUI(month, allowedDate);
  }, [allowedDate, month]);

  return (
    <Container
      flexDirection="column"
      flex="1"
      padding="20px"
      alignItems="center"
      justifyContent="center"
    >
      <Container flexDirection="column" gap="12px">
        <CalendarMonthsWrapperUI>
          <Typography
            as="span"
            variant="text-sm-bold"
            color={theme.palette.interface.lighter[200]}
            styled={customTextDayCss}
          >
            {weekdaysLabel.monday}
          </Typography>
          <Typography
            as="span"
            variant="text-sm-bold"
            color={theme.palette.interface.lighter[200]}
            styled={customTextDayCss}
          >
            {weekdaysLabel.tuesday}
          </Typography>
          <Typography
            as="span"
            variant="text-sm-bold"
            color={theme.palette.interface.lighter[200]}
            styled={customTextDayCss}
          >
            {weekdaysLabel.wednesday}
          </Typography>
          <Typography
            as="span"
            variant="text-sm-bold"
            color={theme.palette.interface.lighter[200]}
            styled={customTextDayCss}
          >
            {weekdaysLabel.thursday}
          </Typography>
          <Typography
            as="span"
            variant="text-sm-bold"
            color={theme.palette.interface.lighter[200]}
            styled={customTextDayCss}
          >
            {weekdaysLabel.friday}
          </Typography>
          <Typography
            as="span"
            variant="text-sm-bold"
            color={theme.palette.interface.lighter[200]}
            styled={customTextDayCss}
          >
            {weekdaysLabel.saturday}
          </Typography>
          <Typography
            as="span"
            variant="text-sm-bold"
            color={theme.palette.interface.lighter[200]}
            styled={customTextDayCss}
          >
            {weekdaysLabel.sunday}
          </Typography>
        </CalendarMonthsWrapperUI>
        <Typography
          as="span"
          variant="text-sm-bold"
          color={theme.palette.interface.lighter[200]}
          transform="capitalize"
          styled={customTextMonthCss}
        >
          {getLocaleDate(month, locale)}
        </Typography>
      </Container>
      <CalendarDaysWrapperUI>
        {days.map((d) => (
          <CardDay key={d.date.getTime()} day={d} onClick={handleSelectDate} />
        ))}
      </CalendarDaysWrapperUI>
    </Container>
  );
}

const MemoizedComponent = memo(Month);
export { MemoizedComponent as Month };
