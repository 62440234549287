import { memo, JSX } from "react";

import styled, { keyframes } from "styled-components";

import { Container } from "../Container";

const bounce = keyframes`
  0% {
    transform: translateY(-4px) scaleX(1) scaleY(1);
  }
  75% {
    transform: translateY(-2px) scaleX(0.9) scaleY(1.1);
  }
  95%, 100% {
    transform: translateY(0) scaleX(1.1) scaleY(0.7);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const bounceShadow = keyframes`
  0% {
    opacity: 0.3;
    transform: scaleX(0.5) scaleY(0.5);
  }
  90% {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
`;

const InlineUI = styled(Container)`
  width: 16px;
  opacity: 0;
  animation: ${fadeIn} 0.4s 0.3s cubic-bezier(0.7, 0, 1, 1);
  animation-fill-mode: forwards;
`;

InlineUI.displayName = "InlineUI";

const ContainerUI = styled(Container)`
  &:nth-child(2) {
    span {
      animation: ${bounceShadow} 0.6s cubic-bezier(0.7, 0, 1, 1) 0.2s infinite
        alternate;
    }
    div {
      animation: ${bounce} 0.6s 0.2s cubic-bezier(0.7, 0, 1, 1) infinite
        alternate;
    }
  }

  &:last-child {
    span {
      animation: ${bounceShadow} 0.6s cubic-bezier(0.7, 0, 1, 1) 0.4s infinite
        alternate;
    }
    div {
      animation: ${bounce} 0.6s 0.4s cubic-bezier(0.7, 0, 1, 1) infinite
        alternate;
    }
  }
`;

ContainerUI.displayName = "ContainerUI";

const ShadowUI = styled.span`
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 2px;
  border-radius: 50%;
  background-color: rgba(79, 91, 146, 0.4);
  -webkit-filter: blur(1px);
  filter: blur(1px);
  animation: ${bounceShadow} 0.6s cubic-bezier(0.7, 0, 1, 1) infinite alternate;
`;

ShadowUI.displayName = "ShadowUI";

const BallUI = styled.div<{ $overlay?: string }>`
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme, $overlay }) =>
    $overlay === "dark" || $overlay === "base"
      ? theme.palette.white
      : theme.palette.primary.lighter[100]};
  animation: ${bounce} 0.6s cubic-bezier(0.7, 0, 1, 1) infinite alternate;
`;

BallUI.displayName = "BallUI";

export type InlineProps = {
  overlay?: "white" | "light" | "base" | "dark";
};

function Inline({ overlay }: InlineProps): JSX.Element {
  return (
    <Container
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <InlineUI
        aria-label="dots"
        alignItems="center"
        justifyContent="space-between"
      >
        <ContainerUI alignItems="center" justifyContent="center">
          <BallUI $overlay={overlay} />
          <ShadowUI />
        </ContainerUI>
        <ContainerUI alignItems="center" justifyContent="center">
          <BallUI $overlay={overlay} />
          <ShadowUI />
        </ContainerUI>
        <ContainerUI alignItems="center" justifyContent="center">
          <BallUI $overlay={overlay} />
          <ShadowUI />
        </ContainerUI>
      </InlineUI>
    </Container>
  );
}

const MemoizedComponent = memo(Inline);
export { MemoizedComponent as Inline };
