import { memo, useState, JSX } from "react";

import { AbstractInput } from "../../AbstractInput";
import { DatePickerSingleDay } from "../DatePickerSingleDay/DatePickerSingleDay";
import { TimePicker } from "../TimePicker/TimePicker";

type Range = {
  start: string;
  end: string;
};

export type DateAndTimePickerProps = {
  variant?: "md" | "lg";
  dateValue?: Date;
  onChangeDate: (value?: Date) => void;
  timeValue?: string;
  onChangeTime: (value?: string) => void;
  rangeTime: Array<Range>;
  stepTime?: number;
  disabledTimes?: Array<Range>;
  locale: string;
};

const defaultDisabledTimes: NonNullable<
  DateAndTimePickerProps["disabledTimes"]
> = [];
const defaultDate = new Date();

function DateAndTimePicker({
  onChangeDate,
  onChangeTime,
  rangeTime,
  variant = "md",
  dateValue = defaultDate,
  timeValue = "12:00",
  stepTime = 30,
  disabledTimes = defaultDisabledTimes,
  locale,
}: DateAndTimePickerProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  return (
    <AbstractInput
      variant="md"
      containerRef={setAnchorEl}
      containerWidth="fit-content"
    >
      <DatePickerSingleDay
        value={dateValue}
        onChange={onChangeDate}
        variant={variant}
        anchorElement={anchorEl ?? undefined}
        dropdownWidth="fit-anchor"
        borderStyle="textBackground"
        locale={locale}
      />
      <TimePicker
        value={timeValue}
        onChange={onChangeTime}
        range={rangeTime}
        step={stepTime}
        variant={variant}
        disabledTimes={disabledTimes}
        anchorElement={anchorEl ?? undefined}
        borderStyle="textBackground"
        locale={locale}
      />
    </AbstractInput>
  );
}

const MemoizedDateAndTimePicker = memo(DateAndTimePicker);
export { MemoizedDateAndTimePicker as DateAndTimePicker };
