export type BranchConfig = {
  pr?: number;
  queryBranch?: string;
  dataEnvironment?: string;
};

function getConfigFromSearchParams(): BranchConfig {
  const searchParams = new URLSearchParams(window.location.search);
  const queryBranch = searchParams.get("queryBranch") ?? undefined;
  const dataEnvironment = searchParams.get("dataEnvironment") ?? undefined;
  return { queryBranch, dataEnvironment };
}

export function getConfig(): BranchConfig {
  const {
    queryBranch: queryBranchFromSearchParams,
    dataEnvironment: dataEnvironmentFromSearchParams,
  } = getConfigFromSearchParams();
  return {
    queryBranch:
      queryBranchFromSearchParams ??
      import.meta.env.VITE_DATA_CONTEXT_DEFAULT_BRANCH,
    dataEnvironment: dataEnvironmentFromSearchParams,
  };
}
