import {
  CSSProperties,
  ForwardedRef,
  JSX,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";

import { cx, sva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";
import { Row, flexRender } from "@tanstack/react-table";
import { VirtualItem } from "@tanstack/react-virtual";

type TableRowProps<T extends object> = {
  row: Row<T>;
  offset?: number;
  isSubrow?: boolean;
  elevatePin?: boolean;
  virtualRow?: VirtualItem;
  onClick?: (row: Row<T>) => void;
};

function TableRow<T extends object>(
  { row, elevatePin, virtualRow, onClick }: TableRowProps<T>,
  ref: ForwardedRef<HTMLTableRowElement | null>,
): JSX.Element {
  const isSubrow = row.depth > 0;

  const [rowElement, setRowElement] = useState<HTMLTableRowElement | null>(
    null,
  );

  useImperativeHandle<HTMLTableRowElement | null, HTMLTableRowElement | null>(
    ref,
    () => rowElement,
    [rowElement],
  );

  const classes = styles({
    elevatePin,
    isSubrow: isSubrow,
    virtualized: !!virtualRow,
  });
  const style: CSSProperties = {
    transform: virtualRow ? `translateY(${virtualRow.start}px)` : undefined,
    // @ts-expect-error - TS doesn't know about CSS variables
    "--depth": row.depth,
  };

  const handleClick = useCallback(() => {
    onClick?.(row);
  }, [row, onClick]);

  return (
    <>
      <styled.tr
        ref={setRowElement}
        data-table-role="row"
        data-subrow={isSubrow}
        className={classes.row}
        data-index={virtualRow?.index}
        data-selected={row.getIsSelected()}
        onClick={handleClick}
        style={style}
      >
        {row.getVisibleCells().map((cell, index, array) => {
          const subrow = cell.column.columnDef.meta?.subrow ?? {};
          if (isSubrow && subrow.ignore) {
            return null;
          }

          const width =
            isSubrow && !!subrow.span
              ? array
                  .slice(index, index + subrow.span)
                  .reduce((acc, cell) => acc + cell.column.getSize(), 0)
              : cell.column.getSize();
          return (
            <styled.td
              key={cell.id}
              className={cx(
                classes.cell,
                cell.column.columnDef.meta?.cellClassName,
              )}
              data-table-role="cell"
              data-expanded={cell.row.getIsExpanded()}
              data-pinned={cell.column.getIsPinned()}
              data-subrow={isSubrow}
              data-last-column-left={cell.column.getIsLastColumn("left")}
              data-first-column-right={cell.column.getIsFirstColumn("right")}
              // eslint-disable-next-line @mobsuccess-devops/mobsuccess/react
              style={{
                width,
                left:
                  cell.column.getIsPinned() === "left"
                    ? `${cell.column.getStart("left")}px`
                    : undefined,
                right:
                  cell.column.getIsPinned() === "right"
                    ? `${cell.column.getStart("right")}px`
                    : undefined,
              }}
            >
              {flexRender(
                isSubrow
                  ? subrow.cell ?? cell.column.columnDef.cell
                  : cell.column.columnDef.cell,
                cell.getContext(),
              )}
            </styled.td>
          );
        })}
      </styled.tr>
    </>
  );
}

const styles = sva({
  slots: ["row", "cell"],
  base: {
    row: {
      display: "flex",
      width: "full",
      borderBottom: "1px solid",
      borderColor: "interface.100",
      backgroundColor: "white",
      "&[data-subrow='true']": {
        backgroundColor:
          "color-mix(in srgb, token(colors.interface) calc(var(--depth, 0) * 5%), white)",
      },
      "&[data-selected='true']": {
        backgroundColor: "interface.100",
      },
    },
    cell: {
      display: "flex",
      padding: "md",
      backgroundColor: "inherit",
      "&[data-pinned='left']": {
        position: "sticky",
        elevation: "sticker",
      },
      "&[data-pinned='right']": {
        position: "sticky",
        elevation: "sticker",
      },
    },
  },
  variants: {
    virtualized: {
      true: {
        row: {
          position: "absolute",
        },
      },
    },
    isSubrow: {
      true: {
        row: {
          borderBottom: "none",
          backgroundColor:
            "color-mix(in srgb, token(colors.interface) calc(var(--depth, 0) * 100%), white)",
        },
      },
    },
    elevatePin: {
      true: {
        cell: {
          "&[data-pinned='left']": {
            "&[data-last-column-left='true']": {
              boxShadowColor:
                "linear-gradient(to right, token(colors.interface), transparent)",
              shadow: "-2px 0px 4px -4px inset",
            },
          },
          "&[data-pinned='right']": {
            "&[data-first-column-right='true']": {
              boxShadowColor:
                "linear-gradient(to left, token(colors.interface), transparent)",
              shadow: "2px 0px 4px -4px inset",
            },
          },
        },
      },
    },
  },
});

const MemoizedTableRow = forwardRef(TableRow);
export { MemoizedTableRow as TableRow };
