import { ReactNode, JSX } from "react";

import styled, { useTheme } from "styled-components";

import { NativeProps } from "../../../types/native-props";
import { Typography } from "../../Typography";

const LabelUI = styled.label`
  display: inline-flex;
  gap: 4px;
  align-items: baseline;
  color: ${({ theme }) => theme.palette.interface.lighter["100"]};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.h2};
  letter-spacing: 0.03em;
  line-height: ${({ theme }) => theme.lineHeight.medium};
  text-align: left;
  margin-bottom: 8px;
`;

LabelUI.displayName = "LabelUI";

export type LabelProps = {
  required?: boolean;
  description?: ReactNode | string;
} & NativeProps<"label">;

/**
 * @deprecated Use Pandark's Label instead
 */
export function Label({
  children,
  htmlFor,
  required,
  description,
  ...rest
}: LabelProps): JSX.Element | null {
  const { palette } = useTheme();

  return (
    <LabelUI htmlFor={htmlFor} {...rest}>
      {children}
      {description && (
        <Typography as="span" variant="text-sm" color={palette.interface.base}>
          {description}
        </Typography>
      )}
      {required && (
        <Typography
          as="span"
          variant="text-md-regular"
          color={palette.danger.base}
        >
          *
        </Typography>
      )}
    </LabelUI>
  );
}
