import { cva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";
import type {
  HTMLStyledProps,
  RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/types";

export const buttonStyles = cva({
  base: {
    display: "flex",
    alignItems: "center",
    borderRadius: "md",
    border: "none",
    boxSizing: "border-box",
    cursor: "pointer",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    transitionDuration: "0.2s",
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "color, background, box-shadow",
    "& svg": {
      height: "var(--icon-height, 20px)",
    },
    _disabled: {
      opacity: "0.5",
      cursor: "not-allowed",
    },
    _focus: {
      boxShadow:
        "0 0 0 4px color-mix(in srgb, token(colors.primary) 30%, transparent)",
    },
  },
  variants: {
    fill: {
      true: {
        width: "full",
        justifyContent: "center",
      },
      false: {
        width: "fit-content",
      },
    },
    size: {
      xs: {
        height: "md",
        gap: "6px",
        padding: "6px",
        textStyle: "xs",
      },
      sm: {
        height: "32px",
        gap: "sm",
        padding: "8px",
        textStyle: "sm",
      },
      md: {
        height: "40px",
        gap: "10px",
        padding: "10px",
        textStyle: "md",
      },
      lg: {
        height: "48px",
        gap: "14px",
        padding: "14px",
        textStyle: "lg",
      },
      xl: {
        height: "56px",
        gap: "md",
        padding: "md",
        textStyle: "xl",
        "--icon-height": "lg",
      },
    },
    palette: {
      primary: { colorPalette: "primary" },
      secondary: { colorPalette: "secondary" },
      interface: { colorPalette: "interface" },
      success: { colorPalette: "success" },
      danger: { colorPalette: "danger" },
      warning: { colorPalette: "warning" },
      info: { colorPalette: "info" },
    },
    variant: {
      light: {
        color: "colorPalette.600",
        background: "colorPalette.200",
        boxShadow:
          "0 0 0 0 color-mix(in srgb, token(colors.colorPalette) 30%, transparent)",
        _focus: {
          boxShadow:
            "0 0 0 4px color-mix(in srgb, token(colors.colorPalette) 30%, transparent)",
        },
        "&:hover:enabled": {
          background: "colorPalette.100",
        },
      },
      dark: {
        color: "white",
        background: "colorPalette.500",
        boxShadow:
          "0 0 0 0 color-mix(in srgb, token(colors.colorPalette) 30%, transparent)",
        _focus: {
          boxShadow:
            "0 0 0 4px color-mix(in srgb, token(colors.colorPalette) 30%, transparent)",
        },
        "&:hover:enabled": {
          background: "colorPalette.600",
        },
      },
      linear: {
        border: "1px solid",
        borderColor: "colorPalette.600",
        color: "colorPalette.600",
        background: "white",
        boxShadow:
          "0 0 0 0 color-mix(in srgb, token(colors.colorPalette) 30%, transparent)",
        _focus: {
          boxShadow:
            "0 0 0 4px color-mix(in srgb, token(colors.colorPalette) 30%, transparent)",
        },
        "&:hover:enabled": {
          color: "colorPalette.400",
        },
      },
      transparent: {
        color: "colorPalette.600",
        background: "none",
        transitionProperty: "color, background",
        _focus: {
          boxShadow: "none",
          background: "colorPalette.100",
        },
        "&:hover:enabled": {
          color: "colorPalette.400",
          background: "colorPalette.100",
        },
      },
    },
    underline: {
      true: {
        "&:not(svg)": {
          underline: true,
        },
      },
    },
  },
  defaultVariants: {
    variant: "light",
    fill: false,
    size: "md",
    palette: "primary",
    underline: true,
  },
});

export const Button = styled("button", buttonStyles);

export type ButtonProps = HTMLStyledProps<"button"> &
  RecipeVariantProps<typeof buttonStyles>;
