import { type JSX, useCallback, useMemo } from "react";

import {
  DatePicker as ArkDatePicker,
  type DatePickerPresetTriggerProps,
  type UseDatePickerContext,
  type DatePickerRootProps,
  Portal,
} from "@ark-ui/react";
import { ReactComponent as CalendarIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/21-Date_Calendar/calendar.svg";
import { ReactComponent as CloseIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";
import { css, cx } from "@mobsuccess-devops/styled-system/css";

import { HStack } from "../../Layout";
import { buttonStyles } from "../Button/Button";
import { datePicker } from "../DatePicker/DatePicker";
import { MonthView } from "../DatePicker/MonthView";
import { YearView } from "../DatePicker/YearView";
import {
  dateToCalendarDate,
  dateToLocalizedIsoDate,
  isCalendarDate,
} from "../DatePicker/utils";
import { Input } from "../Input/Input";

import { DaysView } from "./DaysView";

type DateRangePreset = DatePickerPresetTriggerProps["value"];
type RangePreset = {
  id: string;
  label: string;
  range: DateRangePreset;
};

export type DateRangePickerProps = Omit<
  DatePickerRootProps,
  | "value"
  | "onValueChange"
  | "defaultValue"
  | "min"
  | "max"
  | "selectionMode"
  | "timeZone"
> & {
  value?: Date[];
  defaultValue?: Date[];
  onValueChange?: (value: Date[]) => void;
  min?: Date;
  max?: Date;
  timeZone: string;
  locale: string;
  presets?: RangePreset[];
  withReset?: boolean;
  texts?: {
    from?: string;
    to?: string;
    rangePlaceholder?: string;
    reset?: string;
  };
};

export function DateRangePicker({
  className,
  value: baseValue,
  defaultValue: baseDefaultValue,
  onValueChange,
  min,
  max,
  timeZone,
  locale,
  presets,
  texts: textsProps,
  withReset,
  ...rest
}: DateRangePickerProps): JSX.Element {
  const texts = useMemo(
    () => ({ ...defaultTexts, ...textsProps }),
    [textsProps],
  );
  const classes = datePicker();
  const styles = datePicker.raw();

  const value = useMemo(
    () =>
      baseValue
        ?.map((date) => dateToCalendarDate({ date, timeZone })?.toString())
        .filter((date): date is string => !!date),
    [baseValue, timeZone],
  );

  const defaultValue = useMemo(
    () =>
      baseDefaultValue
        ?.map((date) => dateToCalendarDate({ date, timeZone })?.toString())
        .filter((date): date is string => !!date) ?? [],
    [baseDefaultValue, timeZone],
  );

  const handleValueChange = useCallback<
    NonNullable<DatePickerRootProps["onValueChange"]>
  >(
    ({ value }) => onValueChange?.(value.map((date) => date.toDate(timeZone))),
    [timeZone, onValueChange],
  );

  const getInputValue = useCallback(
    ({ value, format }: UseDatePickerContext) => {
      if (value[0] && isCalendarDate(value[0])) {
        const from = format(value[0], { dateStyle: "short", timeZone });
        const to =
          value[1] && isCalendarDate(value[1])
            ? format(value[1], { dateStyle: "short", timeZone })
            : "???";
        return `${texts.from} ${from} ${texts.to} ${to}`;
      }
      return texts.rangePlaceholder;
    },
    [texts, timeZone],
  );

  return (
    <ArkDatePicker.Root
      {...rest}
      className={cx(className, classes.root)}
      numOfMonths={2}
      selectionMode="range"
      value={value}
      onValueChange={handleValueChange}
      defaultValue={defaultValue}
      min={dateToLocalizedIsoDate({ date: min, timeZone })}
      max={dateToLocalizedIsoDate({ date: max, timeZone })}
      timeZone={timeZone}
      locale={locale}
    >
      <ArkDatePicker.Context>
        {/* eslint-disable-next-line @mobsuccess-devops/mobsuccess/react */}
        {(api) => (
          <>
            <ArkDatePicker.Control>
              <ArkDatePicker.Trigger asChild>
                <Input.Root
                  className={classes.input}
                  value={getInputValue(api)}
                  readOnly
                >
                  <Input.LeadingAddon>
                    <ArkDatePicker.Trigger
                      className={css(
                        buttonStyles.raw({
                          palette: "interface",
                          variant: "transparent",
                          size: "xs",
                          underline: false,
                        }),
                        styles.trigger,
                      )}
                    >
                      <CalendarIcon />
                    </ArkDatePicker.Trigger>
                  </Input.LeadingAddon>
                  <Input.TrailingAddon>
                    <HStack height="full">
                      <ArkDatePicker.ClearTrigger
                        className={css(
                          buttonStyles.raw({
                            palette: "interface",
                            variant: "transparent",
                            size: "xs",
                            underline: false,
                          }),
                          styles.clearTrigger,
                        )}
                      >
                        <CloseIcon className={css({ size: "xs" })} />
                      </ArkDatePicker.ClearTrigger>
                    </HStack>
                  </Input.TrailingAddon>
                </Input.Root>
              </ArkDatePicker.Trigger>
            </ArkDatePicker.Control>
            <Portal>
              <ArkDatePicker.Positioner className={classes.positioner}>
                <ArkDatePicker.Content className={classes.content}>
                  <div className={classes.contentInner}>
                    {Boolean(withReset || presets?.length) && (
                      <div className={classes.actions}>
                        {withReset && (
                          <ArkDatePicker.ClearTrigger
                            className={css(presetStyle, styles.clearTrigger)}
                          >
                            Réinitialiser
                          </ArkDatePicker.ClearTrigger>
                        )}
                        {presets?.map((preset) => (
                          <ArkDatePicker.PresetTrigger
                            key={preset.id}
                            value={preset.range}
                            className={css(presetStyle, styles.presetTrigger)}
                          >
                            {preset.label}
                          </ArkDatePicker.PresetTrigger>
                        ))}
                      </div>
                    )}
                    <DaysView api={api} />
                    <MonthView api={api} />
                    <YearView api={api} />
                  </div>
                </ArkDatePicker.Content>
              </ArkDatePicker.Positioner>
            </Portal>
          </>
        )}
      </ArkDatePicker.Context>
    </ArkDatePicker.Root>
  );
}

const defaultTexts = {
  from: "Du",
  to: "au",
  rangePlaceholder: "Filtrer par date",
};

const presetStyle = buttonStyles.raw({
  palette: "interface",
  variant: "linear",
  size: "xs",
});
