import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  MouseEventHandler,
  ReactNode,
  useMemo,
  JSX,
} from "react";

import { Container } from "../../Container";
import { RadioButtonProps } from "../RadioButton/RadioButton";

type FlexDirection = "row" | "column" | "row-reverse" | "column-reverse";

export type RadioButtonsGroupProps<T extends string | number | boolean> = {
  id?: string;
  className?: string;
  name: string;
  checkedValue: T;
  children?: ReactNode[];
  onChange: (value: T) => void;
  flexDirection?: FlexDirection;
};

const defaultChildren: NonNullable<RadioButtonsGroupProps<string>["children"]> =
  [];

function RadioButtonsGroup<T extends string | number | boolean>({
  id = "",
  className = "",
  children = defaultChildren,
  checkedValue,
  onChange,
  flexDirection = "row",
}: RadioButtonsGroupProps<T>): JSX.Element {
  if (Children.count(children) < 2) {
    throw new Error(
      "RadioButtonsGroup: must have at least two RadioButtons as children",
    );
  }

  const childrenWithProps = useMemo<
    ReactNode[] | typeof children | null
  >(() => {
    return (
      Children.map(children, (child) => {
        if (isValidElement<RadioButtonProps<T>>(child)) {
          if (child.props.value === undefined || child.props.value === null) {
            throw new Error(
              "RadioButtonsGroup: children must have a unique value prop",
            );
          }

          const onChangeWrapper: MouseEventHandler<HTMLDivElement> = () => {
            onChange(child.props.value);
          };

          return cloneElement(child, {
            key: child.props.id,
            isChecked: child.props.value === checkedValue,
            onChange: onChangeWrapper,
          });
        }
        return child;
      }) || null
    );
  }, [checkedValue, children, onChange]);

  return (
    <Container
      id={id}
      gap="8px"
      className={className}
      flexDirection={flexDirection}
    >
      {childrenWithProps ?? null}
    </Container>
  );
}

const MemoizedRadioButtonsGroup = memo(RadioButtonsGroup);
export { MemoizedRadioButtonsGroup as RadioButtonsGroup };
