import styled from "styled-components";

import { BaseButton, ButtonProps } from "./BaseButton";

export const LightButton = styled(BaseButton)<ButtonProps>`
  color: ${({ $color, theme }) => theme.palette[$color].darker[100]};
  background: ${({ theme, $color }) => theme.palette[$color].lighter[600]};
  &:focus {
    box-shadow: ${({ theme, $color }) =>
      `0px 0px 0px 4px ${theme.palette[$color].base + "80"}`};
  }
  &:hover:enabled {
    background: ${({ theme, $color }) => theme.palette[$color].lighter[700]};
    border-color: ${({ $color, theme }) => theme.palette[$color].lighter[100]};
  }
`;

LightButton.displayName = "LightButton";
