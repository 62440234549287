import { memo, MouseEventHandler, useCallback, JSX } from "react";

import styled from "styled-components";

import { useDatePickerContext } from "../../../../../features/date-picker";
import { CustomRangeType } from "../../../../../types/date-picker";

const RangeUI = styled.span`
  color: ${({ theme }) => theme.palette.interface.base};
  font-weight: ${({ theme }) => theme.typography.weight.md.regular};
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  padding: 12px 0 12px 16px;
  cursor: pointer;
`;

RangeUI.displayName = "RangeUI";

export type RangeProps = {
  range: CustomRangeType["range"];
  label: CustomRangeType["label"];
};

function Range({ label, range }: RangeProps): JSX.Element {
  const { setSelectedRange, setMonths } = useDatePickerContext();

  const handleClick = useCallback<MouseEventHandler>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedRange?.(range);
      const start = range.start ? new Date(range.start) : new Date();
      const startMonth = start.getMonth();
      const startYear = start.getFullYear();

      start.setMonth(startMonth + 1);
      const endMonth = start.getMonth();
      const endYear = start.getFullYear();
      setMonths([
        {
          month: startMonth,
          year: startYear,
        },
        {
          month: endMonth,
          year: endYear,
        },
      ]);
    },
    [range, setMonths, setSelectedRange],
  );

  return <RangeUI onClick={handleClick}>{label}</RangeUI>;
}

const MemoizedComponent = memo(Range);
export { MemoizedComponent as Range };
