import { memo, JSX } from "react";

import styled, { css, useTheme } from "styled-components";

import { CustomRangeType } from "../../../../types/date-picker";
import { DarkButton } from "../../../Button/DarkButton";
import { Container } from "../../../Container";
import { Typography } from "../../../Typography";

import { Range } from "./Range/Range";

type CustomRangesUIProps = {
  $isHidden: boolean;
};

const CustomRangesUI = styled(Container)<CustomRangesUIProps>`
  min-width: 200px;
  padding: 6.5px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${({ $isHidden }) => $isHidden && "display: none;"}
    min-width: 380px;
  }
`;
CustomRangesUI.displayName = "CustomRangesUI";

const CustomRangesTextUI = styled.span`
  color: ${({ theme }) => theme.palette.interface.base};
  font-weight: ${({ theme }) => theme.typography.weight.md.regular};
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  padding: 12px 0 12px 16px;
`;
CustomRangesTextUI.displayName = "CustomRangesTextUI";

const CustomRangesMobileWrapperUI = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;
CustomRangesMobileWrapperUI.displayName = "CustomRangesMobileWrapperUI";

const CustomRangesMobileSeparatorUI = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.interface.lighter[400]};
  margin-top: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;
CustomRangesMobileSeparatorUI.displayName = "CustomRangesMobileSeparatorUI";

const CustomRangesTextBottomUI = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;
CustomRangesTextBottomUI.displayName = "CustomRangesTextBottomUI";

const customSpacingTextCss = css`
  display: block;
  margin-bottom: 16px;
`;

const customTextCss = css`
  display: block;
  margin-top: 16px;
  padding-left: 16px;
  margin-bottom: 12px;
`;

export type CustomRangesProps = {
  ranges: Array<CustomRangeType>;
  texts: {
    customRange?: string;
    defineDateRange?: string;
    predefinedRanges?: string;
  };
  onChangeFormatRangeMobile: () => void;
};

function CustomRanges({
  ranges,
  texts,
  onChangeFormatRangeMobile,
}: CustomRangesProps): JSX.Element | null {
  const theme = useTheme();

  if (ranges.length === 0) {
    return null;
  }

  return (
    <CustomRangesUI $isHidden={ranges.length === 0} flexDirection="column">
      <div>
        <CustomRangesMobileWrapperUI>
          <Typography
            as="span"
            variant="text-sm"
            transform="uppercase"
            color={theme.palette.interface.lighter[200]}
            styled={customSpacingTextCss}
          >
            {texts.customRange}
          </Typography>
          <DarkButton onClick={onChangeFormatRangeMobile}>
            {texts.defineDateRange}
          </DarkButton>
        </CustomRangesMobileWrapperUI>
        <CustomRangesMobileSeparatorUI />
        <CustomRangesTextBottomUI>
          <Typography
            as="span"
            variant="text-sm"
            transform="uppercase"
            color={theme.palette.interface.lighter[200]}
            styled={customTextCss}
          >
            {texts.predefinedRanges}
          </Typography>
        </CustomRangesTextBottomUI>
      </div>
      {ranges.map(({ range, label }) => (
        <Range key={label} label={label} range={range} />
      ))}
    </CustomRangesUI>
  );
}

const MemoizedComponent = memo(CustomRanges);
export { MemoizedComponent as CustomRanges };
