import { RefObject, useEffect, useRef } from "react";

export function useComponentMountedRef(): RefObject<boolean> {
  const mountedRef = useRef(false);
  useEffect(() => {
    setTimeout(() => {
      mountedRef.current = true;
    }, 0);
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return mountedRef;
}
