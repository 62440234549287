import { memo, MouseEventHandler, useCallback, JSX } from "react";

import { ReactComponent as ArrowLeftIcon } from "@mobsuccess-devops/streamline/regular/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg";
import { ReactComponent as ArrowRightIcon } from "@mobsuccess-devops/streamline/regular/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg";

import styled from "styled-components";

import {
  getPreviousAndNextMonth,
  useDatePickerSingleDayContext,
} from "../../../../features/date-picker";
import { Container } from "../../../Container";

import { MonthPicker } from "./MonthPicker/MonthPicker";

const HeaderUI = styled(Container)`
  height: 72px;
  padding: 12px;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.interface.lighter[400]};
`;

HeaderUI.displayName = "HeaderUI";

const ArrowLeftUI = styled(ArrowLeftIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.interface.lighter[200]};
  width: 12px;
`;

ArrowLeftUI.displayName = "ArrowLeftUI";

const ArrowRightUI = styled(ArrowRightIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.interface.lighter[200]};
  width: 12px;
`;

ArrowRightUI.displayName = "ArrowRightUI";

const ArrowContainerUI = styled(Container)`
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.interface.lighter[600]};
  }
`;

ArrowContainerUI.displayName = "ArrowContainerUI";

function Header(): JSX.Element {
  const { setMonths, allowedDate } = useDatePickerSingleDayContext();
  const handleChangeRange = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      const { direction } = e.currentTarget.dataset;
      if (!direction) {
        return;
      }
      setMonths(([start, end]) => {
        if (
          direction === "next" &&
          (!allowedDate.end || new Date(end.year, end.month) > allowedDate.end)
        ) {
          return [start, end];
        } else if (
          direction === "prev" &&
          (!allowedDate.start ||
            new Date(start.year, start.month) < allowedDate.start)
        ) {
          return [start, end];
        }
        if (direction === "next") {
          const [, next] = getPreviousAndNextMonth(end);
          return [end, next];
        }
        const [prev] = getPreviousAndNextMonth(start);
        return [prev, start];
      });
    },
    [allowedDate.end, allowedDate.start, setMonths],
  );
  return (
    <HeaderUI justifyContent="space-between" alignItems="center" width="100%">
      <ArrowContainerUI
        justifyContent="center"
        alignItems="center"
        height="32px"
        width="32px"
        data-direction="previous"
        onClick={handleChangeRange}
      >
        <ArrowLeftUI />
      </ArrowContainerUI>
      <MonthPicker />
      <ArrowContainerUI
        justifyContent="center"
        alignItems="center"
        height="32px"
        width="32px"
        data-direction="next"
        onClick={handleChangeRange}
      >
        <ArrowRightUI />
      </ArrowContainerUI>
    </HeaderUI>
  );
}

const MemoizedComponent = memo(Header);
export { MemoizedComponent as Header };
