/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import { ReactNode, JSX } from "react";

import { css, cx } from "@mobsuccess-devops/styled-system/css";

type InputPrefixProps = {
  className?: string;
  children?: ReactNode;
};

export function InputPrefix({
  className,
  children,
}: InputPrefixProps): JSX.Element {
  return <div className={cx(prefixCss, className)}>{children}</div>;
}

type InputSuffixProps = {
  className?: string;
  children?: ReactNode;
};

export function InputSuffix({
  className,
  children,
}: InputSuffixProps): JSX.Element {
  return <div className={cx(suffixCss, className)}>{children}</div>;
}

type InputLeadingAddonProps = {
  className?: string;
  children?: ReactNode;
};

export function InputLeadingAddon({
  className,
  children,
}: InputLeadingAddonProps): JSX.Element {
  return <div className={cx(leadingAddonCss, className)}>{children}</div>;
}

type InputTrailingAddonProps = {
  className?: string;
  children?: ReactNode;
};

export function InputTrailingAddon({
  className,
  children,
}: InputTrailingAddonProps): JSX.Element {
  return <div className={cx(trailingAddonCss, className)}>{children}</div>;
}

export const prefixCss = css({
  order: 1,
  pointerEvents: "none",
  color: "interface",
  "&:has(~ div>input:placeholder-shown), &:has(~ input:placeholder-shown)": {
    color: "#BCC1DE",
  },
});

const suffixCss = css({
  order: 3,
  pointerEvents: "none",
  color: "interface",
  "&:has(~ div>input:placeholder-shown), &:has(~ input:placeholder-shown)": {
    color: "#BCC1DE",
  },
});

export const leadingAddonCss = css({
  order: 0,
  height: "100%",
  marginLeft: "-sm",
});

export const trailingAddonCss = css({
  order: 4,
  height: "100%",
  marginRight: "-sm",
});
