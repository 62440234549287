import styled, { css } from "styled-components";

import { BaseButton, ButtonProps } from "./BaseButton";

export type TransparentButtonProps = ButtonProps & {
  underline?: boolean;
};

export const TransparentButton = styled(BaseButton).withConfig({
  shouldForwardProp: (prop) => !["fill", "underline"].includes(prop),
})<TransparentButtonProps>`
  color: ${({ $color, theme }) => theme.palette[$color].darker[100]};
  background: none;

  ${({ underline = true, $color }) =>
    underline &&
    css`
      :not(svg) {
        position: relative;
        &:after {
          position: absolute;
          content: "";
          height: 0px;
          bottom: -3px;
          margin: 0 auto;
          left: 0;
          right: 0;
          width: 0;
          background: ${({ theme }) => theme.palette[$color].lighter[100]};
          transition: all 0.1s linear;
        }
      }
    `}
  &:focus {
    box-shadow: none;
    :not(svg) {
      &:after {
        width: 100%;
        height: 2px;
        background: ${({ $color, theme }) =>
          theme.palette[$color].lighter[300]};
      }
    }
  }
  &:hover:enabled {
    color: ${({ theme, $color }) => theme.palette[$color].lighter[100]};
    :not(svg) {
      &:after {
        height: 2px;
        width: 100%;
      }
    }
  }
`;

TransparentButton.displayName = "TransparentButton";
