import { useCallback, useMemo } from "react";

import { DatePickerContextValue } from "../../types/date-picker";

declare global {
  // this is an override for the global date interface
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Date {
    toLocaleFormat(local?: string): string;
  }
}

// add a new method "toLocaleFormat" to the date prototype to get the date in a specific format using local (e.g. `en-US` -> `MM/dd/yyyy`)
Date.prototype.toLocaleFormat = function toLocaleFormat(
  this: Date,
  local?: string,
) {
  const format = new Intl.DateTimeFormat(local, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return format.format(this);
};

type Month = {
  month: number;
  year: number;
};

type Weekdays =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

const daysKeys: Array<Weekdays> = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export function useWeekdaysLabels(locale?: string): Record<Weekdays, string> {
  return useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const labels: Record<Weekdays, string> = {} as Record<Weekdays, string>;
    const date = new Date();
    date.setDate(date.getDate() - date.getDay());
    for (let i = 0; i < 7; i++) {
      const { DateTimeFormat } = Intl;
      labels[daysKeys[i]] = DateTimeFormat(locale, {
        weekday: "short",
      }).format(date);

      date.setDate(date.getDate() + 1);
    }
    return labels;
  }, [locale]);
}

export function isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function getPreviousAndNextMonth({
  month,
  year,
}: Month): [Month, Month] {
  const previousYear = month === 0 ? year - 1 : year;
  const previousMonth = month === 0 ? 11 : month - 1;
  const nextYear = month === 11 ? year + 1 : year;
  const nextMonth = month === 11 ? 0 : month + 1;
  return [
    { month: previousMonth, year: previousYear },
    { month: nextMonth, year: nextYear },
  ];
}

function getAllDaysInMonth({ month, year }: Month): Date[] {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function getAllDaysInMonthUI(
  { month, year }: Month,
  allowedDate: DatePickerContextValue["allowedDate"],
): Array<{
  isDisabled: boolean;
  date: Date;
}> {
  const currentList = getAllDaysInMonth({ month, year }).map((d) => ({
    isDisabled:
      (!!allowedDate?.start && allowedDate?.start > d) ||
      (!!allowedDate?.end && allowedDate.end < d),
    date: d,
  }));
  const [previous, next] = getPreviousAndNextMonth({ month, year });
  const firstDay = currentList[0].date.getDay() % 7;
  const previousList = getAllDaysInMonth(previous).slice(
    firstDay > 0 ? -firstDay : Infinity,
  );
  const list = [
    ...previousList.map((d) => ({ isDisabled: true, date: d })),
    ...currentList,
  ];
  const missings = 42 - list.length;
  const nextList = getAllDaysInMonth(next).slice(0, missings);
  list.push(...nextList.map((d) => ({ isDisabled: true, date: d })));
  return list;
}

export function getAllDaysInMonthSingleDayUI(
  { month, year }: Month,
  allowedDate: DatePickerContextValue["allowedDate"],
): Array<{
  isDisabled: boolean;
  date: Date;
}> {
  const currentList = getAllDaysInMonth({ month, year }).map((d) => ({
    isDisabled:
      (!!allowedDate?.start && allowedDate?.start > d) ||
      (!!allowedDate?.end && allowedDate.end < d),
    date: d,
  }));
  const [previous, next] = getPreviousAndNextMonth({ month, year });
  const firstDay = (currentList[0].date.getDay() - 1) % 7;
  const previousList = getAllDaysInMonth(previous).slice(
    firstDay > 0 ? -firstDay : Infinity,
  );
  const list = [
    ...previousList.map((d) => ({ isDisabled: true, date: d })),
    ...currentList,
  ];
  const missings = 42 - list.length;
  const nextList = getAllDaysInMonth(next).slice(0, missings);
  list.push(...nextList.map((d) => ({ isDisabled: true, date: d })));
  return list;
}

export function getNewRange(
  date: Date,
  range: [Month, Month],
): [
  {
    month: number;
    year: number;
  },
  Month,
] {
  const [start, end] = range;
  const dateMonth = {
    month: date.getMonth(),
    year: date.getFullYear(),
  };
  // if date is before start first month in range then we want to return a new range with [prev month to new date month , new date month]
  const [previous, next] = getPreviousAndNextMonth(dateMonth);
  if (date < new Date(start.year, start.month)) {
    return [dateMonth, next];
  }
  // if date is after end month in range then we want to return a new range with [new date month, next month to new date month]
  if (date > new Date(end.year, end.month + 1, 0)) {
    return [previous, dateMonth];
  }
  // if date is in range then we want to return the same range
  return range;
}

function getCaptureGroup(part: string) {
  switch (part) {
    case "day":
      return "(?<day>\\d{1,2})";
    case "month":
      return "(?<month>\\d{1,2})";
    case "year":
      return "(?<year>\\d{4})";
    default:
      return "";
  }
}

function getLocaleFormat(locale: string): {
  regex: RegExp;
  format: string;
} {
  try {
    const date = new Date(2000, 0, 2);

    const fmt = new Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
      .format(date)
      .replace(/2000/, "year")
      .replace(/01/, "month")
      .replace(/02/, "day");

    const groups = fmt.match(
      /^(?<first>[A-Za-z]+)(?<separator>[-\\.\\/])(?<second>[A-Za-z]+).(?<third>[A-Za-z]+)$/,
    )?.groups;
    if (!groups) {
      throw new Error("Invalid format");
    }

    const { first, second, third, separator } = groups;

    const regex = `^${getCaptureGroup(
      first,
    )}?(?:${separator})?${getCaptureGroup(
      second,
    )}?(?:${separator})?${getCaptureGroup(third)}?`;

    return {
      regex: new RegExp(regex),
      format: fmt
        .replace(/year/, "yyyy")
        .replace(/month/, "mm")
        .replace(/day/, "dd"),
    };
  } catch (e) {
    const regex = `^${getCaptureGroup("day")}?(?:\\/)?${getCaptureGroup(
      "month",
    )}?(?:\\/)?${getCaptureGroup("year")}?`;

    return {
      regex: new RegExp(regex),
      format: "dd/mm/yyyy",
    };
  }
}

export function useParsedDate(
  locale: string,
  date: Date | undefined,
): (value: string) =>
  | {
      date: Date;
      format: string;
    }
  | undefined {
  return useCallback(
    (value: string) => {
      if (!date) {
        return undefined;
      }
      const current = new Date(date);
      const { regex, format } = getLocaleFormat(locale);

      const match = regex.exec(value);

      if (!match?.groups) {
        return {
          date: current,
          format: format,
        };
      }

      const { day, month, year } = match.groups;

      if (day && day.replace(/^0+/, "")) {
        current.setDate(parseInt(day, 10));
      }
      if (month && month.replace(/^0+/, "")) {
        current.setMonth(parseInt(month, 10) - 1);
      }
      if (year) {
        current.setFullYear(parseInt(year, 10));
      }

      return {
        date: current,
        format,
      };
    },
    [date, locale],
  );
}

export * from "./context";
