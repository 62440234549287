import { type JSX, useCallback, useMemo } from "react";

import { datePickerAnatomy } from "@ark-ui/anatomy";
import {
  DatePicker as ArkDatePicker,
  type DatePickerRootProps,
  Portal,
} from "@ark-ui/react";
import { ReactComponent as CalendarIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/21-Date_Calendar/calendar.svg";
import { ReactComponent as CloseIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";
import { cx, css, sva } from "@mobsuccess-devops/styled-system/css";

import { HStack } from "../../Layout";
import { buttonStyles } from "../Button/Button";
import { Input } from "../Input/Input";

import { DayView } from "./DayView";
import { MonthView } from "./MonthView";
import { YearView } from "./YearView";
import { dateToCalendarDate, dateToLocalizedIsoDate } from "./utils";

export type DatePickerProps = Omit<
  DatePickerRootProps,
  | "value"
  | "onValueChange"
  | "defaultValue"
  | "min"
  | "max"
  | "selectionMode"
  | "timeZone"
> & {
  value?: Date[];
  defaultValue?: Date[];
  onValueChange?: (value: Date[]) => void;
  min?: Date;
  max?: Date;
  timeZone: string;
  locale: string;
  placeholder?: string;
};

export function DatePicker({
  className,
  value: baseValue,
  defaultValue: baseDefaultValue,
  onValueChange,
  min,
  max,
  timeZone,
  locale,
  ...rest
}: DatePickerProps): JSX.Element {
  const classes = datePicker();
  const styles = datePicker.raw();

  const value = useMemo(
    () =>
      baseValue
        ?.map((date) => dateToCalendarDate({ date, timeZone })?.toString())
        .filter((date): date is string => !!date),
    [baseValue, timeZone],
  );

  const defaultValue = useMemo(
    () =>
      baseDefaultValue
        ?.map((date) => dateToCalendarDate({ date, timeZone })?.toString())
        .filter((date): date is string => !!date) ?? [],
    [baseDefaultValue, timeZone],
  );

  const handleValueChange = useCallback<
    NonNullable<DatePickerRootProps["onValueChange"]>
  >(
    ({ value }) => onValueChange?.(value.map((date) => date?.toDate(timeZone))),
    [timeZone, onValueChange],
  );

  return (
    <ArkDatePicker.Root
      {...rest}
      className={cx(className, classes.root)}
      value={value}
      defaultValue={defaultValue}
      onValueChange={handleValueChange}
      min={dateToLocalizedIsoDate({ date: min, timeZone })}
      max={dateToLocalizedIsoDate({ date: max, timeZone })}
      locale={locale}
      timeZone={timeZone}
      selectionMode="single"
    >
      <ArkDatePicker.Context>
        {/* eslint-disable-next-line @mobsuccess-devops/mobsuccess/react */}
        {(api) => (
          <>
            <ArkDatePicker.Control>
              <ArkDatePicker.Input asChild>
                <Input.Root className={classes.input}>
                  <Input.LeadingAddon>
                    <ArkDatePicker.Trigger
                      className={css(
                        buttonStyles.raw({
                          palette: "interface",
                          variant: "transparent",
                          size: "xs",
                          underline: false,
                        }),
                        styles.trigger,
                      )}
                    >
                      <CalendarIcon />
                    </ArkDatePicker.Trigger>
                  </Input.LeadingAddon>
                  <Input.TrailingAddon>
                    <HStack height="100%">
                      <ArkDatePicker.ClearTrigger
                        className={css(
                          buttonStyles.raw({
                            palette: "interface",
                            variant: "transparent",
                            size: "xs",
                            underline: false,
                          }),
                          styles.clearTrigger,
                        )}
                      >
                        <CloseIcon
                          className={css({ size: "xs", color: "interface" })}
                        />
                      </ArkDatePicker.ClearTrigger>
                    </HStack>
                  </Input.TrailingAddon>
                </Input.Root>
              </ArkDatePicker.Input>
            </ArkDatePicker.Control>
            <Portal>
              <ArkDatePicker.Positioner className={classes.positioner}>
                <ArkDatePicker.Content className={classes.content}>
                  <DayView />
                  <MonthView api={api} />
                  <YearView api={api} />
                </ArkDatePicker.Content>
              </ArkDatePicker.Positioner>
            </Portal>
          </>
        )}
      </ArkDatePicker.Context>
    </ArkDatePicker.Root>
  );
}

export const datePicker = sva({
  slots: datePickerAnatomy
    .extendWith("ranges", "tableWrapper", "contentInner", "actions")
    .keys(),
  base: {
    content: {
      backgroundColor: "white",
      borderRadius: "md",
      boxShadow: "md",
      animation: "appear 0.2s ease-in-out",
      zIndex: 9999,
    },
    contentInner: {
      display: "flex",
    },
    actions: {
      display: "flex",
      flexDirection: "column",
      borderRight: "1px solid #E3E6F5",
      paddingY: "12px",
      paddingLeft: "md",
      minWidth: "128px",
      gap: "12px",
      color: "interface.500",
      fontSize: "sm",
      lineHeight: "xl",
      fontWeight: "500",
    },
    input: {
      minWidth: "226px",
      width: "100%",
    },
    trigger: {
      display: "inline-flex",
      height: "100%",
      padding: "8px",
      width: "fit-content",
    },
    viewControl: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "full",
      height: "72px",
      padding: "12px",
      borderBottom: "1px solid #E3E6F5",
    },
    prevTrigger: {
      backgroundColor: "transparent",
      border: "none",
      justifyContent: "center",
      alignItems: "center",
      height: "32px",
      width: "32px",
      cursor: "pointer",
      borderRadius: "4px",
      _childSvg: {
        color: "#959EC9",
        width: "12px",
      },
      _hover: {
        backgroundColor: "interface.200",
      },
    },
    nextTrigger: {
      backgroundColor: "transparent",
      border: "none",
      justifyContent: "center",
      alignItems: "center",
      height: "32px",
      width: "32px",
      cursor: "pointer",
      borderRadius: "4px",
      _childSvg: {
        color: "#959EC9",
        width: "12px",
      },
      _hover: {
        backgroundColor: "interface.200",
      },
    },
    clearTrigger: {
      border: "none",
      textStyle: "body",
      color: "interface",
    },
    presetTrigger: {
      border: "none",
      textStyle: "body",
      color: "interface",
    },
    ranges: {
      display: "flex",
    },
    tableWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      width: "100%",
    },
    table: {
      borderSpacing: 0,
      padding: "md",
    },
    tableHeader: {
      fontSize: "12px",
      fontWeight: "bold",
      color: "#959EC9",
    },
    tableCellTrigger: {
      display: "inline-flex",
      backgroundColor: "transparent",
      border: "none",
      fontSize: "12px",
      color: "interface.500",
      justifyContent: "center",
      alignItems: "center",
      height: "40px",
      width: "40px",
      borderRadius: "4px",
      _hover: {
        "&:not([data-disabled])": {
          cursor: "pointer",
        },
        "&:not(:is([data-today], [data-selected], [data-in-range]))": {
          backgroundColor: "interface.100",
        },
      },
      "&[data-today]": {
        backgroundColor: "interface.200",
        "&:not([data-disabled])": {
          color: "#AA2508",
          backgroundColor: "#FAE0DC",
        },
      },
      "&[data-in-range]": {
        backgroundColor: "secondary.200",
        color: "#AA2508",
      },
      _selected: {
        backgroundColor: "secondary",
        color: "white!",
      },
      _disabled: {
        color: "#E3E6F5",
        cursor: "not-allowed",
        _hover: {
          color: "#959EC9",
        },
      },
    },
  },
});
