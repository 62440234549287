/* eslint-disable @mobsuccess-devops/mobsuccess/react */
import { JSX, useState } from "react";

import {
  FileUpload as ArkFileUpload,
  type FileUploadRootProps as ArkFileUploadRootProps,
} from "@ark-ui/react";
import { ReactComponent as FolderUploadIcon } from "@mobsuccess-devops/streamline/regular/16-Files-Folders/02-Folders/folder-upload.svg";
import { cx, sva } from "@mobsuccess-devops/styled-system/css";

import { Typo } from "../../Typo";
import { Button } from "../Button/Button";

export type FileUploadProps = Omit<ArkFileUploadRootProps, "asChild"> & {
  title: string;
  description?: string;
  triggerText?: string;
  inline?: true;
};

export function FileUpload({
  className,
  title,
  description,
  triggerText,
  inline,
  disabled,
  ...props
}: FileUploadProps): JSX.Element {
  const [dragHovering, setDragHovering] = useState(false);
  const classes = styles({ inline, dragHovering, disabled });

  return (
    <ArkFileUpload.Root
      className={cx(classes.root, className)}
      asChild
      onDragOver={() => setDragHovering(true)}
      onDragLeave={() => setDragHovering(false)}
      disabled={disabled}
      {...props}
    >
      <ArkFileUpload.Dropzone>
        <FolderUploadIcon
          className={classes.icon}
          data-sm={inline && !triggerText}
          data-md={(!inline && !triggerText) || (inline && !!triggerText)}
          data-lg={!inline && !!triggerText}
        />
        <div className={classes.texts}>
          <ArkFileUpload.Label asChild>
            <Typo.Body>{title}</Typo.Body>
          </ArkFileUpload.Label>
          {description && <Typo.Muted>{description}</Typo.Muted>}
          {triggerText && (
            <Button variant="dark" disabled={disabled}>
              {triggerText}
            </Button>
          )}
        </div>
      </ArkFileUpload.Dropzone>
    </ArkFileUpload.Root>
  );
}

const styles = sva({
  slots: ["root", "icon", "texts"],
  base: {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "lg",
      padding: "xl",
      backgroundColor: "interface.100",
      borderRadius: "md",
      position: "relative",
      cursor: "pointer",
      _after: {
        content: "''",
        position: "absolute",
        inset: "sm",
        borderRadius: "sm",
        background: `linear-gradient(to right, token(colors.interface.300) 50%, transparent 0%) top repeat-x, 
          linear-gradient(token(colors.interface.300) 50%, transparent 0%) right repeat-y,
          linear-gradient(to right, token(colors.interface.300) 50%, transparent 0%) bottom repeat-x,
          linear-gradient(token(colors.interface.300) 50%, transparent 0%) left repeat-y`,
        backgroundSize: "24px 2px, 2px 24px",
      },
      "& > *": {
        zIndex: 2,
      },
    },
    icon: {
      color: "interface",
      "&[data-sm='true']": {
        size: "42px",
      },
      "&[data-md='true']": {
        size: "56px",
      },
      "&[data-lg='true']": {
        size: "80px",
      },
    },
    texts: {
      display: "flex",
      flexDirection: "column",
      gap: "md",
      alignItems: "center",
    },
  },
  variants: {
    inline: {
      true: {
        root: {
          flexDirection: "row",
        },
        texts: {
          alignItems: "start",
          gap: "sm",
        },
      },
    },
    dragHovering: {
      true: {
        root: {
          _after: {
            borderColor: "interface.400",
          },
        },
      },
    },
    disabled: {
      true: {
        root: {
          cursor: "not-allowed",
          opacity: "0.5",
        },
      },
    },
  },
});
