import { memo, JSX } from "react";

import { ReactComponent as CrossIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";

import styled from "styled-components";

import { NativeProps } from "../../../types/native-props";
import { Container } from "../../Container";
import { PaletteColorsKeys } from "../../Theme/theme";

type CrossUIProps = {
  $type: "dark" | "light" | "transparent";
  $background: PaletteColorsKeys | string;
  $size: "sm" | "md" | "lg" | "xl";
};

const CrossUI = styled(Container)<CrossUIProps>`
  width: 8px;
  height: 8px;
  color: ${({ $type, $background, theme }) =>
    theme?.palette && $background in theme.palette
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        theme.palette[$background as PaletteColorsKeys].lighter[
          $type === "light" ? 200 : 500
        ]
      : "white"};
  cursor: pointer;
  ${({ $size }) => $size === "sm" && "margin-left: 8px;"}
  ${({ $size }) => $size === "md" && "margin-left: 8px;"}
  ${({ $size }) => $size === "lg" && "margin-left: 10px;"}
  ${({ $size }) => $size === "xl" && "margin-left: 12px;"}
`;

CrossUI.displayName = "CrossUI";

type CrossProps = {
  variant: "dark" | "light" | "transparent";
  background: PaletteColorsKeys | string;
  size: "sm" | "md" | "lg" | "xl";
} & NativeProps<"div">;

function Cross({
  variant,
  background,
  size,
  ...intrinsicProps
}: CrossProps): JSX.Element {
  return (
    <CrossUI
      $background={background}
      $type={variant}
      $size={size}
      {...intrinsicProps}
    >
      <CrossIcon />
    </CrossUI>
  );
}

const MemoizedComponent = memo(Cross);
export { MemoizedComponent as Cross };
