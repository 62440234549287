import type { ReactNode } from "react";

import type { GroupApi } from "@zag-js/toast";
import type { CreateToasterReturn, Options } from "@zag-js/toast";

export type SnackApi = {
  create<T extends ReactNode>(
    content: string,
    options?: Omit<Options<T>, "type">,
  ): ReturnType<GroupApi["create"]>;
} & Omit<GroupApi<CreateToasterReturn>, "success" | "error" | "create">;

export function createSnackApi(snack: CreateToasterReturn): SnackApi {
  return {
    ...snack,
    create(content, options) {
      return snack.create({
        ...options,
        title: content,
      });
    },
  };
}
