import { useCallback, useEffect } from "react";

import { useQuery } from "@tanstack/react-query";

type UseStorageOptions<T> = {
  defaultValue?: T;
};

function useStorage<T>(
  storage: Storage,
  key: string,
  options: UseStorageOptions<T>,
): [T, (value: T) => void] {
  const { data, refetch } = useQuery(["storage", key], () => {
    const value = storage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return options.defaultValue ?? null;
  });

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === key) {
        refetch();
      }
    };
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [key, refetch]);

  const setValue = useCallback(
    (value: T) => {
      storage.setItem(key, JSON.stringify(value));
    },
    [key, storage],
  );

  return [data, setValue];
}

export function useLocalStorage<T>(
  key: string,
  options: UseStorageOptions<T> = {},
): [T, (value: T) => void] {
  return useStorage(localStorage, key, options);
}

export function useSessionStorage<T>(
  key: string,
  options: UseStorageOptions<T> = {},
): [T, (value: T) => void] {
  return useStorage(sessionStorage, key, options);
}
