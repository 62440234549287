import {
  ForwardedRef,
  JSX,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";

import { sva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";
import { Row, Table } from "@tanstack/react-table";
import { Virtualizer } from "@tanstack/react-virtual";

import { useElementIsOverflowing } from "../../../features/react-tools/dom";

import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";

export type TableLayoutProps<T extends object> = {
  table: Table<T>;
  className?: string;
  virtualizer?: Virtualizer<HTMLDivElement, Element>;
  onRowClick?: (row: Row<T>) => void;
};

function TableLayout<T extends object>(
  { table, className, virtualizer, onRowClick }: TableLayoutProps<T>,
  ref: ForwardedRef<HTMLTableElement | null>,
): JSX.Element {
  const [tableElement, setTableElement] = useState<HTMLTableElement | null>(
    null,
  );
  useImperativeHandle<HTMLTableElement | null, HTMLTableElement | null>(
    ref,
    () => tableElement,
  );

  const overflows = useElementIsOverflowing(tableElement);

  const classes = styles({
    hasHorizontalScroll: overflows.horizontal,
  });

  const { rows } = table.getRowModel();
  const virtualRows = virtualizer?.getVirtualItems();
  const bodyStyle = {
    height: virtualizer?.getTotalSize(),
  };

  return (
    <table ref={setTableElement} className={className}>
      <TableHeader
        table={table}
        className={classes.head}
        elevatePin={overflows.horizontal}
      />
      <styled.tbody className={classes.body} style={bodyStyle}>
        {virtualRows
          ? virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <TableRow
                  row={row}
                  key={row.id}
                  virtualRow={virtualRow}
                  elevatePin={overflows.horizontal}
                  ref={virtualizer?.measureElement}
                  onClick={onRowClick}
                />
              );
            })
          : rows.map((row) => {
              return <TableRow key={row.id} row={row} onClick={onRowClick} />;
            })}
      </styled.tbody>
      <TableFooter
        table={table}
        className={classes.footer}
        elevatePin={overflows.horizontal}
      />
    </table>
  );
}

const styles = sva({
  slots: ["body", "head", "footer"],
  base: {
    head: {
      top: 0,
      display: "grid",
      position: "sticky",
      elevation: "header",
      width: "full",
    },
    footer: {
      bottom: 0,
      position: "sticky",
      elevation: "header",
      width: "full",
    },
    body: {
      display: "grid",
      position: "relative",
      width: "full",
      minHeight: "full",
    },
  },
});

const MemoizedTableLayout = forwardRef(TableLayout);

export { MemoizedTableLayout as TableLayout };
