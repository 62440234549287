import {
  cva,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

export const mutedStyle = cva({
  base: {
    textStyle: "muted",
    color: "interface.400",
  },
});

export type MutedProps = RecipeVariantProps<typeof mutedStyle>;

export const Muted = styled("p", mutedStyle);
