// this file has been generated automatically, do not modify it
import { forwardRef, type ForwardedRef, type SVGProps, type JSX } from "react";

import { styled } from "@mobsuccess-devops/styled-system/jsx";

import { ReactComponent as Off } from "./off.svg";
import { ReactComponent as On } from "./on.svg";

type ComponentProps = SVGProps<SVGSVGElement> & {
  variant: "on" | "off";
} & { title?: string };

function Component(
  { variant, children: _children, ...props }: ComponentProps,
  ref: ForwardedRef<SVGSVGElement>,
): JSX.Element | null {
  switch (variant) {
    case "on":
      return <On {...props} ref={ref} />;
    case "off":
      return <Off {...props} ref={ref} />;
    default:
      return null;
  }
}

export const Switch = Object.assign(styled(forwardRef(Component)), {
  variants: ["on", "off"],
});
