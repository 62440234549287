import { cva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

const badge = cva({
  base: {
    display: "inline-flex",
    borderRadius: "round",
    alignItems: "center",
    gap: "sm",
    padding: "2px 10px",
  },
  variants: {
    palette: {
      primary: { colorPalette: "primary" },
      secondary: { colorPalette: "secondary" },
      interface: { colorPalette: "interface" },
      success: { colorPalette: "success" },
      danger: { colorPalette: "danger" },
      warning: { colorPalette: "warning" },
      info: { colorPalette: "info" },
    },
    variant: {
      light: {
        color: "colorPalette",
        background: "colorPalette.100",
        _hover: {
          background: "colorPalette.200",
        },
      },
      dark: {
        color: "white",
        background: "colorPalette",
        _hover: {
          background: "colorPalette.600",
        },
      },
    },
    size: {
      sm: {
        fontSize: "sm",
        lineHeight: "sm",
        _childSvg: {
          height: "12px",
        },
      },
      md: {
        fontSize: "md",
        lineHeight: "md",
        _childSvg: {
          height: "16px",
        },
      },
      lg: {
        fontSize: "lg",
        lineHeight: "lg",
        _childSvg: {
          height: "20px",
        },
      },
      xl: {
        fontSize: "xl",
        lineHeight: "xl",
        _childSvg: {
          height: "24px",
        },
      },
    },
  },
  defaultVariants: {
    palette: "primary",
    variant: "dark",
    size: "md",
  },
});

export const Badge = styled("div", badge);
