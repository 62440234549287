import type { ReactNode } from "react";

import type { createToaster } from "@ark-ui/react";
import type { Options, GroupApi } from "@zag-js/toast";

type CreateToasterReturn = ReturnType<typeof createToaster>;

export type ToastApi = {
  success<T extends ReactNode>(
    options: Options<T>,
  ): ReturnType<GroupApi["success"]>;
  error<T extends ReactNode>(
    options: Options<T>,
  ): ReturnType<GroupApi["error"]>;
  warning<T extends ReactNode>(
    options: Options<T>,
  ): ReturnType<GroupApi["create"]>;
  info<T extends ReactNode>(
    options: Options<T>,
  ): ReturnType<GroupApi["create"]>;
  create<T extends ReactNode>(
    options: Options<T> & {
      type: "info" | "warning" | "error" | "success";
    },
  ): ReturnType<GroupApi["create"]>;
} & Omit<CreateToasterReturn, "success" | "error" | "create">;

export function createToastApi(toast: CreateToasterReturn): ToastApi {
  return {
    ...toast,
    success(options) {
      return toast.success(options);
    },
    error(options) {
      return toast.error(options);
    },
    warning(options) {
      return toast.create({
        type: "custom",
        ...options,
      });
    },
    info(options) {
      return toast.create({
        type: "info",
        ...options,
      });
    },
    create(options) {
      return toast.create({
        ...options,
        type: options.type === "warning" ? "custom" : options.type,
      });
    },
  };
}
