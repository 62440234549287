import { ReactNode, memo, useMemo, JSX } from "react";

import {
  Config,
  ContextData,
  DataContext,
} from "@mobsuccess-devops/react-data-context";

import { getConfig, type BranchConfig } from "../../features/data";
import { useAuth } from "../../public/auth/auth";

export type DataContextProviderProps = {
  host: string;
  data?: ContextData;
  children?: ReactNode;
  services: Record<string, string>;
  environment?: "production" | "development";
  branchOverrides?: Record<string, BranchConfig>;
};

function DataContextProvider({
  data,
  host,
  services,
  environment,
  children = null,
}: DataContextProviderProps): JSX.Element | null {
  const { jwt } = useAuth();

  const servicesOverrides = useMemo(() => {
    // Extract microservice pr number from search params
    const servicesOverrides: Record<string, string> = {};
    const searchParams = new URLSearchParams(window.location.search);

    const isPrerelease = import.meta.env.VITE_IS_PRERELEASE === "true";
    if (isPrerelease) {
      Object.keys(services).forEach((key) => {
        servicesOverrides[key + "-microservice"] = "pre";
      });
    }

    for (const [key, value] of searchParams) {
      if (/.+-microservice$/.test(key) && /^(pr-\d+|pre)$/.test(value)) {
        servicesOverrides[key] = value;
      }
    }
    return servicesOverrides;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We don't want to update this hook
  }, []);

  const dataContextConfig = useMemo<Config | null>(() => {
    if (!jwt) {
      return null;
    }
    const { dataEnvironment, queryBranch } = getConfig();
    return {
      host,
      services,
      queryBranch,
      dataEnvironment,
      authorization: jwt,
      msEnvironment: environment,
    };
  }, [jwt, host, services, environment]);

  return dataContextConfig ? (
    <DataContext
      data={data}
      config={dataContextConfig}
      servicesOverrides={servicesOverrides}
    >
      {children}
    </DataContext>
  ) : null;
}

export default memo(DataContextProvider);
