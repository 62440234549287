import { type JSX, type PropsWithChildren, memo } from "react";

import { ReactComponent as DangerIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/danger.svg";
import { ReactComponent as InfoIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/info.svg";
import { ReactComponent as SuccessIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/success.svg";
import { ReactComponent as WarningIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/warning.svg";
import { ReactComponent as CloseIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";
import { sva } from "@mobsuccess-devops/styled-system/css";
import { Box, Flex } from "@mobsuccess-devops/styled-system/jsx";

import { IconButton } from "../../Button";

type AlertRootProps = PropsWithChildren<{
  palette?: "success" | "info" | "warning" | "danger";
  onCloseClick?: () => void;
}>;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function AlertRoot({
  children,
  onCloseClick,
  palette,
}: AlertRootProps): JSX.Element {
  const classes = styles({ palette, withCloseButton: !!onCloseClick });
  const icons = {
    success: <SuccessIcon className={classes.icon} title="success" />,
    info: <InfoIcon className={classes.icon} title="info" />,
    warning: <WarningIcon className={classes.icon} title="warning" />,
    danger: <DangerIcon className={classes.icon} title="danger" />,
  };
  const icon = icons[palette ?? "info"] ?? null;
  return (
    <div className={classes.root}>
      {icon}
      {onCloseClick && (
        <IconButton className={classes.close} onClick={onCloseClick}>
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </div>
  );
}

type AlertActionsProps = PropsWithChildren;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function AlertActions({ children }: AlertActionsProps): JSX.Element {
  return (
    <Flex gridArea="actions" marginTop="md" justify="flex-end" gap="md">
      {children}
    </Flex>
  );
}

type AlertContentProps = PropsWithChildren;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function AlertContent({ children }: AlertContentProps): JSX.Element {
  return (
    <Box gridArea="content" alignSelf="center">
      {children}
    </Box>
  );
}

export const Alert = {
  Root: memo(AlertRoot),
  Content: memo(AlertContent),
  Actions: memo(AlertActions),
};

const styles = sva({
  slots: ["root", "icon", "close"],
  base: {
    root: {
      padding: "12px",
      border: "1px solid token(colors.colorPalette.500)",
      bg: "token(colors.colorPalette.100)",
      borderRadius: "md",
      color: "token(colors.colorPalette.600)",
      position: "relative",
      textStyle: "md",
      display: "grid",
      flexDirection: "column",
      columnGap: "md",
      stroke: "token(colors.colorPalette.300)",
    },
    icon: {
      gridArea: "icon",
      width: "20px",
      height: "24px",
    },
    close: {
      gridArea: "close",
      color: "token(colors.colorPalette.300)",
    },
  },
  variants: {
    palette: {
      success: { root: { colorPalette: "success" } },
      info: { root: { colorPalette: "info" } },
      warning: { root: { colorPalette: "warning" } },
      danger: { root: { colorPalette: "danger" } },
    },
    withCloseButton: {
      true: {
        root: {
          gridTemplateAreas: `
          "icon content close"
          "actions actions actions"
          `,
          gridTemplateColumns: "min-content 1fr min-content",
        },
      },
      false: {
        root: {
          gridTemplateAreas: `
          "icon content"
          "actions actions"
          `,
          gridTemplateColumns: "min-content 1fr",
        },
      },
    },
  },
  defaultVariants: {
    palette: "info",
    withCloseButton: false,
  },
});
