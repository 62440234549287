/* eslint-disable @mobsuccess-devops/mobsuccess/react */

/* eslint-disable no-case-declarations */
import {
  type ReactNode,
  type JSX,
  isValidElement,
  type FunctionComponentElement,
  useState,
  useCallback,
  useMemo,
  useId,
} from "react";

import { ReactComponent as SettingsIcon } from "@mobsuccess-devops/streamline/custom/react-ui/settings-slider.svg";
import { ReactComponent as CrossIcon } from "@mobsuccess-devops/streamline/regular/01-Interface Essential/33-Form-Validation/close.svg";
import { css, sva } from "@mobsuccess-devops/styled-system/css";
import { HStack, VStack, styled } from "@mobsuccess-devops/styled-system/jsx";
import type { ColumnFiltersState, Table } from "@tanstack/react-table";

import { useLocales } from "../../../../features/localization";
import { useToggle } from "../../../../features/utils/states-hooks";
import { IconButton } from "../../../Button";
import { Dialog } from "../../Dialog/Dialog";
import { Input } from "../../Input/Input";
import { NumberInput } from "../../NumberInput/NumberInput";
import { Select } from "../../Select/Select";
import { Switch } from "../../Switch/Switch";

type FilterStatesProps<T> = {
  classes: ReturnType<typeof styles>;
  table: Table<T>;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function TableStates<T>({ table, classes }: FilterStatesProps<T>): JSX.Element {
  return (
    <styled.div className={classes["filter-state"]}>
      {table
        .getState()
        .columnFilters.filter((filter) => !!filter.value)
        .map((filter) => {
          const col = table.getColumn(filter.id);
          return (
            <div key={filter.id} className={classes.badge}>
              <span className={classes["badge:label"]}>
                {col?.columnDef.meta?.label ??
                  (typeof col?.columnDef.header !== "function"
                    ? col?.columnDef.header
                    : filter.id)}
                :
              </span>
              <span className={classes["badge:value"]}>
                {String(filter.value)}
              </span>
              <div
                className={classes["badge:dismisser"]}
                onClick={() =>
                  table.setColumnFilters(
                    table
                      .getState()
                      .columnFilters.filter((f) => f.id !== filter.id),
                  )
                }
              >
                <CrossIcon />
              </div>
            </div>
          );
        })}
    </styled.div>
  );
}

type FilterEntryProps<T> = {
  columnId: string;
  table: Table<T>;
  onChangeFilter: (columnId: string, value: unknown) => void;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function FilterEntry<T>({
  columnId,
  table,
  onChangeFilter,
}: FilterEntryProps<T>): JSX.Element {
  const column = table.getColumn(columnId);
  if (!column) {
    throw new Error(`Column ${columnId} not found`);
  }
  const config = column.columnDef.meta?.filter;
  if (!config) {
    throw new Error(`Column ${columnId} has no filter config`);
  }
  const entry = useMemo(() => {
    switch (config.type) {
      case "text":
        return (
          <Input.Root
            defaultValue={String(column.getFilterValue() ?? "")}
            placeholder={config.placeholder}
            onChange={(e) => onChangeFilter(columnId, e.target.value)}
          />
        );
      case "number":
        return (
          <NumberInput.Root
            locale="fr-FR"
            placeholder={config.placeholder}
            defaultValue={String(column.getFilterValue())}
            onValueChange={(details) =>
              onChangeFilter(columnId, details.valueAsNumber)
            }
          />
        );
      case "list":
        const values = column.getColumnUniqueValues();
        const mapper =
          config.mapper ||
          ((v) =>
            Array.from(v.keys()).map((k) => ({
              value: String(k),
              label: String(k),
            })));
        const mapped = mapper(values);
        return (
          <Select.Root
            multiple
            items={mapper(values)}
            defaultValue={
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              (column.getFilterValue() as string[]) ??
              mapped.map((v) => v.value)
            }
            onValueChange={(details) => onChangeFilter(columnId, details.value)}
          />
        );
      case "switch":
        return (
          <Switch
            label={config.label}
            defaultChecked={Boolean(column.getFilterValue())}
            onCheckedChange={(details) =>
              onChangeFilter(columnId, details.checked)
            }
          />
        );
      case "date-range":
      case "custom":
        throw new Error("Not implemented");
      default:
        return null;
    }
  }, [config, column, onChangeFilter, columnId]);
  const style = { gridArea: columnId };

  return <div style={style}>{entry}</div>;
}

type TableFiltersLayoutProps<T> = {
  id: string;
  areas: string;
  table: Table<T>;
  filters: Set<string>;
  toggleOpen: () => void;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function TableFiltersLayout<T>({
  id,
  table,
  areas,
  filters,
  toggleOpen,
}: TableFiltersLayoutProps<T>): JSX.Element {
  const [filtersValue, setFiltersValue] = useState<ColumnFiltersState>(
    table.getState().columnFilters,
  );

  const handleChangeFilter = useCallback((columnId: string, value: unknown) => {
    setFiltersValue((prev) => {
      if (!prev.some((filter) => filter.id === columnId)) {
        return [...prev, { id: columnId, value }];
      }
      return prev.map((filter) => {
        if (filter.id === columnId) {
          return {
            id: filter.id,
            value,
          };
        }
        return filter;
      });
    });
  }, []);

  const gridStyle = {
    gridTemplateAreas: areas,
  };

  const handleSubmit = useCallback(() => {
    table.setColumnFilters(filtersValue);
    toggleOpen();
  }, [table, filtersValue, toggleOpen]);

  const handleClear = useCallback(() => {
    table.setColumnFilters([]);
    toggleOpen();
  }, [table, toggleOpen]);

  return (
    <VStack width="full" alignItems="end" paddingX="md">
      <styled.div gridGap="md" width="full" display="grid" style={gridStyle}>
        {Array.from(filters.values()).map((columnId) => (
          <FilterEntry
            key={columnId}
            columnId={columnId}
            table={table}
            onChangeFilter={handleChangeFilter}
          />
        ))}
      </styled.div>
      <HStack>
        <styled.button
          display="none"
          id={`${id}-clear`}
          onClick={handleClear}
        />
        <styled.button
          display="none"
          id={`${id}-submit`}
          onClick={handleSubmit}
        />
        <styled.button
          display="none"
          id={`${id}-clear`}
          onClick={handleClear}
        />
        <styled.button
          id={`${id}-apply`}
          display="none"
          onClick={handleSubmit}
        />
      </HStack>
    </VStack>
  );
}

export type TableFiltersProps<T> = {
  /** @internal */
  _table?: Table<T>;
  layout?: Array<string[]>;
  placeholderSearch?: string;
};

const defaultLayout = new Array<string[]>();

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export function TableFilters<T>({
  _table: table,
  layout = defaultLayout,
  placeholderSearch,
}: TableFiltersProps<T>): JSX.Element {
  const [open, toggleOpen] = useToggle(false);
  if (!table) {
    throw new Error("TableFilters must be a child of Table");
  }

  const filters = new Set(layout.flat());

  const id = useId();

  const classes = styles({
    hidden: !filters.size,
  });

  const forwardClick = useCallback(
    (type: string) => {
      return () => {
        document.getElementById(`${id}-${type}`);
      };
    },
    [id],
  );

  const locales = useLocales();

  return (
    <>
      <TableStates table={table} classes={classes} />
      <HStack className={classes["global-filter"]} gap="sm">
        <Input.Root
          placeholder={placeholderSearch ?? locales.filters.searchPlaceholder}
          className={css({ backgroundColor: "white" })}
          onChange={(e) => {
            table.setGlobalFilter(e.target.value);
          }}
        />
        <Dialog.Root open={open} onOpenChange={toggleOpen} unmountOnExit>
          <Dialog.Title>Filters</Dialog.Title>
          <Dialog.Trigger
            size="lg"
            variant="icon"
            className={classes["trigger:opener"]}
          >
            <SettingsIcon />
          </Dialog.Trigger>
          <Dialog.Content>
            <TableFiltersLayout
              id={id}
              table={table}
              filters={filters}
              toggleOpen={toggleOpen}
              areas={layout.map((area) => `"${area.join(" ")}"`).join(" ")}
            />
          </Dialog.Content>
          <Dialog.Action action="close" onClick={forwardClick("clear")}>
            Clear
          </Dialog.Action>
          <Dialog.Action variant="dark" onClick={forwardClick("apply")}>
            Apply
          </Dialog.Action>
        </Dialog.Root>
      </HStack>
      {!!table.getState().columnFilters.length && (
        <IconButton
          gridArea="clear"
          size="lg"
          className={classes["trigger:opener"]}
          onClick={() => table.setColumnFilters([])}
        >
          <CrossIcon />
        </IconButton>
      )}
    </>
  );
}

const styles = sva({
  slots: [
    "container",
    "trigger:opener",
    "trigger:closer",
    "global-filter",
    "filter-state",
    "badge",
    "badge:label",
    "badge:value",
    "badge:dismisser",
    "content",
  ],
  base: {
    container: {
      overflow: "hidden",
      gridArea: "filter",
      gap: "md",
      width: "full",
      display: "grid",
      transition: "max-height .5s ease-out",
      maxHeight: "500px",
      _closed: {
        maxHeight: "0px",
      },
    },
    "filter-state": {
      position: "relative",
      gridArea: "filter-state",
      display: "flex",
      overflowX: "auto",
      hideScrollbar: true,
      flex: 1,
      gap: "sm",
      alignItems: "center",
      height: "full",
    },
    badge: {
      padding: "sm",
      gap: "xs",
      alignItems: "center",
      height: "full",
      display: "flex",
      border: "1px dashed",
      borderRadius: "md",
      borderColor: "primary.400",
      backgroundColor: "primary.100",
      color: "primary",
    },
    "badge:label": {
      textStyle: "muted",
      whiteSpace: "nowrap",
      display: "inline-block",
      borderColor: "primary.400",
    },
    "badge:value": {
      textStyle: "muted",
    },
    "badge:dismisser": {
      cursor: "pointer",
      center: true,
      height: "fit-content",
      "&>svg": {
        size: "8px",
      },
    },
    "trigger:closer": {
      gridColumn: "span 2",
      display: "flex",
      justifyContent: "end",
    },
    "global-filter": {
      gridArea: "global-filter",
    },
    "trigger:opener": {
      border: "1px solid",
      backgroundColor: "white",
      order: 2,
      color: "interface",
      borderRadius: "md",
      "&>svg": {
        size: "sm!",
      },
    },
  },
  variants: {
    hidden: {
      true: {
        "trigger:opener": {
          display: "none",
        },
      },
    },
  },
});

export type TableFiltersElement<T> = FunctionComponentElement<
  TableFiltersProps<T>
>;
export function isTableFiltersElement<T>(
  node: ReactNode,
): node is TableFiltersElement<T> {
  return isValidElement(node) && node.type === TableFilters<T>;
}
