import styled from "styled-components";

import { BaseButton, ButtonProps } from "./BaseButton";

export const DarkButton = styled(BaseButton)<ButtonProps>`
  color: ${({ theme }) => theme.palette.white};
  background: ${({ theme, $color }) => theme.palette[$color].base};
  &:focus {
    box-shadow: ${({ theme, $color }) =>
      `0px 0px 0px 4px ${theme.palette[$color].base + "80"}`};
  }
  &:hover:enabled {
    background: ${({ theme, $color }) => theme.palette[$color].darker[100]};
  }
`;

DarkButton.displayName = "DarkButton";
