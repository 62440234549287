import type { ContextType } from "react";

import { t } from "@lingui/macro";
import type { LocalesContext } from "@mobsuccess-devops/react-ui/Locales";

export function useReactUILocales(): ContextType<typeof LocalesContext> {
  return {
    select: {
      selectAll: t`#src.public.react-ui.select.select-all`,
      removeAll: t`#src.public.react-ui.select.remove-all`,
    },
    accountDropdown: {
      searchPlaceholder: t`#src.public.react-ui.account-dropdown.search-placeholder`,
    },
    pagination: {
      pageSizeLabel: t`#src.public.react-ui.pagination.page-size-label`,
      pageSizeOptions: (size: number) =>
        t`#src.public.react-ui.pagination.page-size-options(${size})`,
    },
    colorPicker: {
      savedSectionLabel: t`#src.public.react-ui.color-picker.saved-section-label`,
    },
    filters: {
      searchPlaceholder: t`#src.public.react-ui.filters.search-placeholder`,
    },
  };
}
