import { useEffect, useState } from "react";

function getOverflow(element: Element) {
  return (prev?: { horizontal: boolean; vertical: boolean }) => {
    const newValue = {
      horizontal: element.scrollWidth > element.clientWidth,
      vertical: element.scrollHeight > element.clientHeight,
    };
    if (
      !prev ||
      newValue.horizontal !== prev.horizontal ||
      newValue.vertical !== prev.vertical
    ) {
      return newValue;
    }
    return prev;
  };
}

export function useElementIsOverflowing(element: HTMLElement | null): {
  horizontal: boolean;
  vertical: boolean;
} {
  const [elementIsOverflowing, setElementIsOverflowing] = useState(() => {
    if (!element) {
      return {
        horizontal: false,
        vertical: false,
      };
    }
    return getOverflow(element)();
  });

  useEffect(() => {
    if (!element) {
      return;
    }
    const resize = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const element = entry.target;
        setElementIsOverflowing(getOverflow(element));
      }
    });
    const mutation = new MutationObserver((mutations) => {
      // eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables, @typescript-eslint/no-unused-vars
      for (const _mutation of mutations) {
        setElementIsOverflowing(getOverflow(element));
      }
    });
    resize.observe(element);
    mutation.observe(element, {
      childList: true,
      subtree: true,
    });
    return () => {
      resize.disconnect();
      mutation.disconnect();
    };
  }, [element]);

  return elementIsOverflowing;
}
