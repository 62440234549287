import { cva } from "@mobsuccess-devops/styled-system/css";
import { RecipeVariantProps } from "@mobsuccess-devops/styled-system/types";

export type TextFieldDecorationVariants = RecipeVariantProps<
  typeof textFieldDecorationStyles
>;

export const textFieldDecorationStyles = cva({
  base: {
    outline: "none",
    appearance: "none",
    transition: "box-shadow 0.2s ease-in-out",
    borderRadius: "md",
    _disabled: {
      cursor: "not-allowed",
      borderColor: "interface.300!",
    },
    boxShadow: "0px 0px 0px 4px var(--shadow-color)",
  },
  variants: {
    state: {
      error: {
        borderColor: "danger.500!",
        _after: {
          backgroundColor: "danger.500!",
        },
      },
      success: {
        borderColor: "success.500!",
        _after: {
          backgroundColor: "success.500!",
        },
      },
      warning: {
        borderColor: "warning.500!",
        _after: {
          backgroundColor: "warning.500!",
        },
      },
    },
    compact: {
      true: {
        borderRadius: "sm",
      },
    },
    borderStyle: {
      standard: {
        border: "1px solid",
        borderColor: "interface.300",
        _hover: {
          borderColor: "interface.400",
        },
        "&:focus, &:has(> input:focus), &:has(> div > input:focus)": {
          borderColor: "primary",
          "--shadow-color":
            "color-mix(in srgb, token(colors.primary) 30%, transparent)",
        },
      },
      outlined: {
        _after: {
          position: "absolute",
          bottom: "0px",
          height: "1px",
          width: "0%",
          left: "50%",
          content: '""',
          transition: "width 0.3s ease-in-out, left 0.3s ease-in-out",
          _hover: {
            left: "0",
            backgroundColor: "interface.400",
            width: "100%",
          },
        },
        "&:focus, &:has(> input:focus), &:has(> div > input:focus)": {
          _after: {
            left: "0",
            backgroundColor: "primary",
            width: "100%",
          },
        },
      },
      textBackground: {
        border: "none",
      },
    },
  },
  defaultVariants: {
    compact: false,
    borderStyle: "standard",
  },
});
