import {
  type JSX,
  type ReactNode,
  isValidElement,
  type FunctionComponentElement,
  useCallback,
  type MouseEventHandler,
} from "react";

import { useDialogContext } from "@ark-ui/react";

import { Button, type ButtonProps } from "../Button/Button";

type ActionProps = ButtonProps & {
  action?: "close" | "validate";
};

export function Action({
  action,
  onClick,
  ...props
}: ActionProps): JSX.Element {
  const context = useDialogContext();

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (...args) => {
      await onClick?.(...args);
      if (action) {
        context.setOpen(false);
      }
    },
    [action, context, onClick],
  );

  return <Button {...props} onClick={handleClick} />;
}

export type ActionElement = FunctionComponentElement<ActionProps>;

export function isActionElement(node: ReactNode): node is ActionElement {
  return isValidElement(node) && node.type === Action;
}
