import { JSX } from "react";

import { useTheme } from "styled-components";

import { SelectItem } from "../../../../types/new-select";
import { Container } from "../../../Container";
import { DropdownItem } from "../../../Dropdown";
import { Typography } from "../../../Typography";
import { Checkbox } from "../../Checkbox/Checkbox";

type SelectOptionProps = {
  option: Omit<SelectItem, "type">;
  isActive?: boolean;
  hasCheckbox?: boolean;
  collection?: string;
  onClick: () => void;
};

export function SelectOption({
  option,
  isActive = false,
  hasCheckbox = false,
  collection,
  onClick,
}: SelectOptionProps): JSX.Element {
  const { palette } = useTheme();

  return (
    <DropdownItem
      value={option.value}
      isActive={isActive}
      onClick={onClick}
      caption={collection}
      headingAddon={option.headingAddon}
      trailingAddon={option.trailingAddon}
    >
      <Container alignItems="center" gap="12px">
        {hasCheckbox && <Checkbox isChecked={isActive} onClick={onClick} />}
        <Typography variant="text-md" as="p" color={palette.interface.base}>
          {option.label}
        </Typography>
      </Container>
    </DropdownItem>
  );
}
