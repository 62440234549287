import { memo, useCallback, useMemo, JSX } from "react";

import styled, { css } from "styled-components";

import {
  isSameDate,
  useDatePickerContext,
} from "../../../../../features/date-picker";
import { Container } from "../../../../Container";

type CardDayUIProps = {
  $isHighlight: boolean;
  $isDisabled: boolean;
  $isToday: boolean;
  $isRangeStart: boolean;
  $isRangeEnd: boolean;
  $isInRange: boolean;
};

const disabledCss = css`
  color: ${({ theme }) => theme.palette.interface.lighter[400]};
  &:hover {
    color: ${({ theme }) => theme.palette.interface.lighter[200]};
  }
`;

const todayCss = css`
  color: ${({ theme }) => theme.palette.secondary.darker[300]};
  background-color: ${({ theme }) => theme.palette.secondary.lighter[400]};
  font-weight: 600;
  font-size: 12px;
  border-radius: 4px;
`;

const highlightCss = css<CardDayUIProps>`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.secondary.base};
  // if range start set left border radius to 4px
  ${({ $isRangeStart }) =>
    $isRangeStart &&
    css`
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    `}
  // if range end set right border radius to 4px
  ${({ $isRangeEnd }) =>
    $isRangeEnd &&
    css`
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;

const inRangeCss = css`
  background-color: ${({ theme }) => theme.palette.secondary.lighter[600]};
  color: ${({ theme }) => theme.palette.secondary.darker[300]};
`;

const CardDayUI = styled(Container)<CardDayUIProps>`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.interface.base};
  &:hover {
    background-color: ${({
      theme,
      $isToday,
      $isInRange,
      $isRangeEnd,
      $isRangeStart,
    }) =>
      !$isToday &&
      !$isInRange &&
      !$isRangeEnd &&
      !$isRangeStart &&
      theme.palette.interface.lighter[700]};
  }
  ${({ $isDisabled }) => $isDisabled && disabledCss}
  ${({ $isInRange, $isDisabled }) => $isInRange && !$isDisabled && inRangeCss}
  ${({ $isRangeStart, $isRangeEnd, $isDisabled }) =>
    ($isRangeStart || $isRangeEnd) && !$isDisabled && highlightCss}
  ${({ $isToday, $isDisabled }) => $isToday && !$isDisabled && todayCss}
  cursor: pointer;
`;
CardDayUI.displayName = "CardDayUI";

type CardDayProps = {
  currentDate: Date;
  day: {
    date: Date;
    isDisabled: boolean;
  };
  onClick?: (data: Date) => void;
};

function CardDay({ currentDate, day, onClick }: CardDayProps): JSX.Element {
  const { hoveredDate, setHoveredDate, selectedRange } = useDatePickerContext();

  const handleHovered = useCallback(() => {
    if (selectedRange?.start && !selectedRange?.end) {
      setHoveredDate(day.date);
    }
  }, [setHoveredDate, day.date, selectedRange?.start, selectedRange?.end]);

  const isHighlight = useMemo(() => {
    return isSameDate(day.date, currentDate);
  }, [currentDate, day.date]);
  const handleClick = useCallback(() => {
    onClick?.(day.date);
  }, [onClick, day.date]);

  const isRangeStart = useMemo(() => {
    if (!selectedRange) {
      return false;
    }
    const { start } = selectedRange;
    return start ? isSameDate(start, day.date) : false;
  }, [day.date, selectedRange]);

  const isRangeEnd = useMemo(() => {
    if (!selectedRange) {
      return false;
    }
    const { end } = selectedRange;
    return end ? isSameDate(end, day.date) : false;
  }, [day.date, selectedRange]);

  const isInRange = useMemo(() => {
    if (!selectedRange) {
      return false;
    }
    const { start, end } = selectedRange;
    if (
      start &&
      day.date.getTime() > start.getTime() &&
      end &&
      day.date.getTime() < end.getTime()
    ) {
      return true;
    }
    return false;
  }, [day.date, selectedRange]);

  // hovered date between start and date hovered
  const isHoveredInRange = useMemo(() => {
    if (!selectedRange) {
      return false;
    }
    const { start, end } = selectedRange;
    if (
      start &&
      !end &&
      hoveredDate &&
      start.getTime() < day.date.getTime() &&
      hoveredDate.getTime() > start.getTime() &&
      day.date.getTime() < hoveredDate.getTime()
    ) {
      return true;
    }
    return false;
  }, [hoveredDate, selectedRange, day.date]);

  return (
    <CardDayUI
      $isDisabled={day.isDisabled}
      $isHighlight={isHighlight}
      $isRangeStart={isRangeStart}
      $isRangeEnd={isRangeEnd}
      $isInRange={isInRange || isHoveredInRange}
      $isToday={isSameDate(day.date, new Date())}
      onClick={handleClick}
      onMouseEnter={handleHovered}
      justifyContent="center"
      alignItems="center"
      height="40px"
      width="40px"
    >
      {day.date.getDate()}
    </CardDayUI>
  );
}

const MemoizedComponent = memo(CardDay);
export { MemoizedComponent as CardDay };
