/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import type { JSX, ReactElement, ReactNode } from "react";

import { radioGroupAnatomy } from "@ark-ui/anatomy";
import {
  RadioGroup as ArkRadioGroup,
  type RadioGroupRootProps as ArkRadioGroupRootProps,
  type RadioGroupItemProps as ArkRadioGroupItemProps,
} from "@ark-ui/react";
import { sva, cx } from "@mobsuccess-devops/styled-system/css";

export type RadioGroupItemProps = Omit<ArkRadioGroupItemProps, "children"> & {
  custom?: boolean;
  children: ReactNode;
};

function RadioGroupItem({
  custom,
  children,
  className,
  ...props
}: RadioGroupItemProps): JSX.Element {
  const classes = styles();
  return (
    <ArkRadioGroup.Item
      className={cx(classes.item, className, "group")}
      {...props}
    >
      {custom ? (
        children
      ) : (
        <>
          <ArkRadioGroup.ItemControl className={classes.itemControl} />
          <ArkRadioGroup.ItemText className={classes.itemText}>
            {children}
          </ArkRadioGroup.ItemText>
        </>
      )}
      <ArkRadioGroup.ItemHiddenInput />
    </ArkRadioGroup.Item>
  );
}

function RadioGroupItemControl(): JSX.Element {
  const classes = styles();
  return <ArkRadioGroup.ItemControl className={classes.itemControl} />;
}

export type RadioGroupRootProps = {
  children:
    | Array<ReactElement<RadioGroupItemProps, typeof RadioGroupItem>>
    | ReactElement<RadioGroupItemProps, typeof RadioGroupItem>;
  className?: string;
} & ArkRadioGroupRootProps;

export function RadioGroupRoot({
  className,
  ...props
}: RadioGroupRootProps): JSX.Element {
  const classes = styles();
  return (
    <ArkRadioGroup.Root className={cx(classes.root, className)} {...props} />
  );
}

const styles = sva({
  slots: radioGroupAnatomy.keys(),
  base: {
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "sm",
    },
    item: {
      gap: "sm",
      display: "flex",
      alignItems: "center",
      _focus: {
        colorPalette: "primary",
      },
      _disabled: {
        cursor: "not-allowed",
      },
    },
    itemText: {
      textStyle: "body",
      color: "interface",
      _disabled: {
        color: "interface.300",
      },
    },
    itemControl: {
      center: true,
      size: "md",
      border: "1px solid",
      borderRadius: "50%",
      backgroundColor: "white",
      borderColor: "interface.300",
      _groupDisabled: {
        borderColor: "interface.300!",
        backgroundColor: "interface.200!",
        _after: {
          backgroundColor: "transparent!",
        },
        _checked: {
          _after: {
            backgroundColor: "interface.300!",
          },
        },
      },
      _groupHover: {
        borderColor: "interface.400",
      },
      _groupChecked: {
        borderColor: "primary.400",
      },
      _groupFocus: {
        "--shadow-color": "colors.interface.200",
        _checked: {
          "--shadow-color": "colors.primary.300",
        },
      },
      boxShadow: "0px 0px 0px 4px var(--shadow-color)",
      _after: {
        content: "''",
        transition: "width 0.1s ease-in-out, height 0.1s ease-in-out",
        size: "xs",
        borderRadius: "50%",
        _groupChecked: {
          backgroundColor: "primary.400",
        },
      },
    },
  },
});

export const RadioGroup = {
  Root: RadioGroupRoot,
  Item: RadioGroupItem,
  ItemControl: RadioGroupItemControl,
};
