import {
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  memo,
  useCallback,
  JSX,
} from "react";
import { HTMLAttributeAnchorTarget } from "react";

import { RelativeRoutingType } from "react-router-dom";

import { useHref, useNavigate } from "../../features/navigation";

function isModifiedEvent(event: MouseEvent<HTMLAnchorElement>): boolean {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function shouldProcessLinkClick(
  event: MouseEvent<HTMLAnchorElement>,
  target?: string,
): boolean {
  return (
    event.button === 0 && // Ignore everything but left clicks
    (!target || target === "_self") && // Let browser handle "target=_blank" etc.
    !isModifiedEvent(event) // Ignore clicks with modifier keys
  );
}

export type LinkBaseProps = {
  children?: ReactNode;
  to?: string;
  id?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
  relative?: RelativeRoutingType;
  replace?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

function LinkBase({
  to,
  id,
  target,
  replace,
  onClick,
  relative,
  children,
  className,
}: LinkBaseProps): JSX.Element {
  const navigate = useNavigate();

  const href = useHref(to ?? "/");

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      if (!shouldProcessLinkClick(event, target)) {
        return onClick?.(event);
      }
      event.preventDefault();
      if (onClick) {
        onClick(event);
      }
      if (to) {
        navigate(to, { replace, relative });
      }
    },
    [navigate, onClick, relative, replace, target, to],
  );

  return (
    <a
      href={href}
      target={target}
      onClick={handleClick}
      id={id}
      className={className}
    >
      {children}
    </a>
  );
}

export default memo(LinkBase);
