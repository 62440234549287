import { MouseEvent, useEffect, useMemo, useState, JSX, useRef } from "react";

import { Sizes } from "../../../../types/sizes";
import { Container } from "../../../Container";

import { MultiSelectItem } from "./MultiSelectItem/MultiSelectItem";

type MultiSelectItemsProps = {
  items: Array<{ label: string; value: string | number }>;
  variant: Sizes;
  onRemove: (_: string | number) => (_: MouseEvent) => void;
};

export function MultiSelectItems({
  items,
  variant,
  onRemove,
}: MultiSelectItemsProps): JSX.Element {
  const [options, setOptions] = useState(items);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const excess = useMemo(
    () => items.length - options.length,
    [items.length, options.length],
  );

  useEffect(() => {
    setOptions(items);
  }, [items]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    if (options.length === 0) {
      return;
    }
    if (containerRef.current.scrollWidth > containerRef.current.clientWidth) {
      setOptions((prevOptions) => prevOptions.slice(0, -1));
      return;
    }
  }, [options.length]);

  return (
    <Container ref={containerRef} overflow="auto" gap="4px">
      {options.map((option) => (
        <MultiSelectItem
          key={option.value}
          variant={variant}
          onClick={onRemove(option.value)}
        >
          {option.label}
        </MultiSelectItem>
      ))}
      {excess > 0 && (
        <MultiSelectItem variant={variant} isRest>
          {`+${excess}`}
        </MultiSelectItem>
      )}
    </Container>
  );
}
