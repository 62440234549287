import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

import { sleep } from "../timers/timers";

type RetryConfig = {
  start: number;
  sleep: number;
  scale: number;
  maxSleep: number;
};

declare module "axios" {
  // this is an override for the AxiosRequestConfig interface
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface AxiosRequestConfig {
    retry?: RetryConfig | boolean;
  }
}

const retryInitial = 2e3; // 2s
const retryScaleOff = 1.5;
const retryMaxInterval = 30e3; // 30s
const retryMaxDuration = 30 * 60e3; // wait 30 mins

function request(
  this: AxiosInstance,
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  if (config.retry === true) {
    return {
      ...config,
      retry: {
        start: Date.now(),
        sleep: retryInitial,
        maxSleep: retryMaxInterval,
        scale: retryScaleOff,
      },
    };
  }
  return config;
}

async function response(
  this: AxiosInstance,
  response: AxiosResponse,
): Promise<AxiosResponse> {
  if (response.status !== 202 && response.status !== 429) {
    return response;
  }
  const retry = response.config.retry;
  // retry should never be true here but if the request interceptor fails for any reason, we won't retry
  if (!retry || retry === true) {
    /* istanbul ignore next */ // avoid measuring coverage on this line
    return response;
  }
  if (Date.now() - retry.start > retryMaxDuration) {
    return response;
  }
  const retryAfter = Number(response.headers["retry-after"] ?? 0);
  if (retryAfter > 0) {
    retry.sleep = Math.max(retry.sleep, retryAfter * 1e3);
  }
  const maxSleepAfter = Number(response.headers["x-max-retry-after"] ?? 0);
  if (maxSleepAfter > 0) {
    retry.maxSleep = maxSleepAfter * 1e3;
  }

  await sleep(Math.min(retry.sleep, retry.maxSleep));

  retry.sleep *= retry.scale;

  return await (this ?? axios).request({
    ...response.config,
    retry,
  });
}

async function error(
  this: AxiosInstance,
  error: unknown,
): Promise<AxiosResponse> {
  if (!axios.isAxiosError(error)) {
    throw error;
  }
  const response = error.response;
  if (response && response.status === 429 && response.config.retry) {
    const newresponse = await retry.response.call(this, response);
    if (newresponse === response) {
      throw error;
    }
    return newresponse;
  }
  throw error;
}

export const retry = {
  request,
  response,
  error,
};
