import { Link as LinkReactRouter } from "@mobsuccess-devops/react-router";
import { cva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

const link = cva({
  base: {
    display: "inline-flex",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "6px token(spacing.sm)",
    gap: "sm",
    fontSize: "md",
    textDecoration: "underline",
    textDecorationColor: "colorPalette.400",
    textUnderlineOffset: "5px",
    fontWeight: "600",
    lineHeight: "md",
    color: "colorPalette.600",
    cursor: "pointer",
    letterSpacing: "0.03em",
    _childSvg: {
      size: "sm",
      color: "colorPalette.400",
    },
    _focus: {
      boxShadow: "0px 0px 0px 4px token(colors.colorPalette.200)",
      borderRadius: "8px",
    },
    _hover: {
      textDecorationColor: "colorPalette.600",
      _childSvg: {
        color: "colorPalette.600",
      },
    },
  },
  variants: {
    color: {
      primary: { colorPalette: "primary" },
      secondary: { colorPalette: "secondary" },
      interface: { colorPalette: "interface" },
      success: { colorPalette: "success" },
      warning: { colorPalette: "warning" },
      danger: { colorPalette: "danger" },
      info: { colorPalette: "info" },
    },
    size: {
      sm: { fontSize: "sm", lineHeight: "sm" },
      md: { fontSize: "md", lineHeight: "md" },
      lg: { fontSize: "lg", lineHeight: "lg" },
    },
    disabled: {
      true: {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
  },
  defaultVariants: {
    size: "sm",
    color: "primary",
  },
});

export const Link = styled(LinkReactRouter, link);
