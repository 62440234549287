import { memo, useMemo, JSX } from "react";

import { Navigate, To } from "react-router-dom";

import { useRenderUrlWithKeepParams } from "../../features/navigation";

export type NavigateBaseProps = {
  to: To;
  replace?: boolean;
  state?: unknown;
};

function NavigateBase({ to, replace, state }: NavigateBaseProps): JSX.Element {
  const renderUrlWithKeepParams = useRenderUrlWithKeepParams();
  const newTo = useMemo(
    () => renderUrlWithKeepParams(to),
    [renderUrlWithKeepParams, to],
  );

  return <Navigate to={newTo} replace={replace} state={state} />;
}

export default memo(NavigateBase);
