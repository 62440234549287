import { Body } from "./Body";
import { Muted } from "./Muted";
import { SubTitle } from "./SubTitle";
import { Title } from "./Title";

/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
export const Typo = {
  Title,
  SubTitle,
  Body,
  Muted,
};
