import {
  memo,
  ForwardedRef,
  ReactNode,
  MouseEventHandler,
  useMemo,
  forwardRef,
  JSX,
} from "react";

import { Container } from "../../Container";

import { Action } from "./Action/Action";

export type ActionsProps = {
  id?: string;
  className?: string;
  actions: {
    label: string;
    value: string;
    onClick: MouseEventHandler<HTMLDivElement>;
    icon?: ReactNode;
  }[];
};

function Actions(
  { className, id, actions }: ActionsProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const children = useMemo(
    () =>
      actions.map((action) => {
        const { label, value, onClick, icon } = action;
        return (
          <Action key={value} value={value} onClick={onClick} icon={icon}>
            {label}
          </Action>
        );
      }),
    [actions],
  );
  return (
    <Container
      width="100%"
      overflow="auto"
      ref={ref}
      id={id}
      className={className}
    >
      <Container padding="12px" gap="12px">
        {children}
      </Container>
    </Container>
  );
}

const MemoizedActions = memo(forwardRef(Actions));
export { MemoizedActions as Actions };
