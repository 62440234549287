import type { JSX } from "react";

import {
  ColorPicker as ArkColorPicker,
  type ColorPickerRootProps as ArkColorPickerRootProps,
} from "@ark-ui/react";
import { sva } from "@mobsuccess-devops/styled-system/css";
import { VStack, Wrap } from "@mobsuccess-devops/styled-system/jsx";
import { vstack } from "@mobsuccess-devops/styled-system/patterns";

import { useLocales } from "../../../features/localization";
import { Typo } from "../../Typo";

import { AddShortcut } from "./AddShortcut";
import { Controls } from "./Controls";
import { Shortcut } from "./Shortcut";
import { Trigger } from "./Trigger";
import { colorPickerAnatomy } from "./anatomy";
import { splitColorPickerChildren } from "./utils";

export type ColorPickerRootProps = ArkColorPickerRootProps;
// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables -- this is used in the composition of the component below
function ColorPickerRoot({
  children,
  ...props
}: ColorPickerRootProps): JSX.Element {
  const classes = styles();
  const locales = useLocales();
  const composition = splitColorPickerChildren(children);
  return (
    <ArkColorPicker.Root {...props}>
      {!composition.trigger ? <Trigger /> : composition.trigger}
      <ArkColorPicker.Positioner className={classes.positioner}>
        <ArkColorPicker.Content className={classes.content}>
          <Controls />
          {composition.shortcuts && (
            <VStack alignItems="start">
              <Typo.Muted>{locales.colorPicker.savedSectionLabel}</Typo.Muted>
              <Wrap className={classes.quickAccess}>
                {composition.shortcuts}
              </Wrap>
            </VStack>
          )}
        </ArkColorPicker.Content>
      </ArkColorPicker.Positioner>
    </ArkColorPicker.Root>
  );
}

const styles = sva({
  slots: colorPickerAnatomy.keys(),
  base: {
    positioner: {
      zIndex: "10!",
    },
    content: vstack.raw({
      padding: "md",
      width: "264px",
      borderRadius: "md",
      backgroundColor: "interface.100",
    }),
    quickAccess: {
      width: "full",
      gap: "sm",
    },
  },
});

export const ColorPicker = {
  Root: ColorPickerRoot,
  Shortcut,
  AddShortcut,
  Trigger,
};
