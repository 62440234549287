import {
  useCallback,
  useState,
  useMemo,
  FormEventHandler,
  FocusEventHandler,
  JSX,
} from "react";

import { t, Trans } from "@lingui/macro";
import { DarkButton } from "@mobsuccess-devops/react-ui/Button";
import { Container } from "@mobsuccess-devops/react-ui/Container";
import { AdvancedInput } from "@mobsuccess-devops/react-ui/Form";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";

import styled, { css, useTheme } from "styled-components";

import { usePasswordValidation } from "../../../features/password";
import { AuthActionEnum, useAuth } from "../../../public/auth/auth";

import PasswordTooltip from "./PasswordTooltip";

const FormUI = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

const paswordLostCss = css`
  text-decoration: underline;
  cursor: pointer;
`;

type ResetPasswordProps = {
  onChangeAuthAction: (action: AuthActionEnum, state?: object) => void;
};

function ResetPassword({
  onChangeAuthAction,
}: ResetPasswordProps): JSX.Element {
  const { palette } = useTheme();
  const { resetPassword } = useAuth();

  const { code, email } = useMemo(() => {
    const search = new URLSearchParams(window.location.search);
    return {
      code: search.get("code"),
      email: search.get("email"),
    };
  }, []);

  const [hasSubmit, setHasSubmit] = useState(false);
  const [errors, onChangeForm] = usePasswordValidation();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      // get the values from the form event
      e.preventDefault();
      setHasSubmit(true);

      if (
        errors.confirm ||
        errors.password.mandatory.some((rule) => !rule.isValid)
      ) {
        return;
      }

      const password = e.currentTarget.password.value;

      await resetPassword({
        code: String(code),
        email: String(email),
        password,
      });
      onChangeAuthAction(AuthActionEnum.SignIn);
    },
    [
      code,
      email,
      errors.confirm,
      resetPassword,
      onChangeAuthAction,
      errors.password.mandatory,
    ],
  );

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handlePasswordFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      setIsPasswordFocused(e.type === "focus");
    },
    [],
  );

  const handleBackToLogin = useCallback(() => {
    onChangeAuthAction(AuthActionEnum.SignIn);
  }, [onChangeAuthAction]);

  const { captions, state } = useMemo(() => {
    const captions: Record<string, string | undefined> = {
      confirm: "",
      password: "",
    };
    const state: Record<string, "error" | undefined> = {
      confirm: undefined,
      password: undefined,
    };
    if (!hasSubmit) {
      return {
        captions,
        state,
      };
    }
    if (errors.confirm) {
      captions.confirm = t`#Auth.ResetPassword.password-validation-error.miss-match`;
      state.confirm = "error";
    }
    if (errors.password.mandatory.some((rule) => !rule.isValid)) {
      captions.password = t`#Auth.ResetPassword.password-validation-error.weak`;
      state.password = "error";
    }
    return {
      captions,
      state,
    };
  }, [errors.confirm, errors.password.mandatory, hasSubmit]);

  const [passwordInputElement, setPasswordInputElement] =
    useState<HTMLDivElement | null>(null);

  return (
    <FormUI id="auth-form" onSubmit={handleSubmit} onChange={onChangeForm}>
      <AdvancedInput
        type="password"
        name="password"
        variant="md"
        containerWidth="100%"
        state={state.password}
        caption={captions.password}
        autoComplete="new-password"
        onBlur={handlePasswordFocus}
        onFocus={handlePasswordFocus}
        containerRef={setPasswordInputElement}
        label={`${t`#Auth.ResetPassword.password.label`} *`}
        placeholder={t`#Auth.ResetPassword.password.placeholder`}
      />
      <PasswordTooltip
        errors={errors.password}
        show={isPasswordFocused}
        anchorElement={passwordInputElement}
      />
      <AdvancedInput
        variant="md"
        type="password"
        state={state.confirm}
        containerWidth="100%"
        name="confirm-password"
        caption={captions.confirm}
        autoComplete="new-password"
        label={`${t`#Auth.ResetPassword.confirm-password.label`} *`}
        placeholder={t`#Auth.ResetPassword.confirm-password.placeholder`}
      />
      <Container
        flexDirection="column"
        gap="12px"
        width="100%"
        alignItems="center"
      >
        <DarkButton
          fill
          size="lg"
          type="submit"
          formNoValidate
          form="auth-form"
        >
          <Trans>#Auth.ResetPassword.submit</Trans>
        </DarkButton>
        <Typography
          as="span"
          styled={paswordLostCss}
          color={palette.primary.base}
          onClick={handleBackToLogin}
        >
          <Trans>#Auth.ResetPassword.back</Trans>
        </Typography>
      </Container>
    </FormUI>
  );
}

export default ResetPassword;
