import { createContext, useContext } from "react";

import type { DatePickerContextValue } from "../../types/date-picker";
import { DatePickerSingleDayContextValue } from "../../types/date-picker-single-day";

export const DatePickerContext = createContext<DatePickerContextValue | null>(
  null,
);

export function useDatePickerContext(): DatePickerContextValue {
  const context = useContext(DatePickerContext);
  if (!context) {
    throw new Error(
      "useDatePickerContext must be used within a DatePickerContext",
    );
  }
  return context;
}

export const DatePickerSingleDayContext =
  createContext<DatePickerSingleDayContextValue | null>(null);

export function useDatePickerSingleDayContext(): DatePickerSingleDayContextValue {
  const context = useContext(DatePickerSingleDayContext);
  if (!context) {
    throw new Error(
      "useDatePickerSingleDayContext must be used within a DatePickerSingleDayContext",
    );
  }
  return context;
}
