import { AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from "axios";
import { v4 as uuidv4 } from "uuid";

import { ConcurrencyManager } from "../utils/ConcurrencyManager";

declare module "axios" {
  // this is an override for the AxiosRequestConfig interface
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface InternalAxiosRequestConfig {
    id?: string;
  }
}

const concurrencyManager = new ConcurrencyManager({
  maxConcurrent: 10,
});

export function request(
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> {
  config.id = config.id || uuidv4();
  return concurrencyManager.enqueue(config.id, config);
}

export function response(response: AxiosResponse): AxiosResponse {
  if (response.config.id) {
    concurrencyManager.dequeue(response.config.id);
  }
  return response;
}

export function error(error: unknown): Promise<never> {
  if (isAxiosError(error) && error.config?.id) {
    concurrencyManager.dequeue(error.config.id);
  }
  return Promise.reject(error);
}

export const concurrency = {
  request,
  response,
  error,
};
