import {
  memo,
  MouseEventHandler,
  ForwardedRef,
  useCallback,
  forwardRef,
  JSX,
} from "react";

import { ReactComponent as CheckIcon } from "@mobsuccess-devops/streamline/custom/react-ui/custom_checked-checkbox.svg";

import styled, { css } from "styled-components";

import { Container } from "../../Container";

type CheckboxUIProps = {
  $isDisabled: boolean;
  $isChecked: boolean;
  $isHalfChecked: boolean;
};

const disabledCss = css`
  background-color: ${({ theme }) => theme.palette.interface.lighter[300]};
  border: 1px solid ${({ theme }) => theme.palette.interface.lighter[300]};
  cursor: not-allowed;
  :hover {
    background-color: ${({ theme }) => theme.palette.interface.lighter[300]};
    border: 1px solid ${({ theme }) => theme.palette.interface.lighter[300]};
  }
`;

const disabledFullCss = css`
  background-color: ${({ theme }) => theme.palette.interface.lighter[600]};
  border: 1px solid ${({ theme }) => theme.palette.interface.lighter[300]};
  cursor: not-allowed;
  :hover {
    background-color: ${({ theme }) => theme.palette.interface.lighter[600]};
    border: 1px solid ${({ theme }) => theme.palette.interface.lighter[300]};
  }
`;

const CheckboxUI = styled(Container)<CheckboxUIProps>`
  border-radius: 4px;
  background-color: ${({ theme, $isChecked, $isHalfChecked }) =>
    $isChecked || $isHalfChecked
      ? theme.palette.primary.lighter[100]
      : theme.colors.white};
  border: 1px solid
    ${({ theme, $isChecked, $isHalfChecked }) =>
      $isChecked || $isHalfChecked
        ? theme.palette.primary.lighter[100]
        : theme.palette.interface.lighter[200]};
  width: 24px;
  height: 24px;
  transition: all 0.2s cubic-bezier(0.8, 1, 0.17, 1);
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, $isChecked, $isHalfChecked, $isDisabled }) =>
      ($isChecked || $isHalfChecked) && !$isDisabled
        ? theme.palette.primary.base
        : "transparent"};
    border-color: ${({ theme, $isChecked, $isHalfChecked, $isDisabled }) =>
      ($isChecked || $isHalfChecked) && !$isDisabled
        ? theme.palette.primary.base
        : theme.palette.interface.lighter[100]};
  }

  &:focus-within {
    box-shadow: 0px 0px 0px 4px
      ${({ theme }) => `${theme.palette.primary.base}4C`};
  }

  ${({ $isDisabled, $isChecked, $isHalfChecked }) =>
    $isDisabled &&
    ($isChecked || $isHalfChecked ? disabledCss : disabledFullCss)}
`;

CheckboxUI.displayName = "CheckboxUI";

const CheckMarkIconUI = styled(CheckIcon)`
  align-self: center;
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.colors.white} !important;
`;

CheckMarkIconUI.displayName = "CheckMarkIconUI";

const FakeCheckboxUI = styled.input`
  pointer-events: none;
  opacity: 0;
  position: absolute;
`;

FakeCheckboxUI.displayName = "FakeCheckboxUI";

const HalfCheckedUI = styled.div`
  margin: 0px 3px;
  height: 2px;
  width: 14px;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

HalfCheckedUI.displayName = "HalfCheckedUI";

const EmptySvgUI = styled.svg`
  margin: 0px 3px;
  height: 2px;
  width: 14px;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

EmptySvgUI.displayName = "EmptySvgUI";

export type CheckboxProps = {
  id?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  isHalfChecked?: boolean;
  onClick?: MouseEventHandler;
};

function defaultOnClick(): null {
  return null;
}

function Checkbox(
  {
    id,
    isDisabled = false,
    onClick = defaultOnClick,
    isChecked = false,
    isHalfChecked = false,
  }: CheckboxProps,
  ref?: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (isDisabled) {
        return;
      }
      onClick && onClick(event);
    },
    [onClick, isDisabled],
  );
  return (
    <CheckboxUI
      id={id}
      $isChecked={isChecked}
      $isDisabled={isDisabled}
      $isHalfChecked={isHalfChecked}
      onClick={handleClick}
      alignItems="center"
      justifyContent="center"
    >
      <FakeCheckboxUI type="checkbox" ref={ref} />
      <Container
        alignItems="center"
        justifyContent="center"
        width="24px"
        height="24px"
      >
        {isChecked ? (
          <CheckMarkIconUI />
        ) : isHalfChecked ? (
          <HalfCheckedUI />
        ) : (
          <EmptySvgUI />
        )}
      </Container>
    </CheckboxUI>
  );
}

const MemoizedCheckbox = memo(forwardRef(Checkbox));
export { MemoizedCheckbox as Checkbox };
