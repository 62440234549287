import { ForwardedRef, memo, forwardRef, JSX } from "react";

import styled, { css } from "styled-components";

const verticalCss = css`
  width: 1px;
  height: 100%;
`;

const horizontalCss = css`
  width: 100%;
  height: 1px;
`;

const SeparatorUI = styled.div<{
  $orientation: SeparatorProps["orientation"];
  $color: SeparatorProps["color"];
}>`
  flex: 0 0 1px;
  background-color: ${({ $color }) => $color};
  ${({ $orientation }) =>
    $orientation === "vertical" ? verticalCss : horizontalCss}
`;

SeparatorUI.displayName = "SeparatorUI";

export type SeparatorProps = {
  orientation?: "horizontal" | "vertical";
  color: string;
  id?: string;
  className?: string;
};

function Separator(
  { orientation = "horizontal", color, id, className }: SeparatorProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  return (
    <SeparatorUI
      id={id}
      className={className}
      $orientation={orientation}
      $color={color}
      ref={ref}
    />
  );
}

const MemoizedSeparator = memo(forwardRef(Separator));
export { MemoizedSeparator as Separator };
