import { useMemo, JSX } from "react";

import { t } from "@lingui/macro";
import { BlockLoader } from "@mobsuccess-devops/react-ui/BlockLoader";
import { Container } from "@mobsuccess-devops/react-ui/Container";

import { AuthActionEnum } from "../../../public/auth/auth";
import { BusinessUnits } from "../../../types/enums";
import AuthCard from "../AuthCard";
import ForgotPassword from "../ForgotPassword";
import Login, { LoginProps } from "../Login";
import ResetPassword from "../ResetPassword";
import Signout from "../Signout";

type AuthActionProps = LoginProps & {
  businessUnit: BusinessUnits;
  action: AuthActionEnum | null;
};

export default function AuthAction({
  action,
  isLoading,
  businessUnit,
  googleClientId,
  onChangeAuthAction,
}: AuthActionProps): JSX.Element | null {
  const { title, content } = useMemo(() => {
    if (isLoading) {
      return {
        title: t`#Auth.AuthAction.loading`,
        content: (
          <Container height="60px">
            <BlockLoader overlay="white" />
          </Container>
        ),
      };
    }

    switch (action) {
      case AuthActionEnum.ForgotPassword:
        return {
          title: t`#Auth.AuthAction.forgotPassword`,
          content: <ForgotPassword onChangeAuthAction={onChangeAuthAction} />,
        };
      case AuthActionEnum.ResetPassword:
        return {
          title: t`#Auth.AuthAction.resetPassword`,
          content: <ResetPassword onChangeAuthAction={onChangeAuthAction} />,
        };
      case AuthActionEnum.SignOut:
        return {
          title: t`#Auth.AuthAction.signOut`,
          content: <Signout onChangeAuthAction={onChangeAuthAction} />,
        };
      case AuthActionEnum.SignIn:
      default:
        return {
          title: t`#Auth.AuthAction.signIn`,
          content: (
            <Login
              isLoading={isLoading}
              googleClientId={googleClientId}
              onChangeAuthAction={onChangeAuthAction}
            />
          ),
        };
    }
  }, [action, googleClientId, isLoading, onChangeAuthAction]);

  return (
    <AuthCard title={title} businessUnit={businessUnit}>
      {content}
    </AuthCard>
  );
}
