import { memo, useMemo, JSX } from "react";

import { ThemeProvider as ThemeProviderSC } from "styled-components";

import { theme, palettes } from "../Theme/theme";

export type ThemeProviderProps = {
  client?: string;
  children: JSX.Element | JSX.Element[];
};

function ThemeProvider({ client, children }: ThemeProviderProps): JSX.Element {
  const themePalette = useMemo(
    () => ({
      ...theme,
      palette:
        client && client in palettes
          ? palettes[client]
          : palettes["mobsuccess"],
    }),
    [client],
  );
  return <ThemeProviderSC theme={themePalette}>{children}</ThemeProviderSC>;
}

const MemoizedComponent = memo(ThemeProvider);
export { MemoizedComponent as ThemeProvider };
