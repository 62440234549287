import { ReactElement, ReactNode, JSX } from "react";

import { ReactComponent as InfoIcon } from "@mobsuccess-devops/streamline/custom/lco/info.svg";
import { ReactComponent as HelpIcon } from "@mobsuccess-devops/streamline/custom/status-indicator/help.svg";
import { css } from "@mobsuccess-devops/styled-system/css";

import {
  Tooltip as BaseTooltip,
  TooltipProps as BaseTooltioProps,
} from "../../../_PandaArk/Tooltip/Tooltip";

const iconCss = css({
  size: "sm",
});

const icons = { help: HelpIcon, info: InfoIcon };

type TooltipProps = {
  icon: keyof typeof icons;
  children: ReactNode;
  variant?: BaseTooltioProps["variant"];
};

export function Tooltip({
  icon,
  variant,
  children,
}: TooltipProps): JSX.Element {
  const Icon = icons[icon];
  return (
    <BaseTooltip content={children} variant={variant}>
      <Icon className={iconCss} />
    </BaseTooltip>
  );
}

export type TooltipElement = ReactElement<TooltipProps, typeof Tooltip>;
