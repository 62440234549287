import { memo, ForwardedRef, ReactNode, forwardRef, JSX } from "react";

import { Container } from "../../Container";
import { Title as DropdownTitle } from "../Title/Title";

export type GroupProps = {
  id?: string;
  className?: string;
  label?: string;
  children: ReactNode | Array<ReactNode>;
};

function Group(
  { className, id, label, children }: GroupProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  return (
    <Container flexDirection="column" ref={ref} id={id} className={className}>
      {label && <DropdownTitle>{label}</DropdownTitle>}
      {children}
    </Container>
  );
}

const MemoizedGroup = memo(forwardRef(Group));
export { MemoizedGroup as Group };
