import type { JSX, ReactNode } from "react";

import { toastAnatomy } from "@ark-ui/anatomy";
import { Toast as ArkSnack } from "@ark-ui/react";
import { sva } from "@mobsuccess-devops/styled-system/css";
import type { Options } from "@zag-js/toast";

type SnackProps = Options<ReactNode>;

export function Snack({ title }: SnackProps): JSX.Element {
  const classes = styles();
  return (
    <ArkSnack.Root className={classes.root}>
      <ArkSnack.Title className={classes.title}>{title}</ArkSnack.Title>
    </ArkSnack.Root>
  );
}

const styles = sva({
  slots: toastAnatomy.keys(),
  base: {
    root: {
      listStyle: "none",
      boxShadow: "md",
      padding: "sm",
      borderRadius: "md",
      backgroundColor: "interface",
      borderColor: "white",
      animation: "slide-in 0.2s ease-in-out",
      _closed: {
        animation: "slide-out 0.2s ease-in-out",
      },
    },
    title: {
      whiteSpace: "nowrap",
      color: "white",
      textStyle: "body",
    },
  },
});
