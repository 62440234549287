import { createContext, useContext, useEffect, useMemo, useRef } from "react";

import {
  getEndpointServiceBranch,
  MSAxiosInstance,
} from "@mobsuccess-devops/rdc-react-client";
import { useQuery } from "@tanstack/react-query";

import { Config, ContextData, ContextValue } from "../../types/context";

export const Context = createContext(null);

function useServicesOverrides(
  config: Config,
  servicesOverrides: Record<string, string>,
): { overrides: Record<string, string>; isLoading: boolean } {
  const { data, isLoading } = useQuery({
    queryKey: ["services.overrides", servicesOverrides],
    queryFn: async () => {
      if (
        config.msEnvironment === "production" ||
        !Object.keys(servicesOverrides).length ||
        !config.services?.rdc
      ) {
        return null;
      }

      MSAxiosInstance.defaults.baseURL = config.services.rdc;
      if (config.authorization) {
        MSAxiosInstance.defaults.headers.common["Authorization"] =
          config.authorization;
      }
      const overrides = await Promise.all(
        Object.entries(servicesOverrides).map(async ([service, branch]) => {
          try {
            const res = await getEndpointServiceBranch(service, branch);
            if (res.body.status === "ready") {
              return {
                [service.replace(/-microservice$/, "")]: res.body.url,
              };
            }
            return {};
          } catch {
            return {};
          }
        }),
      );
      return overrides.reduce((acc, val) => ({ ...acc, ...val }), {});
    },
  });

  return {
    // @ts-expect-error TODO fix this error
    overrides: data,
    isLoading,
  };
}

export function useContextValue({
  config,
  data,
  servicesOverrides,
}: {
  config: Config;
  data: ContextData;
  servicesOverrides: Record<string, string>;
}): ContextValue {
  const previousContext = useDataContextContext({
    allowEmpty: true,
  });
  const authorizationRef = useRef(config.authorization);

  useEffect(() => {
    // this ensure that queries are not performed again when the authorization changes
    // new queries should be performed with the new authorization
    authorizationRef.current = config.authorization;
  }, [authorizationRef, config.authorization]);

  const isFirstConfigOrDataChangeRef = useRef(true);

  const { overrides, isLoading } = useServicesOverrides(
    config,
    servicesOverrides,
  );

  useEffect(() => {
    if (isFirstConfigOrDataChangeRef.current) {
      // the first useEffect() is called when we are initially mounted
      // we must not clear our workers, or else we might perform some
      // queries twice
      isFirstConfigOrDataChangeRef.current = false;
      return;
    }
  }, [data, config]);
  const oldConfigRef = useRef<Config>();
  const mountedRef = useRef<boolean>(true); // no longer update unmounted components
  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    [mountedRef],
  );
  useEffect(() => {
    oldConfigRef.current = config;
  }, [oldConfigRef, config]);
  const mergedConfig = useMemo(() => {
    if (isLoading) {
      return null;
    }
    const mergeParentConfig = (previousContext: ContextValue): Config =>
      !previousContext
        ? {}
        : {
            ...(previousContext.previousContext
              ? mergeParentConfig(previousContext.previousContext)
              : {}),
            ...previousContext.config,
          };
    const megedConfig = { ...mergeParentConfig(previousContext), ...config };
    return {
      ...megedConfig,
      services: {
        ...megedConfig.services,
        ...(overrides ?? {}),
      },
    };
  }, [isLoading, previousContext, config, overrides]);

  // @ts-expect-error TODO fix this error
  return useMemo(
    () =>
      mergedConfig
        ? {
            previousContext,
            authorizationRef,
            config: mergedConfig,
            data,
          }
        : null,
    [previousContext, mergedConfig, authorizationRef, data],
  );
}

export function useDataContextContext({
  allowEmpty,
}: { allowEmpty?: boolean } = {}): ContextValue {
  const context = useContext(Context);
  if (!context && !allowEmpty) {
    throw new Error(
      "useDataContextContext() must be used inside a DataContext",
    );
  }
  // @ts-expect-error TODO fix this error
  return context;
}

export function useServicesUrls(): {
  [serviceKey: string]: string | undefined;
} {
  const context = useDataContextContext();
  // @ts-expect-error TODO fix this error
  return context.config.services;
}
