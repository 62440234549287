import { memo, ForwardedRef, forwardRef, JSX } from "react";

import styled, { useTheme } from "styled-components";

import { Container } from "../../Container";
import { Typography } from "../../Typography";

type TitleUIProps = {
  $isCentered?: boolean;
};

const TitleUI = styled(Container)<TitleUIProps>`
  justify-content: ${({ $isCentered }) =>
    $isCentered ? "center" : "flex-start"};
  padding: ${({ $isCentered }) =>
    $isCentered ? "16px 0 4px 0" : "16px 0px 4px 16px"};
`;

TitleUI.displayName = "TitleUI";

export type TitleProps = {
  children: string;
  isCentered?: boolean;
  id?: string;
  className?: string;
};

function Title(
  { children, isCentered = false, className, id }: TitleProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const { palette } = useTheme();
  return (
    <TitleUI
      alignItems="center"
      ref={ref}
      id={id}
      className={className}
      $isCentered={isCentered}
    >
      <Typography
        variant="text-sm"
        as="p"
        transform="uppercase"
        color={palette.interface.lighter[200]}
      >
        {children}
      </Typography>
    </TitleUI>
  );
}

const MemoizedTitle = memo(forwardRef(Title));
export { MemoizedTitle as Title };
