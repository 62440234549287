import {
  ReactNode,
  JSX,
  isValidElement,
  FunctionComponentElement,
} from "react";

import { Table } from "@tanstack/react-table";

export type TableVirtualizerProps<T> = {
  /** @internal */
  _table?: Table<T>;
  height: number | string;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables, @mobsuccess-devops/mobsuccess/react
export function TableVirtualizer<T>(_: TableVirtualizerProps<T>): JSX.Element {
  throw new Error("TableVirtualizer must be a child of Table");
}

export type TableVirtualizerElement<T> = FunctionComponentElement<
  TableVirtualizerProps<T>
>;
export function isTableVirtualizerElement<T>(
  node: ReactNode,
): node is TableVirtualizerElement<T> {
  return isValidElement(node) && node.type === TableVirtualizer<T>;
}
