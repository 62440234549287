import {
  ForwardedRef,
  JSXElementConstructor,
  memo,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useMemo,
  useState,
  forwardRef,
  JSX,
} from "react";

import styled from "styled-components";

import { HIGHEST_ZINDEX } from "../../../features/constants/css-utils";
import {
  DatePickerContext,
  getPreviousAndNextMonth,
} from "../../../features/date-picker";
import type {
  CustomRangeType,
  DatePickerContextValue,
} from "../../../types/date-picker";
import { DarkButton } from "../../Button/DarkButton";
import { LightButton } from "../../Button/LightButton";
import { Container } from "../../Container";

import { CustomRanges } from "./CustomRanges/CustomRanges";
import { Header } from "./Header/Header";
import { Month } from "./Month/Month";
import { SelectedRange } from "./SelectedRange/SelectedRange";

const DatePickerWrapperUI = styled.div`
  width: fit-content;
  user-select: none;
  display: flex;
  flex-direction: column;
`;
DatePickerWrapperUI.displayName = "DatePickerWrapperUI";

const ButtonUI = styled.div`
  background-color: rgb(96 165 250);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: fit-content;
`;
ButtonUI.displayName = "ButtonUI";

type DropdownProps = {
  $actionBarPosition: "left" | "right";
};

const DropdownUI = styled(Container)<DropdownProps>`
  z-index: ${HIGHEST_ZINDEX};
  position: absolute;
  top: 60px;
  right: ${({ $actionBarPosition }) =>
    $actionBarPosition === "right" ? "0" : "auto"};
  left: ${({ $actionBarPosition }) =>
    $actionBarPosition === "left" ? "0" : "auto"};
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12);
`;
DropdownUI.displayName = "DropdownUI";

const ContentUI = styled.div`
  display: flex;
`;
ContentUI.displayName = "ContentUI";

type CalendarUIProps = {
  $contentLeft: boolean;
  $forceVisible: boolean;
  $removeSpacingLeft: boolean;
};

const CalendarUI = styled.div<CalendarUIProps>`
  ${({ $contentLeft, $removeSpacingLeft }) =>
    $contentLeft && !$removeSpacingLeft && "margin-left: 1rem;"}
  ${({ $contentLeft }) => $contentLeft && "border-left-width: 1px;"}
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-left-width: 1px;
  border-color: black;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${({ $forceVisible }) => !$forceVisible && "display: none;"}
  }
`;
CalendarUI.displayName = "CalendarUI";

const BottomUI = styled(Container)`
  border-top: 1px solid ${({ theme }) => theme.palette.interface.lighter[400]};
`;
BottomUI.displayName = "BottomUI";

export type DatePickerProps = {
  id?: string;
  className?: string;
  allowedDate: DatePickerContextValue["allowedDate"];
  locale?: string;
  children: ReactElement<
    { onClick: MouseEventHandler },
    JSXElementConstructor<unknown>
  >;
  customRanges?: Array<CustomRangeType>;
  value?: DatePickerContextValue["selectedRange"];
  onChanges?: DatePickerContextValue["setSelectedRange"];
  onApply: (range: DatePickerContextValue["selectedRange"]) => void;
  onCancel?: () => void;
  texts?: {
    apply?: string;
    cancel?: string;
    customRange?: string;
    defineDateRange?: string;
    predefinedRanges?: string;
  };
  actionBarPosition?: "left" | "right";
  isOpen: boolean;
};

const defaultCustomRanges: NonNullable<DatePickerProps["customRanges"]> = [];
const defaultTexts = {};

function DatePicker(
  {
    id,
    className,
    allowedDate,
    onApply,
    onCancel,
    value: selectedRangeValue,
    onChanges: setSelectedRange,
    children,
    isOpen,
    texts: {
      apply: applyText = "Appliquer",
      cancel: cancelText = "Annuler",
      ...texts
    } = defaultTexts,
    customRanges = defaultCustomRanges,
    actionBarPosition = "left",
  }: DatePickerProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const [pendingDate, setPendingDate] = useState<Date | undefined>(undefined);
  const [hoveredDate, setHoveredDate] = useState<Date | undefined>(undefined);
  const [customFormatRangeMobile, setCustomFormatRangeMobile] = useState(false);

  const [months, setMonths] = useState<DatePickerContextValue["months"]>(() => {
    const today = new Date();
    const currentMonth = {
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    const [, next] = getPreviousAndNextMonth(currentMonth);
    return [currentMonth, next];
  });

  const handleChangeFormatRangeMobile = useCallback(
    () => setCustomFormatRangeMobile(!customFormatRangeMobile),
    [customFormatRangeMobile],
  );

  const handleMonths = useCallback<DatePickerContextValue["setMonths"]>(
    (range) => setMonths(range),
    [],
  );

  const handlePendingDate = useCallback<
    DatePickerContextValue["setPendingDate"]
  >((date) => setPendingDate(date ?? undefined), []);

  const handleHoveredDate = useCallback<
    DatePickerContextValue["setHoveredDate"]
  >((date) => setHoveredDate(date ?? undefined), []);
  const value = useMemo<DatePickerContextValue>(() => {
    return {
      allowedDate,
      pendingDate: pendingDate ?? null,
      setPendingDate: handlePendingDate,
      hoveredDate: hoveredDate ?? null,
      setHoveredDate: handleHoveredDate,
      selectedRange: selectedRangeValue,
      months,
      setSelectedRange: setSelectedRange,
      setMonths: handleMonths,
    };
  }, [
    allowedDate,
    pendingDate,
    handlePendingDate,
    hoveredDate,
    handleHoveredDate,
    selectedRangeValue,
    months,
    setSelectedRange,
    handleMonths,
  ]);

  const handleClickApply = useCallback(() => {
    if (selectedRangeValue) {
      onApply(selectedRangeValue);
    }
  }, [onApply, selectedRangeValue]);

  const [start, end] = months;

  return (
    <DatePickerContext.Provider value={value}>
      <Container ref={ref}>
        {children}
        {isOpen && (
          <DatePickerWrapperUI id={id} className={className}>
            <DropdownUI
              $actionBarPosition={actionBarPosition || "left"}
              flexDirection="column"
            >
              <ContentUI>
                {!customFormatRangeMobile && customRanges.length > 0 && (
                  <CustomRanges
                    texts={texts}
                    ranges={customRanges}
                    onChangeFormatRangeMobile={handleChangeFormatRangeMobile}
                  />
                )}
                <CalendarUI
                  $removeSpacingLeft={customFormatRangeMobile}
                  $forceVisible={
                    customFormatRangeMobile || customRanges.length === 0
                  }
                  $contentLeft={customRanges.length > 0}
                >
                  <Header
                    formatRangeMobile={customFormatRangeMobile}
                    onChangeFormatRangeMobile={handleChangeFormatRangeMobile}
                  />
                  <Container>
                    <Month
                      key={`${start.month}-${start.year}`}
                      current={new Date()}
                      month={start}
                    />
                    <Month
                      key={`${end.month}-${end.year}`}
                      current={new Date()}
                      month={end}
                      possiblyInvisible
                    />
                  </Container>
                </CalendarUI>
              </ContentUI>
              <BottomUI
                justifyContent="flex-end"
                alignItems="center"
                gap="32px"
                padding="20px"
              >
                <SelectedRange />
                <Container gap="16px">
                  {onCancel ? (
                    <LightButton onClick={onCancel}>{cancelText}</LightButton>
                  ) : null}
                  <DarkButton
                    onClick={handleClickApply}
                    disabled={
                      !selectedRangeValue?.start && !selectedRangeValue?.end
                        ? false
                        : !selectedRangeValue?.start || !selectedRangeValue?.end
                    }
                  >
                    {applyText}
                  </DarkButton>
                </Container>
              </BottomUI>
            </DropdownUI>
          </DatePickerWrapperUI>
        )}
      </Container>
    </DatePickerContext.Provider>
  );
}

const MemoizedDatePicker = memo(forwardRef(DatePicker));
export { MemoizedDatePicker as DatePicker };
